import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Grid,
  Autocomplete,
} from '@material-ui/core';
import validateCIF from '../../../utils/validateCIF';
import * as i18n from '../../../utils/i18n';
import { getShops, getShop, updateShop, createShop, shopSelector } from '../../../features/ShopSlice';
import Loading from '../../Loading';
import { useTranslation } from 'react-i18next';

const ShopManageForm = (props) => {
  const {handleClose, mode, shopId} = props;
  const dispatch = useDispatch();
  const { shop } = useSelector(shopSelector);
  const [countryCode, setCountryCode] = useState('');
  const [county, setCounty] = useState('');
  const [counties, setCounties] = useState([]);
  const [cities, setCities] = useState([]);
  const [initialValues, setInitialValues] = useState({
    name: shop ? shop.name : '',
    address: shop ? shop.address : '',
    cif: shop ? shop.cif : '',
    country: shop ? shop.country : '',
    county: shop ? shop.county : '',
    city: shop ? shop.city : '',
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (mode === 'edit' && shopId) {
      dispatch(getShop(shopId));
    }
  }, [dispatch, mode, shopId]);

  useEffect(() => {
    if (mode === 'edit' && shop) {
      setInitialValues({
        name: shop.name,
        address: shop.address,
        cif: shop.cif,
        country: shop.country,
        county: shop.county,
        city: shop.city,
      });
      setCountryCode(shop.country);
      setCounty(shop.county);
    } else {
      setInitialValues({
        name: '',
        address: '',
        cif: '',
        country: '',
        county: '',
        city: '',
      });
    }
  }, [shop, mode]);

  useEffect(() => {
    setCounties([]);
    if (countryCode) {
      i18n.counties(countryCode).then(data => {
        if (data) {
          setCounties(data);
        }
      });
    } else if (shop?.country) {
      i18n.counties(shop.country).then(data => {
        if (data) {
          setCounties(data);
        }
      })
    }
  }, [countryCode, shop]);

  useEffect(() => {
    setCities([]);
    if (countryCode && county) {
      i18n.cities(countryCode, county).then(data => {
        if (data) {
          setCities(data);
        }
      })
    } else if (shop?.country && shop?.county) {
      i18n.cities(shop.country, shop.county).then(data => {
        if (data) {
          setCities(data);
        }
      })
    }
  }, [countryCode, county, shop]);

  const handleSubmit = (data, formikData) => {
    if (mode === 'add') {
      dispatch(createShop(data)).then((response) => {
        if (!response.error) {
          handleClose();
          dispatch(getShops());
        }
      });
    } else {
      dispatch(updateShop({endpoint: shop.id, data})).then((response) => {
        if (!response.error) {
          handleClose();
          dispatch(getShops());
        }
      });
    }
    formikData.setSubmitting(false);
  };

  Yup.addMethod(Yup.string, 'cif', function (errorMessage) {
    return this.test(`cif`, errorMessage, function (value) {
      const { path, createError } = this;
      return validateCIF(value) || createError({ path, message: errorMessage })
    });
  });

  if (mode === 'edit' && !shop) {
    return <Loading />;
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={
              Yup.object().shape({
                name: Yup.string().max(255).required(t('Name is required')),
                cif: Yup.string().required(t('CIF is required')).cif(t('CIF is invalid')),
                address: Yup.string().max(255).required(t('Address is required')),
                country: Yup.string().required(t('Country is required')),
                county: Yup.string().required(t('County is required')),
                city: Yup.string().required(t('City is required')),
              })
            }
            onSubmit={(data, formikData) => handleSubmit(data, formikData)}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
              dirty,
              isValid
            }) => (
              <Form id={mode}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label={t("Name")}
                      margin="dense"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="cif"
                      error={Boolean(touched.cif && errors.cif)}
                      fullWidth
                      helperText={touched.cif && errors.cif}
                      label={t("CIF")}
                      margin="dense"
                      name="cif"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.cif}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="address"
                      error={Boolean(touched.address && errors.address)}
                      fullWidth
                      helperText={touched.address && errors.address}
                      label={t("Address")}
                      margin="dense"
                      name="address"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.address}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                  {(mode === 'edit' && shop) ? (
                    <Autocomplete
                      sx={{mt: 1, mb: 0.5}}
                      freeSolo
                      id="country"
                      name="country"
                      options={i18n.countries}
                      value={i18n.getCountryByCode(values.country) ?? {code: '', label: ''}}
                      getOptionLabel={option => option.label}
                      onChange={(e, value) => {
                        setFieldValue('country', value !== null ? value.code : initialValues.country);
                        setCountryCode(value !== null ? value.code : initialValues.country);
                        setFieldValue('county', '');
                        setFieldValue('city', '');
                      }}
                      includeInputInList
                      onOpen={handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Country")}
                          name="country"
                          error={Boolean(touched.country && errors.country)}
                          helperText={touched.country && errors.country}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  ) : (
                    <Autocomplete
                      sx={{mt: 1, mb: 0.5}}
                      freeSolo
                      id="country"
                      name="country"
                      options={i18n.countries}
                      getOptionLabel={option => option.label}
                      onChange={(e, value) => {
                        setFieldValue('country', value !== null ? value.code : initialValues.country);
                        setCountryCode(value !== null ? value.code : initialValues.country);
                        setFieldValue('county', '');
                        setFieldValue('city', '');
                      }}
                      includeInputInList
                      onOpen={handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Country")}
                          name="country"
                          error={Boolean(touched.country && errors.country)}
                          helperText={touched.country && errors.country}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  )}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                  {(mode === 'edit' && shop) ? (
                    <Autocomplete
                      sx={{mt: 1, mb: 0.5}}
                      freeSolo
                      id="county"
                      name="county"
                      disabled={!values.country || !counties.length}
                      options={counties}
                      value={counties.length ? values.county : ''}
                      getOptionLabel={option => option}
                      onChange={(e, value) => {
                        setFieldValue('county', value !== null ? value : initialValues.county);
                        setCounty(value !== null ? String(value) : initialValues.county);
                        setFieldValue('city', '');
                      }}
                      includeInputInList
                      onOpen={handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("County")}
                          name="county"
                          error={Boolean(touched.county && errors.county)}
                          helperText={touched.county && errors.county}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  ): (
                    <Autocomplete
                      sx={{mt: 1, mb: 0.5}}
                      freeSolo
                      id="county"
                      name="county"
                      disabled={!values.country || !counties.length}
                      options={counties}
                      getOptionLabel={option => option}
                      onChange={(e, value) => {
                        setFieldValue('county', value !== null ? value : initialValues.county);
                        setCounty(value !== null ? String(value) : initialValues.county);
                        setFieldValue('city', '');
                      }}
                      includeInputInList
                      onOpen={handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("County")}
                          name="county"
                          error={Boolean(touched.county && errors.county)}
                          helperText={touched.county && errors.county}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  )}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                  {(mode === 'edit' && shop) ? (
                    <Autocomplete
                      sx={{mt: 1, mb: 0.5}}
                      freeSolo
                      id="city"
                      name="city"
                      disabled={!values.county || !cities.length}
                      options={cities}
                      value={values.city}
                      getOptionLabel={option => option}
                      onChange={(e, value) => setFieldValue('city', value !== null ? value : initialValues.city)}
                      includeInputInList
                      onOpen={handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("City")}
                          name="city"
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  ) : (
                    <Autocomplete
                      sx={{mt: 1, mb: 0.5}}
                      freeSolo
                      id="city"
                      name="city"
                      disabled={!values.county || !cities.length}
                      options={cities}
                      getOptionLabel={option => option}
                      onChange={(e, value) => setFieldValue('city', value !== null ? value : initialValues.city)}
                      includeInputInList
                      onOpen={handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("City")}
                          name="city"
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  )}
                  </Grid>
                </Grid>
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {t('Save')}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
}

export default ShopManageForm;
