import { Ability, AbilityBuilder } from "@casl/ability";
import store from './store';

const ability = new Ability();

const Can = (action, subject) => {
  return ability.can(action, subject);
};

store.subscribe(() => {
  const user = store.getState().user;
  if (user.user) {
    ability.update(defineRulesFor(user.user));
  }
});

const defineRulesFor = (user) => {
  const { can, rules } = new AbilityBuilder();

  if(!user.roles) {
    return rules;
  }

  if (user.roles.includes('ROLE_ADMIN')) {
    can('view', 'all');
    can('manage', 'all');
  }

  if (user.roles.includes('ROLE_SHOP')) {
    can('view', 'shop');
    can('manage', 'shop');
    can('view', 'ownUser');
    can('manage', 'ownUser');
    can('view', 'users');
    can('manage', 'users');
    can('manage', 'customers');
    can('manage', 'products');
    can('view', 'reports');
    can('manage', 'inventories');
    can('manage', 'inventoryProducts');
    can('manage', 'recipes');
    can('manage', 'recipeProducts');
  }

  if (user.roles.includes('ROLE_MANAGER')) {
    can('view', 'shop');
    can('view', 'ownUser');
    can('manage', 'ownUser');
    can('view', 'users');
    can('manage', 'customers');
    can('manage', 'products');
    can('view', 'reports');
  }

  return rules;
};

export default Can;
