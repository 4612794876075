import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Box, Container, Typography } from '@material-ui/core';
import { GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';
import { toast } from 'react-toastify';
import moment from 'moment';
import RecipeProductListResults from '../components/recipe/product/RecipeProductListResults';
import RecipeProductListToolbar, { StyledTitle } from '../components/recipe/product/RecipeProductListToolbar';
import { getRecipe, updateRecipeProduct, softDeleteRecipeProduct, deleteRecipeProduct, shopSelector, shopClearState } from '../features/ShopSlice';
import Loading from '../../src/components/Loading';
import Can from '../../src/can';
import escapeRegExp from '../../src/utils/escapeRegExp';
import { useTranslation } from 'react-i18next';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useParams } from 'react-router';
import CustomizedDialogs from '../components/Dialog';

const RecipeProductList = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { recipe, isError, errorMessage, isFetching } = useSelector(shopSelector);
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [mode, setMode] = useState('add');
  const [recipeProductId, setRecipeProductId] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const { recipeId } = useParams();
  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);
  const columns = [
    { field: 'product', headerName: t('Product'), flex: 1 },
    { field: 'unit', headerName: t('Unit'), flex: 1 },
    { field: 'qty', headerName: t('Quantity'), flex: 1 },
    { field: 'created', headerName: t('Created'), flex: 1 },
    { field: 'updated', headerName: t('Updated'), flex: 1 },
    { field: 'active', headerName: t('Active'), flex: 1, type: 'boolean' },
    { field: 'actions',
      type: 'actions',
      headerName: t('Actions'),
      cellClassName: 'actions',
      getActions: ({id, row}) => {
        let actions = [];

        if (Can('manage', 'recipeProducts')) {
          actions.push(
            <GridActionsCellItem
            icon={<EditIcon />}
            label={t("Edit")}
            onClick={() => handleOpen('edit', row.id)}
            color="warning"
            aria-label="edit"
            />
          )
          if (row.active) {
            actions.push(
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Soft Delete"
                onClick={handleSoftDeleteClick(id)}
                color="warning"
                aria-label="soft-delete"
              />
            );
          } else {
            actions.push(
              <GridActionsCellItem
                icon={<RestoreIcon />}
                label="Restore"
                onClick={handleRestore({id: id, field: 'active', value: true})}
                color="success"
                aria-label="restore"
              />
            );
          }
          actions.push(
            <GridActionsCellItem
              icon={<DeleteForeverIcon />}
              label="Delete"
              onClick={() => {
                if (recipe.recipeProducts.length === 1) {
                  openDeleteConfirmationDialog();
                } else {
                  handleConfirmOpen(row.id, row.product);
                }
              }}
              color="error" 
              aria-label="delete"
            />
          );
        }

        return actions;
      },
    }
  ];

  useEffect(() => {
    dispatch(getRecipe(recipeId));
  }, [dispatch, recipeId]);

  useEffect(() => {
    if (isError) {
      toast.error(t(errorMessage), {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
    return () => {
      dispatch(shopClearState());
    }
  }, [isError, errorMessage, dispatch, t]);

  useEffect(() => {
    if (recipe && Array.isArray(recipe.recipeProducts)) {
      setRows(mapRecipeProductsToRows(recipe.recipeProducts));
    }
  }, [recipe]);

  const mapRecipeProductsToRows = (recipeProducts) => {
    return recipeProducts.map((recipeProduct, index) => {
      return {
        id: recipeProduct.id,
        recipeProductId: recipeProduct['@id'],
        product: recipeProduct.product.name,
        unit: recipeProduct.unit.name,
        qty: recipeProduct.qty,
        created: moment(recipeProduct.created).format("YYYY-MM-DD"),
        updated: moment(recipeProduct.updated).format("YYYY-MM-DD"),
        active: recipeProduct.active,
      }
    })
  }

  const requestSearch = (searchValue) => {
    if (recipe && recipe.recipeProducts) {
      setSearchText(searchValue);
      const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
      const filteredRows = mapRecipeProductsToRows(recipe.recipeProducts).filter((row) => {
        return Object.keys(row).some((field) => {
          if (field === 'recipeProductId' || !row[field]) {
            return false;
          }
          return searchRegex.test(row[field].toString());
        });
      });
      setRows(filteredRows);
    }
  };

  const handleOpen = (mode, id = null) => {
    setOpen(true);
    setMode(mode);
    setRecipeProductId(id);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
    dispatch(getRecipe(recipeId));
  };

  const openAddDialog = () => {
    handleOpen('add');
  }

  const handleConfirmOpen = (id, product, edit = false) => {
    if (edit) {
      console.log('edit');
    } else {
      setConfirmOpen(true);
      setRecipeProductId(id);
      setConfirmMessage(t('Are you sure you want to delete recipe product: ') + product + '?');
    }
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleSoftDeleteClick = (recipeProductId) => (event) => {
    event.stopPropagation();
    dispatch(softDeleteRecipeProduct(recipeProductId)).then(response => {
      if (!response.error) {
        toast.success(t('Recipe product disabled successfully'), {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        dispatch(getRecipe(recipeId));
        dispatch(shopClearState());
      } else {
        toast.error(t(response.payload), {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        dispatch(shopClearState());
      }
    });
  };

  const handleRestore = (params) => (event) => {
    event.stopPropagation();

    dispatch(updateRecipeProduct({
      id: params.id,
      [params.field]: params.value,
    })).then(response => {
      if (!response.error) {
        toast.success(t('Recipe product restored successfully'), {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        dispatch(getRecipe(recipeId));
        setRows((prev) => prev.map((row) => (
          row.id === params.id ?
          { ...row,
            ...response.payload,
            product: response.payload.product.name,
            unit: response.payload.unit.name,
            created: moment(response.payload.created).format('YYYY-MM-DD'),
            updated: moment(response.payload.updated).format("YYYY-MM-DD"),
          }
          : row
        )));
        dispatch(shopClearState());
      } else {
        toast.error(t(response.payload), {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        setRows((prev) => [...prev]);
        dispatch(shopClearState());
      }
    });
  }

  const openDeleteConfirmationDialog = () => {
    setDeleteConfirmationDialogOpen(true);
  };
  
  const closeDeleteConfirmationDialog = () => {
    setDeleteConfirmationDialogOpen(false);
  };  

  const handleConfirmAction = () => {
    if (recipeProductId) {
      dispatch(deleteRecipeProduct(recipeProductId)).then(response => {
        if (!response.error) {
          toast.success(t('Recipe product removed successfully'), {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          handleConfirmClose();
          dispatch(getRecipe(recipeId));
          dispatch(shopClearState());
        } else {
          toast.error(t(response.payload), {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          dispatch(shopClearState());
        }
      });
    }
  }

  if (isFetching && !recipe) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>{t("Recipe Products")}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>

          <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}
          >
            <StyledTitle>{recipe ? recipe.name : t("Recipe Products")}</StyledTitle>
            
            <RecipeProductListToolbar />
          </Box>
          
          <RecipeProductListResults
            rows={rows}
            columns={columns}
            searchText={searchText}
            requestSearch={requestSearch}
            openAddDialog={openAddDialog}
            mode={mode}
            open={open}
            handleClose={handleClose}
            recipeProductId={recipeProductId}
            confirmOpen={confirmOpen}
            handleConfirmClose={handleConfirmClose}
            confirmMessage={confirmMessage}
            handleConfirmAction={handleConfirmAction}
          />

          <CustomizedDialogs
            open={deleteConfirmationDialogOpen}
            handleClose={closeDeleteConfirmationDialog}
            title="Cannot delete row."
            maxWidth="xs"
            fullWidth
          >
            <Typography color="error">You must have at least one product.</Typography>
          </CustomizedDialogs>

        </Container>
      </Box>
    </>
  )
}

export default RecipeProductList;
