import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Button,
  Box
} from '@material-ui/core';
import { DataGrid, GridActionsCellItem, GridToolbarContainer } from '@mui/x-data-grid';
import Stack from '@mui/material/Stack';
import { getStands, updateStand, softDeleteStand, deleteStand, adminSelector, adminClearState } from '../../../features/AdminSlice';
import moment from 'moment';
import { toast } from 'react-toastify';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RestoreIcon from '@mui/icons-material/Restore';
import EditIcon from '@mui/icons-material/Edit';
import StandManageForm from './StandManageForm';
import Dialog from '../../Dialog';
import Confirm from 'src/components/Confirm';
import { useTranslation } from 'react-i18next';

const Stands = (props) => {
    const { t } = useTranslation();
    const { stands, areas } = useSelector(adminSelector);
    const dispatch = useDispatch();
    const [rows, setRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [standId, setStandId] = useState(null);
    const [mode, setMode] = useState('add');
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmMessage, setConfirmMessage] = useState('');
    const columns = [
      { field: 'name', headerName: t('Name'), flex: 1, editable: true },
      { field: 'seats', headerName: t('Seats'), flex: 1, editable: true },
      { field: 'area', headerName: t('Area'), flex: 1, editable: true },
      { field: 'created', headerName: t('Created'), flex: 1, editable: false },
      { field: 'updated', headerName: t('Updated'), flex: 1, editable: false },
      { field: 'active', headerName: t('Active'), flex: 1, editable: false, type: 'boolean' },
      {
        field: 'actions',
        type: 'actions',
        headerName: t('Actions'),
        width: 100,
        cellClassName: 'actions',
        getActions: ({id, row}) => {
          let actions = [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={() => handleOpen('edit', id)}
              color="warning"
              aria-label="edit"
            />
          ];
          if (row.active) {
            actions.push(
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Soft Delete"
                onClick={handleSoftDeleteClick(id)}
                color="warning"
                aria-label="soft-delete"
              />
            );
          } else {
            actions.push(
              <GridActionsCellItem
                icon={<RestoreIcon />}
                label="Restore"
                onClick={handleEdit({id: id, field: 'active', value: true})}
                color="success"
                aria-label="restore"
              />
            );
          }
          actions.push(
            <GridActionsCellItem
              icon={<DeleteForeverIcon />}
              label="Delete"
              onClick={() => handleConfirmOpen(row.id, row.name)}
              color="error" 
              aria-label="delete"
            />
          );

          return actions;
        },
      },
    ];
      
    useEffect(() => {
      dispatch(getStands());
      return () => dispatch(adminClearState());
    }, [dispatch]);
 
    useEffect(() => {
      const mappedRows = stands.map(stand => {
        return {
          id: stand.id,
          name: t(stand.name),
          seats: t(stand.seats),
          area: t(stand.area.name),
          created: moment(stand.created.replace('+00:00', '')).format('YYYY-MM-DD HH:mm:ss'),
          updated: moment(stand.updated.replace('+00:00', '')).format('YYYY-MM-DD HH:mm:ss'),
          active: stand.active,
        }
      });
      setRows(mappedRows);
      return () => dispatch(adminClearState());
    }, [stands, dispatch, t]);

    const handleOpen = (mode, id = null) => {
      setOpen(true);
      setMode(mode);
      setStandId(id);
    };

    const handleClose = (event, reason) => {
      if (reason !== 'backdropClick') {
        setOpen(false);
      }
      dispatch(getStands());
    };

    const handleConfirmOpen = (id, name, edit = false) => {
      if (edit) {
        console.log('edit');
      } else {
        setConfirmOpen(true);
        setStandId(id);
        setConfirmMessage(t('Are you sure you want to delete stand: ') + name + '?');
      }
    };
  
    const handleConfirmClose = () => {
      setConfirmOpen(false);
    };
  
    const handleEdit = (params) => (event) => {
      event.stopPropagation();
      dispatch(updateStand({
        id: params.id,
        [params.field]: params.value,
      })).then(response => {
        if (!response.error) {
          toast.success(t('Data updated successfully'), {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          setRows((prev) => prev.map((row) => (
            row.id === params.id ?
            { ...row,
              ...response.payload,
              area: response.payload.area.name,
              created: moment(response.payload.created.replace('+00:00', '')).format('YYYY-MM-DD HH:mm:ss'),
              updated: moment(response.payload.updated.replace('+00:00', '')).format('YYYY-MM-DD HH:mm:ss'),
            }
            : row
          )));
          dispatch(adminClearState());
        } else {
          toast.error(t(response.payload), {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          setRows((prev) => [...prev]);
          dispatch(adminClearState());
        }
      });
    }
  
    const handleSoftDeleteClick = (id) => (event) => {
      event.stopPropagation();
      dispatch(softDeleteStand(id)).then(response => {
        if (!response.error) {
          toast.success(t('Data disabled successfully'), {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          dispatch(getStands());
          dispatch(adminClearState());
        } else {
          toast.error(t(response.payload), {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          dispatch(adminClearState());
        }
      });
    };

    const handleConfirmAction = () => {
      if (standId) {
        dispatch(deleteStand(standId)).then(response => {
          if (!response.error) {
            toast.success(t('Data removed successfully'), {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
            });
            handleConfirmClose();
            dispatch(getStands());
            dispatch(adminClearState());
          } else {
            toast.error(t(response.payload), {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
            });
            dispatch(adminClearState());
          }
        });
      }
    }
  
    return (
      <Box sx={{ pt: 3 }}>
        <Card>
          <CardHeader
            title={t("Stands")}
          />
          <Divider />
          <CardContent>
            <DataGrid
              componentsProps={{
                pagination: {
                  labelRowsPerPage: t('Rows per page')
                }
              }}
              components={{
                NoRowsOverlay: () => (
                  <Stack alignItems="center" justifyContent="center" sx={{pt: 15, fontSize: 16, fontWeight: 'bold'}}>
                    {t("No data found")}
                  </Stack>
                ),
                Toolbar: () => (
                  <GridToolbarContainer sx={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}>
                    <Button color="primary" startIcon={<AddIcon />} onClick={() => handleOpen('add')}>
                      {t("Add stand")}
                    </Button>
                  </GridToolbarContainer>
                )
              }}
              rows={rows}
              columns={columns}
              autoHeight
              disableColumnFilter
              disableColumnMenu
              disableSelectionOnClick
              density="comfortable"
              onCellEditCommit={handleEdit}
            />
            <Dialog
              title={mode === 'add' ? t("Add stand") : t("Edit stand")}
              open={open}
              handleClose={handleClose}
            >
              <StandManageForm handleClose={handleClose} mode={mode} standId={standId} areas={areas} />
            </Dialog>
            <Confirm
              open={confirmOpen}
              handleClose={handleConfirmClose}
              message={confirmMessage}
              handleConfirmAction={handleConfirmAction}
            />
          </CardContent>
        </Card>
      </Box>
    );
  };
  
  export default Stands;