import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    Button
  } from '@material-ui/core';
  import { shopSelector } from '../../../features/ShopSlice';
  import { blue } from '@material-ui/core/colors';
  import { ShoppingBag as ShoppingBagIcon } from 'react-feather';
  import { Link } from 'react-router-dom';
  import { useTranslation } from 'react-i18next';
  import { useSelector } from 'react-redux';
  import Loading from 'src/components/Loading';
  
  const ProductsCard = ({ productCategories, shop }) => {
    const { t } = useTranslation();
    const { isFetching } = useSelector(shopSelector);
    
    const calculateTotalProducts = (productCategories) => {
      let total = 0;
      if (!Array.isArray(productCategories)) return total; 

      productCategories.forEach(parentCategory => {
        if (!Array.isArray(parentCategory.productCategories)) return; 

        parentCategory.productCategories.forEach(childCategory => {
          if (Array.isArray(childCategory.products)) { 
            total += childCategory.products.length;
          }
        });
      });
      return total;
    };
    const totalProducts = calculateTotalProducts(productCategories || []);

    const currentShopID = shop ? shop['@id'] : null;
    const filteredCategories = (productCategories && currentShopID) ? productCategories.filter(
      category => {
        const categoryShopID = category.shop;
        return !category.parent 
                && (category.name === 'Bar' || category.name === 'Restaurant') 
                && categoryShopID === currentShopID;
      }
    ) : [];

    if (isFetching && !productCategories && !shop && !totalProducts ) {
      return <Loading />;
    }

    return (
      <Card sx={{ height: '100%' }} >
        <CardContent>
          <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
            <Grid item>
              <Typography 
                color="textSecondary" 
                gutterBottom 
                variant="h4"
              >
                PRODUCTS
              </Typography>

              <Typography 
                color="textPrimary" 
                variant="h5" 
                sx={{ mt: 2 }}
              >
                Total: {totalProducts}
              </Typography>
            </Grid>
            <Grid item>
              <Avatar
                sx={{
                  backgroundColor: blue[600],
                  height: 56,
                  width: 56
                }}
              >
                <ShoppingBagIcon />
              </Avatar>
            </Grid>
          </Grid>
          <Box
            sx={{
              pt: 2,
              display: 'flex',

              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            {(() => {    

              if (!Array.isArray(productCategories)) {
                return <Typography variant="h6">Error loading categories</Typography>;
              }
              
              return filteredCategories.map(category => (
                <Button 
                  key={category.id} 
                  component={Link} 
                  to={`/app/admin/shops/${shop.id}/products/${category.id}`}
                  variant="contained" 
                  color="primary" 
                  size="small" 
                  sx={{ textDecoration: 'none', '&:hover': { backgroundColor: 'someColor', } }} 
                >
                  {t(category.name)}
                </Button>
              ));
              
            })()}
          </Box>
        </CardContent>
      </Card>
    );
  };
  
  export default ProductsCard;
  