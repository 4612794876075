import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import ReactCountryFlag from "react-country-flag"

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    localStorage.setItem('lng', lng);
    i18n.changeLanguage(lng);
  };

  return (
    <Box className="language-selector">
      <Button onClick={() => changeLanguage('en')}>
        <ReactCountryFlag countryCode="US" svg style={{
          fontSize: '1.5em',
          lineHeight: '1.5em',
        }}/>
      </Button>
      <Button onClick={() => changeLanguage('ro')}>
        <ReactCountryFlag countryCode="RO" svg style={{
          fontSize: '1.5em',
          lineHeight: '1.5em',
        }}/>
      </Button>
    </Box>
  )
};

export default LanguageSwitcher;