import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../api/index.js';

export const getOrder = createAsyncThunk(
  'order/getOrder',
  async (data, thunkAPI) => {
    try {
      return api
        .getOrder(data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const customCreateOrder = createAsyncThunk(
  'order/customCreateOrder',
  async (dataToSend, thunkAPI) => {
    try {
      const response = await api.customCreateOrder(dataToSend);
      if (response.status === 201) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue('An unexpected error occurred');
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(
        e.response.data.violations
          ? e.response.data.violations[0].message
          : e.response.data.message
          ? e.response.data.message
          : e.response.data.detail
      );
    }
  }
);

export const updateOrder = createAsyncThunk(
  'order/updateOrder',
  async (data, thunkAPI) => {
    try {
      const id = data.id;
      delete data.id;
      return api
        .updateOrder(id, data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const softDeleteOrder = createAsyncThunk(
  'order/softDeleteOrder',
  async (data, thunkAPI) => {
    try {
      return api
        .softDeleteOrder(data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const deleteOrder = createAsyncThunk(
  'admin/deleteOrder',
  async (data, thunkAPI) => {
    try {
      return api
        .deleteOrder(data)
        .then((response) => {
          if (response.status === 204) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getParentProductCategories = createAsyncThunk(
  'order/getParentProductCategories',
  async (data, thunkAPI) => {
    try {
      return api
        .getParentProductCategories()
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getProductsByParentId = createAsyncThunk(
  'order/getProductsByParentId',
  async (parentId, thunkAPI) => {
    try {
      return api
        .getProductsByParentId(parentId)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const orderSlice = createSlice({
  name: 'order',
  initialState: {
    id: '',
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    orderTypes: [],
    order: null,
    orders: [],
    cartItems: [],
    parentProductCategories: [],
    hasChanges: false,
  },
  reducers: {
    cartClearState: (state) => {
      state.cartItems = [];
      state.hasChanges = false;
    },

    addExistingOrderToCart: (state, action) => {
      return { ...state, cartItems: action.payload };
    },

    addToCart: (state, action) => {
      const { id } = action.payload;
      if (!id) {
        return state;
      }

      if (state.order) {
        state.order.orderProducts = state.order.orderProducts.map((item) => {
          if (action.payload.product && item.id === id) {
            return { ...item, qty: item.qty + 1 };
          } else if (!action.payload.product && item.product.id === id) {
            return { ...item, qty: item.qty + 1 };
          } else {
            return item;
          }
        });
      }

      const newItem = {
        ...action.payload,
        qty: 1,
        visible: true,
        index: state.cartItems.length
      };

      // //old commented code start-------------
      // if (
      //   action.payload.product &&
      //   !state.order.orderProducts.find((item) => item.id === id)
      // ) {
      //   updatedCartItems.push({ ...action.payload, qty: 1 });
      // } else if (
      //   !action.payload.product &&
      //   !state.order.orderProducts.find((item) => item.product.id === id)
      // ) {
      //   updatedCartItems.push({ ...action.payload, qty: 1 });
      // } else if (!state.cartItems.find((item) => item.id === id)) {
      //   updatedCartItems.push({ ...action.payload, qty: 1 });
      // }
      // //old commented code end-------------

      state.cartItems.push(newItem);
      state.hasChanges = true;
    },

    removeFromCart: (state, action) => {

      const { index } = action.payload;
      if (index === undefined || index === null) {
        return state;
      }
    
      if (state.order && state.order.orderProducts) {
        state.order.orderProducts = state.order.orderProducts.filter(item => item.index !== index);
      }

      state.cartItems = state.cartItems.filter(item => item.index !== index);

      // //old commented code start-------------
      // const updatedCartItems = state.cartItems.map((item) => {
      //   if (item.id === id && item.qty > 1) {
      //     return { ...item, qty: item.qty - 1 };
      //   } else {
      //     return item;
      //   }
      // });
      // const index = updatedCartItems.map((item) => item.id).indexOf(id);

      // if (state.cartItems.find((item) => item.id === id && item.qty === 1)) {
      //   updatedCartItems.splice(index, 1);
      // }

      // state.cartItems = updatedCartItems;
      // //old commented code end-------------

      state.hasChanges = true;
    },

    updateProductComments: (state, action) => {
      const { index, comments } = action.payload;
      const product = state.cartItems.find(item => item.index === index);
      if (product) {
        product.comments = comments;
        state.hasChanges = true;
      }
    }

  },
  extraReducers: (builder) => {
    builder
      .addCase(getParentProductCategories.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.parentProductCategories = payload['hydra:member'];
        return state;
      })
      .addCase(getParentProductCategories.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getParentProductCategories.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getProductsByParentId.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.products = payload['hydra:member'];
        return state;
      })
      .addCase(getProductsByParentId.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getProductsByParentId.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(customCreateOrder.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.orderProducts = payload['hydra:member'];
        return state;
      })
      .addCase(customCreateOrder.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(customCreateOrder.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getOrder.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        const orderProducts = payload.orderProducts.map((item) => {
          if (
            state.cartItems.find((cartItem) => cartItem.id === item.product.id)
          ) {
            item.qty += state.cartItems.find(
              (cartItem) => cartItem.id === item.product.id
            ).qty;
          }
          return item;
        });
        state.order = { ...payload, orderProducts: orderProducts };
        return state;
      })
      .addCase(getOrder.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getOrder.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(updateOrder.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(updateOrder.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(updateOrder.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(softDeleteOrder.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(softDeleteOrder.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(softDeleteOrder.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(deleteOrder.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(deleteOrder.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(deleteOrder.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      });
  },
});

export const {
  cartClearState,
  addToCart,
  removeFromCart,
  addExistingOrderToCart,
  updateProductComments
} = orderSlice.actions;
export const orderSelector = (state) => state.order;
