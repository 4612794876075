import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Grid,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { getInventoryProduct, updateInventoryProduct, shopSelector } from '../../../features/ShopSlice';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const InventoryProductManageForm = (props) => {
  const { t } = useTranslation();
  const { handleClose, mode, inventoryProductId } = props;
  const { inventoryProduct, inventory} = useSelector(shopSelector);
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({
    product: '', 
    unit: '', 
    vat: '',
    price: 0, 
    vatPrice: 0, 
    documentQuantity: '', 
    qty: '', 
    vatIncluded: true, 
    active: true 
  });
  const validationSchema = {
    price: Yup.number().typeError(t('You must specify a number')).required(t('Price is required')),
    documentQuantity: Yup.number().typeError(t('You must specify a number')).required(t('Quantity is required')),
    qty: Yup.number().typeError(t('You must specify a number')).required(t('Quantity is required')),
  };

  useEffect(() => {
    if (mode === 'edit' && inventoryProductId) {
      dispatch(getInventoryProduct(inventoryProductId));
    }
  }, [dispatch, mode, inventoryProductId]);

  useEffect(() => {
    if (mode === 'edit' && inventoryProduct) {
      setInitialValues({
        product: inventoryProduct.product.name || '', 
        unit: inventoryProduct.unit.name || '', 
        vat: inventoryProduct.vat,
        price: inventoryProduct.price || 0, 
        vatPrice: inventoryProduct.vatPrice || 0, 
        documentQuantity: inventoryProduct.documentQuantity || '', 
        qty: inventoryProduct.qty || '', 
        vatIncluded: inventoryProduct.vatIncluded, 
        active: inventoryProduct.active, 
      });
    }
  }, [inventoryProduct, mode]);

  const handleSubmit = (data, formikData) => {
    if (mode === 'add') {
      console.log("add");
    } else {

      data.id = inventoryProduct.id;
      data.product = inventoryProduct.product['@id'];
      data.unit = inventoryProduct.unit['@id'];
      data.vat = inventoryProduct.vat['@id'];
      data.inventory = inventoryProduct.inventory;
      data.price = data.price.toString();
      data.vatPrice = data.vatPrice.toString();
      data.documentQuantity = parseInt(data.documentQuantity, 10);
      data.qty = parseInt(data.qty, 10);

      dispatch(updateInventoryProduct(data)).then((response) => {
        if (!response.error) {
          toast.success(t('Data updated successfully'), {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          handleClose();
        } else {
          toast.error(t(response.payload), {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
        }
      });
    }
    formikData.setSubmitting(false);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={Yup.object().shape(validationSchema)}
            onSubmit={(data, formikData) => handleSubmit(data, formikData)}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue
            }) => (
              <Form id="add-inventory-product">
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.product && errors.product)}
                      fullWidth
                      helperText={touched.product && errors.product}
                      label={t("Product")}
                      margin="dense"
                      name="product"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.product}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.unit && errors.unit)}
                      fullWidth
                      helperText={touched.unit && errors.unit}
                      label={t("Unit")}
                      margin="dense"
                      name="unit"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.unit}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                {inventory.documentType.name !== 'Inventory' && (
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(touched.vat && errors.vat)}
                        fullWidth
                        helperText={touched.vat && errors.vat}
                        label={t("Vat")}
                        margin="dense"
                        name="vat"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.vat.name || ''}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                )}

                {inventory.documentType.name !== 'Inventory' && (
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(touched.price && errors.price)}
                        fullWidth
                        helperText={touched.price && errors.price}
                        label={t("Price")}
                        margin="dense"
                        name="price"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          const vatValue = values.vat ? values.vat.value : 0;
                          const inputValue = parseFloat(e.target.value);
                          if (!isNaN(inputValue)) {
                            const calculatedVatPrice = (inputValue * vatValue) / 100;
                            setFieldValue("vatPrice", calculatedVatPrice.toFixed(2)); 
                          } 
                        }}
                        value={values.price}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                )}

                
                {inventory.documentType.name !== 'Inventory' && (
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(touched.vatPrice && errors.vatPrice)}
                        fullWidth
                        helperText={touched.vatPrice && errors.vatPrice}
                        label={t("VAT Price")}
                        margin="dense"
                        name="vatPrice"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.vatPrice}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                )}

                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.documentQuantity && errors.documentQuantity)}
                      fullWidth
                      helperText={touched.documentQuantity && errors.documentQuantity}
                      label={t("Document Quantity")}
                      margin="dense"
                      name="documentQuantity"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        const { value } = e.target;
                        setFieldValue(`qty`, value);
                      }}
                      value={values.documentQuantity}
                      variant="outlined"
                      InputProps={{
                        readOnly: inventory.documentType.name === "Inventory" 
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.qty && errors.qty)}
                      fullWidth
                      helperText={touched.qty && errors.qty}
                      label={t("Physical Quantity")}
                      margin="dense"
                      name="qty"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.qty}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                {inventory.documentType.name !== 'Inventory' && (
                  <Grid item xs={1}>
                    <Box display="flex" alignItems="center" height="100%">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.vatIncluded}
                            onChange={() => {
                              const newValue = !values.vatIncluded; 
                              setFieldValue('vatIncluded', newValue); 
                            }}
                            name="vatIncluded"
                            color="primary"
                          />
                        }
                        label={t("VAT Included")}
                      />
                    </ Box>
                  </Grid>
                )}

                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {t("Save")}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
}

export default InventoryProductManageForm;
