import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography
} from '@material-ui/core';
import { uploadAvatar } from '../../features/UserSlice';
import UserService from '../../services';
import { useTranslation } from 'react-i18next';

const AccountProfile = (props) => {
  const user = props.user;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleChange = event => {
    if (event.target.files.length) {
      const file = event.target.files[0];
      dispatch(uploadAvatar(file));
    }
  };

  return (
    <Card {...props}>
      <CardContent>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Avatar
            src={user.avatar}
            sx={{
              height: 100,
              width: 100
            }}
          />
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h3"
          >
            {user.firstName} {user.lastName}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body2"
          >
            {UserService.getJobTitleFromRoles(user.roles)}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          color="primary"
          fullWidth
          variant="text"
          component="label"
          onChange={handleChange}
        >
          {t("Upload picture")}
          <input type="file" hidden accept="image/*" />
        </Button>
      </CardActions>
    </Card>
  )
};

export default AccountProfile;
