import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import { GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';
import { toast } from 'react-toastify';
import moment from 'moment';
import ProductListResults from '../components/product/ProductListResults';
import ProductListToolbar, {
  StyledTitle,
} from '../components/product/ProductListToolbar';
import {
  updateProduct,
  softDeleteProduct,
  deleteProduct,
  shopSelector,
  shopClearState,
  getSelectedProductCategory,
} from '../features/ShopSlice';
import Loading from '../components/Loading';
import Can from '../can';
import escapeRegExp from '../utils/escapeRegExp';
import { useTranslation } from 'react-i18next';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useParams } from 'react-router';

const ProductList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selectedProductCategory, isError, errorMessage, isFetching } =
    useSelector(shopSelector);
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [mode, setMode] = useState('add');
  const [productId, setProductId] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const { childId } = useParams();
  const columns = [
    { field: 'name', headerName: t('Name'), flex: 1 },
    { field: 'description', headerName: t('Description'), flex: 1 },
    { field: 'price', headerName: t('Price'), flex: 1 },
    { field: 'unit', headerName: t('Unit'), flex: 1 },
    { field: 'vat', headerName: t('Vat'), flex: 1 },
    { field: 'productType', headerName: t('Type'), flex: 1 },
    { field: 'quantity', headerName: t('Quantity'), flex: 1 },
    { field: 'created', headerName: t('Created'), flex: 1 },
    {
      field: 'active',
      headerName: t('Active'),
      flex: 1,
      type: 'boolean',
      hide: !Can('manage', 'products'),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: t('Actions'),
      cellClassName: 'actions',
      getActions: ({ id, row }) => {
        let actions = [];

        if (Can('manage', 'products')) {
          actions.push(
            <GridActionsCellItem
              icon={<EditIcon />}
              label={t('Edit')}
              onClick={() => handleOpen('edit', row.id)}
              color='warning'
              aria-label='edit'
            />
          );
          if (row.active) {
            actions.push(
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label='Soft Delete'
                onClick={handleSoftDeleteClick(id)}
                color='warning'
                aria-label='soft-delete'
              />
            );
          } else {
            actions.push(
              <GridActionsCellItem
                icon={<RestoreIcon />}
                label='Restore'
                onClick={handleRestore({
                  id: id,
                  field: 'active',
                  value: true,
                })}
                color='success'
                aria-label='restore'
              />
            );
          }
          actions.push(
            <GridActionsCellItem
              icon={<DeleteForeverIcon />}
              label='Delete'
              onClick={() => handleConfirmOpen(row.id, row.name)}
              color='error'
              aria-label='delete'
            />
          );
        }

        return actions;
      },
    },
  ];

  useEffect(() => {
    dispatch(getSelectedProductCategory(childId));
  }, [dispatch, childId]);

  useEffect(() => {
    if (isError) {
      toast.error(t(errorMessage), {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
    return () => {
      dispatch(shopClearState());
    };
  }, [isError, errorMessage, dispatch, t]);

  useEffect(() => {
    if (selectedProductCategory && selectedProductCategory.products) {
      const categoryName = selectedProductCategory.name;
      if (Array.isArray(selectedProductCategory.products)) {
        setRows(
          mapProductsToRows(selectedProductCategory.products, categoryName)
        );
      }
    }
  }, [selectedProductCategory]);

  const mapProductsToRows = (products, categoryName) => {
    return products.map((product, index) => {
      return {
        id: product.id,
        productId: product['@id'],
        name: product.name,
        description: product.description,
        price: product.price,
        unit: product.unit.name,
        vat: product.vat.name,
        productType: product.productType.name,
        quantity: product.quantity,
        category: categoryName ? categoryName : '-',
        created: moment(product.created).format('YYYY-MM-DD'),
        active: product.active,
      };
    });
  };

  const requestSearch = (searchValue) => {
    if (selectedProductCategory && selectedProductCategory.products) {
      setSearchText(searchValue);
      const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
      const filteredRows = mapProductsToRows(
        selectedProductCategory.products
      ).filter((row) => {
        return Object.keys(row).some((field) => {
          if (field === 'productId' || !row[field]) {
            return false;
          }
          return searchRegex.test(row[field].toString());
        });
      });
      setRows(filteredRows);
    }
  };

  const handleOpen = (mode, id = null) => {
    setOpen(true);
    setMode(mode);
    setProductId(id);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
    dispatch(getSelectedProductCategory(childId));
  };

  const openAddDialog = () => {
    handleOpen('add');
  };

  const handleConfirmOpen = (id, name, edit = false) => {
    if (edit) {
      console.log('edit');
    } else {
      setConfirmOpen(true);
      setProductId(id);
      setConfirmMessage(
        t('Are you sure you want to delete product: ') + name + '?'
      );
    }
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleSoftDeleteClick = (productId) => (event) => {
    event.stopPropagation();
    dispatch(softDeleteProduct(productId)).then((response) => {
      if (!response.error) {
        toast.success(t('Product disabled successfully'), {
          position: 'bottom-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        dispatch(getSelectedProductCategory(childId));
        dispatch(shopClearState());
      } else {
        toast.error(t(response.payload), {
          position: 'bottom-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        dispatch(shopClearState());
      }
    });
  };

  const handleRestore = (params) => (event) => {
    event.stopPropagation();
    const payload = {
      id: params.id,
      data: {
        [params.field]: params.value,
      },
    };
    dispatch(updateProduct(payload)).then((response) => {
      if (!response.error) {
        toast.success(t('Data updated successfully'), {
          position: 'bottom-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        dispatch(getSelectedProductCategory(childId));
        setRows((prev) =>
          prev.map((row) =>
            row.id === params.id
              ? {
                  ...row,
                  ...response.payload,
                  unit: response.payload.unit.name,
                  vat: response.payload.vat.name,
                  productType: response.payload.productType.name,
                  category: response.payload.category ? response.payload.category.name : '',
                  created: moment(
                    response.payload.created.replace('+00:00', '')
                  ).format('YYYY-MM-DD'),
                }
              : row
          )
        );
        dispatch(shopClearState());
      } else {
        toast.error(t(response.payload), {
          position: 'bottom-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        setRows((prev) => [...prev]);
        dispatch(shopClearState());
      }
    });
  };

  const handleConfirmAction = () => {
    if (productId) {
      dispatch(deleteProduct(productId)).then((response) => {
        if (!response.error) {
          toast.success(t('Product removed successfully'), {
            position: 'bottom-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          handleConfirmClose();
          dispatch(getSelectedProductCategory(childId));
          dispatch(shopClearState());
        } else {
          toast.error(t(response.payload), {
            position: 'bottom-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          dispatch(shopClearState());
        }
      });
    }
  };

  if (isFetching && !selectedProductCategory) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>{t('Products')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <StyledTitle>
              {selectedProductCategory
                ? selectedProductCategory.name
                : t('Products')}
            </StyledTitle>

            <ProductListToolbar />
          </Box>

          <ProductListResults
            rows={rows}
            columns={columns}
            searchText={searchText}
            requestSearch={requestSearch}
            openAddDialog={openAddDialog}
            mode={mode}
            open={open}
            handleClose={handleClose}
            productId={productId}
            confirmOpen={confirmOpen}
            handleConfirmClose={handleConfirmClose}
            confirmMessage={confirmMessage}
            handleConfirmAction={handleConfirmAction}
          />
        </Container>
      </Box>
    </>
  );
};

export default ProductList;
