import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Box, Container, Avatar } from '@material-ui/core';
import { toast } from 'react-toastify';
import moment from 'moment';
import Loading from '../components/Loading';
import { getEmployees, updateUser, softDeleteUser, userSelector, userClearState } from '../features/UserSlice';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';
import Dialog from '../components/Dialog';
import UserManageForm from '../components/users/UserManageForm';
import Confirm from '../components/Confirm';
import UserService from '../services';
import Can from '../can';
import { GridActionsCellItem } from '@mui/x-data-grid';
import escapeRegExp from '../utils/escapeRegExp';
import DataGridFilterable from '../components/utils/DataGridFilterable';
import { useTranslation } from 'react-i18next';

const Users = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { employees, isError, errorMessage, isFetching } = useSelector(userSelector);
  const [searchText, setSearchText] = useState('');
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [mode, setMode] = useState('add');
  const columns = [
    { field: 'name',
      headerName: t('Name'),
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Avatar
              src={params.row.avatar}
              sx={{
                width: 48,
                height: 48,
                mr: 1
              }}
            />
            {params.row.name}
          </>
        );
      }
    },
    { field: 'email', headerName: t('Email'), flex: 1 },
    { field: 'phone', headerName: t('Phone'), flex: 1 },
    { field: 'department', headerName: t('Department'), flex: 1 },
    { field: 'created', headerName: t('Created'), flex: 1 },
    { field: 'shop', headerName: t('Shop'), flex: 1, hide: !Can('view', 'all') },
    { field: 'active', headerName: t('Active'), flex: 1, type: 'boolean', hide: !Can('view', 'all') },
    { field: 'actions',
      type: 'actions',
      headerName: t('Actions'),
      cellClassName: 'actions',
      getActions: ({id, row}) => {
        let actions = [
          <GridActionsCellItem
            icon={<EditIcon />}
            label={t("Edit")}
            onClick={() => handleOpen('edit', row.userId)}
            color="warning"
            aria-label="edit"
          />,
        ];
        if (Can('view', 'all')) {
          if (row.active) {
            actions.push(<GridActionsCellItem
              icon={<DeleteIcon />}
              label={t("Soft Delete")}
              onClick={() => handleConfirmOpen(row.userId, row.name)}
              color="error"
              aria-label="soft-delete"
            />);
          } else {
            actions.push(<GridActionsCellItem
              icon={<RestoreIcon />}
              label={t("Restore")}
              onClick={() => handleRestore(row.userId)}
              color="success"
              aria-label="restore"
            />);
          }
        } else {
          actions.push(<GridActionsCellItem
            icon={<DeleteIcon />}
            label={t("Soft Delete")}
            onClick={() => handleConfirmOpen(row.userId, row.name)}
            color="error"
            aria-label="soft-delete"
          />);
        }
        return actions;
      },
    }
  ];

  const handleOpen = (mode, id = null) => {
    setOpen(true);
    setMode(mode);
    setUserId(id);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  const handleConfirmOpen = (id, name, edit = false) => {
    if (edit) {
      console.log('edit');
    } else {
      setConfirmOpen(true);
      setUserId(id);
      setConfirmMessage(t('Are you sure you want to delete user: ') + name + '?');
    }
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleConfirmAction = () => {
    if (userId) {
      dispatch(softDeleteUser(userId)).then((response) => {
        if (!response.error) {
        toast.success(t('User deleted successfully'), {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        handleConfirmClose();
        dispatch(getEmployees());
        dispatch(userClearState());
      } else {
        toast.error(t(response.payload), {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        dispatch(userClearState());
      }
      });
    }
  }

  const mapEmployeesToRows = (employees) => {
    return employees.map((employee, index) => {
      return {
        id: employee.id,
        userId: employee['@id'],
        avatar: employee.avatar ?? '',
        name: employee.lastName + ' ' + employee.firstName,
        email: employee.email,
        phone: employee.phone,
        department: UserService.getDepartmentFromRoles(employee.roles),
        created: moment(employee.created).format("YYYY-MM-DD"),
        active: employee.active,
        shop: employee.shop.name,
      }
    })
  }

  const requestSearch = (searchValue) => {
    if (employees) {
      setSearchText(searchValue);
      const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
      const filteredRows = mapEmployeesToRows(employees).filter((row) => {
        return Object.keys(row).some((field) => {
          if (field === 'userId') {
            return false;
          }
          return searchRegex.test(row[field].toString());
        });
      });
      setRows(filteredRows);
    }
  };

  const handleRestore = (id) => {
    const data = {id: id, active: true};
    dispatch(updateUser(data)).then(response => {
      if (!response.error) {
        toast.success(t('User restored successfully'), {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        dispatch(getEmployees());
        dispatch(userClearState());
      } else {
        toast.error(t(response.payload), {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        dispatch(userClearState());
      }
    });
  }

  useEffect(() => {
    dispatch(getEmployees());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      toast.error(t(errorMessage), {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
    return () => {
      dispatch(userClearState());
    }
  }, [isError, errorMessage, dispatch, t]);

  useEffect(() => {
    if (employees) {
      setRows(mapEmployeesToRows(employees));
    }
  }, [employees]);

  const openAddDialog = () => {
    handleOpen('add');
  }

  if (isFetching && !employees) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>{t('Users')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Box sx={{ pt: 3 }}>
            <DataGridFilterable
              addButtonText="Add User"
              openAddDialog={openAddDialog}
              searchText={searchText}
              requestSearch={requestSearch}
              rows={rows}
              columns={columns}
              autoHeight
              disableColumnFilter
              disableColumnMenu
              disableSelectionOnClick
              density="comfortable"
            />
          </Box>
          <Dialog
            title={mode === 'add' ? "Add user" : "Edit user"}
            open={open}
            handleClose={handleClose}
          >
            <UserManageForm handleClose={handleClose} mode={mode} userId={userId} />
          </Dialog>
          <Confirm
            open={confirmOpen}
            handleClose={handleConfirmClose}
            message={confirmMessage}
            handleConfirmAction={handleConfirmAction}
          />
        </Container>
      </Box>
    </>
  );
};

export default Users;
