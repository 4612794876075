import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../api/index.js';

export const getOrderTypes = createAsyncThunk(
  'admin/getOrderTypes',
  async (data, thunkAPI) => {
    try {
      return api
        .getOrderTypes()
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getOrderType = createAsyncThunk(
  'admin/getOrderType',
  async (data, thunkAPI) => {
    try {
      return api
        .getOrderType(data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const addOrderType = createAsyncThunk(
  'admin/addOrderType',
  async (data, thunkAPI) => {
    try {
      return api
        .addOrderType(data)
        .then((response) => {
          if (response.status === 201) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const updateOrderType = createAsyncThunk(
  'admin/updateOrderType',
  async (data, thunkAPI) => {
    try {
      const id = data.id;
      delete data.id;
      return api
        .updateOrderType(id, data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const softDeleteOrderType = createAsyncThunk(
  'admin/softDeleteOrderType',
  async (data, thunkAPI) => {
    try {
      return api
        .softDeleteOrderType(data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const deleteOrderType = createAsyncThunk(
  'admin/deleteOrderType',
  async (data, thunkAPI) => {
    try {
      return api
        .deleteOrderType(data)
        .then((response) => {
          if (response.status === 204) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getPaymentTypes = createAsyncThunk(
  'admin/getPaymentTypes',
  async (data, thunkAPI) => {
    try {
      return api
        .getPaymentTypes()
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getPaymentType = createAsyncThunk(
  'admin/getPaymentType',
  async (data, thunkAPI) => {
    try {
      return api
        .getPaymentType(data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const addPaymentType = createAsyncThunk(
  'admin/addPaymentType',
  async (data, thunkAPI) => {
    try {
      return api
        .addPaymentType(data)
        .then((response) => {
          if (response.status === 201) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const updatePaymentType = createAsyncThunk(
  'admin/updatePaymentType',
  async (data, thunkAPI) => {
    try {
      const id = data.id;
      delete data.id;
      return api
        .updatePaymentType(id, data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const softDeletePaymentType = createAsyncThunk(
  'admin/softDeletePaymentType',
  async (data, thunkAPI) => {
    try {
      return api
        .softDeletePaymentType(data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const deletePaymentType = createAsyncThunk(
  'admin/deletePaymentType',
  async (data, thunkAPI) => {
    try {
      return api
        .deletePaymentType(data)
        .then((response) => {
          if (response.status === 204) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getStatuses = createAsyncThunk(
  'admin/getStatuses',
  async (data, thunkAPI) => {
    try {
      return api
        .getStatuses()
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getStatus = createAsyncThunk(
  'admin/getStatus',
  async (data, thunkAPI) => {
    try {
      return api
        .getStatus(data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const addStatus = createAsyncThunk(
  'admin/addStatus',
  async (data, thunkAPI) => {
    try {
      return api
        .addStatus(data)
        .then((response) => {
          if (response.status === 201) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const updateStatus = createAsyncThunk(
  'admin/updateStatus',
  async (data, thunkAPI) => {
    try {
      const id = data.id;
      delete data.id;
      return api
        .updateStatus(id, data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const softDeleteStatus = createAsyncThunk(
  'admin/softDeleteStatus',
  async (data, thunkAPI) => {
    try {
      return api
        .softDeleteStatus(data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const deleteStatus = createAsyncThunk(
  'admin/deleteStatus',
  async (data, thunkAPI) => {
    try {
      return api
        .deleteStatus(data)
        .then((response) => {
          if (response.status === 204) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getProductTypes = createAsyncThunk(
  'admin/getProductTypes',
  async (data, thunkAPI) => {
    try {
      return api
        .getProductTypes()
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getProductType = createAsyncThunk(
  'admin/getProductType',
  async (data, thunkAPI) => {
    try {
      return api
        .getProductType(data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const addProductType = createAsyncThunk(
  'admin/addProductType',
  async (data, thunkAPI) => {
    try {
      return api
        .addProductType(data)
        .then((response) => {
          if (response.status === 201) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const updateProductType = createAsyncThunk(
  'admin/updateProductType',
  async (data, thunkAPI) => {
    try {
      const id = data.id;
      delete data.id;
      return api
        .updateProductType(id, data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const softDeleteProductType = createAsyncThunk(
  'admin/softDeleteProductType',
  async (data, thunkAPI) => {
    try {
      return api
        .softDeleteProductType(data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const deleteProductType = createAsyncThunk(
  'admin/deleteProductType',
  async (data, thunkAPI) => {
    try {
      return api
        .deleteProductType(data)
        .then((response) => {
          if (response.status === 204) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getUnits = createAsyncThunk(
  'admin/getUnits',
  async (data, thunkAPI) => {
    try {
      return api
        .getUnits()
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getUnit = createAsyncThunk(
  'admin/getUnit',
  async (data, thunkAPI) => {
    try {
      return api
        .getUnit(data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const addUnit = createAsyncThunk(
  'admin/addUnit',
  async (data, thunkAPI) => {
    try {
      return api
        .addUnit(data)
        .then((response) => {
          if (response.status === 201) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const updateUnit = createAsyncThunk(
  'admin/updateUnit',
  async (data, thunkAPI) => {
    try {
      const id = data.id;
      delete data.id;
      return api
        .updateUnit(id, data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const softDeleteUnit = createAsyncThunk(
  'admin/softDeleteUnit',
  async (data, thunkAPI) => {
    try {
      return api
        .softDeleteUnit(data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const deleteUnit = createAsyncThunk(
  'admin/deleteUnit',
  async (data, thunkAPI) => {
    try {
      return api
        .deleteUnit(data)
        .then((response) => {
          if (response.status === 204) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getVats = createAsyncThunk(
  'admin/getVats',
  async (data, thunkAPI) => {
    try {
      return api
        .getVats()
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getVat = createAsyncThunk(
  'admin/getVat',
  async (data, thunkAPI) => {
    try {
      return api
        .getVat(data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const addVat = createAsyncThunk(
  'admin/addVat',
  async (data, thunkAPI) => {
    try {
      return api
        .addVat(data)
        .then((response) => {
          if (response.status === 201) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const updateVat = createAsyncThunk(
  'admin/updateVat',
  async (data, thunkAPI) => {
    try {
      const id = data.id;
      delete data.id;
      return api
        .updateVat(id, data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const softDeleteVat = createAsyncThunk(
  'admin/softDeleteVat',
  async (data, thunkAPI) => {
    try {
      return api
        .softDeleteVat(data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const deleteVat = createAsyncThunk(
  'admin/deleteVat',
  async (data, thunkAPI) => {
    try {
      return api
        .deleteVat(data)
        .then((response) => {
          if (response.status === 204) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getAreas = createAsyncThunk(
  'admin/getAreas',
  async (data, thunkAPI) => {
    try {
      return api
        .getAreas()
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getArea = createAsyncThunk(
  'admin/getArea',
  async (data, thunkAPI) => {
    try {
      return api
        .getArea(data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const addArea = createAsyncThunk(
  'admin/addArea',
  async (data, thunkAPI) => {
    try {
      return api
        .addArea(data)
        .then((response) => {
          if (response.status === 201) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const updateArea = createAsyncThunk(
  'admin/updateArea',
  async (data, thunkAPI) => {
    try {
      const id = data.id;
      delete data.id;
      return api
        .updateArea(id, data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const softDeleteArea = createAsyncThunk(
  'admin/softDeleteArea',
  async (data, thunkAPI) => {
    try {
      return api
        .softDeleteArea(data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const deleteArea = createAsyncThunk(
  'admin/deleteArea',
  async (data, thunkAPI) => {
    try {
      return api
        .deleteArea(data)
        .then((response) => {
          if (response.status === 204) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getStands = createAsyncThunk(
  'admin/getStands',
  async (data, thunkAPI) => {
    try {
      return api
        .getStands()
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getStand = createAsyncThunk(
  'admin/getStand',
  async (data, thunkAPI) => {
    try {
      return api
        .getStand(data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const addStand = createAsyncThunk(
  'admin/addStand',
  async (data, thunkAPI) => {
    try {
      return api
        .addStand(data)
        .then((response) => {
          if (response.status === 201) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const updateStand = createAsyncThunk(
  'admin/updateStand',
  async (data, thunkAPI) => {
    try {
      const id = data.id;
      delete data.id;
      return api
        .updateStand(id, data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const customFreeStand = createAsyncThunk(
  'stand/customFreeStand',
  async (data, thunkAPI) => {
    try {
      const response = await api.customFreeStand(data);
      if (response.status === 201) {
        return response.data;
      } else {
        const errorMessage = response.data?.message || 'Failed to free stand';
        return thunkAPI.rejectWithValue(errorMessage);
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.violations?.[0]?.message ||
        error.response?.data?.message ||
        'An unknown error occurred';
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const softDeleteStand = createAsyncThunk(
  'admin/softDeleteStand',
  async (data, thunkAPI) => {
    try {
      return api
        .softDeleteStand(data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const deleteStand = createAsyncThunk(
  'admin/deleteStand',
  async (data, thunkAPI) => {
    try {
      return api
        .deleteStand(data)
        .then((response) => {
          if (response.status === 204) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(
            e.response.data.violations
              ? e.response.data.violations[0].message
              : e.response.data.message
              ? e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    id: '',
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    orderTypes: [],
    orderType: null,
    paymentTypes: [],
    paymentType: null,
    statuses: [],
    status: null,
    productTypes: [],
    productType: null,
    units: [],
    unit: null,
    stands: [],
    stand: null,
    vats: [],
    vat: null,
    areas: [],
    area: null,
  },
  reducers: {
    adminClearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrderTypes.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.orderTypes = payload['hydra:member'];
        return state;
      })
      .addCase(getOrderTypes.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getOrderTypes.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getOrderType.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.orderType = payload;
        return state;
      })
      .addCase(getOrderType.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getOrderType.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(addOrderType.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(addOrderType.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(addOrderType.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(updateOrderType.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(updateOrderType.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(updateOrderType.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(softDeleteOrderType.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(softDeleteOrderType.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(softDeleteOrderType.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(deleteOrderType.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(deleteOrderType.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(deleteOrderType.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getPaymentTypes.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.paymentTypes = payload['hydra:member'];
        return state;
      })
      .addCase(getPaymentTypes.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getPaymentTypes.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getPaymentType.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.paymentType = payload;
        return state;
      })
      .addCase(getPaymentType.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getPaymentType.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(addPaymentType.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(addPaymentType.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(addPaymentType.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(updatePaymentType.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(updatePaymentType.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(updatePaymentType.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(softDeletePaymentType.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(softDeletePaymentType.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(softDeletePaymentType.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(deletePaymentType.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(deletePaymentType.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(deletePaymentType.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getStatuses.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.statuses = payload['hydra:member'].map((status) => ({
          ...status,
          showInDashboard: !status.slug.includes('busy'),
        }));
        return state;
      })
      .addCase(getStatuses.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getStatuses.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getStatus.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.status = payload;
        return state;
      })
      .addCase(getStatus.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getStatus.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(addStatus.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(addStatus.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(addStatus.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(updateStatus.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(updateStatus.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(updateStatus.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(softDeleteStatus.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(softDeleteStatus.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(softDeleteStatus.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(deleteStatus.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(deleteStatus.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(deleteStatus.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getProductTypes.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.productTypes = payload['hydra:member'];
        return state;
      })
      .addCase(getProductTypes.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getProductTypes.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getProductType.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.productType = payload;
        return state;
      })
      .addCase(getProductType.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getProductType.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(addProductType.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(addProductType.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(addProductType.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(updateProductType.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(updateProductType.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(updateProductType.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(softDeleteProductType.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(softDeleteProductType.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(softDeleteProductType.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(deleteProductType.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(deleteProductType.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(deleteProductType.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getUnits.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.units = payload['hydra:member'];
        return state;
      })
      .addCase(getUnits.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getUnits.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getUnit.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.unit = payload;
        return state;
      })
      .addCase(getUnit.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getUnit.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(addUnit.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(addUnit.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(addUnit.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(updateUnit.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(updateUnit.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(updateUnit.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(softDeleteUnit.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(softDeleteUnit.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(softDeleteUnit.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(deleteUnit.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(deleteUnit.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(deleteUnit.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getVats.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.vats = payload['hydra:member'];
        return state;
      })
      .addCase(getVats.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getVats.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getVat.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.vat = payload;
        return state;
      })
      .addCase(getVat.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getVat.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(addVat.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(addVat.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(addVat.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(updateVat.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(updateVat.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(updateVat.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(softDeleteVat.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(softDeleteVat.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(softDeleteVat.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(deleteVat.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(deleteVat.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(deleteVat.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getAreas.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.areas = payload['hydra:member'];
        return state;
      })
      .addCase(getAreas.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getAreas.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getArea.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.area = payload;
        return state;
      })
      .addCase(getArea.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getArea.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(addArea.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(addArea.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(addArea.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(updateArea.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(updateArea.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(updateArea.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(softDeleteArea.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(softDeleteArea.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(softDeleteArea.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(deleteArea.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(deleteArea.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(deleteArea.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getStands.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.stands = payload['hydra:member'];
        return state;
      })
      .addCase(getStands.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getStands.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getStand.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.stand = payload;
        return state;
      })
      .addCase(getStand.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getStand.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(addStand.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(addStand.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(addStand.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(updateStand.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(updateStand.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(updateStand.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(customFreeStand.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.orderProducts = payload['hydra:member'];
        return state;
      })
      .addCase(customFreeStand.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(customFreeStand.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.payload || 'An unknown error occurred';
      })
      .addCase(softDeleteStand.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(softDeleteStand.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(softDeleteStand.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(deleteStand.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(deleteStand.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(deleteStand.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      });
  },
});

export const { adminClearState } = adminSlice.actions;

export const adminSelector = (state) => state.admin;
