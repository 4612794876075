import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { saveNewPassword, resetPassword, userSelector } from '../features/UserSlice';
import { toast } from 'react-toastify';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useTranslation } from 'react-i18next';

const ForgotPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isFetching, isSuccess, isError, errorMessage } = useSelector(userSelector);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [showPassword, setShowPassword] = useState(false);

  const handleNewPasswordSubmit = (data) => {
    dispatch(saveNewPassword({token, data}));
  };

  const handleSubmit = (data) => {
    dispatch(resetPassword(data));
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (isError) {
      toast.error(t(errorMessage), {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  }, [isError, errorMessage, t]);

  if (token) {
    if (isSuccess) {
      toast.success(t('Password updated successfully'), {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      navigate('/login', {replace: true});
    }

    return (
      <>
        <Helmet>
          <title>{t("Forgot Password")}</title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center'
          }}
        >
          <Container maxWidth="sm">
            <Formik
              initialValues={{
                password: ''
              }}
              validationSchema={Yup.object().shape({
                password: Yup.string().max(255).required(t('Password is required'))
              })}
              onSubmit={(data) => {
                handleNewPasswordSubmit(data);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box sx={{ mb: 1 }}>
                    <Typography
                      color="textPrimary"
                      variant="h2"
                    >
                      {t("Reset your password")}
                    </Typography>
                    <Typography
                      color="textPrimary"
                      variant="subtitle1"
                    >
                      {t("Enter your new password.")}
                    </Typography>
                  </Box>
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label={t("Password")}
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type={showPassword ? 'text' : 'password'}
                    value={values.password}
                    variant="outlined"
                    InputProps={{
                      endAdornment:
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={(e) => e.preventDefault()}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                    }}
                  />
                  <Box sx={{ py: 2 }}>
                    <Button
                      color="primary"
                      disabled={isSubmitting && isFetching}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      {t("Save password")}
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        </Box>
      </>
    );
  }

  if (isSuccess) {
    return (
      <>
        <Helmet>
          <title>{t("Forgot Password")}</title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center'
          }}
        >
          <Container maxWidth="md">
            <Typography variant="h2">
              {t("Please follow the instructions in the email to reset your password.")}
            </Typography>
          </Container>
        </Box>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{t("Forgot Password")}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email(t('Must be a valid email')).max(255).required(t('Email is required'))
            })}
            onSubmit={(data) => {
              handleSubmit(data);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 1 }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    {t("Reset your password")}
                  </Typography>
                  <Typography
                    color="textPrimary"
                    variant="subtitle1"
                  >
                    {t("Enter your email address and we will send you a password reset link.")}
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label={t("Email Address")}
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting && isFetching}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {t("Send password reset email")}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default ForgotPassword;
