import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Grid,
  Typography,
  Divider,
  Button,
  Card,
  CardContent,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  orderSelector,
  getParentProductCategories,
  cartClearState,
} from '../../features/OrderSlice';
import { userSelector } from 'src/features/UserSlice';
import Loading from 'src/components/Loading';
import { useNavigate, useLocation } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useParams } from 'react-router';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Badge from '@mui/material/Badge';
import { getTotalItems } from 'src/utils/cartHelper.js';

const OrderStandCategories = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector(userSelector);
  const { parentProductCategories, cartItems } = useSelector(orderSelector);
  const [selectedParentCategory, setSelectedParentCategory] = useState({});
  const [selectedFeaturedChildCategory, setSelectedFeaturedChildCategory] =
    useState({});
  const { standId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { name, seats, status, statuses, addedProducts, orderId } =
    location.state || {};
  const isEditingOrder = Boolean(orderId);

  useEffect(() => {
    dispatch(getParentProductCategories());
  }, [dispatch]);

  const handleBackClick = () => {
    dispatch(cartClearState());
    navigate('/app/dashboard');
  };

  const handleParentClick = (category) => {
    setSelectedParentCategory(category);

    if (isEditingOrder) {
      navigate(
        `/app/stands/${standId}/orders/${orderId}/categories/${category.id}`,
        {
          state: {
            name,
            seats,
            status,
            statuses,
            parentCategory: category,
            orderId,
          },
          replace: true,
        }
      );
    } else {
      navigate(`/app/stands/${standId}/categories/${category.id}`, {
        state: {
          name,
          seats,
          status,
          statuses,
          parentCategory: category,
          addedProducts,
        },
        replace: true,
      });
    }
  };

  const handleFeaturedClick = (parentId, subcategory) => {
    setSelectedFeaturedChildCategory(subcategory);

    if (isEditingOrder) {
      navigate(
        `/app/stands/${standId}/orders/${orderId}/categories/${parentId}/${subcategory.id}`,
        {
          state: {
            name,
            seats,
            status,
            statuses,
            fromFeaturedChildCategory: true,
            orderId,
          },
          replace: true,
        }
      );
    } else {
      navigate(
        `/app/stands/${standId}/categories/${parentId}/${subcategory.id}`,
        {
          state: {
            name,
            seats,
            status,
            statuses,
            fromFeaturedChildCategory: true,
            addedProducts,
          },
          replace: true,
        }
      );
    }
  };

  const handleOrderItemsClick = () => {
    if (isEditingOrder) {
      navigate(`/app/stands/${standId}/orders/${orderId}`, {
        state: { name, seats, status, statuses, orderId },
        replace: true,
      });
    } else {
      navigate(`/app/stands/${standId}/checkout`, {
        state: { name, seats, status, statuses },
        replace: true,
      });
    }
  };

  if (
    !user ||
    !user.shop ||
    !(parentProductCategories && parentProductCategories.length > 1) ||
    !location.state ||
    !name
  ) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Order Stand Categories</title>
      </Helmet>

      <Grid
        container
        sx={{
          backgroundColor: '#FAFAFA',
        }}
      >
        <Grid
          container
          sx={{
            backgroundColor: '#F5F5F5',
          }}
        >
          <Grid
            item
            xs={8}
          >
            <Button
              onClick={handleBackClick}
              sx={{ color: '#949494' }}
            >
              <ArrowBackIosIcon
                sx={{ fontSize: '2.5rem', fontWeight: 'bold' }}
              />
              <Typography
                variant='h6'
                sx={{
                  color: '#949494',
                  fontSize: '1.5rem',
                  textTransform: 'capitalize',
                }}
              >
                {t('Dashboard')}
              </Typography>
            </Button>
          </Grid>

          <Grid
            item
            xs={4}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'right',
              paddingRight: 1,
            }}
          >
            <Typography
              variant='h6'
              sx={{ color: '#949494', fontSize: '1.5rem' }}
            >
              {t('T') + ': ' + (name || t('Default Table Name'))}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
        >
          <Divider />
        </Grid>

        <Grid
          container
          sx={{
            marginTop: 1,
            padding: 1,
          }}
        >
          {parentProductCategories.map((category, index) => (
            <Grid
              item
              xs={6}
              key={category.id}
              sx={{
                paddingRight: index === 0 ? 1 : 'none',
                paddingLeft: index === 1 ? 1 : 'none',
                position: 'relative',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: '10%',
                  right: index === 0 ? 0 : 'unset',
                  left: index === 1 ? 0 : 'unset',
                  width: '0.5px',
                  height: '40%',
                  backgroundColor: '#949494',
                },
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  bottom: '10%',
                  right: index === 0 ? 0 : 'unset',
                  left: index === 1 ? 0 : 'unset',
                  width: '0.5px',
                  height: '40%',
                  backgroundColor: '#949494',
                },
              }}
            >
              <Button
                sx={{
                  padding: '35px',
                  fontSize: '20px',
                  borderRadius: '0 !important',
                  backgroundColor:
                    selectedParentCategory.id === category.id
                      ? '#52B9C7'
                      : '#52B9C7',
                  border: '1px solid #52B9C7',
                  '&:hover': {
                    backgroundColor:
                      selectedParentCategory.id === category.id
                        ? '#008B8B'
                        : '#6bc5d1',
                    borderColor: '#e0e0e0',
                  },
                  '&:focus': {
                    backgroundColor:
                      selectedParentCategory.id === category.id
                        ? 'grey'
                        : '#f5f5f5 !important',
                    borderColor: '#e0e0e0',
                  },
                }}
                variant={
                  selectedParentCategory.id === category.id
                    ? 'contained'
                    : 'outlined'
                }
                fullWidth
                onClick={() => {
                  handleParentClick(category);
                }}
              >
                <span
                  style={{
                    display: 'block',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: '100%',
                    color:
                      selectedParentCategory.id === category.id
                        ? '#fff'
                        : '#f5f5f5',
                    textTransform: 'capitalize',
                  }}
                >
                  {t(category.name)}
                </span>
              </Button>
            </Grid>
          ))}
        </Grid>

        <Grid
          container
          sx={{
            padding: 1,
          }}
        >
          <Grid
            item
            xs={12}
          >
            <Typography
              sx={{ textAlign: 'center', color: '#949494', fontSize: '1.2rem' }}
            >
              {t('Quick access')}
            </Typography>
          </Grid>

          {parentProductCategories.map((category, index) => (
            <Grid
              item
              xs={6}
              key={category.id}
              sx={{
                mt: 1,
                paddingRight: index === 0 ? 1 : 'none',
                paddingLeft: index === 1 ? 1 : 'none',
                position: 'relative',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: '10%',
                  right: index === 0 ? 0 : 'unset',
                  left: index === 1 ? 0 : 'unset',
                  width: '0.5px',
                  height: '40%',
                  backgroundColor: '#949494',
                },
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  bottom: '10%',
                  right: index === 0 ? 0 : 'unset',
                  left: index === 1 ? 0 : 'unset',
                  width: '0.5px',
                  height: '40%',
                  backgroundColor: '#949494',
                },
              }}
            >
              <Grid
                container
                spacing={1}
              >
                {category.productCategories.filter(
                  (subcategory) => subcategory.featured
                ).length === 0 ? (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      paddingLeft: 1,
                      paddingTop: '0 !important',
                      mt: 1,
                    }}
                  >
                    <Card>
                      <CardContent>
                        <Typography
                          sx={{ textAlign: 'center', fontSize: '1rem' }}
                        >
                          {`No featured categories for ${category.name}`}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ) : (
                  category.productCategories
                    .filter((subcategory) => subcategory.featured)
                    .map((subcategory) => (
                      <Grid
                        item
                        xs={12}
                        key={subcategory.id}
                      >
                        <Button
                          key={subcategory.name}
                          variant='outlined'
                          sx={{
                            padding: '30px',
                            fontSize: '15px',
                            borderRadius: '0 !important',
                            backgroundColor:
                              selectedFeaturedChildCategory.id ===
                              subcategory.id
                                ? '#52B9C7'
                                : '#52B9C7',
                            border: '1px solid #52B9C7',
                            '&:hover': {
                              backgroundColor:
                                selectedFeaturedChildCategory.id ===
                                subcategory.id
                                  ? '#008B8B'
                                  : '#6bc5d1',
                              borderColor: '#e0e0e0',
                            },
                            '&:focus': {
                              backgroundColor:
                                selectedFeaturedChildCategory.id ===
                                subcategory.id
                                  ? 'grey'
                                  : '#f5f5f5 !important',
                              borderColor: '#e0e0e0',
                            },
                          }}
                          fullWidth
                          onClick={() => {
                            handleFeaturedClick(category.id, subcategory);
                          }}
                        >
                          <span
                            style={{
                              display: 'block',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              maxWidth: '100%',
                              color:
                                selectedFeaturedChildCategory.id ===
                                subcategory.id
                                  ? '#fff'
                                  : '#f5f5f5',
                              textTransform: 'capitalize',
                            }}
                          >
                            {t(subcategory.name)}
                          </span>
                        </Button>
                      </Grid>
                    ))
                )}
              </Grid>
            </Grid>
          ))}

          {cartItems && cartItems.length > 0 && (
            <Grid
              container
              alignItems='center'
              justifyContent='center'
              position={'sticky'}
              bottom='8px'
              sx={{
                marginTop: '10px',
              }}
            >
              <Button
                fullWidth
                sx={{
                  fontSize: '16px',
                  border: '1px solid #008B8B',
                  backgroundColor: 'rgba(232, 232, 232, 0.9)',
                  color: '#008B8B',
                  maxWidth: '70%',
                  height: '50px',
                  '&:hover': {
                    backgroundColor: 'rgba(232, 232, 232, 0.9)',
                  },
                }}
                onClick={() => handleOrderItemsClick()}
                endIcon={
                  <Badge
                    badgeContent={getTotalItems(cartItems)}
                    color='error'
                  >
                    <ShoppingCartIcon
                      sx={{
                        width: '25px',
                        height: '25px',
                      }}
                    />
                  </Badge>
                }
              >
                <span
                  style={{
                    display: 'block',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    textTransform: 'capitalize',
                  }}
                >
                  Order Items
                </span>
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default OrderStandCategories;
