import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { toast } from 'react-toastify';
import moment from 'moment';
import Loading from '../../components/Loading';
import Dialog from '../../components/Dialog';
import ShopManageForm from '../../components/admin/shops/ShopManageForm';
import Confirm from '../../components/Confirm';
import { getShops, updateShop, softDeleteShop, shopSelector, shopClearState } from '../../features/ShopSlice';
import escapeRegExp from '../../utils/escapeRegExp';
import DataGridFilterable from '../../components/utils/DataGridFilterable';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const AdminShops = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { shops, isError, errorMessage, isFetching } = useSelector(shopSelector);
  const [searchText, setSearchText] = useState('');
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [shopId, setShopId] = useState(null);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [mode, setMode] = useState('add');
  const navigate = useNavigate();
  const columns = [
    { field: 'name', 
      headerName: t('Name'), 
      flex: 1,
      renderCell: (params) => (
        <span 
          onClick={() => handleRowClick(params.row)}
          style={{cursor: 'pointer', color: '#5664d2'}}
        >
          {params.row.name}
        </span>
      )
    },
    { field: 'address', headerName: t('Address'), flex: 1 },
    { field: 'cif', headerName: t('CIF'), flex: 1 },
    { field: 'country', headerName: t('Country'), flex: 1 },
    { field: 'county', headerName: t('County'), flex: 1 },
    { field: 'city', headerName: t('City'), flex: 1 },
    { field: 'created', headerName: t('Created'), flex: 1 },
    { field: 'active', headerName: t('Active'), flex: 1, type: 'boolean' },
    { field: 'actions',
      type: 'actions',
      headerName: t('Actions'),
      cellClassName: 'actions',
      getActions: ({id, row}) => {
        let actions = [
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label={t("View Products")}
            onClick={() => handleRowClick(row)}
            color="primary"
            aria-label="view-products"
          />,
          <GridActionsCellItem
            icon={<EditIcon />}
            label={t("Edit")}
            onClick={() => handleOpen('edit', row.id)}
            color="warning"
            aria-label="edit"
          />
        ];
        if (row.active) {
          actions.push(<GridActionsCellItem
            icon={<DeleteIcon />}
            label={t("Soft Delete")}
            onClick={() => handleConfirmOpen(row.id, row.name)}
            color="error"
            aria-label="soft-delete"
          />);
        } else {
          actions.push(<GridActionsCellItem
            icon={<RestoreIcon />}
            label={t("Restore")}
            onClick={() => handleRestore(row.id)}
            color="success"
            aria-label="restore"
          />);
        }
        return actions;
      },
    }
  ];

  useEffect(() => {
    dispatch(getShops());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      toast.error(t(errorMessage), {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
    return () => {
      dispatch(shopClearState());
    }
  }, [isError, errorMessage, dispatch, t]);

  useEffect(() => {
    if (shops) {
      setRows(mapShopsToRows(shops));
    }
  }, [shops]);

  const mapShopsToRows = (shops) => {
    return shops.map((shop, index) => {
      return {
        id: shop.id,
        shopId: shop['@id'],
        name: shop.name,
        address: shop.address,
        cif: shop.cif,
        country: shop.country,
        county: shop.county,
        city: shop.city,
        created: moment(shop.created).format("YYYY-MM-DD"),
        active: shop.active,
      }
    })
  }

  const handleOpen = (mode, id = null) => {
    setOpen(true);
    setMode(mode);
    setShopId(id);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  const handleConfirmOpen = (id, name, edit = false) => {
    if (edit) {
      console.log('edit');
    } else {
      setConfirmOpen(true);
      setShopId(id);
      setConfirmMessage(t('Are you sure you want to delete shop: ') + name + '?');
    }
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleConfirmAction = () => {
    if (shopId) {
      dispatch(softDeleteShop(shopId)).then((response) => {
        if (!response.error) {
          toast.success(t('Shop deleted successfully'), {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          handleConfirmClose();
          dispatch(getShops());
          dispatch(shopClearState());
        } else {
          toast.error(t(response.payload), {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          dispatch(shopClearState());
        }
      });
    }
  }

  const handleRestore = (id) => {
    const data = {active: true};
    dispatch(updateShop({endpoint: id, data})).then(response => {
      if (!response.error) {
        toast.success(t('Shop restored successfully'), {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        dispatch(getShops());
        dispatch(shopClearState());
      } else {
        toast.error(t(response.payload), {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        dispatch(shopClearState());
      }
    });
  }

  const requestSearch = (searchValue) => {
    if (shops) {
      setSearchText(searchValue);
      const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
      const filteredRows = mapShopsToRows(shops).filter((row) => {
        return Object.keys(row).some((field) => {
          if (field === 'shopId') {
            return false;
          }
          return searchRegex.test(row[field].toString());
        });
      });
      setRows(filteredRows);
    }
  };

  const openAddDialog = () => {
    handleOpen('add');
  }

  const handleRowClick = (row) => {
    navigate(`/app/admin/shops/${row.id}`, { replace: false });
  };

  if (isFetching && !shops) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>{t('Shops')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Box sx={{ pt: 3 }}>
            <DataGridFilterable
              addButtonText="Add Shop"
              openAddDialog={openAddDialog}
              searchText={searchText}
              requestSearch={requestSearch}
              rows={rows}
              columns={columns}
              autoHeight
              disableColumnFilter
              disableColumnMenu
              disableSelectionOnClick
              density="comfortable"
            />
          </Box>
          <Dialog
            title={mode === 'add' ? "Add shop" : "Edit shop"}
            open={open}
            handleClose={handleClose}
          >
            <ShopManageForm handleClose={handleClose} mode={mode} shopId={shopId} />
          </Dialog>
          <Confirm
            open={confirmOpen}
            handleClose={handleConfirmClose}
            message={confirmMessage}
            handleConfirmAction={handleConfirmAction}
          />
        </Container>
      </Box>
    </>
  );
}

export default AdminShops;
