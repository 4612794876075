import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Grid,
} from '@material-ui/core';
import { getRecipeProduct, updateRecipeProduct, shopSelector } from '../../../features/ShopSlice';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const RecipeProductManageForm = (props) => {
  const { t } = useTranslation();
  const { handleClose, mode, recipeProductId } = props;
  const { recipeProduct } = useSelector(shopSelector);
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({
    product: '', 
    unit: '', 
    qty: '', 
    active: true 
  });
  const validationSchema = {
    qty: Yup.number().typeError(t('You must specify a number')).required(t('Quantity is required')),
  };

  useEffect(() => {
    if (mode === 'edit' && recipeProductId) {
      dispatch(getRecipeProduct(recipeProductId));
    }
  }, [dispatch, mode, recipeProductId]);

  useEffect(() => {
    if (mode === 'edit' && recipeProduct) {
      setInitialValues({
        product: recipeProduct.product.name || '', 
        unit: recipeProduct.unit.name || '', 
        qty: recipeProduct.qty || '', 
        active: recipeProduct.active || true, 
      });
    }
  }, [recipeProduct, mode]);

  const handleSubmit = (data, formikData) => {
    if (mode === 'add') {
      console.log("add");
    } else {
      data.id = recipeProduct.id;
      data.product = recipeProduct.product['@id'];
      data.unit = recipeProduct.unit['@id'];
      data.recipe = recipeProduct.recipe;
      data.qty = parseInt(data.qty, 10);

      dispatch(updateRecipeProduct(data)).then((response) => {
        if (!response.error) {
          toast.success(t('Data updated successfully'), {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          handleClose();
        } else {
          toast.error(t(response.payload), {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
        }
      });
    }
    formikData.setSubmitting(false);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={Yup.object().shape(validationSchema)}
            onSubmit={(data, formikData) => handleSubmit(data, formikData)}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue
            }) => (
              <Form id="add-recipe-product">
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.product && errors.product)}
                      fullWidth
                      helperText={touched.product && errors.product}
                      label={t("Product")}
                      margin="dense"
                      name="product"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.product}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.unit && errors.unit)}
                      fullWidth
                      helperText={touched.unit && errors.unit}
                      label={t("Unit")}
                      margin="dense"
                      name="unit"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.unit}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.qty && errors.qty)}
                      fullWidth
                      helperText={touched.qty && errors.qty}
                      label={t("Quantity")}
                      margin="dense"
                      name="qty"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.qty}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {t("Save")}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
}

export default RecipeProductManageForm;
