import { SvgIcon } from '@material-ui/core';

const ClearFilteredButton = () => {

  const defaultColor = "#F3EFEF"; 

  return (
    <SvgIcon viewBox="0 0 25 15">
        <path id="Vector" d="M22.5002 4.97256e-08H8.01816C7.6898 -6.54544e-05 7.36465 0.0645866 7.06129 0.19026C6.75793 0.315934 6.48231 0.500166 6.2502 0.732422L0.366211 6.61602C-0.12207 7.1043 -0.12207 7.8957 0.366211 8.38359L6.2502 14.2676C6.71895 14.7363 7.35488 15 8.01777 15H22.5002C23.8811 15 25.0002 13.8809 25.0002 12.5V2.5C25.0002 1.11914 23.8811 4.97256e-08 22.5002 4.97256e-08ZM19.192 9.92422C19.4361 10.1684 19.4361 10.5641 19.192 10.8082L18.3084 11.6918C18.0643 11.9359 17.6686 11.9359 17.4244 11.6918L15.0002 9.26758L12.576 11.6918C12.3318 11.9359 11.9361 11.9359 11.692 11.6918L10.8084 10.8082C10.5643 10.5641 10.5643 10.1684 10.8084 9.92422L13.2326 7.5L10.8084 5.07578C10.5643 4.83164 10.5643 4.43594 10.8084 4.1918L11.692 3.3082C11.9361 3.06406 12.3318 3.06406 12.576 3.3082L15.0002 5.73242L17.4244 3.3082C17.6686 3.06406 18.0643 3.06406 18.3084 3.3082L19.192 4.1918C19.4361 4.43594 19.4361 4.83164 19.192 5.07578L16.7678 7.5L19.192 9.92422Z" fill={defaultColor}/>
    </SvgIcon>
  )
};

export default ClearFilteredButton;