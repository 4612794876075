import React from 'react';
import {
  Box,
  Container,
} from '@material-ui/core';
import DataGridFilterable from '../utils/DataGridFilterable';
import Dialog from '../Dialog';
import CustomerManageForm from './CustomerManageForm';
import Confirm from '../Confirm';

const CustomerListResults = (props) => {
  const {
    rows,
    columns,
    searchText,
    requestSearch,
    openAddDialog,
    mode,
    open,
    handleClose,
    customerId,
    confirmOpen,
    handleConfirmClose,
    confirmMessage,
    handleConfirmAction,
    openAddress,
    handleAddAddress
  } = props;

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        <Box>
          <DataGridFilterable
            addButtonText="Add Customer"
            openAddDialog={openAddDialog}
            searchText={searchText}
            requestSearch={requestSearch}
            rows={rows}
            columns={columns}
            autoHeight
            disableColumnFilter
            disableColumnMenu
            disableSelectionOnClick
            density="comfortable"
          />
        </Box>
        <Dialog
          title={mode === 'add' ? "Add customer" : "Edit customer"}
          open={open}
          handleClose={handleClose}
          maxWidth={openAddress ? "md" : "sm"}
        >
          <CustomerManageForm
            handleClose={handleClose}
            mode={mode}
            customerId={customerId}
            openAddress={openAddress}
            handleAddAddress={handleAddAddress}
          />
        </Dialog>
        <Confirm
          open={confirmOpen}
          handleClose={handleConfirmClose}
          message={confirmMessage}
          handleConfirmAction={handleConfirmAction}
        />
      </Container>
    </Box>
  );
};

export default CustomerListResults;
