import {
  Box,
  Card,
  Button,
  TextField,
  IconButton
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';

const QuickSearchToolbar = (props) => {
  const { t } = useTranslation();

  return (
    <GridToolbarContainer>
      <Box sx={{
        p: 0.5,
        pb: 0,
      }}>
        <TextField
          variant="standard"
          value={props.value}
          onChange={props.onChange}
          placeholder={t("Search…")}
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                title={t("Clear")}
                aria-label={t("Clear")}
                size="small"
                style={{ visibility: props.value ? 'visible' : 'hidden' }}
                onClick={props.clearSearch}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
          sx={{
            width: {
              xs: 1,
              sm: 'auto',
            },
            m: (theme) => theme.spacing(1, 0.5, 1.5),
            '& .MuiSvgIcon-root': {
              mr: 0.5,
            },
            '& .MuiInput-underline:before': {
              borderBottom: 1,
              borderColor: 'divider',
            },
          }}
        />
      </Box>
      {props.addButtonText && (
        <Box sx={{ marginLeft: 'auto' }}>
          <Button color="primary" startIcon={<AddIcon />} onClick={props.openAddDialog}>
            {t(props.addButtonText)}
          </Button>
        </Box>
      )}
    </GridToolbarContainer>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  openAddDialog: PropTypes.func.isRequired,
  addButtonText: PropTypes.string,
};

const DataGridFilterable = (props) => {
  const { t } = useTranslation();

  return (
    <Card>
      <Box sx={{ display: 'flex', height: '100%', flexGrow: 1 }}>
        <DataGrid
          {...props}
          components={{
            NoRowsOverlay: () => (
              <Stack alignItems="center" justifyContent="center" sx={{pt: 15, fontSize: 16, fontWeight: 'bold'}}>
                {t('No data found')}
              </Stack>
            ),
            Toolbar: QuickSearchToolbar
          }}
          componentsProps={{
            toolbar: {
              openAddDialog: () => props.openAddDialog(),
              value: props.searchText,
              onChange: (event) => props.requestSearch(event.target.value),
              clearSearch: () => props.requestSearch(''),
              addButtonText: props.addButtonText
            },
            pagination: {
              labelRowsPerPage: t('Rows per page')
            }
          }}
        />
      </Box>
    </Card>
  );
}

export default DataGridFilterable;