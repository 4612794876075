import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { updateUser, userSelector, userClearState } from '../../features/UserSlice';
import { toast } from 'react-toastify';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useTranslation } from 'react-i18next';

const AccountProfilePassword = (props) => {
  const dispatch = useDispatch();
  const { isPasswordUpdateSuccess, isFetching } = useSelector(userSelector);
  const initialValues = {
    oldPassword: '',
    password: ''
  };
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (isPasswordUpdateSuccess) {
      toast.success(t('Password updated successfully'), {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
    return () => {
      dispatch(userClearState());
    }
  }, [isPasswordUpdateSuccess, dispatch, t]);

  const handleSubmit = (data, resetForm) => {
    dispatch(updateUser(data)).then(() => {
      resetForm();
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  return (
    <Box sx={{ pt: 3 }}>
      <Formik
        initialValues={initialValues}
        validationSchema={
          Yup.object().shape({
            oldPassword: Yup.string().max(255).required(t('Old password is required')),
            password: Yup.string().max(255).required(t('New password is required'))
          })
        }
        onSubmit={(data, { resetForm }) => {
          handleSubmit(data, resetForm);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue
        }) => (
          <form id="update-password" onSubmit={handleSubmit}>
            <Card>
              <CardHeader title={t("Update password")} />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <TextField
                        error={Boolean(touched.oldPassword && errors.oldPassword)}
                        fullWidth
                        helperText={touched.oldPassword && errors.oldPassword}
                        label={t("Old password")}
                        name="oldPassword"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type={showOldPassword ? 'text' : 'password'}
                        value={values.oldPassword}
                        variant="outlined"
                        InputProps={{
                          endAdornment:
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowOldPassword}
                                onMouseDown={(e) => e.preventDefault()}
                              >
                                {showOldPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                        }}
                      />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        label={t("New password")}
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type={showPassword ? 'text' : 'password'}
                        value={values.password}
                        variant="outlined"
                        InputProps={{
                          endAdornment:
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={(e) => e.preventDefault()}
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                        }}
                      />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 2
                }}
              >
                <Button
                  color="primary"
                  disabled={isSubmitting && isFetching}
                  type="submit"
                  variant="contained"
                >
                  {t("Update password")}
                </Button>
              </Box>
            </Card>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default AccountProfilePassword;
