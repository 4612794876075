import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { en } from './assets/i18n/en';
import { ro } from './assets/i18n/ro';

const resources = {
  en: en,
  ro: ro
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'en',
    lng: localStorage.getItem('lng'),
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;