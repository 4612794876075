const validateCIF = (cif = '') => {
  if (typeof cif === 'string') {
    if (cif.toUpperCase().includes('RO')) {
      cif = cif.substr(2).trim();
    }

    cif = parseInt(cif);

    if (cif.length > 10 || cif.length < 2) {
      return false;
    }

    let v = 753217532;
    let t = 0;
    const c1 = cif % 10;

    cif = parseInt(cif / 10);

    while (cif > 0) {
      t += (cif % 10) * (v % 10);
      cif = parseInt(cif / 10);
      v = parseInt(v / 10);
    }

    let c2 = t * 10 % 11;

    if (c2 === 10) {
      c2 = 0;
    }

    return c1 === c2;
  }
};

export default validateCIF;
