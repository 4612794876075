import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@material-ui/core';
import GlobalStyles from './components/GlobalStyles';
import theme from './theme';
import routes from './routes';
import UserService from './services';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { userSelector, getUser } from './features/UserSlice';
import Loading from './components/Loading';

const App = () => {
  const dispatch = useDispatch();
  const content = useRoutes(routes(UserService.isAuthenticated()));
  const { user } = useSelector(userSelector);

  useEffect(() => {
    if (UserService.isAuthenticated()) {
      dispatch(getUser());
    }
  }, [dispatch]);

  if (UserService.isAuthenticated()) {
    if (!user) {
      return <Loading />;
    }
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {content}
        <ToastContainer />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
