import React from 'react';
import { useState } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import TableIcon from 'src/icons/table_icons/TableIcon';
import { useTranslation } from 'react-i18next';
import Dialog from '../../components/Dialog';
import { customFreeStand } from '../../features/AdminSlice';
import { customGetAreas } from 'src/features/ShopSlice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading';
import { LoadingButton } from '@mui/lab';
import { addExistingOrderToCart } from 'src/features/OrderSlice';

const OrderStand = ({ id, name, seats, status, statuses, order }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const defaultColor = '#009688';
  const defaultName = 'free';
  const currentStatus = status;
  const navigate = useNavigate();
  const [recentOrderId, setRecentOrderId] = useState(null);
  const [isViewOrderAvailable, setIsViewOrderAvailable] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    if (order) {
      setIsViewOrderAvailable(true);
      setRecentOrderId(order.id);
    } else {
      toast.error(t('Error! There is no recent order found on this table!'), {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      console.error(t('Error! There is no recent order found on this table!'));
    }
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  const handleStatusChange = (newStatus) => {
    setIsSubmitting(true);
    if (newStatus.slug && newStatus.slug.includes('free')) {
      dispatch(customFreeStand({ id: id }))
        .then((response) => {
          toast.success(t('Stand updated successfully to "free"!'), {
            position: 'bottom-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          setIsSubmitting(false);
          dispatch(customGetAreas());
          handleClose();
        })
        .catch((error) => {
          toast.error(t(error.message || t('Failed to free the stand')), {
            position: 'bottom-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
        });
    }
  };

  const handleFreeTableClick = () => {
    if (
      !currentStatus ||
      (currentStatus &&
        currentStatus.slug &&
        currentStatus.slug.includes('free'))
    ) {
      navigate(`/app/stands/${id}`, {
        state: { name, seats, status, statuses },
        replace: true,
      });
    }
  };

  const handleViewOrder = () => {
    if (recentOrderId) {
      dispatch(
        addExistingOrderToCart(
          order.orderProducts.map((item, index) => ({
            ...item.product,
            id: item.product.id,
            quantity: item.qty,
            comments: item.comments,
            index: index
          }))
        )
      );

      navigate(`/app/stands/${id}/orders/${recentOrderId}`, {
        state: {
          name,
          seats,
          status,
          statuses,
          orderNumber: order.orderNumber,
        },
        replace: true,
      });
    } else {
      toast.error(t('No recent order found'), {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  };

  if (
    !currentStatus ||
    (currentStatus.slug && currentStatus.slug.includes('free'))
  ) {
    return (
      <Button onClick={handleFreeTableClick}>
        <Box>
          <TableIcon
            name={name}
            seats={seats}
            status={currentStatus}
          />
        </Box>
      </Button>
    );
  }

  return (
    <>
      <Button onClick={handleOpen}>
        <Box>
          <TableIcon
            name={name}
            seats={seats}
            status={currentStatus}
          />
        </Box>
      </Button>

      <Dialog
        title={`${t('Actions')}: ${name}`}
        open={open}
        handleClose={handleClose}
        maxWidth='xs'
        fullWidth
      >
        {' '}
        {isViewOrderAvailable ? (
          <Grid
            container
            spacing={2}
            justifyContent='center'
          >
            {currentStatus && (
              <>
                <Grid
                  item
                  xs={8}
                >
                  <Button
                    variant='contained'
                    color='warning'
                    fullWidth
                    size='medium'
                    onClick={handleViewOrder}
                    disabled={!isViewOrderAvailable}
                  >
                    {t('View order')}
                  </Button>
                </Grid>
              </>
            )}

            {statuses.map((status) => {
              if (!status.showInDashboard || !currentStatus) {
                return null;
              }

              return (
                <Grid
                  item
                  xs={8}
                  key={status['@id']}
                >
                  <LoadingButton
                    fullWidth
                    loading={isSubmitting}
                    variant='contained'
                    color='primary'
                    onClick={() => handleStatusChange(status)}
                    disabled={isSubmitting}
                    sx={{
                      backgroundColor: status.color || defaultColor,
                      '&:hover': {
                        backgroundColor: status.color || defaultColor,
                      },
                      '&:disabled': {
                        backgroundColor: '#E0E0E0!important',
                        color: '#B1B1B1!important',
                        border: '1px solid #B1B1B1',
                      },
                      height: '35px',
                    }}
                  >
                    {!isSubmitting && (
                      <span
                        style={{
                          display: 'block',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {status.name ? t(status.name) : t(defaultName)}
                      </span>
                    )}
                  </LoadingButton>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Loading />
        )}
      </Dialog>
    </>
  );
};

export default OrderStand;
