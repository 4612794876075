import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from '../api/index.js';

export const createShop = createAsyncThunk(
  "shop/createShop",
  async (data, thunkAPI) => {
    try {
      return api.createShop(data)
        .then((response) => {
          if (response.status === 201) {
            return { ...response.data};
          } else {
            return thunkAPI.rejectWithValue('There was an error with your request. Please try again later.');
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail ?
                e.response.data.detail
                : e.response.data['hydra:description']
          );
        });
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.detail);
    }
  }
);

export const updateShop = createAsyncThunk(
  'shop/updateShop',
  async(data, thunkAPI) => {
    try {
      return api.updateShop(data.endpoint, data.data)
        .then((response) => {
          if (response.status === 200) {
            return {...response.data};
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail ?
                e.response.data.detail
                : e.response.data['hydra:description']
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getOrderTypes = createAsyncThunk(
  'shops/getOrderTypes',
  async(data, thunkAPI) => {
    try {
      return api.getOrderTypes()
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getPaymentTypes = createAsyncThunk(
  'shops/getPaymentTypes',
  async(data, thunkAPI) => {
    try {
      return api.getPaymentTypes()
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getProductTypes = createAsyncThunk(
  'shops/getProductTypes',
  async(data, thunkAPI) => {
    try {
      return api.getProductTypes()
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getUnits = createAsyncThunk(
  'admin/getUnits',
  async(data, thunkAPI) => {
    try {
      return api.getUnits()
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getUnitsByShopIdForAdmin = createAsyncThunk(
  'admin/getUnitsByShopIdForAdmin',
  async(shopId, thunkAPI) => {
    try {
      return api.getUnitsByShopIdForAdmin(shopId)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getVats = createAsyncThunk(
  'admin/getVats',
  async(data, thunkAPI) => {
    try {
      return api.getVats()
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getVatsByShopIdForAdmin = createAsyncThunk(
  'admin/getVatsByShopIdForAdmin',
  async(shopId, thunkAPI) => {
    try {
      return api.getVatsByShopIdForAdmin(shopId)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getShop = createAsyncThunk(
  'shops/getShop',
  async(data, thunkAPI) => {
    try {
      return api.getShop(data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getShops = createAsyncThunk(
  'shops/getShops',
  async(data, thunkAPI) => {
    try {
      return api.getShops()
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const softDeleteShop = createAsyncThunk(
  'shops/softDeleteShop',
  async(data, thunkAPI) => {
    try {
      return api.softDeleteShop(data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getCustomers = createAsyncThunk(
  'shops/getCustomers',
  async(data, thunkAPI) => {
    try {
      return api.getCustomers()
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const updateCustomer = createAsyncThunk(
  'shop/updateCustomer',
  async(data, thunkAPI) => {
    try {
      return api.updateCustomer(data.endpoint, data.data)
        .then((response) => {
          if (response.status === 200) {
            return {...response.data};
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail ?
                e.response.data.detail
                : e.response.data['hydra:description']
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const softDeleteCustomer = createAsyncThunk(
  'shops/softDeleteCustomer',
  async(data, thunkAPI) => {
    try {
      return api.softDeleteCustomer(data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getSelectedCustomer = createAsyncThunk(
  'users/getSelectedCustomer',
  async(data, thunkAPI) => {
    try {
      return api.getCustomer(data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const addCustomer = createAsyncThunk(
  "shop/addCustomer",
  async (data, thunkAPI) => {
    try {
      return api.addCustomer(data)
        .then((response) => {
          if (response.status === 201) {
            return { ...response.data};
          } else {
            return thunkAPI.rejectWithValue('There was an error with your request. Please try again later.');
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail ?
                e.response.data.detail
                : e.response.data['hydra:description']
          );
        });
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.detail);
    }
  }
);

export const addCustomerAddress = createAsyncThunk(
  "shop/addCustomerAddress",
  async (data, thunkAPI) => {
    try {
      return api.addCustomerAddress(data)
        .then((response) => {
          if (response.status === 201) {
            return { ...response.data};
          } else {
            return thunkAPI.rejectWithValue('There was an error with your request. Please try again later.');
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail ?
                e.response.data.detail
                : e.response.data['hydra:description']
          );
        });
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.detail);
    }
  }
);

export const getProducts = createAsyncThunk(
  'shop/getProducts',
  async(data, thunkAPI) => {
    try {
      return api.getProducts()
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getProductsByShopIdForAdmin = createAsyncThunk(
  'shop/getProductsByShopIdForAdmin',
  async(shopId, thunkAPI) => {
    try {
      return api.getProductsByShopIdForAdmin(shopId)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getSelectedProduct = createAsyncThunk(
  'shop/getSelectedProduct',
  async(data, thunkAPI) => {
    try {
      return api.getProduct(data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const addProduct = createAsyncThunk(
  'shop/addProduct',
  async(data, thunkAPI) => {
    try {
      return api.addProduct(data)
        .then((response) => {
          if (response.status === 201) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const updateProduct = createAsyncThunk(
  'shop/updateProduct',
  async(data, thunkAPI) => {
    try {
      const id = data.id;
      delete data.id;
      return api.updateProduct(id, data.data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const softDeleteProduct = createAsyncThunk(
  'shop/softDeleteProduct',
  async(data, thunkAPI) => {
    try {
      return api.softDeleteProduct(data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  'shop/deleteProduct',
  async(data, thunkAPI) => {
    try {
      return api.deleteProduct(data)
        .then((response) => {
          if (response.status === 204) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getProductCategories = createAsyncThunk(
  'shop/getProductCategories',
  async(data, thunkAPI) => {
    try {
      return api.getProductCategories()
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getProductCategoriesByShopIdForAdmin = createAsyncThunk(
  'shop/getProductCategoriesByShopIdForAdmin',
  async(shopId, thunkAPI) => {
    try {
      return api.getProductCategoriesByShopIdForAdmin(shopId)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getParentProductCategories = createAsyncThunk(
  'shop/getParentProductCategories',
  async(data, thunkAPI) => {
    try {
      return api.getParentProductCategories()
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getSelectedProductCategory = createAsyncThunk(
  'shop/getSelectedProductCategory',
  async(data, thunkAPI) => {
    try {
      return api.getProductCategory(data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getEditingProductCategory = createAsyncThunk(
  'shop/getEditingProductCategory',
  async(data, thunkAPI) => {
    try {
      const response = await api.getProductCategory(data);
      if (response.status === 200) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data.violations ?
        e.response.data.violations[0].message
        : e.response.data.message ?
          e.response.data.message
          : e.response.data.detail
      );
    }
  }
);

export const addProductCategory = createAsyncThunk(
  'shop/addProductCategory',
  async(data, thunkAPI) => {
    try {
      return api.addProductCategory(data)
        .then((response) => {
          if (response.status === 201) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const updateProductCategory = createAsyncThunk(
  'shop/updateProductCategory',
  async(data, thunkAPI) => {
    try {
      const id = data.id;
      delete data.id;
      return api.updateProductCategory(id, data.data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const softDeleteProductCategory = createAsyncThunk(
  'shop/softDeleteProductCategory',
  async(data, thunkAPI) => {
    try {
      return api.softDeleteProductCategory(data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const deleteProductCategory = createAsyncThunk(
  'shop/deleteProductCategory',
  async(data, thunkAPI) => {
    try {
      return api.deleteProductCategory(data)
        .then((response) => {
          if (response.status === 204) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getInventories = createAsyncThunk(
  'admin/getInventories',
  async(data, thunkAPI) => {
    try {
      return api.getInventories()
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getInventoriesByShopIdForAdmin = createAsyncThunk(
  'admin/getInventoriesByShopIdForAdmin',
  async(shopId, thunkAPI) => {
    try {
      return api.getInventoriesByShopIdForAdmin(shopId)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getInventory = createAsyncThunk(
  'admin/getInventory',
  async(data, thunkAPI) => {
    try {
      return api.getInventory(data)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        } else {
          return thunkAPI.rejectWithValue(response);
        }
      })
      .catch((e) => {
        return thunkAPI.rejectWithValue(e.response.data.violations ?
          e.response.data.violations[0].message
          : e.response.data.message ?
            e.response.data.message
            : e.response.data.detail
        );
      });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const customCreateInventory = createAsyncThunk(
  'shop/customCreateInventory',
  async(data, thunkAPI) => {
    try {
      return api.customCreateInventory(data)
      .then((response) => {
        if (response.status === 201) {
          return response.data;
        } else {
          return thunkAPI.rejectWithValue(response);
        }
      })
      .catch((e) => {
        return thunkAPI.rejectWithValue(e.response.data.violations ?
          e.response.data.violations[0].message
          : e.response.data.message ?
            e.response.data.message
            : e.response.data.detail
        );
      });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const updateInventory = createAsyncThunk(
  'admin/updateInventory',
  async(data, thunkAPI) => {
    try {
      const id = data.id;
      delete data.id;
      return api.updateInventory(id, data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const softDeleteInventory = createAsyncThunk(
  'admin/softDeleteInventory',
  async(data, thunkAPI) => {
    try {
      return api.softDeleteInventory(data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const deleteInventory = createAsyncThunk(
  'admin/deleteInventory',
  async(data, thunkAPI) => {
    try {
      return api.deleteInventory(data)
        .then((response) => {
          if (response.status === 204) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getInventoryProducts = createAsyncThunk(
  'admin/getInventoryProducts',
  async(data, thunkAPI) => {
    try {
      return api.getInventoryProducts()
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getInventoryProduct = createAsyncThunk(
  'admin/getInventoryProduct',
  async(data, thunkAPI) => {
    try {
      return api.getInventoryProduct(data)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        } else {
          return thunkAPI.rejectWithValue(response);
        }
      })
      .catch((e) => {
        return thunkAPI.rejectWithValue(e.response.data.violations ?
          e.response.data.violations[0].message
          : e.response.data.message ?
            e.response.data.message
            : e.response.data.detail
        );
      });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const updateInventoryProduct = createAsyncThunk(
  'admin/updateInventoryProduct',
  async(data, thunkAPI) => {
    try {
      const id = data.id;
      delete data.id;
      return api.updateInventoryProduct(id, data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const softDeleteInventoryProduct = createAsyncThunk(
  'admin/softDeleteInventoryProduct',
  async(data, thunkAPI) => {
    try {
      return api.softDeleteInventoryProduct(data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const deleteInventoryProduct = createAsyncThunk(
  'admin/deleteInventoryProduct',
  async(data, thunkAPI) => {
    try {
      return api.deleteInventoryProduct(data)
        .then((response) => {
          if (response.status === 204) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getDocumentTypes = createAsyncThunk(
  'admin/getDocumentTypes',
  async(data, thunkAPI) => {
    try {
      return api.getDocumentTypes()
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getRecipes = createAsyncThunk(
  'admin/getRecipes',
  async(data, thunkAPI) => {
    try {
      return api.getRecipes()
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getRecipesByShopIdForAdmin = createAsyncThunk(
  'admin/getRecipesByShopIdForAdmin',
  async(shopId, thunkAPI) => {
    try {
      return api.getRecipesByShopIdForAdmin(shopId)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getRecipe = createAsyncThunk(
  'admin/getRecipe',
  async(data, thunkAPI) => {
    try {
      return api.getRecipe(data)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        } else {
          return thunkAPI.rejectWithValue(response);
        }
      })
      .catch((e) => {
        return thunkAPI.rejectWithValue(e.response.data.violations ?
          e.response.data.violations[0].message
          : e.response.data.message ?
            e.response.data.message
            : e.response.data.detail
        );
      });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const customCreateRecipe = createAsyncThunk(
  'shop/customCreateRecipe',
  async(data, thunkAPI) => {
    try {
      return api.customCreateRecipe(data)
      .then((response) => {
        if (response.status === 201) {
          return response.data;
        } else {
          return thunkAPI.rejectWithValue(response);
        }
      })
      .catch((e) => {
        return thunkAPI.rejectWithValue(e.response.data.violations ?
          e.response.data.violations[0].message
          : e.response.data.message ?
            e.response.data.message
            : e.response.data.detail
        );
      });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const updateRecipe = createAsyncThunk(
  'admin/updateRecipe',
  async(data, thunkAPI) => {
    try {
      const id = data.id;
      delete data.id;
      return api.updateRecipe(id, data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const softDeleteRecipe = createAsyncThunk(
  'admin/softDeleteRecipe',
  async(data, thunkAPI) => {
    try {
      return api.softDeleteRecipe(data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const deleteRecipe = createAsyncThunk(
  'admin/deleteRecipe',
  async(data, thunkAPI) => {
    try {
      return api.deleteRecipe(data)
        .then((response) => {
          if (response.status === 204) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getRecipeProducts = createAsyncThunk(
  'admin/getRecipeProducts',
  async(data, thunkAPI) => {
    try {
      return api.getRecipeProducts()
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getRecipeProduct = createAsyncThunk(
  'admin/getRecipeProduct',
  async(data, thunkAPI) => {
    try {
      return api.getRecipeProduct(data)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        } else {
          return thunkAPI.rejectWithValue(response);
        }
      })
      .catch((e) => {
        return thunkAPI.rejectWithValue(e.response.data.violations ?
          e.response.data.violations[0].message
          : e.response.data.message ?
            e.response.data.message
            : e.response.data.detail
        );
      });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const updateRecipeProduct = createAsyncThunk(
  'admin/updateRecipeProduct',
  async(data, thunkAPI) => {
    try {
      const id = data.id;
      delete data.id;
      return api.updateRecipeProduct(id, data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const softDeleteRecipeProduct = createAsyncThunk(
  'admin/softDeleteRecipeProduct',
  async(data, thunkAPI) => {
    try {
      return api.softDeleteRecipeProduct(data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const deleteRecipeProduct = createAsyncThunk(
  'admin/deleteRecipeProduct',
  async(data, thunkAPI) => {
    try {
      return api.deleteRecipeProduct(data)
        .then((response) => {
          if (response.status === 204) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getAreas = createAsyncThunk(
  'admin/getAreas',
  async(data, thunkAPI) => {
    try {
      return api.getAreas()
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const customGetAreas = createAsyncThunk(
  'admin/customGetAreas',
  async(data, thunkAPI) => {
    try {
      return api.customGetAreas()
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const shopSlice = createSlice({
  name: "shop",
  initialState: {
    id: "",
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    orderTypes: [],
    paymentTypes: [],
    shop: null,
    shops: null,
    customers: [],
    selectedCustomer: null,
    products: [],
    selectedProduct: null,
    productCategories: [],
    parentProductCategories: [],
    selectedProductCategory: null,
    editingProductCategory: null,
    productTypes: [],
    units: [],
    vats: [],
    inventory: null,
    inventories: [],
    documentTypes: [],
    inventoryProduct: null,
    inventoryProducts: [],
    recipe: null,
    recipes: [],
    areas: [],
  },
  reducers: {
    shopClearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.selectedCustomer = null;
      state.selectedProduct = null;
      state.selectedProductCategory = null;
      state.editingProductCategory = null;

      return state;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createShop.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.id = payload?.id;
      })
      .addCase(createShop.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(createShop.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(updateShop.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.id = payload?.id;
      })
      .addCase(updateShop.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(updateShop.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getOrderTypes.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.orderTypes = payload['hydra:member'];
        return state;
      })
      .addCase(getOrderTypes.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getOrderTypes.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getPaymentTypes.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.paymentTypes = payload['hydra:member'];
        return state;
      })
      .addCase(getPaymentTypes.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getPaymentTypes.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getProductTypes.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.productTypes = payload['hydra:member'];
        return state;
      })
      .addCase(getProductTypes.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getProductTypes.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getUnits.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.units = payload['hydra:member'];
        return state;
      })
      .addCase(getUnits.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getUnits.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getUnitsByShopIdForAdmin.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.units = payload['hydra:member'];
        return state;
      })
      .addCase(getUnitsByShopIdForAdmin.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getUnitsByShopIdForAdmin.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getVats.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.vats = payload['hydra:member'];
        return state;
      })
      .addCase(getVats.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getVats.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getVatsByShopIdForAdmin.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.vats = payload['hydra:member'];
        return state;
      })
      .addCase(getVatsByShopIdForAdmin.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getVatsByShopIdForAdmin.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getShop.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.shop = payload;
        return state;
      })
      .addCase(getShop.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getShop.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getShops.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.shops = payload['hydra:member'];
        return state;
      })
      .addCase(getShops.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getShops.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(softDeleteShop.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(softDeleteShop.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(softDeleteShop.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getCustomers.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.customers = payload['hydra:member'];
        return state;
      })
      .addCase(getCustomers.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getCustomers.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(updateCustomer.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.id = payload?.id;
      })
      .addCase(updateCustomer.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(updateCustomer.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(softDeleteCustomer.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(softDeleteCustomer.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(softDeleteCustomer.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getSelectedCustomer.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.selectedCustomer = payload;
        return state;
      })
      .addCase(getSelectedCustomer.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getSelectedCustomer.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(addCustomer.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.id = payload?.id;
      })
      .addCase(addCustomer.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(addCustomer.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(addCustomerAddress.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.id = payload?.id;
      })
      .addCase(addCustomerAddress.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(addCustomerAddress.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getProducts.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.products = payload['hydra:member'];
        return state;
      })
      .addCase(getProducts.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getProducts.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getProductsByShopIdForAdmin.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.products = payload['hydra:member'];
        return state;
      })
      .addCase(getProductsByShopIdForAdmin.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getProductsByShopIdForAdmin.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getSelectedProduct.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.selectedProduct = payload;
        return state;
      })
      .addCase(getSelectedProduct.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getSelectedProduct.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(addProduct.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(addProduct.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(addProduct.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(updateProduct.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(updateProduct.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(updateProduct.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(softDeleteProduct.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(softDeleteProduct.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(softDeleteProduct.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(deleteProduct.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(deleteProduct.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(deleteProduct.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getProductCategories.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.productCategories = payload['hydra:member'];
        return state;
      })
      .addCase(getProductCategories.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getProductCategories.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getProductCategoriesByShopIdForAdmin.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.productCategories = payload['hydra:member'];
        return state;
      })
      .addCase(getProductCategoriesByShopIdForAdmin.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getProductCategoriesByShopIdForAdmin.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getParentProductCategories.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.parentProductCategories = payload['hydra:member'];
        return state;
      })
      .addCase(getParentProductCategories.pending, (state, { payload }) => {
          state.isFetching = true;
      })
      .addCase(getParentProductCategories.rejected, (state, { payload }) => {
          state.isFetching = false;
          state.isError = true;
          state.errorMessage = payload;
      })
      .addCase(getSelectedProductCategory.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.selectedProductCategory = payload;
      })
      .addCase(getSelectedProductCategory.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getSelectedProductCategory.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getEditingProductCategory.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.editingProductCategory = payload;
        return state;
      })
      .addCase(getEditingProductCategory.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getEditingProductCategory.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(addProductCategory.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(addProductCategory.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(addProductCategory.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(updateProductCategory.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(updateProductCategory.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(updateProductCategory.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(softDeleteProductCategory.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(softDeleteProductCategory.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(softDeleteProductCategory.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(deleteProductCategory.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(deleteProductCategory.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(deleteProductCategory.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getInventories.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.inventories = payload['hydra:member'];
        return state;
      })
      .addCase(getInventories.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getInventories.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getInventoriesByShopIdForAdmin.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.inventories = payload['hydra:member'];
        return state;
      })
      .addCase(getInventoriesByShopIdForAdmin.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getInventoriesByShopIdForAdmin.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getInventory.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.inventory = payload;
        return state;
      })
      .addCase(getInventory.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getInventory.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(customCreateInventory.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(customCreateInventory.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(customCreateInventory.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(updateInventory.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(updateInventory.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(updateInventory.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(softDeleteInventory.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(softDeleteInventory.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(softDeleteInventory.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(deleteInventory.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(deleteInventory.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(deleteInventory.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getDocumentTypes.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.documentTypes = payload['hydra:member'];
        return state;
      })
      .addCase(getDocumentTypes.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getDocumentTypes.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getInventoryProducts.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.inventoryProducts = payload['hydra:member'];
        return state;
      })
      .addCase(getInventoryProducts.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getInventoryProducts.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getInventoryProduct.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.inventoryProduct = payload;
        return state;
      })
      .addCase(getInventoryProduct.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getInventoryProduct.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(updateInventoryProduct.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(updateInventoryProduct.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(updateInventoryProduct.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(softDeleteInventoryProduct.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(softDeleteInventoryProduct.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(softDeleteInventoryProduct.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(deleteInventoryProduct.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(deleteInventoryProduct.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(deleteInventoryProduct.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getRecipes.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.recipes = payload['hydra:member'];
        return state;
      })
      .addCase(getRecipes.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getRecipes.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getRecipesByShopIdForAdmin.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.recipes = payload['hydra:member'];
        return state;
      })
      .addCase(getRecipesByShopIdForAdmin.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getRecipesByShopIdForAdmin.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getRecipe.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.recipe = payload;
        return state;
      })
      .addCase(getRecipe.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getRecipe.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(customCreateRecipe.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(customCreateRecipe.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(customCreateRecipe.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(updateRecipe.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(updateRecipe.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(updateRecipe.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(softDeleteRecipe.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(softDeleteRecipe.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(softDeleteRecipe.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(deleteRecipe.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(deleteRecipe.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(deleteRecipe.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getRecipeProducts.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.recipeProducts = payload['hydra:member'];
        return state;
      })
      .addCase(getRecipeProducts.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getRecipeProducts.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getRecipeProduct.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.recipeProduct = payload;
        return state;
      })
      .addCase(getRecipeProduct.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getRecipeProduct.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(updateRecipeProduct.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(updateRecipeProduct.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(updateRecipeProduct.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(softDeleteRecipeProduct.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(softDeleteRecipeProduct.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(softDeleteRecipeProduct.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(deleteRecipeProduct.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(deleteRecipeProduct.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(deleteRecipeProduct.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getAreas.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.areas = payload['hydra:member'];
        return state;
      })
      .addCase(getAreas.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getAreas.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(customGetAreas.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.areas = payload['hydra:member'];
        return state;
      })
      .addCase(customGetAreas.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(customGetAreas.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
  }
});

export const { shopClearState } = shopSlice.actions;

export const shopSelector = (state) => state.shop;
