import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
  Box,
  Button,
  MenuItem,
  Container,
  TextField,
  Grid,
} from '@material-ui/core';
import {
  addStand,
  getStand,
  updateStand,
  adminSelector,
} from '../../../features/AdminSlice';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const StandManageForm = (props) => {
  const { t } = useTranslation();
  const { handleClose, mode, standId, areas } = props;
  const { stand } = useSelector(adminSelector);
  const dispatch = useDispatch();
  const activeAreas = areas.filter((area) => area.active);
  const [initialValues, setInitialValues] = useState({
    name: '',
    seats: '',
    area: activeAreas.length > 0 ? activeAreas[0]['@id'] : '',
  });
  const validationSchema = {
    name: Yup.string().max(255).required(t('Name is required')),
    seats: Yup.number()
      .typeError(t('You must specify a seats number'))
      .required(t('Value is required for seats'))
      .min(0, t('Value for seats must be greater than or equal to 0')),
    area: Yup.string().required(t('Area is required')),
  };

  useEffect(() => {
    if (mode === 'edit' && standId) {
      dispatch(getStand(standId));
    }
  }, [dispatch, mode, standId]);

  useEffect(() => {
    if (mode === 'edit' && stand) {
      const activeAreaIds = areas
        .filter((area) => area.active)
        .map((area) => area['@id']);

      let selectedArea = stand.area['@id'];

      if (!activeAreaIds.includes(stand.area['@id'])) {
        selectedArea = activeAreaIds.length > 0 ? activeAreaIds[0] : '';
      }

      setInitialValues({
        name: t(stand.name),
        seats: stand.seats,
        area: selectedArea,
      });
    }
  }, [stand, areas, mode, t]);

  const handleSubmit = (data, formikData) => {
    const selectedArea = areas.find((area) => area['@id'] === data.area);
    if (!selectedArea || !selectedArea.active) {
      toast.error(t('Please select an active area.'), {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      return;
    }
    if (mode === 'add') {
      dispatch(addStand(data)).then((response) => {
        if (!response.error) {
          toast.success(t('Data added successfully'), {
            position: 'bottom-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          handleClose();
        } else {
          toast.error(t(response.payload), {
            position: 'bottom-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
        }
      });
    } else {
      data.id = stand.id;
      dispatch(updateStand(data)).then((response) => {
        if (!response.error) {
          toast.success(t('Data updated successfully'), {
            position: 'bottom-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          handleClose();
        } else {
          toast.error(t(response.payload), {
            position: 'bottom-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
        }
      });
    }
    formikData.setSubmitting(false);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <Container maxWidth='sm'>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={Yup.object().shape(validationSchema)}
            onSubmit={(data, formikData) => handleSubmit(data, formikData)}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <Form id='add-stand'>
                <Grid
                  container
                  spacing={1}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label={t('Name')}
                      margin='dense'
                      name='name'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant='outlined'
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      error={Boolean(touched.seats && errors.seats)}
                      fullWidth
                      helperText={touched.seats && errors.seats}
                      label={t('Seats')}
                      margin='dense'
                      name='seats'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='number'
                      value={values.seats}
                      variant='outlined'
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      select
                      label={t('Area')}
                      name='area'
                      value={values.area}
                      onChange={handleChange}
                      variant='outlined'
                      fullWidth
                      helperText={touched.area && errors.area}
                      error={Boolean(touched.area && errors.area)}
                    >
                      {areas
                        .filter((area) => area.active)
                        .map((area) => (
                          <MenuItem
                            key={area['@id']}
                            value={area['@id']}
                          >
                            {area.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Box sx={{ py: 2 }}>
                  <Button
                    color='primary'
                    disabled={isSubmitting}
                    fullWidth
                    size='large'
                    type='submit'
                    variant='contained'
                  >
                    {t('Save')}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default StandManageForm;
