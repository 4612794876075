import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { getOrderTypes, getPaymentTypes, getShop, updateShop, shopSelector } from '../../features/ShopSlice';
import { userSelector } from '../../features/UserSlice';
import { toast } from 'react-toastify';
import Loading from '../Loading';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

const ShopSettings = (props) => {
  const dispatch = useDispatch();
  const { isFetching, orderTypes, paymentTypes, shop } = useSelector(shopSelector);
  const { user } = useSelector(userSelector);
  const initialValues = {
    orderTypes: shop?.orderTypes,
    paymentTypes: shop?.paymentTypes,
    orderNumberStart: shop?.orderNumberStart ?? 0
  };
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    orderNumberStart: Yup.number()
      .typeError(t('You must specify a number'))
      .required(t('Value is required'))
      .min(0, t('Value must be greater than or equal to 0')),
  });
  

  useEffect(() => {
    if (user) {
      dispatch(getShop(user.shop.id));
    }
    dispatch(getOrderTypes());
    dispatch(getPaymentTypes());
  }, [dispatch, user]);

  const handleSubmit = (data) => {
    dispatch(updateShop({endpoint: user.shop.id, data: data})).then((response) => {
      if (!response.error) {
        toast.success(t('Settings saved successfully'), {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      } else {
        toast.error(response.payload, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
    });
  };

  if (!user || !shop) {
    return <Loading />;
  }

  return (
    <Box sx={{ pt: 3 }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(data, { resetForm }) => {
          handleSubmit(data, resetForm);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form id="shop-settings" onSubmit={handleSubmit}>
            <Card>
              <CardHeader title={t("Shop settings")} />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={4} xs={12}>
                    <TextField
                      error={Boolean(touched.orderNumberStart && errors.orderNumberStart)}
                      helperText={touched.orderNumberStart && errors.orderNumberStart}
                      fullWidth
                      label={t("Order Number")}
                      name="orderNumberStart"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="number"
                      value={values.orderNumberStart}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    {orderTypes.length ? (
                      <FormControl component="fieldset" variant="standard">
                        <FormLabel component="legend">{t("Order Types")}</FormLabel>
                        <FormGroup>
                          {orderTypes.map(orderType => (
                            <FormControlLabel
                              key={orderType.id}
                              onChange={handleChange}
                              control={
                                <Checkbox
                                  name="orderTypes"
                                  value={orderType['@id']}
                                  defaultChecked={shop.orderTypes.includes(orderType['@id'])}
                                />
                              }
                              label={t(orderType.name)}
                            />
                          ))}
                        </FormGroup>
                      </FormControl>
                    ) : null}
                  </Grid>
                  <Grid item md={3} xs={12}>
                    {paymentTypes.length ? (
                      <FormControl component="fieldset" variant="standard">
                        <FormLabel component="legend">{t("Payment Types")}</FormLabel>
                        <FormGroup>
                          {paymentTypes.map(paymentType => (
                            <FormControlLabel
                              key={paymentType.id}
                              onChange={handleChange}
                              control={
                                <Checkbox
                                  name="paymentTypes"
                                  value={paymentType['@id']}
                                  defaultChecked={shop.paymentTypes.includes(paymentType['@id'])}
                                />
                              }
                              label={t(paymentType.name)}
                            />
                          ))}
                        </FormGroup>
                      </FormControl>
                    ) : null}
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 2
                }}
              >
                <Button
                  color="primary"
                  disabled={isSubmitting && isFetching}
                  type="submit"
                  variant="contained"
                >
                  {t("Save settings")}
                </Button>
              </Box>
            </Card>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default ShopSettings;
