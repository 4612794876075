import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Grid,
  Autocomplete,
} from '@material-ui/core';
import { userSelector } from '../../features/UserSlice';
import {
  getSelectedProduct,
  getProducts,
  addProduct,
  updateProduct,
  shopSelector,
  getProductCategories,
  getProductCategoriesByShopIdForAdmin,
  getProductTypes,
  getUnits,
  getUnitsByShopIdForAdmin,
  getVats,
  getVatsByShopIdForAdmin,
} from '../../features/ShopSlice';
import Loading from '../Loading';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useParams } from 'react-router';
import UserService from 'src/services';

const ProductManageForm = (props) => {
  const { handleClose, mode, productId } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useSelector(userSelector);
  const {
    selectedProduct,
    productTypes,
    units,
    vats,
    selectedProductCategory,
  } = useSelector(shopSelector);
  const { shopId } = useParams();
  const isAdmin = UserService.isAdmin();

  const [initialValues, setInitialValues] = useState({
    name: '',
    description: '',
    price: '',
    unit: '',
    vat: '',
    productType: '',
    quantity: 0,
    category: '',
  });

  const safeInitialValues = {
    name: initialValues.name || '',
    description: initialValues.description || '',
    price: initialValues.price || '',
    unit: initialValues.unit || '',
    vat: initialValues.vat || '',
    productType: initialValues.productType || '',
    quantity: initialValues.quantity || '',
    category: initialValues.category || '',
  };

  useEffect(() => {
    dispatch(getProductTypes());
    if (shopId && isAdmin) {
      dispatch(getProductCategoriesByShopIdForAdmin(shopId));
      dispatch(getUnitsByShopIdForAdmin(shopId));
      dispatch(getVatsByShopIdForAdmin(shopId));
    } else {
      dispatch(getProductCategories());
      dispatch(getUnits());
      dispatch(getVats());
    }
  }, [dispatch, shopId, isAdmin]);

  useEffect(() => {
    if (mode === 'edit' && productId) {
      dispatch(getSelectedProduct(productId));
    }
  }, [dispatch, mode, productId]);

  useEffect(() => {
    let initialVal = {};
    if (mode === 'edit' && selectedProduct) {
      initialVal = {
        name: selectedProduct.name,
        description: selectedProduct.description,
        price: String(selectedProduct.price),
        unit: selectedProduct.unit['@id'],
        vat: selectedProduct.vat['@id'],
        productType: selectedProduct.productType['@id'],
        quantity: selectedProduct.quantity,
        category: selectedProductCategory['@id'],
      };
    } else {
      initialVal = {
        name: '',
        description: '',
        price: '',
        unit: '',
        vat: '',
        productType: '',
        quantity: 0,
        category: '',
      };
    }
    setInitialValues(initialVal);
  }, [selectedProduct, mode, selectedProductCategory]);

  const handleSubmit = (data, formikData) => {
    if (mode === 'add') {
      data.shop = isAdmin ? `/api/shops/` + shopId : user.shop['@id'];
      data.category = selectedProductCategory['@id'];
      dispatch(addProduct(data)).then((response) => {
        if (!response.error) {
          handleClose();
          dispatch(getProducts());
        }
      });
    } else {
      dispatch(updateProduct({ id: selectedProduct.id, data: data })).then(
        (response) => {
          if (!response.error) {
            handleClose();
            dispatch(getProducts());
          }
        }
      );
    }
    formikData.setSubmitting(false);
  };

  if (mode === 'edit' && !selectedProduct) {
    return <Loading />;
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <Container>
          <Grid container>
            <Formik
              initialValues={safeInitialValues}
              enableReinitialize={true}
              validationSchema={Yup.object().shape({
                name: Yup.string().max(255).required(t('Name is required')),
                price: Yup.number()
                  .typeError(t('You must specify a number'))
                  .required(t('Price is required')),
                unit: Yup.string().max(20).required(t('Unit is required')),
                vat: Yup.string().max(20).required(t('Vat is required')),
                productType: Yup.string()
                  .max(20)
                  .required(t('Type is required')),
                quantity: Yup.number()
                  .typeError(t('You must specify a number'))
                  .required(t('Quantity is required')),
              })}
              onSubmit={(data, formikData) => handleSubmit(data, formikData)}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue,
                dirty,
                isValid,
              }) => (
                <Form id={mode}>
                  <Grid
                    container
                    spacing={1}
                  >
                    <Grid
                      item
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.name && errors.name)}
                        fullWidth
                        helperText={touched.name && errors.name}
                        label={t('Name')}
                        margin='dense'
                        name='name'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                        variant='outlined'
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <TextField
                        error={Boolean(
                          touched.description && errors.description
                        )}
                        fullWidth
                        helperText={touched.description && errors.description}
                        label={t('Description')}
                        margin='dense'
                        name='description'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.description}
                        variant='outlined'
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.price && errors.price)}
                        fullWidth
                        helperText={touched.price && errors.price}
                        label={t('Price')}
                        margin='dense'
                        name='price'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.price}
                        variant='outlined'
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      {mode === 'edit' && selectedProduct ? (
                        <Autocomplete
                          sx={{ mt: 1, mb: 0.5 }}
                          freeSolo
                          name='unit'
                          value={
                            values.unit
                              ? _.find(units, { '@id': values.unit })
                              : { '@id': '', name: '' }
                          }
                          options={units}
                          getOptionLabel={(option) => option.name}
                          onChange={(e, value) =>
                            setFieldValue(
                              'unit',
                              value !== null ? value['@id'] : values.unit
                            )
                          }
                          includeInputInList
                          onOpen={handleBlur}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t('Unit')}
                              name='unit'
                              error={Boolean(touched.unit && errors.unit)}
                              helperText={touched.unit && errors.unit}
                            />
                          )}
                        />
                      ) : (
                        <Autocomplete
                          sx={{ mt: 1, mb: 0.5 }}
                          freeSolo
                          name='unit'
                          options={units}
                          getOptionLabel={(option) => option.name}
                          onChange={(e, value) =>
                            setFieldValue(
                              'unit',
                              value !== null ? value['@id'] : values.unit
                            )
                          }
                          includeInputInList
                          onOpen={handleBlur}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t('Unit')}
                              name='unit'
                              error={Boolean(touched.unit && errors.unit)}
                              helperText={touched.unit && errors.unit}
                            />
                          )}
                        />
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      {mode === 'edit' && selectedProduct ? (
                        <Autocomplete
                          sx={{ mt: 1, mb: 0.5 }}
                          freeSolo
                          name='vat'
                          value={
                            values.vat
                              ? _.find(vats, { '@id': values.vat })
                              : { '@id': '', name: '' }
                          }
                          options={vats}
                          getOptionLabel={(option) => option.name}
                          onChange={(e, value) =>
                            setFieldValue(
                              'vat',
                              value !== null ? value['@id'] : values.vat
                            )
                          }
                          includeInputInList
                          onOpen={handleBlur}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t('Vat')}
                              name='vat'
                              error={Boolean(touched.vat && errors.vat)}
                              helperText={touched.vat && errors.vat}
                            />
                          )}
                        />
                      ) : (
                        <Autocomplete
                          sx={{ mt: 1, mb: 0.5 }}
                          freeSolo
                          name='vat'
                          options={vats}
                          getOptionLabel={(option) => option.name}
                          onChange={(e, value) =>
                            setFieldValue(
                              'vat',
                              value !== null ? value['@id'] : values.vat
                            )
                          }
                          includeInputInList
                          onOpen={handleBlur}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t('Vat')}
                              name='vat'
                              error={Boolean(touched.vat && errors.vat)}
                              helperText={touched.vat && errors.vat}
                            />
                          )}
                        />
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      {mode === 'edit' && selectedProduct ? (
                        <Autocomplete
                          sx={{ mt: 1, mb: 0.5 }}
                          freeSolo
                          name='productType'
                          value={
                            values.productType
                              ? _.find(productTypes, {
                                  '@id': values.productType,
                                })
                              : { '@id': '', name: '' }
                          }
                          options={productTypes}
                          getOptionLabel={(option) => option.name}
                          onChange={(e, value) =>
                            setFieldValue(
                              'productType',
                              value !== null ? value['@id'] : values.productType
                            )
                          }
                          includeInputInList
                          onOpen={handleBlur}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t('Type')}
                              name='productType'
                              error={Boolean(
                                touched.productType && errors.productType
                              )}
                              helperText={
                                touched.productType && errors.productType
                              }
                            />
                          )}
                        />
                      ) : (
                        <Autocomplete
                          sx={{ mt: 1, mb: 0.5 }}
                          freeSolo
                          name='productType'
                          options={productTypes}
                          getOptionLabel={(option) => option.name}
                          onChange={(e, value) =>
                            setFieldValue(
                              'productType',
                              value !== null ? value['@id'] : values.productType
                            )
                          }
                          includeInputInList
                          onOpen={handleBlur}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t('Type')}
                              name='productType'
                              error={Boolean(
                                touched.productType && errors.productType
                              )}
                              helperText={
                                touched.productType && errors.productType
                              }
                            />
                          )}
                        />
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.quantity && errors.quantity)}
                        fullWidth
                        helperText={touched.quantity && errors.quantity}
                        label={t('Quantity')}
                        margin='dense'
                        name='quantity'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.quantity}
                        variant='outlined'
                        type='number'
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <TextField
                        label={t('Category')}
                        value={selectedProductCategory.name}
                        InputProps={{
                          readOnly: true,
                        }}
                        style={{ display: 'none' }}
                      />
                    </Grid>
                  </Grid>
                  <Box sx={{ py: 2 }}>
                    <Button
                      color='primary'
                      disabled={isSubmitting}
                      fullWidth
                      size='large'
                      type='submit'
                      variant='contained'
                    >
                      {t('Save')}
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default ProductManageForm;
