import { SvgIcon, Box, Typography } from '@material-ui/core';

const TableIcon = ({ name, seats, status, ...props }) => {
  const defaultColor = '#009688';
  const color = status?.color ?? defaultColor;

  const width = 130;
  const triangleSide = width - width / 4;
  const triangleTop = (width / 2) * -1;
  const triangleLeft = (width / 8) * -1;
  const triangleRadius = width / 10;

  return (
    <Box
      style={{
        width: '140px',
        height: '140px',
        backgroundColor: '#F5F5F5',
        borderRadius: '13px',
        padding: '6px',
      }}
    >
      <Box
        position='relative'
        width='100%'
        height='100%'
      >
        <Box
          style={{
            position: 'absolute',
            left: '5%',
            top: '4%',
            zIndex: 3,
            overflow: 'hidden',
          }}
        >
          <Typography
            style={{
              fontSize: '18px',
              fontWeight: '700',
              textTransform: 'none',
              color: 'white',
            }}
          >
            {name}
          </Typography>
        </Box>
        <Box
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 1,
            borderRadius: '7px',
            left: 0,
            top: 0,
            overflow: 'hidden',
          }}
        >
          <Box
            style={{
              width: 0,
              height: 0,
              borderLeftColor: color,
              borderLeftWidth: triangleSide,
              borderTopColor: 'transparent',
              borderTopWidth: triangleSide,
              borderStyle: 'solid',
              borderBottomColor: 'transparent',
              borderBottomWidth: triangleSide,
              position: 'absolute',
              top: triangleTop,
              left: triangleLeft,
              borderRadius: triangleRadius,
              transform: 'rotate(225deg)',
            }}
          ></Box>
          <SvgIcon
            width='63'
            height='63'
            viewBox='0 0 63 63'
            fill='none'
            style={{
              position: 'absolute',
              zIndex: 2,
              width: '100%',
              height: '50%',
              top: '30%',
              left: '-10%',
              textAlign: 'left',
            }}
          >
            <path
              d='M 55.5 6.6522 H 0 V 50 H 6.3406 V 18.2935 L 44.7582 17.7727 L 55.5 6.6522 Z'
              fill='white'
            />
            <path
              d='M0 6.65217H55.5L62.5 0H5.92209L0 6.65217Z'
              fill='white'
            />
          </SvgIcon>
        </Box>
        <SvgIcon
          width='36'
          height='41'
          fill='none'
          viewBox='0 0 36 41'
          style={{
            position: 'absolute',
            width: '30%',
            right: 0,
            bottom: 0,
            height: '75%',
          }}
        >
          <g clipPath='url(#clip0_2187_2)'>
            <path
              fill={color}
              d='M24.0948 15.7557C27.1474 12.1709 26.9766 6.51911 23.7133 3.13216C20.4499 -0.254777 15.3298 -0.094342 12.2772 3.49051C9.22463 7.07535 9.39546 12.7271 12.6588 16.114C15.9221 19.501 21.0422 19.3406 24.0948 15.7557Z'
            />
            <path
              fill={color}
              d='M36 41C36 38.5049 35.5344 36.0342 34.6298 33.729C33.7252 31.4238 32.3994 29.3293 30.7279 27.565C29.0565 25.8007 27.0722 24.4011 24.8883 23.4463C22.7044 22.4914 20.3638 22 18 22C15.6362 22 13.2956 22.4914 11.1117 23.4463C8.92784 24.4011 6.94353 25.8007 5.27208 27.565C3.60062 29.3293 2.27475 31.4238 1.37017 33.729C0.465583 36.0342 -2.06649e-07 38.5049 0 41H18H36Z'
            />
          </g>
          <defs>
            <clipPath id='clip0_2187_2'>
              <rect
                width='36'
                height='41'
                fill='white'
              />
            </clipPath>
          </defs>
        </SvgIcon>
        <Box
          style={{
            position: 'absolute',
            right: '6%',
            bottom: '-3%',
          }}
        >
          <Typography
            style={{
              color: color,
              fontFamily: 'Roboto',
              fontSize: '20px',
              fontWeight: '700',
              dominantBaseline: 'middle',
              textAnchor: 'middle',
              textTransform: 'none',
            }}
          >
            x{seats}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TableIcon;
