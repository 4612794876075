import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import { GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import RestoreIcon from '@mui/icons-material/Restore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { toast } from 'react-toastify';
import moment from 'moment';
import ProductCategoryListResults from '../components/product/category/ProductCategoryListResults';
import ProductCategoryListToolbar, {
  StyledTitle,
} from '../components/product/category/ProductCategoryListToolbar';
import {
  updateProductCategory,
  softDeleteProductCategory,
  deleteProductCategory,
  getSelectedProductCategory,
  shopSelector,
  shopClearState,
  getProductCategories,
} from '../features/ShopSlice';
import Loading from '../components/Loading';
import Can from '../can';
import escapeRegExp from '../utils/escapeRegExp';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import UserService from 'src/services';

const ProductCategoryList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selectedProductCategory, isError, errorMessage, isFetching } =
    useSelector(shopSelector);
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [mode, setMode] = useState('add');
  const [productCategoryId, setProductCategoryId] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const { parentId, shopId } = useParams();
  const navigate = useNavigate();
  const isAdmin = UserService.isAdmin();
  const isShop = UserService.isShop();
  const [isDeleting, setIsDeleting] = useState(false);
  const [disableFeaturedStarIcon, setDisableFeaturedStarIcon] = useState(false);

  const columns = [
    {
      field: 'name',
      headerName: t('Name'),
      flex: 1,
      renderCell: (params) => (
        <span
          onClick={() => handleRowClick(params.row)}
          style={{ cursor: 'pointer', color: '#5664d2' }}
        >
          {params.row.name}
        </span>
      ),
    },
    { field: 'description', headerName: t('Description'), flex: 1 },
    { field: 'created', headerName: t('Created'), flex: 1 },
    {
      field: 'active',
      headerName: t('Active'),
      flex: 1,
      type: 'boolean',
      hide: !Can('manage', 'products'),
    },
    {
      field: 'featured',
      headerName: t('Featured'),
      headerAlign: 'center',
      flex: 1,
      hide: !Can('manage', 'products'),
      renderCell: (params) => {
        return (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            style={{ width: '100%', height: '100%' }}
          >
            {params.row.featured ? (
              <StarIcon
                style={{ color: '#ffc107', cursor: 'pointer' }}
                onClick={handleToggleFeatured(
                  params.row.id,
                  params.row.featured
                )}
              />
            ) : (
              <StarBorderIcon
                style={{
                  color: '#9e9e9e',
                  cursor: disableFeaturedStarIcon ? 'not-allowed' : 'pointer',
                }}
                onClick={
                  disableFeaturedStarIcon
                    ? undefined
                    : handleToggleFeatured(params.row.id, params.row.featured)
                }
              />
            )}
          </Box>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: t('Actions'),
      cellClassName: 'actions',
      flex: 1,
      getActions: ({ id, row }) => {
        let actions = [
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label={t('View Products')}
            onClick={() => handleRowClick(row)}
            color='primary'
            aria-label='view-products'
          />,
        ];

        if (Can('manage', 'products')) {
          actions.push(
            <GridActionsCellItem
              icon={<EditIcon />}
              label={t('Edit')}
              onClick={() => handleOpen('edit', row.id)}
              color='warning'
              aria-label='edit'
            />
          );
          if (row.active) {
            actions.push(
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label='Soft Delete'
                onClick={handleSoftDeleteClick(id)}
                color='warning'
                aria-label='soft-delete'
              />
            );
          } else {
            actions.push(
              <GridActionsCellItem
                icon={<RestoreIcon />}
                label='Restore'
                onClick={handleRestore({
                  id: id,
                  field: 'active',
                  value: true,
                })}
                color='success'
                aria-label='restore'
              />
            );
          }

          actions.push(
            <GridActionsCellItem
              icon={<DeleteForeverIcon />}
              label='Delete'
              onClick={() => handleConfirmOpen(row.id, row.name)}
              color='error'
              aria-label='delete'
            />
          );
        }

        return actions;
      },
    },
  ];

  useEffect(() => {
    dispatch(getSelectedProductCategory(parentId));
  }, [dispatch, parentId]);

  useEffect(() => {
    if (isError) {
      toast.error(t(errorMessage), {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
    return () => {
      dispatch(shopClearState());
    };
  }, [isError, errorMessage, dispatch, t]);

  useEffect(() => {
    if (selectedProductCategory && selectedProductCategory.productCategories) {
      const parentName = selectedProductCategory.name;

      if (Array.isArray(selectedProductCategory.productCategories)) {
        setRows(
          mapProductCategoriesToRows(
            selectedProductCategory.productCategories,
            parentName
          )
        );

        const featuredCategoriesCount =
          selectedProductCategory.productCategories.filter(
            (cat) => cat.featured
          ).length;
        setDisableFeaturedStarIcon(featuredCategoriesCount >= 4);
      }
    }
  }, [selectedProductCategory]);

  const mapProductCategoriesToRows = (productCategories, parentName) => {
    return productCategories.map((productCategory, index) => {
      return {
        id: productCategory.id,
        productCategoryId: productCategory['@id'],
        name: productCategory.name,
        description: productCategory.description,
        parent: parentName ? parentName : '-',
        created: moment(productCategory.created).format('YYYY-MM-DD'),
        active: productCategory.active,
        featured: productCategory.featured,
        products: productCategory.products ? productCategory.products : [],
      };
    });
  };

  const requestSearch = (searchValue) => {
    if (selectedProductCategory && selectedProductCategory.productCategories) {
      setSearchText(searchValue);
      const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
      const filteredRows = mapProductCategoriesToRows(
        selectedProductCategory.productCategories
      ).filter((row) => {
        return Object.keys(row).some((field) => {
          if (field === 'productCategoryId' || !row[field]) {
            return false;
          }
          return searchRegex.test(row[field].toString());
        });
      });
      setRows(filteredRows);
    }
  };

  const handleOpen = (mode, id = null) => {
    setOpen(true);
    setMode(mode);
    setProductCategoryId(id);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
    dispatch(getSelectedProductCategory(parentId));
  };

  const openAddDialog = () => {
    handleOpen('add');
  };

  const handleConfirmOpen = (id, name, edit = false) => {
    if (edit) {
      console.log('edit');
    } else {
      setConfirmOpen(true);
      setProductCategoryId(id);
      setConfirmMessage(
        t('Are you sure you want to delete category: ') + name + '?'
      );
    }
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleSoftDeleteClick = (productCategoryId) => (event) => {
    event.stopPropagation();
    dispatch(softDeleteProductCategory(productCategoryId)).then((response) => {
      if (!response.error) {
        toast.success(t('Product category disabled successfully'), {
          position: 'bottom-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        dispatch(getSelectedProductCategory(parentId));
        dispatch(shopClearState());
      } else {
        toast.error(t(response.payload), {
          position: 'bottom-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        dispatch(shopClearState());
      }
    });
  };

  const handleRestore = (params) => (event) => {
    event.stopPropagation();
    const payload = {
      id: params.id,
      data: {
        [params.field]: params.value,
      },
    };
    dispatch(updateProductCategory(payload)).then((response) => {
      if (!response.error) {
        toast.success(t('Data updated successfully'), {
          position: 'bottom-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        dispatch(getSelectedProductCategory(parentId));
        setRows((prev) =>
          prev.map((row) =>
            row.id === params.id
              ? {
                  ...row,
                  ...response.payload,
                  parent: response.payload.parent.name,
                  created: moment(
                    response.payload.created.replace('+00:00', '')
                  ).format('YYYY-MM-DD'),
                }
              : row
          )
        );
        dispatch(shopClearState());
      } else {
        toast.error(t(response.payload), {
          position: 'bottom-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        setRows((prev) => [...prev]);
        dispatch(shopClearState());
      }
    });
  };

  const handleConfirmAction = () => {
    if (productCategoryId) {
      setIsDeleting(true);
      dispatch(deleteProductCategory(productCategoryId)).then((response) => {
        if (!response.error) {
          toast.success(t('Product category removed successfully'), {
            position: 'bottom-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          handleConfirmClose();
          dispatch(getSelectedProductCategory(parentId));
          dispatch(shopClearState());
        } else {
          toast.error(t(response.payload), {
            position: 'bottom-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          dispatch(shopClearState());
        }

        setIsDeleting(false);
      });
    }
  };

  const handleRowClick = (row) => {
    if (isAdmin) {
      navigate(`/app/admin/shops/${shopId}/products/${parentId}/${row.id}`, {
        replace: false,
      });
    } else if (isShop) {
      navigate(`/app/products/${parentId}/${row.id}`, { replace: false });
    }
  };

  const handleToggleFeatured =
    (productCategoryId, currentFeaturedStatus) => (event) => {
      event.stopPropagation();

      const updateData = {
        id: productCategoryId,
        data: {
          featured: !currentFeaturedStatus,
        },
      };

      dispatch(updateProductCategory(updateData)).then((response) => {
        if (!response.error) {
          toast.success(t('Product category updated successfully'), {
            position: 'bottom-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          dispatch(getSelectedProductCategory(parentId));
          dispatch(getProductCategories());
          dispatch(shopClearState());
        } else {
          toast.error(response.payload, {
            position: 'bottom-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          dispatch(shopClearState());
        }
      });
    };

  if (isFetching && !selectedProductCategory) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>{t('Product Categories')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <StyledTitle>
              {selectedProductCategory
                ? selectedProductCategory.name
                : t('Categories')}
            </StyledTitle>

            <ProductCategoryListToolbar />
          </Box>

          <ProductCategoryListResults
            rows={rows}
            columns={columns}
            searchText={searchText}
            requestSearch={requestSearch}
            openAddDialog={openAddDialog}
            mode={mode}
            open={open}
            handleClose={handleClose}
            productCategoryId={productCategoryId}
            confirmOpen={confirmOpen}
            handleConfirmClose={handleConfirmClose}
            confirmMessage={confirmMessage}
            handleConfirmAction={handleConfirmAction}
            isDeleting={isDeleting}
          />
        </Container>
      </Box>
    </>
  );
};

export default ProductCategoryList;
