import { useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  ListSubheader,
} from '@material-ui/core';
import FastfoodOutlinedIcon from '@mui/icons-material/FastfoodOutlined';
import {
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  Users as UsersIcon,
  User as UserIcon,
  Command as CommandIcon,
  Box as BoxIcon,
} from 'react-feather';
import NavItem from './NavItem';
import { logoutUser, uploadAvatar } from '../features/UserSlice';
import { useDispatch, useSelector } from 'react-redux';
import UserService from '../services';
import Can from '../can';
import { useTranslation } from 'react-i18next';
import {
  shopSelector,
  getParentProductCategories,
} from '../features/ShopSlice';

const DashboardSidebar = ({ onMobileClose, openMobile, user }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { parentProductCategories } = useSelector(shopSelector);
  const items = [
    {
      title: t('Overview'),
      type: 'subheader',
      icon: '',
    },
    {
      href: '/app/dashboard',
      icon: CommandIcon,
      title: t('Dashboard'),
      visible: true,
    },
    // {
    //   href: '/app/reports',
    //   icon: BarChartIcon,
    //   title: t('Reports'),
    //   visible: Can('view', 'reports'),
    // },
    {
      href: '/app/customers',
      icon: UsersIcon,
      title: t('Customers'),
      visible: Can('view', 'customers'),
    },
    {
      icon: ShoppingBagIcon,
      title: t('Products'),
      visible: Can('view', 'products'),
      nested: true,
      links: parentProductCategories.map((parentProductCategory) => {
        return {
          href: `/app/products/${parentProductCategory.id}`,
          title: t(parentProductCategory.name),
          visible: true,
        };
      }),
    },
    {
      href: '/app/inventories',
      icon: BoxIcon,
      title: t('Inventories'),
      visible: Can('view', 'inventories'),
    },
    {
      href: '/app/recipes',
      icon: FastfoodOutlinedIcon,
      title: t('Recipes'),
      visible: Can('view', 'recipes'),
    },
    {
      title: t('Management'),
      type: 'subheader',
      icon: '',
    },
    {
      href: '/app/users',
      icon: UsersIcon,
      title: t('Users'),
      visible: Can('view', 'users'),
    },
    {
      href: '/app/account',
      icon: UserIcon,
      title: t('Account'),
      visible: Can('view', 'ownUser'),
    },
    {
      href: '/app/settings',
      icon: SettingsIcon,
      title: t('Settings'),
      visible: true,
    },
  ];

  useEffect(() => {
    dispatch(getParentProductCategories());
  }, [dispatch]);

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate('/login', { replace: true });
  };

  const handleChange = (event) => {
    if (event.target.files.length) {
      const file = event.target.files[0];
      dispatch(uploadAvatar(file));
    }
  };

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2,
        }}
      >
        {Can('manage', 'ownUser') ? (
          <Avatar
            component={RouterLink}
            src={user.avatar}
            sx={{
              cursor: 'pointer',
              width: 64,
              height: 64,
            }}
            to='/app/account'
          />
        ) : (
          <Button
            onChange={handleChange}
            component='label'
            sx={{
              borderRadius: 10,
            }}
          >
            <input
              type='file'
              hidden
              accept='image/*'
            />
            <Avatar
              src={user.avatar}
              sx={{
                width: 64,
                height: 64,
              }}
            />
          </Button>
        )}
        <Typography
          color='textPrimary'
          variant='h5'
        >
          {user.firstName} {user.lastName}
        </Typography>
        <Typography
          color='textSecondary'
          variant='body2'
        >
          {t(UserService.getJobTitleFromRoles(user.roles))}
        </Typography>
        <Button
          sx={{ pt: 1 }}
          onClick={handleLogout}
        >
          {t('Logout')}
        </Button>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item, key) =>
            item.type && item.type === 'subheader' ? (
              <ListSubheader key={key}>{item.title}</ListSubheader>
            ) : item.visible ? (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
                links={item.links}
                nested={item.nested}
              />
            ) : null
          )}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor='left'
          onClose={onMobileClose}
          open={openMobile}
          variant='temporary'
          PaperProps={{
            sx: {
              width: 256,
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor='left'
          open
          variant='persistent'
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)',
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default DashboardSidebar;
