import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { 
    Box, 
    Container, 
} from '@material-ui/core';
import { toast } from 'react-toastify';
import AdminShopCardsToolbar, { StyledTitle } from 'src/components/admin/shops/AdminShopCardsToolbar';
import { shopSelector, shopClearState, getShop, getProductCategoriesByShopIdForAdmin } from '../../features/ShopSlice';
import { userSelector } from 'src/features/UserSlice';
import Loading from '../../../src/components/Loading';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import ProductsCard from './Cards/ProductsCard';
import InventoriesCard from './Cards/InventoriesCard';
import RecipesCard from './Cards/RecipesCard';

const AdminShopCards = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { shop, productCategories, isError, errorMessage, isFetching } = useSelector(shopSelector);
  const { user } = useSelector(userSelector);
  const isAdmin = user.roles.includes('ROLE_ADMIN');
  const { shopId } = useParams();
  
  useEffect(() => {
    dispatch(getShop(shopId));
  }, [dispatch, shopId]);

  useEffect(() => {
    if(shopId && isAdmin) {
      dispatch(getProductCategoriesByShopIdForAdmin(shopId));
    }
    return () => dispatch(shopClearState());
  }, [dispatch, shopId, isAdmin]);

  useEffect(() => {
    if (isError) {
      toast.error(t(errorMessage), {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
    return () => {
      dispatch(shopClearState());
    }
  }, [isError, errorMessage, dispatch, t]);

  if (isFetching && !productCategories.productCategories && !shop) {
    return <Loading />;
  }

  return (
    <>
       <Helmet>
        <title>{t("Shop")}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>

          <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}
          >
            <StyledTitle>{shop ? shop.name : t("Shop")}</StyledTitle>
            <AdminShopCardsToolbar />
          </Box>
          
          <Box
            sx={{
              display: 'flex',
              gap: 3,
              marginTop: 3,
              flexDirection: 'row'
            }}
          >
            <Box sx={{ height: '150px', flex: 1 }}>
              <ProductsCard 
                productCategories={productCategories}
                shop={shop || ''}
              />
            </Box>

            <Box sx={{ height: '150px', flex: 1 }}>
              <InventoriesCard 
                shop={shop || ''}
              />
            </Box>

            <Box sx={{ height: '150px', flex: 1 }}>
              <RecipesCard 
                shop={shop || ''}
              />
            </Box>

          </Box>

        </Container>
      </Box>
    </>
  )
}

export default AdminShopCards;
