import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Autocomplete,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { updateShop, shopSelector, shopClearState } from '../../features/ShopSlice';
import { toast } from 'react-toastify';
import validateCIF from '../../utils/validateCIF';
import * as i18n from '../../utils/i18n';
import Can from '../../can';
import { useTranslation } from 'react-i18next';

const CompanyDetails = (props) => {
  const dispatch = useDispatch();
  const user = props.user;
  const { isFetching } = useSelector(shopSelector);
  const [countryCode, setCountryCode] = useState(user.shop.country);
  const [county, setCounty] = useState(user.shop.county);
  const [counties, setCounties] = useState([]);
  const [cities, setCities] = useState([]);
  const initialValues = {
    name: user.shop.name,
    address: user.shop.address,
    cif: user.shop.cif,
    country: user.shop.country,
    county: user.shop.county,
    city: user.shop.city,
  };
  const { t } = useTranslation();

  const handleSubmit = (data) => {
    dispatch(updateShop({endpoint: user.shop.id, data})).then(response => {
      if (!response.error) {
        toast.success(t('Company updated successfully'), {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        dispatch(shopClearState());
      } else {
        toast.error(t(response.payload), {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        dispatch(shopClearState());
      }
    });
  };

  Yup.addMethod(Yup.string, 'cif', function (errorMessage) {
    return this.test(`cif`, errorMessage, function (value) {
      const { path, createError } = this;
      return validateCIF(value) || createError({ path, message: errorMessage })
    });
  });

  useEffect(() => {
    setCounties([]);
    if (countryCode) {
      i18n.counties(countryCode).then(data => {
        if (data) {
          setCounties(data);
        }
      });
    } else if (user.shop.country) {
      i18n.counties(user.shop.country).then(data => {
        if (data) {
          setCounties(data);
        }
      })
    }
  }, [countryCode, user.shop.country]);

  useEffect(() => {
    setCities([]);
    if (countryCode && county) {
      i18n.cities(countryCode, county).then(data => {
        if (data) {
          setCities(data);
        }
      })
    } else if (user.shop.country && user.shop.county) {
      i18n.cities(user.shop.country, user.shop.county).then(data => {
        if (data) {
          setCities(data);
        }
      })
    }
  }, [countryCode, county, user.shop.country, user.shop.county]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={
        Yup.object().shape({
          name: Yup.string().max(255).required(t('Company name is required')),
          cif: Yup.string().required(t('CIF is required')).cif(t('CIF is invalid')),
          address: Yup.string().max(255).required(t('Address is required')),
          country: Yup.string().required(t('Country is required')),
          county: Yup.string().required(t('County is required')),
          city: Yup.string().required(t('City is required')),
        })
      }
      onSubmit={(data) => handleSubmit(data)}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue
      }) => (
        <form id="update-company" onSubmit={handleSubmit}>
          <Card>
            <CardHeader title={t("Company")} />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label={t("Company name")}
                      margin="dense"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="cif"
                      fullWidth
                      label={t("CIF")}
                      margin="dense"
                      name="cif"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.cif}
                      variant="outlined"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="address"
                      error={Boolean(touched.address && errors.address)}
                      fullWidth
                      helperText={touched.address && errors.address}
                      label={t("Address")}
                      margin="dense"
                      name="address"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.address}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      sx={{mt: 1, mb: 0.5}}
                      freeSolo
                      id="country"
                      name="country"
                      value={i18n.getCountryByCode(values.country)}
                      options={i18n.countries}
                      getOptionLabel={option => option.label}
                      onChange={(e, value) => {
                        setFieldValue('country', value !== null ? value.code : initialValues.country);
                        setCountryCode(value !== null ? value.code : initialValues.country);
                        setFieldValue('county', '');
                        setFieldValue('city', '');
                      }}
                      includeInputInList
                      onOpen={handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Country")}
                          name="country"
                          error={Boolean(touched.country && errors.country)}
                          helperText={touched.country && errors.country}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      sx={{mt: 1, mb: 0.5}}
                      freeSolo
                      id="county"
                      name="county"
                      disabled={!values.country || !counties.length}
                      options={counties}
                      value={counties.length ? values.county : ''}
                      getOptionLabel={option => option}
                      onChange={(e, value) => {
                        setFieldValue('county', value !== null ? value : initialValues.county);
                        setCounty(value !== null ? String(value) : initialValues.county);
                        setFieldValue('city', '');
                      }}
                      includeInputInList
                      onOpen={handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("County")}
                          name="county"
                          error={Boolean(touched.county && errors.county)}
                          helperText={touched.county && errors.county}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      sx={{mt: 1, mb: 0.5}}
                      freeSolo
                      id="city"
                      name="city"
                      disabled={!values.county || !cities.length}
                      options={cities}
                      value={values.city}
                      getOptionLabel={option => option}
                      onChange={(e, value) => setFieldValue('city', value !== null ? value : initialValues.city)}
                      includeInputInList
                      onOpen={handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("City")}
                          name="city"
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      )}
                    />
                  </Grid>
              </Grid>
            </CardContent>
            {Can('manage', 'shop') && (
              <>
                <Divider />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    p: 2
                  }}
                >
                  <Button
                    color="primary"
                    disabled={isSubmitting && isFetching}
                    type="submit"
                    variant="contained"
                  >
                    {t("Save")}
                  </Button>
                </Box>
              </>
            )}
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default CompanyDetails;
