export const ro = {
  translation: {
    'Overview': 'Generale',
    'Dashboard': 'Panou control',
    'Customers': 'Clienti',
    'Products': 'Produse',
    'List': 'Lista',
    'Categories': 'Categorii',
    'Management': 'Administrare',
    'Shops': 'Magazine',
    'Users': 'Utilizatori',
    'Account': 'Cont',
    'Settings': 'Setari',
    'Owner': 'Proprietar',
    'Logout': 'Deconectare',
    'Password updated successfully': 'Parola schimbata cu succes',
    'Old password is required': 'Parola veche este obligatorie',
    'New password is required': 'Parola noua este obligatorie',
    'Update password': 'Schimba parola',
    'Old password': 'Parola veche',
    'New password': 'Parola noua',
    'Settings saved successfully': 'Setari salvate cu succes',
    'Shop settings': 'Setari de magazin',
    'Order Number': 'Numar de ordine',
    'Order Types': 'Tipuri de comanda',
    'Delivery': 'Livrare',
    'Pickup': 'Ridicare',
    'Serving at table': 'Servire la masa',
    'Payment Types': 'Tipuri de plata',
    'Cash': 'Numerar',
    'Bank transfer': 'Transfer bancar',
    'Card': 'Card',
    'Save settings': 'Salveaza setarile',
    'Save': 'Salveaza',
    'Name': 'Nume',
    'Address': 'Adresa',
    'Country': 'Tara',
    'County': 'Judet',
    'City': 'Localitate',
    'Created': 'Data creare',
    'Updated': 'Data modificare',
    'Active': 'Activ',
    'Actions': 'Actiuni',
    'Add Shop': 'Adauga magazin',
    'Add shop': 'Adauga magazin',
    'Search…': 'Cauta…',
    'Edit Shop': 'Modifica magazin',
    'Edit shop': 'Modifica magazin',
    'Name is required': 'Numele este obligatoriu',
    'CIF is required': 'CIF este obligatoriu',
    'CIF is invalid': 'CIF invalid',
    'Address is required': 'Adresa este obligatorie',
    'Country is required': 'Tara este obligatorie',
    'County is required': 'Judetul este obligatoriu',
    'City is required': 'Localitatea este obligatorie',
    'Are you sure you want to delete shop: ': 'Esti sigur ca vrei sa stergi magazinul: ',
    'Shop deleted successfully': 'Magazin sters cu succes',
    'Shop restored successfully': 'Magazin restaurat cu succes',
    'Add User': 'Adauga utilizator',
    'Add user': 'Adauga utilizator',
    'Edit User': 'Modifica utilizator',
    'Edit user': 'Modifica utilizator',
    'Email': 'E-mail',
    'Phone': 'Telefon',
    'Department': 'Departament',
    'Shop': 'Magazin',
    'First name': 'Prenume',
    'Last name': 'Nume',
    'Phone number': 'Telefon',
    'Phone Number': 'Telefon',
    'Email address': 'Adresa de e-mail',
    'Email Address': 'Adresa de e-mail',
    'Password': 'Parola',
    'Kitchen': 'Bucatarie',
    'Must be a valid email': 'Adresa de e-mail invalida',
    'Email is required': 'Adresa de e-mail este obligatorie',
    'First name is required': 'Prenumele este obligatoriu',
    'Last name is required': 'Numele este obligatoriu',
    'Phone number is required': 'Numarul de telefon este obligatoriu',
    'Password is required': 'Parola este obligatorie',
    'Role is required': 'Departamentul este obligatoriu',
    'Are you sure you want to delete user: ': 'Esti sigur ca vrei sa stergi utilizatorul: ',
    'User deleted successfully': 'Utilizator sters cu succes',
    'User restored successfully': 'Utilizator restaurat cu succes',
    'No': 'Nu',
    'Yes': 'Da',
    'Upload picture': 'Incarca poza',
    'Profile': 'Profil',
    'Profile updated successfully': 'Profil modificat cu succes',
    'Company': 'Companie',
    'Company name': 'Denumire',
    'Company name is required': 'Denumirea este obligatorie',
    'Add Order Type': 'Adauga tip comanda',
    'Add order type': 'Adauga tip comanda',
    'Data added successfully': 'Date adaugate cu succes',
    'Data disabled successfully': 'Date dezactivate cu succes',
    'Data removed successfully': 'Date sterse cu succes',
    'Data updated successfully': 'Date modificate cu succes',
    'No data found': 'Fara rezultate',
    'Add payment type': 'Adauga tip plata',
    'Add Payment Type': 'Adauga tip plata',
    'Statuses': 'Statusuri',
    'Color': 'Culoare',
    'Add status': 'Adauga status',
    'Add Status': 'Adauga status',
    'Pending': 'In asteptare',
    'Are you sure you want to delete status:': 'Esti sigur ca vrei sa stergi statusul:',
    'Pick a color:': 'Alege o culoare:',
    'Rows per page': 'Randuri pe pagina',
    'Sign in': 'Intra in cont',
    'Sign in now': 'Logheaza-te',
    'Don\'t have an account?': 'Nu ai cont?',
    'Register': 'Inregistreaza-te',
    'Forgot password?': 'Ai uitat parola?',
    'Create new account': 'Creaza cont nou',
    'Sign up now': 'Inregistreaza-te',
    'Have an account?': 'Ai cont deja?',
    'Reset your password': 'Reseteaza-ti parola',
    'Enter your email address and we will send you a password reset link.': 'Introdu adresa ta de e-mail si iti vom trimite un link de resetare parola',
    'Send password reset email': 'Trimite e-mail de resetare parola',
    'Please follow the instructions in the email to reset your password.': 'Va rugam urmati instructiunile din e-mail-ul de resetare parola',
    'Forgot Password': 'Resetare parola',
    'Enter your new password.': 'Introdu noua parola',
    'Save password': 'Salveaza parola',
    'Type': 'Tip',
    'Description': 'Descriere',
    'Category': 'Categorie',
    'Add Product Category': 'Adauga categorie produs',
    'Add product category': 'Adauga categorie produs',
    'Edit product category': 'Modifica categorie produs',
    'Price': 'Pret',
    'Unit': 'Unitate',
    'Add Product': 'Adauga produs',
    'Add product': 'Adauga produs',
    'Edit product': 'Modifica produs',
    'Product Types': 'Tipuri de produs',
    'Add product type': 'Adauga tip produs',
    'Edit product type': 'Modifica tip produs',

    // NEW TRANSLATIONS:

    //Dashboard Sidebar:
    'Inventories': 'Inventare',
    'Recipes': 'Retete',

    //Dashboard-Areas with tables:
    'There are no areas on this shop.': 'Nu sunt Zone pe acest magazin',
    'No Stands in this Area.': 'Nu sunt Mese in aceasta Zona.',

    //Customers:
    'Add Customer': 'Adauga client',
    'Are you sure you want to delete customer: ': 'Esti sigur/a ca vrei sa stergi clientul: ',
    'Edit customer': 'Editare client',
    'Open Address Section': 'Deschide sectiunea cu adresa',
    'Close Address Section': 'Inchide sectiunea cu adresa',
    'Street': 'Strada',
    'Number': 'Numar',
    'Building': 'Cladire',
    'Entrance': 'Scara',
    'Floor': 'Etaj',
    'Apartment': 'Apartament',
    'Indications': 'Indicatii',
    'Customer deleted successfully': 'Client sters cu succes',

    //Products:
    'Featured': 'Preferate',
    'Product category updated successfully': 'Categorie de produs actualizata cu succes',
    'Quantity': 'Cantitate',
    'Vat': 'TVA', 
    'Product disabled successfully': 'Produs dezactivat cu succes',
    'Are you sure you want to delete product: ': 'Esti sigur/a ca vrei sa stergi produsul: ',
    'Product removed successfully': 'Produs sters cu succes',
    'Featured category': 'Categorie preferata',
    'Product category disabled successfully': 'Categorie de produs dezactivată cu succes',
    'Are you sure you want to delete category: ': 'Esti sigur/a ca vrei sa stergi categoria: ',
    'Product category added successfully': 'Categorie de produs adaugata cu succes',
    'Product category removed successfully': 'Categorie de produs stearsa cu succes',

    //Inventories:
    'Document Type': 'Tip document',
    'Add Inventory': 'Adauga Inventar',
    'Edit Inventory': 'Editeaza Inventar',
    'Product': 'Produs',
    'VAT Price': 'Pret TVA',
    'Doc. Quantity': 'Cantitate Doc.',
    'Physical Quantity': 'Cantitate fizica',
    'VAT Included': 'TVA Inclus',
    'Inventory disabled successfully': 'Inventar dezactivat cu succes',
    'Inventory restored successfully': 'Inventar reactivat cu succes',
    'Are you sure you want to delete inventory: ': 'Esti sigur/a ca vrei sa stergi inventarul: ',
    'Inventory removed successfully': 'Inventar sters cu succes',
    'Add inventory product': 'Adauga produs/e in inventar',
    'Edit inventory product': 'Editeaza produsul in inventar', 
    'Document Quantity': 'Cantitate document',
    'Inventory product disabled successfully': 'Produs in inventar dezactivat cu succes',
    'Inventory product restored successfully': 'Produs in inventar reactivat cu succes',
    'Are you sure you want to delete inventory product: ': 'Esti sigur/a ca vrei sa stergi din inventar produsul: ',
    'Inventory product removed successfully': 'Produs din inventar sters cu succes',

    //Recipes:
    'Add Recipe': 'Adauga Reteta',
    'Edit Recipe': 'Editeaza Reteta',
    'Recipe disabled successfully': 'Reteta dezactivata cu succes',
    'Recipe restored successfully': 'Reteta reactivata cu succes',
    'Are you sure you want to delete recipe: ': 'Esti sigur/a ca vrei sa stergi reteta: ',
    'Recipe removed successfully': 'Reteta stearsa cu succes',
    'Edit recipe product': 'Editeaza produsul in reteta',
    'Recipe product disabled successfully': 'Produs in reteta dezactivat cu succes',
    'Recipe product restored successfully': 'Produs in reteta reactivat cu succes',
    'Are you sure you want to delete recipe product: ': 'Esti sigur/a ca vrei sa stergi din reteta produsul: ',
    'Recipe product removed successfully': 'Produs din reteta sters cu succes',

    // Settings:
    'Areas': 'Zone',
    'Add area': 'Adauga zona',
    'Edit area': 'Editeaza zona',
    'Are you sure you want to delete area: ': 'Esti sigur/a ca vrei sa stergi zona: ',
    'Stands': 'Mese',
    'Seats': 'Locuri',
    'Area': 'Zona',
    'Add stand': 'Adauga masa',
    'Edit stand': 'Editeaza masa',
    'Are you sure you want to delete stand: ': 'Esti sigur/a ca vrei sa stergi masa: ',
    'Units': 'Unitati de masura',
    'Add unit': 'Adauga unitate de masura',
    'Edit unit': 'Editeaza unitate de masura',
    'Are you sure you want to delete unit: ': 'Esti sigur/a ca vrei sa stergi unitatea de masura: ',
    'Vats': 'Cote TVA',
    'Add vat': 'Adauga TVA',
    'Edit vat': 'Editeaza TVA',
    'Are you sure you want to delete vat: ': 'Esti sigur/a ca vrei sa stergi TVA: ',
    'Value': 'Valoare',

    // Order:
    'T': 'M',
    'Default Table Name': 'Nume masa',
    'Quick access': 'Acces rapid',
    'Menu': 'Meniu', 
    'product': 'produs',
    'Extra options for': 'Optiuni extra pentru',
    'Comments': 'Comentarii',
    'Add with Comments': 'Adauga cu Comentarii',
    'Order Items': 'Produse in comanda',
    'Order details': 'Detalii comanda',
    'Edit Comments': 'Editare comentarii',
    'Are you sure you want to remove product: ': 'Esti sigur/a ca vrei sa stergi produsul: ',
    'from order?': 'din comanda?',
    'No products are added.': 'Nu sunt adaugate produse.',
    'An order must contain at least one product!': 'O comanda trebuie sa contina cel putin un produs!',
    'Place order': 'Plaseaza comanda',
    'Order added successfully': 'Comanda adaugata cu succes',
    'View order': 'Vezi comanda',
    'Free': 'Elibereaza',
    'Stand updated successfully to "free"!': 'Masa a fost eliberata cu succes!',
    'No recent order found': 'Nicio comanda recenta găsita',
    'Error! There is no recent order found on this table!': 'Eroare! Nu a fost găsita nicio comanda recenta pe aceasta masa!',
    'Failed to free the stand': 'Eliberarea mesei a esuat',
    'Order no.': 'Comanda nr.',
    '- Details': '- Detalii',
    'Update order': 'Actualizare',
    'Order updated successfully': 'Comanda actualizata cu succes'
  }
};