import { useState } from 'react';
import {
  NavLink as RouterLink,
  matchPath,
  useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, ListItem, Box } from '@material-ui/core';
import { Collapse } from '@mui/material';
import {
  ChevronDown as ExpandMore,
  ChevronUp as ExpandLess,
} from 'react-feather';

const NavItem = ({
  href,
  icon: Icon,
  title,
  nested,
  links,
  ...rest
}) => {
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const active = href ? !!matchPath({
    path: href,
    end: false
  }, location.pathname) : false;

  const handleClick = () => {
    setOpen(!open);
  };

  if (nested) {
    return (
      <>
        <ListItem
          sx={{
            display: 'flex',
            py: 0
          }}
          {...rest}
        >
          <Button
            onClick={handleClick}
            sx={{
              color: 'text.secondary',
              fontWeight: 'medium',
              justifyContent: 'flex-start',
              letterSpacing: 0,
              py: 1.25,
              textTransform: 'none',
              width: '100%',
              '& svg': {
                mr: 1
              }
            }}
          >
            {Icon && (
              <Icon size="20" />
            )}
            <span>
              {title}
            </span>
            <Box sx={{ marginLeft: 'auto' }}>
              {open ? <ExpandLess /> : <ExpandMore />}
            </Box>
          </Button>
        </ListItem>
        {nested && (
          <Collapse in={open} timeout="auto" unmountOnExit>
          {links.map((link, key) => 
            <Button
              key={key}
              component={RouterLink}
              sx={{
                color: 'text.secondary',
                fontWeight: 'medium',
                justifyContent: 'flex-start',
                letterSpacing: 0,
                pl: 8,
                textTransform: 'none',
                width: '100%',
                ...(!!matchPath({
                  path: link.href,
                  end: false
                }, location.pathname) && {
                  color: 'primary.main'
                }),
                '& svg': {
                  mr: 1
                }
              }}
              to={link.href}
            >
              <span>
                {link.title}
              </span>
            </Button>
          )}
          </Collapse>
        )}
      </>
    );
  }

  return (
    <ListItem
      sx={{
        display: 'flex',
        py: 0
      }}
      {...rest}
    >
      <Button
        component={RouterLink}
        sx={{
          color: 'text.secondary',
          fontWeight: 'medium',
          justifyContent: 'flex-start',
          letterSpacing: 0,
          py: 1.25,
          textTransform: 'none',
          width: '100%',
          ...(active && {
            color: 'primary.main'
          }),
          '& svg': {
            mr: 1
          }
        }}
        to={href}
      >
        {Icon && (
          <Icon size="20" />
        )}
        <span>
          {title}
        </span>
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
  nested: PropTypes.bool,
  links: PropTypes.array,
};

export default NavItem;
