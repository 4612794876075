import { Grid } from '@material-ui/core';
import ReactLoading from 'react-loading';

const Loading = () => {
  return (
    <Grid container direction="column" justifyContent="center" sx={{minHeight: '100%'}}>
      <Grid item justify="center" align="center">
        <ReactLoading type="spinningBubbles" color="#5664d2" />
      </Grid>
    </Grid>
  );
}

export default Loading;