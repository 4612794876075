import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import AccountProfilePassword from '../../components/account/AccountProfilePassword';
import OrderTypes from '../../components/admin/settings/OrderTypes';
import PaymentTypes from '../../components/admin/settings/PaymentTypes';
import Statuses from '../../components/admin/settings/Statuses';
import ProductTypes from '../../components/admin/settings/ProductTypes';
import Units from 'src/components/admin/settings/Units';
import Stands from 'src/components/admin/settings/Stands';
import Areas from 'src/components/admin/settings/Areas';
import Vats from 'src/components/admin/settings/Vats';

const SettingsView = () => (
  <>
    <Helmet>
      <title>Settings</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container>
        <Box sx={{ pt: 3 }}>
          <AccountProfilePassword />
        </Box>
        <Box sx={{ pt: 3 }}>
          <OrderTypes />
        </Box>
        <Box sx={{ pt: 3 }}>
          <PaymentTypes />
        </Box>
        <Box sx={{ pt: 3 }}>
          <Statuses />
        </Box>
        <Box sx={{ pt: 3 }}>
          <ProductTypes />
        </Box>
        <Box sx={{ pt: 3 }}>
          <Units />
        </Box>
        <Box sx={{ pt: 3 }}>
          <Vats />
        </Box>
        <Box sx={{ pt: 3 }}>
          <Areas />
        </Box>
        <Box sx={{ pt: 3 }}>
          <Stands />
        </Box>
      </Container>
    </Box>
  </>
);

export default SettingsView;
