import axios from 'axios';
import UserService from '../services';

const API = axios.create({ baseURL: process.env.REACT_APP_API });

API.interceptors.request.use((req) => {
  if (UserService.isAuthTokenValid()) {
    req.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  } else {
    UserService.logout();
  }

  return req;
});

export const login = (data) => API.post('/login', data);
export const register = (data) => API.post('/users', data);
export const getUser = (id) =>
  API.get(id.includes('api') ? id.replace('/api', '') : `/users/${id}`);
export const uploadAvatar = (id, data) => API.post(`/users/${id}/avatar`, data);
export const updateUser = (id, data) =>
  API.put(id.includes('api') ? id.replace('/api', '') : `/users/${id}`, data);
export const resetPassword = (data) => API.post(`/forgot-password/`, data);
export const saveNewPassword = (token, data) =>
  API.post(`/forgot-password/${token}`, data);
export const softDeleteUser = (id) =>
  API.put(id.replace('/api', ''), { active: false });
export const getEmployees = (id) => API.get(`/users/${id}/employees`);

export const createShop = (data) => API.post('/shops', data);
export const updateShop = (id, data) => API.put(`/shops/${id}`, data);
export const getShop = (id) => API.get(`/shops/${id}`);
export const getShops = () => API.get(`/shops`);
export const softDeleteShop = (id) =>
  API.put(`/shops/${id}`, { active: false });

export const getOrderTypes = () => API.get(`/order_types`);
export const getOrderType = (id) => API.get(`/order_types/${id}`);
export const addOrderType = (data) => API.post(`/order_types`, data);
export const updateOrderType = (id, data) =>
  API.put(`/order_types/${id}`, data);
export const softDeleteOrderType = (id) =>
  API.put(`/order_types/${id}`, { active: false });
export const deleteOrderType = (id) => API.delete(`/order_types/${id}`);

export const getPaymentTypes = () => API.get(`/payment_types`);
export const getPaymentType = (id) => API.get(`/payment_types/${id}`);
export const addPaymentType = (data) => API.post(`/payment_types`, data);
export const updatePaymentType = (id, data) =>
  API.put(`/payment_types/${id}`, data);
export const softDeletePaymentType = (id) =>
  API.put(`/payment_types/${id}`, { active: false });
export const deletePaymentType = (id) => API.delete(`/payment_types/${id}`);

export const getStatuses = () => API.get(`/statuses`);
export const getStatus = (id) => API.get(`/statuses/${id}`);
export const addStatus = (data) => API.post(`/statuses`, data);
export const updateStatus = (id, data) => API.put(`/statuses/${id}`, data);
export const softDeleteStatus = (id) =>
  API.put(`/statuses/${id}`, { active: false });
export const deleteStatus = (id) => API.delete(`/statuses/${id}`);

export const getCustomers = () => API.get(`/customers`);
export const getCustomer = (id) =>
  API.get(id.includes('api') ? id.replace('/api', '') : `/customers/${id}`);
export const addCustomer = (data) => API.post(`/customers`, data);
export const updateCustomer = (id, data) =>
  API.put(
    id.includes('api') ? id.replace('/api', '') : `/customers/${id}`,
    data
  );
export const softDeleteCustomer = (id) =>
  API.put(id.includes('api') ? id.replace('/api', '') : `/customers/${id}`, {
    active: false,
  });

export const addCustomerAddress = (data) =>
  API.post(`/customer_addresses`, data);

export const getProducts = () => API.get(`/products`);
export const getProductsByParentId = (parentId) =>
  API.get(`products?category.parent=${parentId}`);
export const getProductsByShopIdForAdmin = (shopId) =>
  API.get(`/admin/shops/${shopId}/products`);
export const getProduct = (id) => API.get(`/products/${id}`);
export const addProduct = (data) => API.post(`/products`, data);
export const updateProduct = (id, data) => API.put(`/products/${id}`, data);
export const softDeleteProduct = (id) =>
  API.put(`/products/${id}`, { active: false });
export const deleteProduct = (id) => API.delete(`/products/${id}`);

export const getProductCategories = () => API.get(`/product_categories`);
export const getParentProductCategories = () =>
  API.get(`/product_categories?exists[parent]=false`);
export const getProductCategoriesByShopIdForAdmin = (shopId) =>
  API.get(`/admin/shops/${shopId}/product_categories`);
export const getProductCategory = (id) => API.get(`/product_categories/${id}`);
export const addProductCategory = (data) =>
  API.post(`/product_categories`, data);
export const updateProductCategory = (id, data) =>
  API.put(`/product_categories/${id}`, data);
export const softDeleteProductCategory = (id) =>
  API.put(`/product_categories/${id}`, { active: false });
export const deleteProductCategory = (id) =>
  API.delete(`/product_categories/${id}`);

export const getProductTypes = () => API.get(`/product_types`);
export const getProductType = (id) => API.get(`/product_types/${id}`);
export const addProductType = (data) => API.post(`/product_types`, data);
export const updateProductType = (id, data) =>
  API.put(`/product_types/${id}`, data);
export const softDeleteProductType = (id) =>
  API.put(`/product_types/${id}`, { active: false });
export const deleteProductType = (id) => API.delete(`/product_types/${id}`);

export const getUnits = () => API.get(`/units`);
export const getUnitsByShopIdForAdmin = (shopId) =>
  API.get(`/admin/shops/${shopId}/units`);
export const getUnit = (id) => API.get(`/units/${id}`);
export const addUnit = (data) => API.post(`/units`, data);
export const updateUnit = (id, data) => API.put(`/units/${id}`, data);
export const softDeleteUnit = (id) =>
  API.put(`/units/${id}`, { active: false });
export const deleteUnit = (id) => API.delete(`/units/${id}`);

export const getVats = () => API.get(`/vats`);
export const getVatsByShopIdForAdmin = (shopId) =>
  API.get(`/admin/shops/${shopId}/vats`);
export const getVat = (id) => API.get(`/vats/${id}`);
export const addVat = (data) => API.post(`/vats`, data);
export const updateVat = (id, data) => API.put(`/vats/${id}`, data);
export const softDeleteVat = (id) => API.put(`/vats/${id}`, { active: false });
export const deleteVat = (id) => API.delete(`/vats/${id}`);

export const getAreas = () => API.get(`/areas`);
export const customGetAreas = () => API.get(`/areas/custom_get_areas`);
export const getArea = (id) => API.get(`/areas/${id}`);
export const addArea = (data) => API.post(`/areas`, data);
export const updateArea = (id, data) => API.put(`/areas/${id}`, data);
export const softDeleteArea = (id) =>
  API.put(`/areas/${id}`, { active: false });
export const deleteArea = (id) => API.delete(`/areas/${id}`);

export const getStands = () => API.get(`/stands`);
export const getStand = (id) => API.get(`/stands/${id}`);
export const addStand = (data) => API.post(`/stands`, data);
export const updateStand = (id, data) => API.put(`/stands/${id}`, data);
export const customFreeStand = (data) =>
  API.patch(`/stands/custom_free_stand`, data);
export const softDeleteStand = (id) =>
  API.put(`/stands/${id}`, { active: false });
export const deleteStand = (id) => API.delete(`/stands/${id}`);

export const getDocumentTypes = () => API.get(`/document_types`);

export const getInventories = () => API.get(`/inventories`);
export const getInventoriesByShopIdForAdmin = (shopId) =>
  API.get(`/admin/shops/${shopId}/inventories`);
export const getInventory = (id) => API.get(`/inventories/${id}`);
export const customCreateInventory = (data) =>
  API.post(`/inventories/custom_create`, data);
export const updateInventory = (id, data) =>
  API.put(`/inventories/${id}`, data);
export const softDeleteInventory = (id) =>
  API.put(`/inventories/${id}`, { active: false });
export const deleteInventory = (id) => API.delete(`/inventories/${id}`);

export const getInventoryProducts = () => API.get(`/inventory_products`);
export const getInventoryProduct = (id) => API.get(`/inventory_products/${id}`);
export const updateInventoryProduct = (id, data) =>
  API.put(`/inventory_products/${id}`, data);
export const softDeleteInventoryProduct = (id) =>
  API.put(`/inventory_products/${id}`, { active: false });
export const deleteInventoryProduct = (id) =>
  API.delete(`/inventory_products/${id}`);

export const getRecipes = () => API.get(`/recipes`);
export const getRecipesByShopIdForAdmin = (shopId) =>
  API.get(`/admin/shops/${shopId}/recipes`);
export const getRecipe = (id) => API.get(`/recipes/${id}`);
export const customCreateRecipe = (data) =>
  API.post(`/recipes/custom_create`, data);
export const updateRecipe = (id, data) => API.put(`/recipes/${id}`, data);
export const softDeleteRecipe = (id) =>
  API.put(`/recipes/${id}`, { active: false });
export const deleteRecipe = (id) => API.delete(`/recipes/${id}`);

export const getRecipeProducts = () => API.get(`/recipe_products`);
export const getRecipeProduct = (id) => API.get(`/recipe_products/${id}`);
export const updateRecipeProduct = (id, data) =>
  API.put(`/recipe_products/${id}`, data);
export const softDeleteRecipeProduct = (id) =>
  API.put(`/recipe_products/${id}`, { active: false });
export const deleteRecipeProduct = (id) => API.delete(`/recipe_products/${id}`);

export const getOrders = () => API.get(`/orders`);
export const getOrder = (id) => API.get(`/orders/${id}`);
export const customCreateOrder = (data) =>
  API.post(`/orders/custom_create`, data);
export const updateOrder = (id, data) => API.put(`/orders/${id}`, data);
export const softDeleteOrder = (id) =>
  API.put(`/orders/${id}`, { active: false });
export const deleteOrder = (id) => API.delete(`/orders/${id}`);
