import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Autocomplete,
} from '@material-ui/core';
import { userSelector } from '../../features/UserSlice';
import {
  getSelectedCustomer,
  getCustomers,
  addCustomer,
  updateCustomer,
  addCustomerAddress,
  shopSelector
} from '../../features/ShopSlice';
import Loading from '../Loading';
import * as i18n from '../../utils/i18n';
import validateCIF from '../../utils/validateCIF';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const CustomerManageForm = (props) => {
  const {handleClose, mode, customerId, openAddress, handleAddAddress} = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useSelector(userSelector);
  const { selectedCustomer } = useSelector(shopSelector);
  const [countryCode, setCountryCode] = useState(user.shop.country);
  const [county, setCounty] = useState(user.shop.county);
  const [counties, setCounties] = useState([]);
  const [cities, setCities] = useState([]);
  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    type: 'PF',
    cif: '',
    street: '',
    number: '',
    building: '',
    entrance: '',
    floor: '',
    apartment: '',
    indications: '',
    country: user.shop.country,
    county: user.shop.county,
    city: user.shop.city,
  });
  Yup.addMethod(Yup.string, 'cif', function (errorMessage) {
    return this.test(`cif`, errorMessage, function (value) {
      const { path, createError } = this;
      if (value) {
        return validateCIF(value) || createError({ path, message: errorMessage })
      }
      return true;
    });
  });

  useEffect(() => {
    if (mode === 'edit' && customerId) {
      dispatch(getSelectedCustomer(customerId));
    }
  }, [dispatch, mode, customerId]);

  useEffect(() => {
    let initialVal = {};
    if (mode === 'edit' && selectedCustomer) {
      initialVal = {
        firstName: selectedCustomer.firstName,
        lastName: selectedCustomer.lastName,
        email: selectedCustomer.email,
        phone: selectedCustomer.phone,
        type: selectedCustomer.type,
        cif: selectedCustomer.cif,
        street: '',
        number: '',
        building: '',
        entrance: '',
        floor: '',
        apartment: '',
        indications: '',
        country: selectedCustomer.country,
        county: selectedCustomer.county,
        city: selectedCustomer.city,
      };
    } else {
      initialVal = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        type: 'PF',
        cif: '',
        street: '',
        number: '',
        building: '',
        entrance: '',
        floor: '',
        apartment: '',
        indications: '',
        country: user.shop.country,
        county: user.shop.county,
        city: user.shop.city,
      };
    }
    setInitialValues(initialVal);
  }, [selectedCustomer, mode, user.shop]);

  const handleSubmit = (data, formikData) => {
    if (mode === 'add') {
      const address = _.pick(data, ['street', 'number', 'building', 'entrance', 'floor', 'apartment', 'indications', 'country', 'county', 'city']);
      data.shop = user.shop['@id'];
      dispatch(addCustomer(data)).then((response) => {
        if (!response.error) {
          if (address.street !== '' || address.number !== '' || address.building !== '' || address.entrance !== '' || address.floor !== '') {
            address.customer = response.payload['@id'];
            dispatch(addCustomerAddress(address)).then(res => {
              if (!res.error) {
                handleClose();
                dispatch(getCustomers());
              }
            });
          } else {
            handleClose();
            dispatch(getCustomers());
          }
        }
      });
    } else {
      dispatch(updateCustomer({endpoint: selectedCustomer['@id'], data: data})).then((response) => {
        if (!response.error) {
          handleClose();
          dispatch(getCustomers());
        }
      });
    }
    formikData.setSubmitting(false);
  };

  useEffect(() => {
    setCounties([]);
    if (countryCode) {
      i18n.counties(countryCode).then(data => {
        if (data) {
          setCounties(data);
        }
      });
    } else if (user.shop.country) {
      i18n.counties(user.shop.country).then(data => {
        if (data) {
          setCounties(data);
        }
      })
    }
  }, [countryCode, user.shop.country]);

  useEffect(() => {
    setCities([]);
    if (countryCode && county) {
      i18n.cities(countryCode, county).then(data => {
        if (data) {
          setCities(data);
        }
      })
    } else if (user.shop.country && user.shop.county) {
      i18n.cities(user.shop.country, user.shop.county).then(data => {
        if (data) {
          setCities(data);
        }
      })
    }
  }, [countryCode, county, user.shop.country, user.shop.county]);

  if (mode === 'edit' && !selectedCustomer) {
    return <Loading />;
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container>
          <Grid container>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              validationSchema={Yup.object().shape({
                firstName: Yup.string().max(255).required(t('First name is required')),
                lastName: Yup.string().max(255).required(t('Last name is required')),
                email: Yup.string().email(t('Must be a valid email')).max(255),
                phone: Yup.string().max(20).required(t('Phone number is required')),
                type: Yup.string().required(t('Type is required')),
                cif: Yup.string().cif(t('CIF is invalid')),
              })}
              onSubmit={(data, formikData) => handleSubmit(data, formikData)}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue,
                dirty,
                isValid
              }) => (
                <Form id={mode}>
                  <Grid container spacing={1}>
                    <Grid item xs={openAddress ? 6 : 12}>
                      <TextField
                        error={Boolean(touched.firstName && errors.firstName)}
                        fullWidth
                        helperText={touched.firstName && errors.firstName}
                        label={t("First name")}
                        margin="dense"
                        name="firstName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.firstName}
                        variant="outlined"
                      />
                    </Grid>
                    {openAddress && (
                      <>
                        <Grid item xs={4}>
                          <TextField
                            error={Boolean(touched.street && errors.street)}
                            fullWidth
                            helperText={touched.street && errors.street}
                            label={t("Street")}
                            margin="dense"
                            name="street"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.street}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            error={Boolean(touched.number && errors.number)}
                            fullWidth
                            helperText={touched.number && errors.number}
                            label={t("Number")}
                            margin="dense"
                            name="number"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.number}
                            variant="outlined"
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={openAddress ? 6 : 12}>
                      <TextField
                        error={Boolean(touched.lastName && errors.lastName)}
                        fullWidth
                        helperText={touched.lastName && errors.lastName}
                        label={t("Last name")}
                        margin="dense"
                        name="lastName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.lastName}
                        variant="outlined"
                      />
                    </Grid>
                    {openAddress && (
                      <>
                        <Grid item xs={2}>
                          <TextField
                            error={Boolean(touched.building && errors.building)}
                            fullWidth
                            helperText={touched.building && errors.building}
                            label={t("Building")}
                            margin="dense"
                            name="building"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.building}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            error={Boolean(touched.entrance && errors.entrance)}
                            fullWidth
                            helperText={touched.entrance && errors.entrance}
                            label={t("Entrance")}
                            margin="dense"
                            name="entrance"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.entrance}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            error={Boolean(touched.floor && errors.floor)}
                            fullWidth
                            helperText={touched.floor && errors.floor}
                            label={t("Floor")}
                            margin="dense"
                            name="floor"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.floor}
                            variant="outlined"
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={openAddress ? 6 : 12}>
                      <TextField
                        error={Boolean(touched.phone && errors.phone)}
                        fullWidth
                        helperText={touched.phone && errors.phone}
                        label={t("Phone number")}
                        margin="dense"
                        name="phone"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.phone}
                        variant="outlined"
                      />
                    </Grid>
                    {openAddress && (
                      <>
                        <Grid item xs={2}>
                          <TextField
                            error={Boolean(touched.apartment && errors.apartment)}
                            fullWidth
                            helperText={touched.apartment && errors.apartment}
                            label={t("Apartment")}
                            margin="dense"
                            name="apartment"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.apartment}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            error={Boolean(touched.indications && errors.indications)}
                            fullWidth
                            helperText={touched.indications && errors.indications}
                            label={t("Indications")}
                            margin="dense"
                            name="indications"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.indications}
                            variant="outlined"
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={openAddress ? 6 : 12}>
                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        label={t("Email Address")}
                        margin="dense"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        value={values.email || ''}
                        variant="outlined"
                      />
                    </Grid>
                    {openAddress && (
                      <Grid item xs={6}>
                        <Autocomplete
                          sx={{mt: 1}}
                          freeSolo
                          id="country"
                          name="country"
                          value={i18n.getCountryByCode(values.country)}
                          options={i18n.countries}
                          getOptionLabel={option => option.label}
                          onChange={(e, value) => {
                            setFieldValue('country', value !== null ? value.code : initialValues.country);
                            setCountryCode(value !== null ? value.code : initialValues.country);
                            setFieldValue('county', '');
                            setFieldValue('city', '');
                          }}
                          includeInputInList
                          onOpen={handleBlur}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("Country")}
                              name="country"
                              error={Boolean(touched.country && errors.country)}
                              helperText={touched.country && errors.country}
                            />
                          )}
                        />
                      </Grid>
                    )}
                    <Grid item xs={openAddress ? 6 : 12}>
                      <FormControl fullWidth>
                        <InputLabel id="type">{t("Type")}</InputLabel>
                        <Select
                          labelId="type"
                          label={t("Type")}
                          value={values.type}
                          name="type"
                          onChange={handleChange}
                        >
                          <MenuItem value="PF">{t("Individual")}</MenuItem>
                          <MenuItem value="PJ">{t("Company")}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {openAddress && (
                      <Grid item xs={6}>
                        <Autocomplete
                          freeSolo
                          id="county"
                          name="county"
                          disabled={!values.country || !counties.length}
                          options={counties}
                          value={counties.length ? values.county : ''}
                          getOptionLabel={option => option}
                          onChange={(e, value) => {
                            setFieldValue('county', value !== null ? value : initialValues.county);
                            setCounty(value !== null ? String(value) : initialValues.county);
                            setFieldValue('city', '');
                          }}
                          includeInputInList
                          onOpen={handleBlur}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("County")}
                              name="county"
                              error={Boolean(touched.county && errors.county)}
                              helperText={touched.county && errors.county}
                            />
                          )}
                        />
                      </Grid>
                    )}
                    <Grid item xs={openAddress ? 6 : 12}>
                      <TextField
                        error={Boolean(touched.cif && errors.cif)}
                        fullWidth
                        helperText={touched.cif && errors.cif}
                        label={t("CIF")}
                        margin="dense"
                        name="cif"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.cif}
                        variant="outlined"
                      />
                    </Grid>
                    {openAddress && (
                      <Grid item xs={6}>
                        <Autocomplete
                          sx={{mt: 1}}
                          freeSolo
                          id="city"
                          name="city"
                          disabled={!values.county || !cities.length}
                          options={cities}
                          value={values.city}
                          getOptionLabel={option => option}
                          onChange={(e, value) => setFieldValue('city', value !== null ? value : initialValues.city)}
                          includeInputInList
                          onOpen={handleBlur}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("City")}
                              name="city"
                              error={Boolean(touched.city && errors.city)}
                              helperText={touched.city && errors.city}
                            />
                          )}
                        />
                      </Grid>
                    )}
                    {mode === 'add' && (
                      <Grid item xs={12}>
                        <Button variant="text" onClick={handleAddAddress}>
                          {openAddress ? t("Close Address Section") : t("Open Address Section")}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                  <Box sx={{ py: 2 }}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      {t("Save")}
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default CustomerManageForm;
