import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Box, Button, Container, TextField, Grid } from '@material-ui/core';
import {
  addArea,
  getArea,
  updateArea,
  adminSelector,
} from 'src/features/AdminSlice';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { userSelector } from 'src/features/UserSlice';

const AreaManageForm = (props) => {
  const { t } = useTranslation();
  const { handleClose, mode, areaId } = props;
  const { area } = useSelector(adminSelector);
  const { user } = useSelector(userSelector);
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({
    name: '',
  });
  const validationSchema = {
    name: Yup.string().max(255).required(t('Name is required')),
  };

  useEffect(() => {
    if (mode === 'edit' && areaId) {
      dispatch(getArea(areaId));
    }
  }, [dispatch, mode, areaId]);

  useEffect(() => {
    if (mode === 'edit' && area) {
      setInitialValues({
        name: t(area.name),
      });
    }
  }, [area, mode, t]);

  const handleSubmit = (data, formikData) => {
    if (mode === 'add') {
      data.shop = user.shop['@id'];
      dispatch(addArea(data)).then((response) => {
        if (!response.error) {
          toast.success(t('Data added successfully'), {
            position: 'bottom-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          handleClose();
        } else {
          toast.error(t(response.payload), {
            position: 'bottom-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
        }
      });
    } else {
      data.id = area.id;
      dispatch(updateArea(data)).then((response) => {
        if (!response.error) {
          toast.success(t('Data updated successfully'), {
            position: 'bottom-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          handleClose();
        } else {
          toast.error(t(response.payload), {
            position: 'bottom-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
        }
      });
    }
    formikData.setSubmitting(false);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <Container maxWidth='sm'>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={Yup.object().shape(validationSchema)}
            onSubmit={(data, formikData) => handleSubmit(data, formikData)}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <Form id='add-area'>
                <Grid
                  container
                  spacing={1}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label={t('Name')}
                      margin='dense'
                      name='name'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant='outlined'
                    />
                  </Grid>
                </Grid>
                <Box sx={{ py: 2 }}>
                  <Button
                    color='primary'
                    disabled={isSubmitting}
                    fullWidth
                    size='large'
                    type='submit'
                    variant='contained'
                  >
                    {t('Save')}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default AreaManageForm;
