import React from 'react';
import {
  Box,
  Container,
} from '@material-ui/core';
import Dialog from '../Dialog';
import Confirm from '../Confirm';
import DataGridFilterable from '../utils/DataGridFilterable';
import ProductManageForm from './ProductManageForm';

const ProductListResults = (props) => {
  const {
    rows,
    columns,
    searchText,
    requestSearch,
    openAddDialog,
    mode,
    open,
    handleClose,
    productId,
    confirmOpen,
    handleConfirmClose,
    confirmMessage,
    handleConfirmAction,
  } = props;
  
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        <Box>
          <DataGridFilterable
            addButtonText="Add Product"
            openAddDialog={openAddDialog}
            searchText={searchText}
            requestSearch={requestSearch}
            rows={rows}
            columns={columns}
            autoHeight
            disableColumnFilter
            disableColumnMenu
            disableSelectionOnClick
            density="comfortable"
          />
        </Box>
        <Dialog
          title={mode === 'add' ? "Add product" : "Edit product"}
          open={open}
          handleClose={handleClose}
        >
          <ProductManageForm
            handleClose={handleClose}
            mode={mode}
            productId={productId}
          />
        </Dialog>
        <Confirm
          open={confirmOpen}
          handleClose={handleConfirmClose}
          message={confirmMessage}
          handleConfirmAction={handleConfirmAction}
        />
      </Container>
    </Box>
  );
}

export default ProductListResults;
