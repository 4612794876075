import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import { GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';
import { toast } from 'react-toastify';
import moment from 'moment';
import CustomerListResults from '../components/customer/CustomerListResults';
import CustomerListToolbar from '../components/customer/CustomerListToolbar';
import { getCustomers, updateCustomer, softDeleteCustomer, shopSelector, shopClearState } from '../features/ShopSlice';
import Loading from '../components/Loading';
import Can from '../can';
import escapeRegExp from '../utils/escapeRegExp';
import { useTranslation } from 'react-i18next';

const CustomerList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { customers, isError, errorMessage, isFetching } = useSelector(shopSelector);
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [mode, setMode] = useState('add');
  const [customerId, setCustomerId] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [openAddress, setOpenAddress] = useState(false);
  const columns = [
    { field: 'firstName', headerName: t('First name'), flex: 1 },
    { field: 'lastName', headerName: t('Last name'), flex: 1 },
    { field: 'email', headerName: t('Email'), flex: 1 },
    { field: 'phone', headerName: t('Phone'), flex: 1 },
    { field: 'cif', headerName: t('CIF'), flex: 1 },
    { field: 'created', headerName: t('Created'), flex: 1 },
    { field: 'active', headerName: t('Active'), flex: 1, type: 'boolean', hide: !Can('manage', 'customers') },
    { field: 'actions',
      type: 'actions',
      headerName: t('Actions'),
      cellClassName: 'actions',
      hide: !Can('manage', 'customers'),
      getActions: ({id, row}) => {
        let actions = [
          <GridActionsCellItem
            icon={<EditIcon />}
            label={t("Edit")}
            onClick={() => handleOpen('edit', row.customerId)}
            color="warning"
            aria-label="edit"
          />,
        ];
        if (Can('view', 'all')) {
          if (row.active) {
            actions.push(<GridActionsCellItem
              icon={<DeleteIcon />}
              label={t("Soft Delete")}
              onClick={() => handleConfirmOpen(row.customerId, row.name)}
              color="error"
              aria-label="soft-delete"
            />);
          } else {
            actions.push(<GridActionsCellItem
              icon={<RestoreIcon />}
              label={t("Restore")}
              onClick={() => handleRestore(row.customerId)}
              color="success"
              aria-label="restore"
            />);
          }
        } else {
          actions.push(<GridActionsCellItem
            icon={<DeleteIcon />}
            label={t("Soft Delete")}
            onClick={() => handleConfirmOpen(row.customerId, row.firstName + ' ' + row.lastName)}
            color="error"
            aria-label="soft-delete"
          />);
        }
        return actions;
      },
    }
  ];

  useEffect(() => {
    dispatch(getCustomers());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      toast.error(t(errorMessage), {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
    return () => {
      dispatch(shopClearState());
    }
  }, [isError, errorMessage, dispatch, t]);

  useEffect(() => {
    if (customers) {
      setRows(mapCustomersToRows(customers));
    }
  }, [customers]);

  const mapCustomersToRows = (customers) => {
    return customers.map((customer, index) => {
      return {
        id: customer.id,
        customerId: customer['@id'],
        firstName: customer.firstName,
        lastName: customer.lastName,
        email: customer.email,
        phone: customer.phone,
        cif: customer.cif,
        created: moment(customer.created).format("YYYY-MM-DD"),
        active: customer.active,
      }
    })
  }

  const requestSearch = (searchValue) => {
    if (customers) {
      setSearchText(searchValue);
      const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
      const filteredRows = mapCustomersToRows(customers).filter((row) => {
        return Object.keys(row).some((field) => {
          if (field === 'customerId') {
            return false;
          }
          return searchRegex.test(row[field].toString());
        });
      });
      setRows(filteredRows);
    }
  };

  const handleOpen = (mode, id = null) => {
    setOpen(true);
    setMode(mode);
    setCustomerId(id);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
      setOpenAddress(false);
    }
  };

  const openAddDialog = () => {
    handleOpen('add');
  }

  const handleConfirmOpen = (id, name, edit = false) => {
    if (edit) {
      console.log('edit');
    } else {
      setConfirmOpen(true);
      setCustomerId(id);
      setConfirmMessage(t('Are you sure you want to delete customer: ') + name + '?');
    }
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleConfirmAction = () => {
    if (customerId) {
      dispatch(softDeleteCustomer(customerId)).then((response) => {
        if (!response.error) {
        toast.success(t('Customer deleted successfully'), {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        handleConfirmClose();
        dispatch(getCustomers());
        dispatch(shopClearState());
      } else {
        toast.error(t(response.payload), {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        dispatch(shopClearState());
      }
      });
    }
  }

  const handleRestore = (id) => {
    const data = {id: id, active: true};
    dispatch(updateCustomer(data)).then(response => {
      if (!response.error) {
        toast.success(t('Customer restored successfully'), {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        dispatch(getCustomers());
        dispatch(shopClearState());
      } else {
        toast.error(t(response.payload), {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        dispatch(shopClearState());
      }
    });
  }

  const handleAddAddress = () => {
    setOpenAddress(!openAddress);
  }

  if (isFetching && !customers) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>{t("Customers")}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <CustomerListToolbar />
          <CustomerListResults
            rows={rows}
            columns={columns}
            searchText={searchText}
            requestSearch={requestSearch}
            openAddDialog={openAddDialog}
            mode={mode}
            open={open}
            handleClose={handleClose}
            customerId={customerId}
            confirmOpen={confirmOpen}
            handleConfirmClose={handleConfirmClose}
            confirmMessage={confirmMessage}
            handleConfirmAction={handleConfirmAction}
            openAddress={openAddress}
            handleAddAddress={handleAddAddress}
          />
        </Container>
      </Box>
    </>
  )
}

export default CustomerList;
