import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Button,
  Grid,
  Typography,
  Divider,
  Card,
  CardContent,
  TextField,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Loading from 'src/components/Loading';
import {
  NavLink as RouterLink,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useParams } from 'react-router';
import Confirm from '../Confirm';
import {
  removeFromCart,
  cartClearState,
  updateOrder,
  orderSelector,
  getOrder,
  updateProductComments,
} from 'src/features/OrderSlice';
import { LoadingButton } from '@mui/lab';
import HomeIcon from '@mui/icons-material/Home';
import Dialog from '../../components/Dialog';

const OrderManage = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { orderId, standId } = useParams();
  const { name, seats, status, statuses, parentCategory, orderNumber } =
    location.state || {};
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [productIdToDelete, setProductIdToDelete] = useState(null);
  const { cartItems, isFetching, hasChanges } = useSelector(orderSelector);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isButtonDisabled = cartItems.length === 0 || !hasChanges;
  const [open, setOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [comments, setComments] = useState('');

  useEffect(() => {
    if (orderId) {
      dispatch(getOrder(orderId));
    }
  }, [dispatch, orderId]);

  const handleConfirmOpen = (id, name) => {
    setConfirmOpen(true);
    setConfirmMessage(
      `${t('Are you sure you want to remove product: ')}"${capitalizeFirstChar(
        name
      )}" ${t('from order?')}`
    );
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleConfirmAction = () => {
    dispatch(removeFromCart({ index: productIdToDelete }));
    handleConfirmClose();
  };

  const handleOpen = (product) => {
    setSelectedProduct(product);
    setComments(product.comments || '');
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  const handleCommentsChange = (event) => {
    setComments(event.target.value);
  };

  const handleSubmitComments = (event) => {
    event.preventDefault();

    if (selectedProduct) {
      dispatch(
        updateProductComments({ index: selectedProduct.index, comments })
      );
      setComments('');
      setOpen(false);
    }
  };

  const handleDeleteForeverProduct = (product) => {
    const productIndex = product.index;
    setProductIdToDelete(productIndex);
    handleConfirmOpen(productIndex, product.name);
  };

  const handleBackClick = () => {
    navigate(`/app/stands/${standId}`, {
      state: { name, seats, status, statuses, parentCategory, orderId },
      replace: true,
    });
  };

  const handleUpdateOrder = () => {
    setIsSubmitting(true);

    const orderProducts = cartItems.map((item) => {
      return {
        product: item.product ? item.product['@id'] : item['@id'],
        qty: item.qty || 1,
        comments: item.comments || '',
      };
    });

    const dataToSend = {
      id: orderId,
      orderProducts,
    };

    dispatch(updateOrder(dataToSend)).then((response) => {
      if (!response.error) {
        toast.success(t('Order updated successfully'), {
          width: '90%',
          position: 'bottom-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        dispatch(cartClearState());
        setIsSubmitting(false);
        navigate('/app/dashboard', { replace: true });
      } else {
        toast.error(t(response.error.message || 'An error occurred'), {
          position: 'bottom-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        setIsSubmitting(false);
      }
    });
  };

  const capitalizeFirstChar = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  if (!cartItems || isFetching) {
    return <Loading />;
  }

  return (
    <Box
      position='relative'
      width='100%'
    >
      <Helmet>
        <title>Order</title>
      </Helmet>

      <Grid
        container
        sx={{
          backgroundColor: '#FAFAFA',
        }}
      >
        <Grid
          container
          sx={{
            backgroundColor: '#F5F5F5',
          }}
        >
          <Grid
            item
            xs={6}
          >
            <Button
              onClick={handleBackClick}
              sx={{ color: '#949494' }}
            >
              <ArrowBackIosIcon
                sx={{ fontSize: '2.5rem', fontWeight: 'bold' }}
              />
              <Typography
                variant='h6'
                sx={{
                  color: '#949494',
                  fontSize: '1.5rem',
                  textTransform: 'capitalize',
                }}
              >
                {t('Menu')}
              </Typography>
            </Button>
          </Grid>

          <Grid
            item
            xs={6}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'right',
              paddingRight: 1,
            }}
          >
            <Typography
              variant='h6'
              sx={{ color: '#949494', fontSize: '1.5rem' }}
            >
              {t('T') + ': ' + (name || t('Default Table Name'))}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
        >
          <Divider />
        </Grid>

        <Grid
          container
          sx={{
            padding: 1,
          }}
        >
          <Grid
            container
            item
            xs={12}
            sx={{
              mb: 1,
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderBottom: '2px solid #949494',
              }}
            >
              {orderNumber && (
                <Typography
                  sx={{ color: '#494949', fontSize: '18px', mt: 1, mb: 1 }}
                >
                  {t('Order no.')}{' '}
                  <span style={{ fontWeight: 'bold', color: '#2E8B57' }}>
                    {orderNumber}
                  </span>{' '}
                  {t('- Details')}
                </Typography>
              )}
            </Grid>

            {cartItems.length === 0 ? (
              <Grid
                container
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: 7,
                }}
              >
                <Card>
                  <CardContent>
                    <Typography sx={{ textAlign: 'center', fontSize: '1rem' }}>
                      {t('No products are added.')}
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: 'center',
                        fontSize: '1rem',
                        marginTop: '1rem',
                      }}
                    >
                      {t('An order must contain at least one product!')}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sx={{
                  mt: 1,
                  pb: 5,
                }}
              >
                {cartItems.map((orderProduct) => (
                  <Grid
                    key={orderProduct.index}
                    sx={{
                      width: '100%',
                      marginBottom: 1,
                      borderRadius: '0 !important',
                      padding: 1,
                    }}
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Grid
                        item
                        xs={10}
                        sx={{
                          backgroundColor: '#E8E8E8',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'start',
                          alignItems: 'start',
                          p: '10px',
                          transition: 'background-color 0.3s ease-in-out',
                          '&:hover, &:focus': {
                            backgroundColor: '#d5dcde',
                          },
                          cursor: 'pointer',
                        }}
                        onClick={() => handleOpen(orderProduct)}
                      >
                        <Typography
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            maxWidth: '100%',
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                            color: '#949494',
                            textTransform: 'capitalize',
                          }}
                        >
                          {t(orderProduct.name)}
                        </Typography>
                        {orderProduct.comments && (
                          <Typography
                            style={{
                              overflow: 'hidden',
                              maxWidth: '100%',
                              fontFamily: 'Roboto',
                              fontWeight: '300',
                              fontSize: '14px',
                              lineHeight: 'normal',
                              color: '#949494',
                              marginTop: '5px',
                            }}
                          >
                            {t(orderProduct.comments)}
                          </Typography>
                        )}
                      </Grid>

                      <Grid
                        item
                        xs={1}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <DeleteForeverIcon
                          color='error'
                          sx={{
                            width: '30px',
                            height: '30px',
                            borderRadius: '50%',
                            backgroundColor: 'transparent',
                            transition: 'background-color 0.3s ease-in-out',
                            '&:hover, &:focus': {
                              backgroundColor: '#E8E8E8',
                            },
                            cursor: 'pointer',
                          }}
                          onClick={() =>
                            handleDeleteForeverProduct(orderProduct)
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        position={'fixed'}
        bottom='8px'
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          width: '-webkit-fill-available',
        }}
      >
        <Grid
          item
          xs={2}
          sm={2}
        >
          <Button
            sx={{
              fontSize: '16px',
              border: '1px solid #008B8B',
              backgroundColor: 'rgba(227, 130, 99, 0.9)',
              color: 'white',
              height: '50px',
              '&:hover': {
                backgroundColor: 'rgba(227, 130, 99, 0.9)',
              },
            }}
            onClick={handleBackClick}
          >
            <Typography
              variant='h3'
              sx={{
                color: 'white',
                fontWeight: 'bold',
              }}
            >
              +
            </Typography>
          </Button>
        </Grid>

        <Grid
          item
          xs={6}
          sm={6}
        >
          <LoadingButton
            loading={isSubmitting}
            variant='contained'
            color='secondary'
            sx={{
              width: '100%',
              fontSize: '16px',
              border: '1px solid #008B8B',
              backgroundColor: 'rgba(82, 185, 199, 0.9)',
              height: '50px',
              '&:hover': {
                backgroundColor: 'rgba(82, 185, 199, 0.9)',
              },
              '&:disabled': {
                backgroundColor: '#E0E0E0!important',
                color: '#B1B1B1!important',
                border: '1px solid #B1B1B1',
              },
            }}
            onClick={handleUpdateOrder}
            disabled={isButtonDisabled}
          >
            {!isSubmitting && (
              <span
                style={{
                  display: 'block',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  textTransform: 'capitalize',
                }}
              >
                {t('Update order')}
              </span>
            )}
          </LoadingButton>
        </Grid>

        <Grid
          item
          xs={2}
          sm={2}
          sx={{ textAlign: 'end' }}
        >
          <Button
            component={RouterLink}
            sx={{
              border: '1px solid #008B8B',
              height: '50px',
            }}
            to='/app/dashboard'
          >
            <HomeIcon
              sx={{ fontSize: 40 }}
              color='primary'
            />
          </Button>
        </Grid>
      </Grid>

      <Confirm
        open={confirmOpen}
        handleClose={handleConfirmClose}
        message={confirmMessage}
        handleConfirmAction={handleConfirmAction}
      />

      <Dialog
        title={t('Extra options for') + ' ' + (selectedProduct?.name || t('product'))}
        open={open}
        handleClose={handleClose}
        maxWidth='xs'
        fullWidth
      >
        <form onSubmit={handleSubmitComments}>
          <TextField
            label={t('Comments')}
            value={comments}
            onChange={handleCommentsChange}
            fullWidth
            margin='normal'
            variant='outlined'
            multiline
            rows={4}
          />
          <Button
            type='submit'
            variant='contained'
            color='primary'
            fullWidth
          >
            {t('Edit Comments')}
          </Button>
        </form>
      </Dialog>
    </Box>
  );
};

export default OrderManage;
