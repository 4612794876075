import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { updateUser, userSelector, userClearState } from '../../features/UserSlice';
import { toast } from 'react-toastify';
import Can from '../../can';
import { useTranslation } from 'react-i18next';

const AccountProfileDetails = (props) => {
  const dispatch = useDispatch();
  const user = props.user;
  const { isUpdateSuccess, isFetching } = useSelector(userSelector);
  const initialValues = {
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    phone: user.phone,
  };
  const { t } = useTranslation();

  useEffect(() => {
    if (isUpdateSuccess) {
      toast.success(t('Profile updated successfully'), {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
    return () => {
      dispatch(userClearState());
    }
  }, [isUpdateSuccess, dispatch, t]);

  const handleSubmit = (data) => {
    dispatch(updateUser(data));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={
        Yup.object().shape({
          email: Yup.string().email(t('Must be a valid email')).max(255).required(t('Email is required')),
          firstName: Yup.string().max(255).required(t('First name is required')),
          lastName: Yup.string().max(255).required(t('Last name is required')),
          phone: Yup.string().max(20).required(t('Phone number is required')),
        })
      }
      onSubmit={(data) => handleSubmit(data)}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue
      }) => (
        <form id="update-user" onSubmit={handleSubmit}>
          <Card>
            <CardHeader title={t("Profile")} />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                    label={t("First name")}
                    name="firstName"
                    value={values.firstName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                    label={t("Last name")}
                    name="lastName"
                    value={values.lastName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    label={t("Email Address")}
                    name="email"
                    disabled
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    error={Boolean(touched.phone && errors.phone)}
                    helperText={touched.phone && errors.phone}
                    label={t("Phone Number")}
                    name="phone"
                    value={values.phone}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            {Can('manage', 'ownUser') && (
              <>
                <Divider />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    p: 2
                  }}
                >
                  <Button
                    color="primary"
                    disabled={isSubmitting && isFetching}
                    type="submit"
                    variant="contained"
                  >
                    {t("Save")}
                  </Button>
                </Box>
              </>
            )}
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default AccountProfileDetails;
