import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Grid,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { userSelector } from '../../../features/UserSlice';
import {
  getEditingProductCategory,
  getProductCategories,
  addProductCategory,
  updateProductCategory,
  shopSelector,
} from '../../../features/ShopSlice';
import Loading from '../../Loading';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import UserService from 'src/services';
import { toast } from 'react-toastify';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const ProductCategoryManageForm = (props) => {
  const { handleClose, mode, productCategoryId } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useSelector(userSelector);
  const { shopId } = useParams();
  const isAdmin = UserService.isAdmin();
  const { selectedProductCategory, editingProductCategory, productCategories } =
    useSelector(shopSelector);
  const [initialValues, setInitialValues] = useState({
    name: '',
    description: '',
    parent: '',
    featured: false,
  });
  const safeInitialValues = {
    name: initialValues.name || '',
    description: initialValues.description || '',
    parent: initialValues.parent || '',
    featured: initialValues.featured,
  };
  const [disableFeaturedCheckbox, setDisableFeaturedCheckbox] = useState(false);
  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'rgba(244,246,248,0.9)',
      color: 'red',
      boxShadow: theme.shadows[1],
      fontSize: theme.typography.pxToRem(14),
      padding: '10px 15px',
    },
  }));

  useEffect(() => {
    if (mode === 'edit' && productCategoryId) {
      dispatch(getEditingProductCategory(productCategoryId));
    }
  }, [dispatch, mode, productCategoryId]);

  useEffect(() => {
    let initialVal = {};
    if (mode === 'edit' && editingProductCategory) {
      initialVal = {
        name: editingProductCategory.name,
        description: editingProductCategory.description,
        parent: editingProductCategory.parent
          ? editingProductCategory.parent['@id']
          : '',
        featured: editingProductCategory.featured,
      };
    } else {
      initialVal = {
        name: '',
        description: '',
        parent: '',
        featured: false,
      };
    }
    setInitialValues(initialVal);
  }, [editingProductCategory, mode]);

  useEffect(() => {
    const featuredCategoriesCount = productCategories.filter(
      (cat) => cat.featured && cat.parent.name === selectedProductCategory.name
    ).length;
    const isEditModeRestriction =
      mode === 'edit' &&
      featuredCategoriesCount >= 4 &&
      editingProductCategory &&
      !editingProductCategory.featured;
    const isAddModeRestriction = mode === 'add' && featuredCategoriesCount >= 4;

    if (isEditModeRestriction || isAddModeRestriction) {
      setDisableFeaturedCheckbox(true);
    } else {
      setDisableFeaturedCheckbox(false);
    }
  }, [
    productCategories,
    selectedProductCategory,
    mode,
    editingProductCategory,
  ]);

  const handleSubmit = (data, formikData) => {
    if (!data.parent) {
      delete data.parent;
    }
    if (mode === 'add') {
      data.shop = isAdmin ? `/api/shops/` + shopId : user.shop['@id'];
      data.parent = selectedProductCategory['@id'];
      dispatch(addProductCategory(data)).then((response) => {
        if (!response.error) {
          toast.success(t('Product category added successfully'), {
            position: 'bottom-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          handleClose();
          dispatch(getProductCategories());
        }
        formikData.setSubmitting(false);
      });
    } else {
      dispatch(
        updateProductCategory({ id: editingProductCategory.id, data: data })
      ).then((response) => {
        if (!response.error) {
          toast.success(t('Product category updated successfully'), {
            position: 'bottom-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          handleClose();
          dispatch(getProductCategories());
        }
        formikData.setSubmitting(false);
      });
    }
  };

  if (mode === 'edit' && !editingProductCategory) {
    return <Loading />;
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <Container>
          <Grid container>
            <Formik
              initialValues={safeInitialValues}
              enableReinitialize={true}
              validationSchema={Yup.object().shape({
                name: Yup.string().max(255).required(t('Name is required')),
              })}
              onSubmit={(data, formikData) => handleSubmit(data, formikData)}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue,
                dirty,
                isValid,
              }) => (
                <Form id={mode}>
                  <Grid
                    container
                    spacing={1}
                  >
                    <Grid
                      item
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.name && errors.name)}
                        fullWidth
                        helperText={touched.name && errors.name}
                        label={t('Name')}
                        margin='dense'
                        name='name'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                        variant='outlined'
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <TextField
                        error={Boolean(
                          touched.description && errors.description
                        )}
                        fullWidth
                        helperText={touched.description && errors.description}
                        label={t('Description')}
                        margin='dense'
                        name='description'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.description}
                        variant='outlined'
                      />
                    </Grid>
                    <Grid
                      item
                      xs={1}
                    >
                      <Box
                        display='flex'
                        alignItems='center'
                        height='100%'
                      >
                        <FormControlLabel
                          style={{ whiteSpace: 'nowrap' }}
                          control={
                            disableFeaturedCheckbox ? (
                              <CustomTooltip
                                title="You already have 4 product categories in the 'featured' section!"
                                placement='right'
                              >
                                <span>
                                  <Checkbox
                                    checked={values.featured}
                                    name='featured'
                                    color='primary'
                                    disabled={true}
                                  />
                                </span>
                              </CustomTooltip>
                            ) : (
                              <Checkbox
                                checked={values.featured}
                                onChange={() => {
                                  setFieldValue('featured', !values.featured);
                                }}
                                name='featured'
                                color='primary'
                              />
                            )
                          }
                          label={t('Featured category')}
                          disabled={disableFeaturedCheckbox}
                        />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <TextField
                        label={t('Category')}
                        value={
                          mode === 'edit'
                            ? editingProductCategory.parent.name
                            : selectedProductCategory.name
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        style={{ display: 'none' }}
                      />
                    </Grid>
                  </Grid>
                  <Box sx={{ py: 2 }}>
                    <Button
                      color='primary'
                      disabled={isSubmitting}
                      fullWidth
                      size='large'
                      type='submit'
                      variant='contained'
                    >
                      {t('Save')}
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default ProductCategoryManageForm;
