import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Confirm = (props) => {
  const {open, handleClose, message, handleConfirmAction, isDeleting} = props;
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("No")}</Button>
        <Button onClick={handleConfirmAction} disabled={isDeleting} autoFocus>
          {t("Yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Confirm;
