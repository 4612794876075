import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import React from 'react';
import { Box, Container } from '@material-ui/core';
import { GridActionsCellItem } from '@mui/x-data-grid';
import {
  getRecipes,
  getRecipesByShopIdForAdmin,
  updateRecipe,
  softDeleteRecipe,
  deleteRecipe,
  shopSelector,
  shopClearState,
} from '../../src/features/ShopSlice';
import moment from 'moment';
import { toast } from 'react-toastify';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTranslation } from 'react-i18next';
import RecipeListToolbar, {
  StyledTitle,
} from '../components/recipe/RecipeListToolbar';
import RecipeListResults from '../components/recipe/RecipeListResults';
import Loading from '../components/Loading';
import Can from '../can';
import escapeRegExp from '../utils/escapeRegExp';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import UserService from 'src/services';

const RecipeList = (props) => {
  const { t } = useTranslation();
  const { recipes, isError, errorMessage, isFetching } =
    useSelector(shopSelector);
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [recipeId, setRecipeId] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [mode, setMode] = useState('add');
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const navigate = useNavigate();
  const isAdmin = UserService.isAdmin();
  const isShop = UserService.isShop();
  const { shopId } = useParams();
  const columns = [
    {
      field: 'name',
      headerName: t('Name'),
      flex: 1,
      renderCell: (params) => (
        <span
          onClick={() => handleRowClick(params.row)}
          style={{ cursor: 'pointer', color: '#5664d2' }}
        >
          {params.row.name}
        </span>
      ),
    },
    { field: 'created', headerName: t('Created'), flex: 1, editable: false },
    { field: 'updated', headerName: t('Updated'), flex: 1, editable: false },
    {
      field: 'active',
      headerName: t('Active'),
      flex: 1,
      editable: false,
      type: 'boolean',
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: t('Actions'),
      width: 100,
      cellClassName: 'actions',
      flex: 1,
      getActions: ({ id, row }) => {
        let actions = [
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label={t('View Products')}
            onClick={() => handleRowClick(row)}
            color='primary'
            aria-label='view-products'
          />,
        ];

        if (Can('manage', 'recipes')) {
          if (row.active) {
            actions.push(
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label='Soft Delete'
                onClick={handleSoftDeleteClick(id)}
                color='warning'
                aria-label='soft-delete'
              />
            );
          } else {
            actions.push(
              <GridActionsCellItem
                icon={<RestoreIcon />}
                label='Restore'
                onClick={handleRestore({
                  id: id,
                  field: 'active',
                  value: true,
                })}
                color='success'
                aria-label='restore'
              />
            );
          }
          actions.push(
            <GridActionsCellItem
              icon={<DeleteForeverIcon />}
              label='Delete'
              onClick={() => handleConfirmOpen(row.id, row.name)}
              color='error'
              aria-label='delete'
            />
          );
        }

        return actions;
      },
    },
  ];

  useEffect(() => {
    if (shopId && isAdmin) {
      dispatch(getRecipesByShopIdForAdmin(shopId));
    } else {
      dispatch(getRecipes());
    }
    return () => dispatch(shopClearState());
  }, [dispatch, shopId, isAdmin]);

  useEffect(() => {
    if (isError) {
      toast.error(t(errorMessage), {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
    return () => {
      dispatch(shopClearState());
    };
  }, [isError, errorMessage, dispatch, t]);

  useEffect(() => {
    if (recipes) {
      setRows(mapRecipesToRows(recipes));
    }
  }, [recipes]);

  const mapRecipesToRows = (recipes) => {
    return recipes.map((recipe, index) => {
      return {
        id: recipe.id,
        recipeId: recipe['@id'],
        name: recipe.name,
        created: moment(recipe.created.replace('+00:00', '')).format(
          'YYYY-MM-DD HH:mm:ss'
        ),
        updated: moment(recipe.updated.replace('+00:00', '')).format(
          'YYYY-MM-DD HH:mm:ss'
        ),
        active: recipe.active,
        recipeProducts: recipe.recipeProducts ? recipe.recipeProducts : [],
      };
    });
  };

  const requestSearch = (searchValue) => {
    if (recipes) {
      setSearchText(searchValue);
      const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
      const filteredRows = mapRecipesToRows(recipes).filter((row) => {
        return Object.keys(row).some((field) => {
          if (field === 'recipeId' || !row[field]) {
            return false;
          }
          return searchRegex.test(row[field].toString());
        });
      });
      setRows(filteredRows);
    }
  };

  const handleOpen = (mode, id = null) => {
    setOpen(true);
    setMode(mode);
    setRecipeId(id);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
    if (shopId && isAdmin) {
      dispatch(getRecipesByShopIdForAdmin(shopId));
    } else {
      dispatch(getRecipes());
    }
  };

  const openAddDialog = () => {
    handleOpen('add');
  };

  const handleConfirmOpen = (id, name, edit = false) => {
    if (edit) {
      console.log('edit');
    } else {
      setConfirmOpen(true);
      setRecipeId(id);
      setConfirmMessage(
        t('Are you sure you want to delete recipe: ') + name + '?'
      );
    }
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleSoftDeleteClick = (recipeId) => (event) => {
    event.stopPropagation();
    dispatch(softDeleteRecipe(recipeId)).then((response) => {
      if (!response.error) {
        toast.success(t('Recipe disabled successfully'), {
          position: 'bottom-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        if (shopId && isAdmin) {
          dispatch(getRecipesByShopIdForAdmin(shopId));
        } else {
          dispatch(getRecipes());
        }
        dispatch(shopClearState());
      } else {
        toast.error(t(response.payload), {
          position: 'bottom-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        dispatch(shopClearState());
      }
    });
  };

  const handleRestore = (params) => (event) => {
    event.stopPropagation();

    dispatch(
      updateRecipe({
        id: params.id,
        [params.field]: params.value,
      })
    ).then((response) => {
      if (!response.error) {
        toast.success(t('Recipe restored successfully'), {
          position: 'bottom-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        setRows((prev) =>
          prev.map((row) =>
            row.id === params.id
              ? {
                  ...row,
                  ...response.payload,
                  name: response.payload.name,
                  created: moment(
                    response.payload.created.replace('+00:00', '')
                  ).format('YYYY-MM-DD HH:mm:ss'),
                  updated: moment(
                    response.payload.updated.replace('+00:00', '')
                  ).format('YYYY-MM-DD HH:mm:ss'),
                }
              : row
          )
        );
        if (shopId && isAdmin) {
          dispatch(getRecipesByShopIdForAdmin(shopId));
        } else {
          dispatch(getRecipes());
        }
        dispatch(shopClearState());
      } else {
        toast.error(t(response.payload), {
          position: 'bottom-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        dispatch(shopClearState());
      }
    });
  };

  const handleConfirmAction = () => {
    if (recipeId) {
      dispatch(deleteRecipe(recipeId)).then((response) => {
        if (!response.error) {
          toast.success(t('Recipe removed successfully'), {
            position: 'bottom-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          handleConfirmClose();
          if (shopId && isAdmin) {
            dispatch(getRecipesByShopIdForAdmin(shopId));
          } else {
            dispatch(getRecipes());
          }
          dispatch(shopClearState());
        } else {
          toast.error(t(response.payload), {
            position: 'bottom-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          dispatch(shopClearState());
        }
      });
    }
  };

  const handleRowClick = (row) => {
    if (isAdmin) {
      navigate(`/app/admin/shops/${shopId}/recipes/${row.id}`, {
        replace: false,
      });
    } else if (isShop) {
      navigate(`/app/recipes/${row.id}`, { replace: false });
    }
  };

  if (isFetching && !recipes) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>{t('Recipes')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <StyledTitle>{t('Recipes')}</StyledTitle>

            <RecipeListToolbar />
          </Box>

          <RecipeListResults
            rows={rows}
            columns={columns}
            searchText={searchText}
            requestSearch={requestSearch}
            openAddDialog={openAddDialog}
            mode={mode}
            open={open}
            handleClose={handleClose}
            recipeId={recipeId}
            confirmOpen={confirmOpen}
            handleConfirmClose={handleConfirmClose}
            confirmMessage={confirmMessage}
            handleConfirmAction={handleConfirmAction}
          />
        </Container>
      </Box>
    </>
  );
};

export default RecipeList;
