export const counties = [
  "ALBA",
  "ARAD",
  "ARGES",
  "BACAU",
  "BIHOR",
  "BISTRITA-NASAUD",
  "BOTOSANI",
  "BRAILA",
  "BRASOV",
  "BUCURESTI",
  "BUZAU",
  "CALARASI",
  "CARAS-SEVERIN",
  "CLUJ",
  "CONSTANTA",
  "COVASNA",
  "DAMBOVITA",
  "DOLJ",
  "GALATI",
  "GIURGIU",
  "GORJ",
  "HARGHITA",
  "HUNEDOARA",
  "IALOMITA",
  "IASI",
  "ILFOV",
  "MARAMURES",
  "MEHEDINTI",
  "MURES",
  "NEAMT",
  "OLT",
  "PRAHOVA",
  "SALAJ",
  "SATU-MARE",
  "SIBIU",
  "SUCEAVA",
  "TELEORMAN",
  "TIMIS",
  "TULCEA",
  "VALCEA",
  "VASLUI",
  "VRANCEA"
];

export const cities = {
  "ALBA": [
    "ABRUD",
    "ABRUD-SAT",
    "AIUD",
    "AIUDUL DE SUS",
    "ALBA IULIA",
    "ALBAC",
    "AMPOITA",
    "ARONESTI",
    "BALESTI",
    "BALESTI-CATUN",
    "BALMOSESTI",
    "BARABANT",
    "BARLESTI (BISTRA)",
    "BAZESTI",
    "BELDIU",
    "BERGHIN",
    "BIDIGESTI",
    "BILANESTI",
    "BISTRA",
    "BLAJ",
    "BLIDESTI",
    "BOBARESTI (SOHODOL)",
    "BOBARESTI (VIDRA)",
    "BODESTI",
    "BOGLESTI",
    "BONCESTI",
    "BORDESTII POIENI",
    "BORLESTI",
    "BOTESTI (CAMPENI)",
    "BRADEANA",
    "BUCERDEA GRANOASA",
    "BUCERDEA VINOASA",
    "BURZESTI",
    "BURZONESTI",
    "CALNIC",
    "CAMPENI",
    "CARPINIS (ROSIA MONTANA)",
    "CERTEGE",
    "CHELETENI",
    "CIONESTI",
    "CISTEI",
    "CIUGUD",
    "CIULDESTI",
    "CIUMBRUD",
    "CIURULEASA",
    "COASTA HENTII",
    "COPAND",
    "COSLARIU",
    "COSLARIU NOU",
    "CRACIUNELU DE JOS",
    "CRETESTI",
    "CRICAU",
    "CRISENI",
    "CUGIR",
    "CULDESTI",
    "CURATURI",
    "CUT",
    "DAMBURENI",
    "DANDUT",
    "DAROAIA",
    "DEALU BISTRII",
    "DEALU CAPSEI",
    "DEALU FRUMOS (VADU MOTILOR)",
    "DEALU GOIESTI",
    "DEALU MUNTELUI",
    "DEONCESTI",
    "DILIMANI",
    "DOS",
    "DOSU VASESTI",
    "DRAGOIESTI-LUNCA",
    "DRAMBAR",
    "DRIC",
    "DUMITRA",
    "DUPA DEAL (LUPSA)",
    "DURASTI",
    "FATA ABRUDULUI",
    "FENES",
    "FICARESTI",
    "FLORESTI (CAMPENI)",
    "FURDUIESTI (CAMPENI)",
    "FURDUIESTI (SOHODOL)",
    "GALDA DE JOS",
    "GALTIU",
    "GANESTI",
    "GARBOVA DE JOS",
    "GARDA-BARBULESTI",
    "GARDE",
    "GHIRBOM",
    "GOIESTI",
    "GURA CORNEI",
    "GURA ROSIEI",
    "GURA SOHODOL",
    "HADARAU",
    "HAIDUCESTI",
    "HAPRIA",
    "HENIG",
    "HOANCA (SOHODOL)",
    "HOANCA (VIDRA)",
    "HODISESTI",
    "HUDRICESTI",
    "IACOBESTI",
    "IGHIEL",
    "IGHIU",
    "IGNATESTI",
    "INCESTI (POSAGA)",
    "JOLDISESTI",
    "LANCRAM",
    "LAZESTI (SCARISOARA)",
    "LAZESTI (VADU MOTILOR)",
    "LAZURI (LUPSA)",
    "LAZURI (SOHODOL)",
    "LEHESTI",
    "LIMBA (CIUGUD)",
    "LIPAIA",
    "LUMINESTI",
    "LUNCA (LUPSA)",
    "LUNCA (VIDRA)",
    "LUNCA AMPOITEI",
    "LUNCA BISERICII",
    "LUNCA DE JOS",
    "LUNCA GOIESTI",
    "LUNCA LARGA (BISTRA)",
    "LUNCA MERILOR",
    "LUNCA METESULUI",
    "LUNCA VESESTI",
    "LUPAIESTI",
    "LUPSA",
    "MANASTIRE",
    "MESENTEA",
    "METES",
    "MICESTI",
    "MIHAIESTI",
    "MIHALT",
    "MIHOESTI",
    "MORARESTI (CIURULEASA)",
    "MORARESTI (SOHODOL)",
    "MORCANESTI",
    "MOTORASTI",
    "MUNESTI",
    "MUSCA",
    "NAMAS",
    "NAPAIESTI",
    "NECSESTI",
    "NELEGESTI",
    "NEMESI",
    "NICORESTI",
    "NOVACESTI",
    "OARDA",
    "OIDESTI",
    "OIEJDEA",
    "PACLISA",
    "PARAU-CARBUNARI",
    "PELES",
    "PERJESTI",
    "PESTE VALEA BISTRII",
    "PETRESTI",
    "PETRISAT",
    "PITARCESTI",
    "PITIGA",
    "PLESCUTA",
    "PODURI",
    "PODURI-BRICESTI",
    "POIANA (BISTRA)",
    "POIANA AMPOIULUI",
    "POIENI (VIDRA)",
    "POIU",
    "PONOREL",
    "POPESTII DE SUS",
    "POSOGANI",
    "PRESACA AMPOIULUI",
    "PUIULETESTI",
    "RAHAU",
    "RATITIS",
    "RECIU",
    "ROBESTI",
    "ROGOZ",
    "ROSIA MONTANA",
    "RUNC (VIDRA)",
    "RUNCURI",
    "SALAGESTI",
    "SANCEL",
    "SANTIMBRU",
    "SARD",
    "SASA",
    "SEBES",
    "SEBESEL",
    "SEBISESTI",
    "SEUSA",
    "SICOIESTI",
    "SIMOCESTI",
    "SOAL",
    "SOHARU",
    "SOHODOL",
    "SORLITA",
    "STEFANCA",
    "STRAJA",
    "STREMT",
    "SURDESTI",
    "TARANESTI",
    "TARINA",
    "TAUTI",
    "TEIUS",
    "TELEAC",
    "TELNA",
    "TIFRA",
    "TIUR",
    "TOLACESTI",
    "TOMNATEC",
    "TOMUSESTI",
    "TOMUTESTI",
    "TOTESTI",
    "TOTOI",
    "TRISORESTI",
    "URDES",
    "VADU MOTILOR",
    "VAI",
    "VALCANEASA",
    "VALEA BISTRII",
    "VALEA CASELOR",
    "VALEA LUPSII",
    "VALEA MORII",
    "VALEA VINTULUI",
    "VALENI (METES)",
    "VALTORI (VADU MOTILOR)",
    "VARSI",
    "VARSII MARI",
    "VARSII MICI",
    "VARSI-RONTU",
    "VARTANESTI",
    "VARTOP",
    "VASESTI",
    "VEZA",
    "VINEREA",
    "VINTU DE JOS",
    "VLADOSESTI",
    "ZARIES",
    "ACHIMETESTI",
    "ACMARIU",
    "ALECUS",
    "ALMASU DE MIJLOC",
    "ALMASU MARE",
    "ANGHELESTI",
    "ARIESENI",
    "ARTI",
    "ASINIP",
    "AVRAM IANCU",
    "AVRAMESTI (ARIESENI)",
    "AVRAMESTI (AVRAM IANCU)",
    "BABA",
    "BACAINTI",
    "BADAI",
    "BAGAU",
    "BAI",
    "BAIA DE ARIES",
    "BALCACIU",
    "BALOMIRU DE CAMP",
    "BARASTI",
    "BARBESTI",
    "BARDESTI",
    "BARLESTI (MOGOS)",
    "BARLESTI (SCARISOARA)",
    "BARLESTI-CATUN",
    "BARSANA",
    "BARZAN",
    "BARZOGANI",
    "BENIC",
    "BETA",
    "BIHARIA",
    "BIIA",
    "BISERICANI",
    "BLANDIANA",
    "BOCESTI",
    "BOCSITURA",
    "BODRESTI",
    "BOGDANESTI (MOGOS)",
    "BOGDANESTI (VIDRA)",
    "BOLDESTI",
    "BOLOVANESTI",
    "BOTANI",
    "BOTESTI (SCARISOARA)",
    "BOTESTI (ZLATNA)",
    "BOZ",
    "BRADESTI",
    "BRADET",
    "BRAZESTI",
    "BUBESTI",
    "BUCIUM",
    "BUCIUM-SAT",
    "BUCURU",
    "BUDAIESTI",
    "BUDENI",
    "BULBUC",
    "BUNINGINEA",
    "BUNTA",
    "BUTESTI (HOREA)",
    "BUTESTI (MOGOS)",
    "CALENE",
    "CALUGARESTI",
    "CAMPU GOBLII",
    "CANDESTI",
    "CAPALNA",
    "CAPALNA DE JOS",
    "CAPTALAN",
    "CAPU DEALULUI",
    "CAPUD",
    "CARASTI",
    "CARPEN",
    "CARPENII DE SUS",
    "CARPINIS (GARBOVA)",
    "CARTULESTI",
    "CASA DE PIATRA",
    "CASOAIA",
    "CENADE",
    "CERBU",
    "CERGAU",
    "CERGAU MARE",
    "CERGAU MIC",
    "CERU-BACAINTI",
    "CETATEA DE BALTA",
    "CETEA",
    "CHEIA",
    "CHEILE CIBULUI",
    "CIB",
    "CICARD",
    "CICAU",
    "CIOARA DE SUS",
    "CIOCASU",
    "CISTEIU DE MURES",
    "CIUCULESTI",
    "CIUGUDU DE JOS",
    "CIUGUDU DE SUS",
    "CIUGUZEL",
    "COASTA VASCULUI",
    "COBLES",
    "COCESTI",
    "COCOSESTI",
    "COJOCANI",
    "COLESENI",
    "COLIBI",
    "COLTESTI",
    "CORNA",
    "CORNU",
    "COROIESTI",
    "CORTESTI",
    "COSTESTI (ALBAC)",
    "COSTESTI (POIANA VADULUI)",
    "COTORASTI",
    "CRACIUNELU DE SUS",
    "CRAIVA",
    "CRISTESTI",
    "CUCUTA",
    "CUNTA",
    "CURMATURA",
    "CURPENI",
    "DAIA ROMANA",
    "DARLESTI",
    "DEAL",
    "DEALU BAJULUI",
    "DEALU CASELOR",
    "DEALU CRISULUI",
    "DEALU DOSTATULUI",
    "DEALU FERULUI",
    "DEALU FRUMOS (GARDA DE SUS)",
    "DEALU GEOAGIULUI",
    "DEALU LAMASOI",
    "DEALU ORDANCUSII",
    "DEALU ROATEI",
    "DECEA",
    "DELENI-OBARSIE",
    "DEVE",
    "DOBRA",
    "DOBRESTI",
    "DOBROT",
    "DOGARESTI",
    "DOLESTI",
    "DOPTAU",
    "DOSTAT",
    "DOSU LUNCII",
    "DRASOV",
    "DUDUIENI",
    "DUMACESTI",
    "DUMBRAVA (SASCIORI)",
    "DUMBRAVA (UNIREA)",
    "DUMBRAVA (ZLATNA)",
    "DUMBRAVITA",
    "DUMESTI",
    "DUPA DEAL (PONOR)",
    "DUPA PLESE",
    "FAGET",
    "FAGETU DE JOS",
    "FAGETU DE SUS",
    "FANTANELE",
    "FARAU",
    "FATA",
    "FATA CRISTESEI",
    "FATA LAPUSULUI",
    "FATA PIETRII",
    "FATA-LAZESTI",
    "FEISA",
    "FERESTI",
    "FERICET",
    "FETENI",
    "FLITESTI",
    "FLORESTI (BUCIUM)",
    "FLORESTI (RAMET)",
    "FLORESTI (SCARISOARA)",
    "GABUD",
    "GALATI",
    "GALBENA",
    "GALDA DE SUS",
    "GAMBAS",
    "GARBOVA",
    "GARBOVA DE SUS",
    "GARBOVITA",
    "GARDA DE SUS",
    "GARDA SEACA",
    "GEAMANA",
    "GEOAGIU DE SUS",
    "GEOGEL",
    "GEOMAL",
    "GHEDULESTI",
    "GHETARI",
    "GHIONCANI",
    "GIURGIUT",
    "GLIGORESTI",
    "GLOD",
    "GLOGOVET",
    "GOASELE",
    "GOJEIESTI",
    "GORGAN",
    "GROSI",
    "GURA ARIESULUI",
    "GURA CUTULUI",
    "GURA IZBITEI",
    "HANASESTI (GARDA DE SUS)",
    "HANASESTI (POIANA VADULUI)",
    "HANES MINA",
    "HARASTI",
    "HATEGANA",
    "HELERESTI",
    "HELESTI",
    "HERIA",
    "HODOBANA",
    "HOLOBANI",
    "HOPARTA",
    "HOREA",
    "HUZARESTI",
    "IBRU",
    "ICLOD",
    "ILIESTI",
    "INCESTI (AVRAM IANCU)",
    "INOC",
    "INTREGALDE",
    "INURI",
    "ISCA",
    "IVANIS",
    "IZBICIOARA",
    "IZBITA",
    "IZLAZ",
    "IZVOARELE (BLAJ)",
    "IZVOARELE (GARDA DE SUS)",
    "IZVOARELE (LIVEZILE)",
    "IZVORU AMPOIULUI",
    "JEFLESTI",
    "JIDOSTINA",
    "JIDVEI",
    "JOJEI",
    "JURCUIESTI",
    "LAZ (SASCIORI)",
    "LAZ (VINTU DE JOS)",
    "LEORINT",
    "LESPEZEA",
    "LIVEZILE",
    "LODROMAN",
    "LOMAN",
    "LOPADEA NOUA",
    "LOPADEA VECHE",
    "LUNCA (POSAGA)",
    "LUNCA (VALEA LUNGA)",
    "LUNCA LARGA (OCOLIS)",
    "LUNCA MURESULUI",
    "LUNCA TARNAVEI",
    "LUPSENI",
    "LUPU",
    "LUPULESTI",
    "MACARESTI",
    "MAGHIERAT",
    "MAGINA",
    "MAGURA (BUCIUM)",
    "MAGURA (GALDA DE JOS)",
    "MAHACENI",
    "MAMALIGANI",
    "MANARADE",
    "MANCESTI",
    "MARGAIA",
    "MARGINENI",
    "MARINESTI",
    "MARTESTI",
    "MARTINIE",
    "MATACINA",
    "MATEI",
    "MATISESTI (CIURULEASA)",
    "MATISESTI (HOREA)",
    "MEDRESTI",
    "MEDVES",
    "MERETEU",
    "MESCREAC",
    "MICOSLACA",
    "MIRASLAU",
    "MODOLESTI (INTREGALDE)",
    "MODOLESTI (VIDRA)",
    "MOGOS",
    "MUGESTI",
    "MUNCELU",
    "MUNTARI",
    "MUNUNA",
    "NADASTIA",
    "NECRILESTI",
    "NEGESTI",
    "NEGRESTI",
    "NICULESTI",
    "NOSLAC",
    "OBREJA",
    "OCNA MURES",
    "OCNISOARA",
    "OCOALE",
    "OCOLIS",
    "ODVEREM",
    "OHABA",
    "OLTENI",
    "ONCESTI",
    "ORASTI",
    "ORGESTI",
    "ORMENIS",
    "PADURE",
    "PADUREA",
    "PAGIDA",
    "PANADE",
    "PANCA",
    "PANTESTI",
    "PARAU GRUIULUI",
    "PARAU LUI MIHAI",
    "PASTESTI",
    "PATRAHAITESTI",
    "PATRANGENI",
    "PATRUSESTI",
    "PATRUTESTI",
    "PETELCA",
    "PETELEI",
    "PETREASA",
    "PETRENI",
    "PIANU",
    "PIANU DE JOS",
    "PIANU DE SUS",
    "PIRITA",
    "PLAI (AVRAM IANCU)",
    "PLAI (GARDA DE SUS)",
    "PLAIURI",
    "PLESESTI",
    "PLESI",
    "PLISTI",
    "PODU LUI PAUL",
    "POIANA (BUCIUM)",
    "POIANA (SOHODOL)",
    "POIANA AIUDULUI",
    "POIANA GALDEI",
    "POIANA URSULUI",
    "POIANA VADULUI",
    "POIENI (BLANDIANA)",
    "POIENI (BUCIUM)",
    "POIENILE-MOGOS",
    "POIENITA (ARIESENI)",
    "POIENITA (VINTU DE JOS)",
    "PONOR",
    "POPESTI",
    "POPESTII DE JOS",
    "POSAGA",
    "POSAGA DE JOS",
    "POSAGA DE SUS",
    "POTIONCI",
    "PRELUCA",
    "PURCARETI",
    "PUSELESTI",
    "RACATAU",
    "RACHIS",
    "RACHITA",
    "RADESTI",
    "RAICANI",
    "RAMET",
    "RAVICESTI",
    "RAZBOIENI-CETATE",
    "REMETEA",
    "RIMETEA",
    "ROSESTI",
    "ROSIA DE SECAS",
    "RUNC (OCOLIS)",
    "RUNC (SCARISOARA)",
    "RUNC (ZLATNA)",
    "RUSESTI",
    "RUSI",
    "SAGAGEA",
    "SALCIUA",
    "SALCIUA DE JOS",
    "SALCIUA DE SUS",
    "SALISTEA",
    "SALISTEA-DEAL",
    "SANBENEDIC",
    "SANCRAI",
    "SANMICLAUS",
    "SANTAMARIE",
    "SARACSAU",
    "SARTAS",
    "SASCIORI",
    "SCARISOARA",
    "SCOARTA",
    "SECASEL",
    "SEGAJ",
    "SFARCEA",
    "SFOARTEA",
    "SIBOT",
    "SILEA",
    "SILIVAS",
    "SIMULESTI",
    "SNIDE",
    "SOHODOL (ALBAC)",
    "SOICESTI",
    "SOIMUS",
    "SONA",
    "SPALNACA",
    "SPATAC",
    "SPRING",
    "STALNISOARA",
    "STANA DE MURES",
    "STANESTI",
    "STAUINI",
    "STEI-ARIESENI",
    "STERTESTI",
    "STIULETI",
    "STRUNGARI",
    "STURU",
    "SUB PIATRA",
    "SUCESTI",
    "SUGAG",
    "SUSENI",
    "TAMBORESTI",
    "TARSA",
    "TARSA-PLAI",
    "TARTARIA",
    "TATARLAUA",
    "TAU",
    "TAU BISTRA",
    "TAUNI",
    "TECSESTI",
    "TEIU",
    "TIBRU",
    "TOCI",
    "TOMESTI",
    "TONEA",
    "TRAMPOIELE",
    "TRANCESTI",
    "TRIFESTI (HOREA)",
    "TRIFESTI (LUPSA)",
    "TURDAS",
    "UIOARA DE JOS",
    "UIOARA DE SUS",
    "UNGUREI",
    "UNIREA",
    "VALCEA",
    "VALCESTI",
    "VALE IN JOS",
    "VALEA ABRUZEL",
    "VALEA ALBA",
    "VALEA BARLUTESTI",
    "VALEA BARNII",
    "VALEA BUCURULUI",
    "VALEA CERBULUI",
    "VALEA CIUCIULUI",
    "VALEA COCESTI",
    "VALEA FAGETULUI",
    "VALEA GIOGESTI",
    "VALEA GOBLII",
    "VALEA HOLHORII",
    "VALEA INZELULUI",
    "VALEA LARGA",
    "VALEA LUI MIHAI",
    "VALEA LUNGA",
    "VALEA MACIULUI",
    "VALEA MANASTIRII",
    "VALEA MARE",
    "VALEA MICA",
    "VALEA MLACII",
    "VALEA NEGRILESII",
    "VALEA POIENII (BUCIUM)",
    "VALEA POIENII (RAMET)",
    "VALEA SASULUI",
    "VALEA SESII (BUCIUM)",
    "VALEA SESII (LUPSA)",
    "VALEA TUPILOR",
    "VALEA UTULUI",
    "VALEA UZEI",
    "VALEA VERDE",
    "VALENI (BUCIUM)",
    "VALISOARA",
    "VALTORI (ZLATNA)",
    "VAMA SEACA",
    "VANVUCESTI",
    "VERDESTI",
    "VESEUS",
    "VIDOLM",
    "VIDRA",
    "VIDRISOARA",
    "VIEZURI",
    "VINGARD",
    "VINTA",
    "VLADESTI",
    "VULCAN (CIURULEASA)",
    "VURPAR",
    "ZAGRIS",
    "ZANZESTI",
    "ZLATNA"
  ],
  "ARAD": [
    "AGRISU MARE",
    "ALUNIS",
    "ANDREI SAGUNA",
    "ARAD",
    "ARANEAG",
    "BARATCA",
    "BOCSIG",
    "BODROGU NOU",
    "BODROGU VECHI",
    "CALUGARENI",
    "CAMNA",
    "CHERELUS",
    "CHIER",
    "CHISINEU-CRIS",
    "CICIR",
    "CRUCENI",
    "CUIED",
    "CURTICI",
    "DOROBANTI",
    "DRAUT",
    "FANTANELE",
    "FELNAC",
    "FISCUT",
    "FRUMUSENI",
    "HORIA",
    "IERMATA",
    "INEU",
    "IRATOSU",
    "LIPOVA",
    "LIVADA",
    "MACEA",
    "MANDRULOC",
    "MINISEL",
    "MINISU DE SUS",
    "MOCREA",
    "MORODA",
    "NADAB",
    "NADAS",
    "NADLAC",
    "PAULIS",
    "PECICA",
    "RADNA",
    "SAGU",
    "SAMBATENI",
    "SANLEANI",
    "SANPAUL",
    "SEBIS",
    "SEDERHAT",
    "SICULA",
    "SILINDIA",
    "SOFRONEA",
    "SOIMOS",
    "TAUT",
    "TISA",
    "TISA NOUA",
    "TURNU",
    "VARIASU MARE",
    "VARIASU MIC",
    "VINGA",
    "VLADIMIRESCU",
    "VOIVODENI",
    "ZADARENI",
    "ZIMANDCUZ",
    "ZIMANDU NOU",
    "ACIUTA",
    "ADEA",
    "AGRISU MIC",
    "ALDESTI",
    "ALMAS",
    "APATEU",
    "ARCHIS",
    "AVRAM IANCU (CERMEI)",
    "AVRAM IANCU (VARFURILE)",
    "BACAU DE MIJLOC",
    "BAIA",
    "BANESTI",
    "BARSA",
    "BARZAVA",
    "BARZESTI",
    "BATA",
    "BATUTA",
    "BELIU",
    "BELOTINT",
    "BENESTI",
    "BERECHIU",
    "BERINDIA",
    "BIRCHIS",
    "BOCHIA",
    "BODESTI",
    "BONTESTI",
    "BOTFEI",
    "BRAZII",
    "BRUSTURI",
    "BRUZNIC",
    "BUCEAVA-SOIMUS",
    "BUDESTI",
    "BUHANI",
    "BULCI",
    "BUTENI",
    "CAPALNAS",
    "CAPORAL ALEXA",
    "CAPRIOARA",
    "CAPRUTA",
    "CARAND",
    "CERMEI",
    "CHELMAC",
    "CHESINT",
    "CHISINDIA",
    "CHISLACA",
    "CIL",
    "CINTEI",
    "CIUNTESTI",
    "CLADOVA",
    "CLIT",
    "COCIUBA",
    "COMANESTI",
    "CONOP",
    "CORBESTI",
    "COROI",
    "COVASINT",
    "CRAIVA",
    "CRISTESTI",
    "CROCNA",
    "CUIAS",
    "CUVESDIA",
    "CUVIN",
    "DEZNA",
    "DIECI",
    "DONCENI",
    "DORGOS",
    "DUD",
    "DULCELE",
    "DUMBRAVA",
    "DUMBRAVITA",
    "FENIS",
    "FIRITEAZ",
    "GALSA",
    "GHIOROC",
    "GRANICERI",
    "GROSENI",
    "GROSI",
    "GROSII NOI",
    "GURA VAII",
    "GURAHONT",
    "GURBA",
    "HALALIS",
    "HALMAGEL",
    "HALMAGIU",
    "HASMAS",
    "HODIS",
    "HONTISOR",
    "HUNEDOARA TIMISANA",
    "IACOBINI",
    "IERCOSENI",
    "IERMATA NEAGRA",
    "IGNESTI",
    "ILTEU",
    "IONESTI",
    "IOSAS",
    "JOIA MARE",
    "JULITA",
    "LABASINT",
    "LALASINT",
    "LAZ",
    "LAZURI",
    "LEASA",
    "LESTIOARA",
    "LUGUZAU",
    "LUNCSOARA",
    "LUPESTI",
    "MADERAT",
    "MADRIGESTI",
    "MAGULICEA",
    "MAILAT",
    "MANASTUR",
    "MANERAU",
    "MARAUS",
    "MASCA",
    "MERMESTI",
    "MILOVA",
    "MINEAD",
    "MINIS",
    "MISCA",
    "MONEASA",
    "MONOROSTIA",
    "MOTIORI",
    "MUNAR",
    "MUSTESTI",
    "NADALBESTI",
    "NEAGRA",
    "NERMIS",
    "NEUDORF",
    "NICOLAE BALCESCU",
    "OBARSIA",
    "ODVOS",
    "OLARI",
    "OSTROV",
    "PAIUSENI",
    "PANCOTA",
    "PARNESTI",
    "PATARS",
    "PAULIAN",
    "PEREGU MARE",
    "PEREGU MIC",
    "PESCARI",
    "PETRIS",
    "PILU",
    "PLESCUTA",
    "POIANA",
    "POIENARI",
    "PRUNISOR",
    "RADESTI",
    "RANUSA",
    "RAPSIG",
    "REVETIS",
    "ROGOZ DE BELIU",
    "ROSIA",
    "ROSIA NOUA",
    "ROSTOCI",
    "SALAJENI",
    "SANMARTIN",
    "SANPETRU GERMAN",
    "SANTANA",
    "SARBI",
    "SATU MARE",
    "SATU MIC",
    "SATU NOU",
    "SAVARSIN",
    "SECACI",
    "SECAS",
    "SECUSIGIU",
    "SEITIN",
    "SELEUS",
    "SELISTE",
    "SELISTEA",
    "SEMLAC",
    "SEPREUS",
    "SIAD",
    "SICLAU",
    "SIMAND",
    "SINTEA MARE",
    "SINTEA MICA",
    "SIRIA",
    "SISTAROVAT",
    "SLATINA DE CRIS",
    "SLATINA DE MURES",
    "SOCODOR",
    "SOMOSCHES",
    "STEJAR",
    "STOINESTI",
    "SUSAG",
    "SUSANI",
    "TAGADAU",
    "TALAGIU",
    "TALMACI",
    "TARMURE",
    "TARNAVITA",
    "TARNOVA",
    "TELA",
    "TEMESESTI",
    "TIPAR",
    "TOC",
    "TOHESTI",
    "TROAS",
    "URVISU DE BELIU",
    "USUSAU",
    "VALEA MARE (GURAHONT)",
    "VALEA MARE (SAVARSIN)",
    "VANATORI",
    "VARADIA DE MURES",
    "VARFURILE",
    "VARNITA",
    "VARSAND",
    "VASILE GOLDIS",
    "VASOAIA",
    "VIDRA",
    "VIRISMORT",
    "ZABALT",
    "ZABRANI",
    "ZARAND",
    "ZERIND",
    "ZERINDU MIC",
    "ZIMBRU"
  ],
  "ARGES": [
    "ALBESTI",
    "ALBESTII DE ARGES",
    "ALBESTII DE MUSCEL",
    "ALBESTII PAMANTENI",
    "ALBESTII UNGURENI",
    "ALBOTA",
    "ANGHINESTI",
    "ARGESELU",
    "BACESTI",
    "BADILA",
    "BAICULESTI",
    "BAJANESTI",
    "BANARESTI",
    "BARBALATESTI",
    "BARZESTI",
    "BASCOV",
    "BATRANI",
    "BILCESTI",
    "BOLCULESTI",
    "BORDEIENI",
    "BORLESTI",
    "BOROVINESTI",
    "BOTARCANI",
    "BOTESTI",
    "BRADU",
    "BRAILENI",
    "BRANZARI",
    "BRATEASCA",
    "BUDEASA",
    "BUDEASA MARE",
    "BUDEASA MICA",
    "BUDISTENI",
    "BUGHEA DE JOS",
    "BUGHEA DE SUS",
    "BUMBUENI",
    "BURDESTI",
    "CALINESTI",
    "CALOTESTI",
    "CAMPULUNG",
    "CARSTIENI",
    "CATANELE",
    "CATEASCA",
    "CATUNASI",
    "CERBU",
    "CERBURENI",
    "CERSANI",
    "CHILII",
    "CHIRITESTI (SUSENI)",
    "CHITANI",
    "CIOBANI",
    "CIOCANAI",
    "CIOCANESTI",
    "CIOMAGESTI",
    "CISMEA",
    "CIUPA-MANCIULESCU",
    "CLUCEREASA",
    "COLIBASI",
    "COLNIC",
    "CONTESTI",
    "COSERI",
    "COSTITA",
    "COTMENITA",
    "CRINTESTI",
    "CRUCISOARA",
    "CURTEA DE ARGES",
    "DARMANESTI",
    "DEALU FRUMOS",
    "DEALU ORASULUI",
    "DEALU VIILOR (MOSOAIA)",
    "DEALU VIILOR (POIANA LACULUI)",
    "DINCULESTI",
    "DOBLEA",
    "DOBROGOSTEA",
    "DOBROTU",
    "DRAGANU-OLTENI",
    "DUMIRESTI",
    "ENCULESTI",
    "FACALETESTI",
    "FAGETU",
    "FANTANEA",
    "FRATESTI",
    "GALASESTI (BUDEASA)",
    "GALCESTI",
    "GALETEANU",
    "GEAMANA",
    "GLAMBOCU",
    "GLODU (CALINESTI)",
    "GODENI",
    "GOLESTI (STEFANESTI)",
    "GORANESTI",
    "GORGANU",
    "GROSANI",
    "GROSI",
    "GURA PRAVAT",
    "GURA VAII",
    "HINTESTI",
    "IZVORANI",
    "LAZARESTI (MOSOAIA)",
    "LERESTI",
    "LIPIA",
    "LOTURI",
    "LUPUENI",
    "MALU VANAT",
    "MARACINENI",
    "MARES",
    "MATAU",
    "MERISANI",
    "METOFU",
    "MICA",
    "MICESTI",
    "MIOARELE",
    "MIOVENI",
    "MOSOAIA",
    "MUSTATESTI",
    "NAMAESTI",
    "NEGRENI",
    "NOAPTES",
    "OARJA",
    "OTELU",
    "PADURENI",
    "PADUROIU DIN DEAL",
    "PARVU ROSU",
    "PAULEASCA (MICESTI)",
    "PIETROASA",
    "PISCANI",
    "PITESTI",
    "PODU BROSTENI",
    "POIANA LACULUI",
    "POIENARI (POIENARII DE MUSCEL)",
    "POIENARII DE MUSCEL",
    "POJORATA",
    "PRISLOPU MARE",
    "PRISLOPU MIC",
    "PURCARENI (MICESTI)",
    "RACOVITA",
    "RADU NEGRU",
    "RANCACIOV",
    "RECEA (CATEASCA)",
    "ROGOJINA",
    "RUGINOASA",
    "SAMARA",
    "SCHIAU",
    "SCHITU GOLESTI",
    "SELARI",
    "SERBANESTI (POIENARII DE MUSCEL)",
    "SILISTENI",
    "SLATIOARELE",
    "SMEI",
    "SMEURA",
    "STARCI",
    "STEFANESTI",
    "STEFANESTI (SUSENI)",
    "STEFANESTII NOI",
    "SUSENI",
    "TIGVENI",
    "TOPOLOVENI",
    "UDENI-ZAVOI",
    "UIASCA",
    "UNGURENI (VALEA IASULUI)",
    "VALCELELE",
    "VALEA CORBULUI",
    "VALEA DANULUI",
    "VALEA IASULUI",
    "VALEA MARE (PRIBOIENI)",
    "VALEA MARE-PODGORIA",
    "VALEA MARE-PRAVAT",
    "VALEA MARULUI",
    "VALEA NANDRII",
    "VALEA PECHII",
    "VALEA RUMANESTILOR",
    "VALEA STANII",
    "VALEA ULEIULUI",
    "VALEA URSULUI",
    "VALENI-PODGORIA",
    "VARZARU",
    "VIISOARA",
    "VLASCUTA",
    "VOINESTI",
    "VRANESTI",
    "ZAVOI",
    "ZIGONENI",
    "ADUNATI",
    "AFRIMESTI",
    "ALBOTELE",
    "ALUNIS",
    "ALUNISU (BAICULESTI)",
    "ALUNISU (BRADULET)",
    "ANINOASA",
    "AREFU",
    "ARGESANI",
    "BABANA",
    "BABAROAGA",
    "BADENI",
    "BADESTI (BARLA)",
    "BADESTI (PIETROSANI)",
    "BADICEA",
    "BADISLAVA",
    "BADULESTI",
    "BAILA",
    "BAJESTI",
    "BALABANI",
    "BALILESTI",
    "BALILESTI (TIGVENI)",
    "BALOTEASCA",
    "BALTATA",
    "BALTENI",
    "BANICESTI",
    "BANTAU",
    "BARANESTI",
    "BARASTI",
    "BARBALANI",
    "BARBATESTI",
    "BARLA",
    "BARLOGU",
    "BARLOI",
    "BARSESTII DE JOS",
    "BARSESTII DE SUS",
    "BASCOVELE",
    "BECULESTI",
    "BELETI",
    "BELETI-NEGRESTI",
    "BEREVOESTI",
    "BERINDESTI",
    "BLAJU",
    "BLEJANI",
    "BOGATI",
    "BOLOVANESTI",
    "BOROBANESTI",
    "BOTENI",
    "BRABETI",
    "BRADETU",
    "BRADULET",
    "BRANISTEA",
    "BRATESTI",
    "BRATIA (BEREVOESTI)",
    "BRATIA (CIOMAGESTI)",
    "BROSTENI (ANINOASA)",
    "BROSTENI (COSTESTI)",
    "BUCOV",
    "BUCSENESTI",
    "BUCSENESTI-LOTASI",
    "BUJOI",
    "BUJORENI",
    "BUNESTI (COTMEANA)",
    "BUNESTI (MALURENI)",
    "BURDEA",
    "BURETESTI",
    "BURLUSI",
    "BURNESTI",
    "BUTA",
    "BUZOESTI",
    "CALDARARU",
    "CANDESTI",
    "CAPATANENII PAMANTENI",
    "CAPATANENII UNGURENI",
    "CAPU PISCULUI (GODENI)",
    "CAPU PISCULUI (MERISANI)",
    "CARCESTI",
    "CARCIUMARESTI",
    "CARPENIS",
    "CATANE",
    "CEAURESTI",
    "CEAUSESTI",
    "CEPARI",
    "CEPARI (POIANA LACULUI)",
    "CEPARII PAMANTENI",
    "CEPARII UNGURENI",
    "CETATENI",
    "CHIRITESTI (UDA)",
    "CHIRITESTI (VEDEA)",
    "CHITESTI",
    "CICANESTI",
    "CIESTI",
    "CIOBANESTI",
    "CIOCANU",
    "CIOCESTI",
    "CIOFRANGENI",
    "CIOLCESTI",
    "CIRESU",
    "CIULNITA",
    "CIURESTI",
    "COCENESTI",
    "COCHINESTI",
    "COCU",
    "COLTU",
    "CORBENI",
    "CORBI",
    "CORBSORI",
    "CORNATEL",
    "COSACI",
    "COSESTI",
    "COSTESTI",
    "COSTESTI (COTMEANA)",
    "COSTESTI-VALSAN",
    "COTEASCA",
    "COTENESTI",
    "COTESTI",
    "COTMEANA",
    "COTMEANA (STOLNICI)",
    "COTU (CUCA)",
    "COTU (UDA)",
    "COTU MALULUI",
    "CRAMPOTANI",
    "CRIVATU",
    "CUCA",
    "CUNGREA",
    "CURTEANCA",
    "DAMBOVICIOARA",
    "DAVIDESTI",
    "DEAGU DE JOS",
    "DEAGU DE SUS",
    "DEALU",
    "DEALU BISERICII",
    "DEALU BRADULUI",
    "DEALU OBEJDEANULUI",
    "DEALU PADURII",
    "DEALU TOLCESII",
    "DEDULESTI",
    "DICONESTI",
    "DINCANI",
    "DOBRESTI",
    "DOGARI",
    "DOMNESTI",
    "DRAGANU",
    "DRAGHESCU",
    "DRAGHICESTI",
    "DRAGHICI",
    "DRAGOLESTI",
    "DRAGOSLAVELE",
    "DUMBRAVA",
    "DUMBRAVESTI",
    "FALFANI",
    "FATA",
    "FEDELESOIU",
    "FLORIENI",
    "FRATICI",
    "FURDUESTI",
    "FURESTI",
    "FURNICOSI",
    "GAINUSA",
    "GALASESTI (SUSENI)",
    "GALESU",
    "GAMACESTI",
    "GANESTI",
    "GARDINESTI",
    "GAUJANI",
    "GIUCLANI",
    "GLAMBOCATA",
    "GLAMBOCATA-DEAL",
    "GLAMBOCEL",
    "GLAMBOCELU",
    "GLAVACIOC",
    "GLIGANU DE JOS",
    "GLIGANU DE SUS",
    "GLODU (LEORDENI)",
    "GOIA",
    "GOLEASCA",
    "GOLESTI (BALILESTI)",
    "GORANI",
    "GREABAN",
    "GREABANU",
    "GRUIU (CATEASCA)",
    "GRUIU (NUCSOARA)",
    "HARSESTI",
    "HARTIESTI",
    "HULUBA",
    "HUMELE",
    "IANCULESTI",
    "IOANICESTI",
    "IONESTI",
    "IZBASESTI",
    "IZVORU",
    "IZVORU DE JOS",
    "IZVORU DE SUS",
    "JGHEABURI",
    "JUGUR",
    "JUPANESTI",
    "LACENI",
    "LACURILE",
    "LAICAI",
    "LANGESTI",
    "LAPUSANI",
    "LAUNELE DE SUS",
    "LAZARESTI (SCHITU GOLESTI)",
    "LEICESTI",
    "LENTEA",
    "LEORDENI",
    "LESILE",
    "LESPEZI",
    "LINTESTI",
    "LIVEZENI",
    "LUCIENI",
    "LUMINILE",
    "LUNCA",
    "LUNCA CORBULUI",
    "LUNCA GARTII",
    "LUNGANI",
    "LUNGULESTI",
    "MACAI",
    "MALU (BARLA)",
    "MALU (GODENI)",
    "MALURENI",
    "MANCIOIU",
    "MANDRA",
    "MANESTI",
    "MANICESTI",
    "MARGHIA DE JOS",
    "MARGHIA DE SUS",
    "MARTALOGI",
    "MARTESTI",
    "MAVRODOLU",
    "MIERCANI",
    "MIHAESTI",
    "MIOARELE (CICANESTI)",
    "MIROSI",
    "MOARA MOCANULUI",
    "MOGOSESTI",
    "MORARESTI",
    "MORASTI",
    "MOSTENI-GRECI",
    "MOZACENI",
    "MOZACENII-VALE",
    "MOZACU",
    "MUSATESTI",
    "MUSCEL",
    "NEGESTI",
    "NEGRASI",
    "NEGRESTI",
    "NEJLOVELU",
    "NIGRISOARA",
    "NUCSOARA",
    "ODAENI",
    "OESTII PAMANTENI",
    "OESTII UNGURENI",
    "OGREZEA",
    "OPRESTI",
    "ORODEL",
    "PACIOIU",
    "PADURETI",
    "PADUROIU DIN VALE",
    "PALANGA",
    "PALTENU",
    "PARASCHIVESTI",
    "PATULENI",
    "PAULEASCA (MALURENI)",
    "PAULENI",
    "PAUNESTI",
    "PETRESTI",
    "PIATRA (BRADULET)",
    "PIATRA (CIOFRANGENI)",
    "PIATRA (STOENESTI)",
    "PIELESTI",
    "PIETROSANI",
    "PITIGAIA",
    "PITOI",
    "PODENI",
    "PODISORU",
    "PODU DAMBOVITEI",
    "PODURI",
    "POIENAREI",
    "POIENARI (CORBENI)",
    "POIENARI (POIENARII DE ARGES)",
    "POIENARII DE ARGES",
    "POIENITA",
    "POPESTI",
    "POPESTI (COCU)",
    "POPESTI (SAPATA)",
    "PRIBOAIA",
    "PRIBOIENI",
    "PRISEACA",
    "PRODANI",
    "PROSIA",
    "PURCARENI (POPESTI)",
    "PUTINA",
    "RACA",
    "RACHITELE DE JOS",
    "RACHITELE DE SUS",
    "RADESTI",
    "RADUTESTI",
    "RAJLETU-GOVORA",
    "RATESTI (TIGVENI)",
    "RATOI",
    "RECEA",
    "REDEA",
    "RETEVOIESTI",
    "ROBAIA",
    "ROCIU",
    "ROMANA",
    "ROTUNDA",
    "RUCAR",
    "RUDENI (MIHAESTI)",
    "RUDENI (SUICI)",
    "SALATRUCU",
    "SALISTEA",
    "SAMAILA",
    "SANDULESTI",
    "SAPATA",
    "SAPUNARI",
    "SATIC",
    "SATU NOU",
    "SBOGHITESTI",
    "SCHITU SCOICESTI",
    "SCHITU-MATEI",
    "SELAREASCA",
    "SENDRULESTI",
    "SERBANESTI (ROCIU)",
    "SERBOENI",
    "SILISTEA",
    "SINESTI",
    "SLAMNESTI",
    "SLANIC",
    "SLATINA",
    "SLOBOZIA",
    "SLOBOZIA (POPESTI)",
    "SLOBOZIA (STOENESTI)",
    "SPIRIDONI",
    "STALPENI",
    "STANESTI",
    "STANICEI",
    "STEFAN CEL MARE",
    "STEJARI",
    "STOENESTI",
    "STOLNICI",
    "STRAMBENI (CALDARARU)",
    "STRAMBENI (SUSENI)",
    "STROESTI",
    "SUICI",
    "SURDULESTI",
    "SUSENI (BOGATI)",
    "SUSLANESTI",
    "TEIU",
    "TEODORESTI",
    "TIGANESTI",
    "TIGVENI (RATESTI)",
    "TITESTI",
    "TOMSANCA",
    "TOMULESTI",
    "TOPLITA",
    "TURBUREA",
    "TURCESTI",
    "TUTANA",
    "TUTULESTI",
    "UDA",
    "ULENI",
    "ULITA",
    "UNGHENI",
    "UNGURENI (BRADULET)",
    "URECHESTI",
    "URLUCEA",
    "URLUENI",
    "URLUIESTI",
    "URSOAIA",
    "VACAREA",
    "VALEA BADENILOR",
    "VALEA BRADULUI",
    "VALEA BRAZILOR",
    "VALEA CALULUI",
    "VALEA CETATUIA",
    "VALEA CUCII",
    "VALEA FAURULUI",
    "VALEA HOTARULUI",
    "VALEA INDARAT",
    "VALEA LUI ENACHE",
    "VALEA LUI MAS",
    "VALEA MAGUREI",
    "VALEA MANASTIRII",
    "VALEA MARE-BRATIA",
    "VALEA MUSCELULUI",
    "VALEA NENII",
    "VALEA POPII (MIHAESTI)",
    "VALEA POPII (PRIBOIENI)",
    "VALEA RIZII",
    "VALEA SILISTII",
    "VALENI",
    "VALSANESTI",
    "VARLOVENI",
    "VARSESTI",
    "VARZAROAIA",
    "VATA",
    "VEDEA",
    "VERNESTI",
    "VETISOARA",
    "VLADESTI",
    "VLADESTI (TIGVENI)",
    "VLADUTA",
    "VONIGEASA",
    "VOROVENI",
    "VULPESTI",
    "VULTURESTI",
    "ZAMFIRESTI (CEPARI)",
    "ZAMFIRESTI (COTMEANA)",
    "ZARNESTI",
    "ZGRIPCESTI",
    "ZIDURILE",
    "ZUVELCATI"
  ],
  "BACAU": [
    "ASAU",
    "BACAU",
    "BALTATA",
    "BARATI",
    "BERESTI-BISTRITA",
    "BIJGHIR",
    "BOGDAN VODA",
    "BOGDANESTI",
    "BOGDANESTI (TRAIAN)",
    "BORZESTI",
    "BRAD (BERESTI-BISTRITA)",
    "BUCHILA",
    "BUCIUMI",
    "BUHOCEL",
    "BUHOCI",
    "BUHUSI",
    "CASIN",
    "CHETRIS",
    "CHITICENI",
    "CIUMASI",
    "CLEJA",
    "COMANESTI",
    "COSTEI",
    "CRIHAN",
    "DARMANEASCA",
    "DARMANESTI",
    "DEALU MARE",
    "DOSPINESTI",
    "DUMBRAVA (BERESTI-BISTRITA)",
    "FANTANELE (HEMEIUS)",
    "FARAOANI",
    "FILIPESTI",
    "FILIPESTI (BOGDANESTI)",
    "FUNDENI",
    "GALBENI (NICOLAE BALCESCU)",
    "GARLENI",
    "GARLENII DE SUS",
    "GAZARIE",
    "GLODISOARELE",
    "HANTA",
    "HELEGIU",
    "HEMEIUS",
    "HERTIOANA DE JOS",
    "HERTIOANA-RAZESI",
    "HOLT",
    "ITESTI",
    "LESPEZI",
    "LETEA VECHE",
    "LILIECI",
    "LUIZI-CALUGARA",
    "LUNCA ASAU",
    "LUNCA DOCHIEI",
    "LUNCANI",
    "MAGURA",
    "MARGINENI",
    "MOINESTI",
    "NEGOIESTI",
    "NICOLAE BALCESCU",
    "OITUZ",
    "ONESTI",
    "OSEBITI",
    "PADURENI (MARGINENI)",
    "PAGUBENI",
    "PODEI",
    "PODIS",
    "POIANA (MARGINENI)",
    "PRAJESTI",
    "RACAUTI",
    "RACHITISU",
    "RADOMIRESTI",
    "ROGOAZA",
    "RUSI-CIUTEA",
    "SARATA",
    "SATU NOU (URECHESTI)",
    "SAUCESTI",
    "SCHINENI (SAUCESTI)",
    "SECUIENI",
    "SERBESTI",
    "SIRETU (LETEA VECHE)",
    "SIRETU (SAUCESTI)",
    "SLOBOZIA (ONESTI)",
    "SLOBOZIA (URECHESTI)",
    "SOHODOL",
    "STEFAN CEL MARE",
    "STRAJA",
    "SURINA",
    "TARGU TROTUS",
    "TRAIAN",
    "TREBES",
    "URECHESTI",
    "VALEA BUDULUI",
    "VALEA SEACA (NICOLAE BALCESCU)",
    "VERMESTI",
    "ZAPODIA (TRAIAN)",
    "AGAS",
    "ALBELE",
    "ANTOHESTI",
    "APA ASAU",
    "ARDEOANI",
    "ARINI",
    "BACIOIU",
    "BACLESTI",
    "BAHNA",
    "BAHNASENI",
    "BAIMAC",
    "BALAIA",
    "BALANEASA",
    "BALANESTI (DEALU MORII)",
    "BALANESTI (PODU TURCULUI)",
    "BALCA",
    "BALCANI",
    "BALOTESTI",
    "BALUSA",
    "BANCA (DEALU MORII)",
    "BARBOASA",
    "BARCANA",
    "BARNA",
    "BARNESTI",
    "BARSANESTI",
    "BARTASESTI",
    "BARZULESTI",
    "BASASTI",
    "BAZGA",
    "BELCIUNEASA",
    "BELEGHET",
    "BENESTI",
    "BERBINCENI",
    "BERESTI",
    "BERESTI-TAZLAU",
    "BERZUNTI",
    "BIBIRESTI",
    "BLAGA",
    "BLAGESTI",
    "BLIDARI",
    "BOANTA",
    "BOBOS",
    "BODEASA",
    "BOGATA",
    "BOGDANA",
    "BOGDANESTI (SCORTENI)",
    "BOISTEA",
    "BOISTEA DE JOS",
    "BOLATAU",
    "BOLOVANIS",
    "BORSANI",
    "BOSIA",
    "BOSOTENI",
    "BOSTANESTI",
    "BOTA",
    "BOTESTI",
    "BRAD (FILIPENI)",
    "BRAD (NEGRI)",
    "BRATESTI",
    "BRATILA",
    "BRUSTUROASA",
    "BUCSA",
    "BUCSESTI",
    "BUDA (BERZUNTI)",
    "BUDA (BLAGESTI)",
    "BUDA (RACHITOASA)",
    "BUDESTI",
    "BURDUSACI",
    "BURUIENIS",
    "BURUIENISU DE SUS",
    "CABESTI",
    "CADARESTI",
    "CAIUTI",
    "CALAPODESTI",
    "CALCAI",
    "CALINESTI",
    "CALINI",
    "CALUGARENI",
    "CAMENCA",
    "CAMPENI",
    "CAPATA",
    "CAPOTESTI",
    "CARACLAU",
    "CARLIGI",
    "CAUIA",
    "CERDAC",
    "CERNU",
    "CETATUIA",
    "CHETRENI",
    "CHICEREA",
    "CHILIA BENEI",
    "CIOBANUS",
    "CIRESOAIA",
    "CIUCANI",
    "CIUGHES",
    "CIUTURESTI",
    "CLIMESTI",
    "COCIU",
    "COLONESTI",
    "COMAN",
    "CONTESTI",
    "CORBASCA",
    "CORNATEL",
    "CORNATELU",
    "CORNESTI",
    "CORNET",
    "CORNII DE JOS",
    "CORNII DE SUS",
    "COSNEA",
    "COTENI",
    "COTOFANESTI",
    "COTU GROSULUI",
    "COTUMBA",
    "CRAIESTI",
    "CUCHINIS",
    "CUCOVA",
    "CUCUIETI (DOFTEANA)",
    "CUCUIETI (SOLONT)",
    "CURITA",
    "DADESTI",
    "DAMIENESTI",
    "DANAILA",
    "DEALU MORII",
    "DEALU PERJULUI",
    "DELENI",
    "DIACONESTI",
    "DIENET",
    "DOFTEANA",
    "DORNENI (PLOPANA)",
    "DORNENI (VULTURENI)",
    "DOROFEI",
    "DRAGESTI (DAMIENESTI)",
    "DRAGESTI (TATARASTI)",
    "DRAGOMIR",
    "DRAGUGESTI",
    "DRAGUSANI",
    "DUMBRAVA (GURA VAII)",
    "DUMBRAVA (RACHITOASA)",
    "ENACHESTI",
    "FAGET",
    "FAGETEL",
    "FAGETU DE SUS",
    "FAGHIENI",
    "FANTANELE (MOTOSENI)",
    "FARCASA",
    "FERESTRAU-OITUZ",
    "FICHITESTI",
    "FILIPENI",
    "FLORESTI (CAIUTI)",
    "FLORESTI (HURUIESTI)",
    "FLORESTI (SCORTENI)",
    "FRUMOASA",
    "FRUMUSELU",
    "FRUNTESTI",
    "FULGERIS",
    "FUNDATURA",
    "FUNDATURA RACHITOASA",
    "FUNDOAIA",
    "FUNDU RACACIUNI",
    "FUNDU TUTOVEI",
    "FUNDU VAII",
    "FURNICARI",
    "GAICEANA",
    "GALBENI (FILIPESTI)",
    "GALERI",
    "GARLA ANEI",
    "GASTENI",
    "GHEORGHE DOJA",
    "GHERDANA",
    "GHILAVESTI",
    "GHIMES",
    "GHIMES-FAGET",
    "GHIONOAIA",
    "GIOSENI",
    "GIURGENI",
    "GIURGIOANA",
    "GLAVANESTI",
    "GODINESTII DE JOS",
    "GODINESTII DE SUS",
    "GOIOASA",
    "GORGHESTI",
    "GRADESTI",
    "GRIGORENI",
    "GURA CRAIESTI",
    "GURA VAII",
    "GURA VAII (RACOVA)",
    "GUTINAS",
    "HAGHIAC (DOFTEANA)",
    "HAGHIAC (RACHITOASA)",
    "HAINEALA",
    "HALMACIOAIA",
    "HANGANESTI",
    "HARJA",
    "HARLESTI",
    "HELTIU",
    "HEMIENI",
    "HORGESTI",
    "HURUIESTI",
    "HUTU",
    "IAZ",
    "ILIESI",
    "ITCANI",
    "IZVORU BERHECIULUI",
    "LAPOS",
    "LARGA",
    "LARGUTA",
    "LEHANCEA",
    "LEONTINESTI",
    "LICHITISENI",
    "LIPOVA",
    "LIVEZI",
    "LUDASI",
    "LUPESTI",
    "MAGAZIA",
    "MAGIRESTI",
    "MAGLA",
    "MALOSU",
    "MANASTIREA CASIN",
    "MARASCU",
    "MARASTI",
    "MARGINEA (BUHUSI)",
    "MARGINEA (OITUZ)",
    "MARVILA",
    "MEDELENI",
    "MILESTII DE JOS",
    "MILESTII DE SUS",
    "MISIHANESTI",
    "MOTOC",
    "MOTOSENI",
    "MOVILITA",
    "MUNCELU",
    "NADISA",
    "NANESTI",
    "NASTASENI",
    "NAZARIOAIA",
    "NEGRENI",
    "NEGRI",
    "NEGULESTI",
    "NEGUSENI",
    "NICORESTI",
    "OBARSIA",
    "OCHENI",
    "ODOBESTI",
    "ONCESTI",
    "ONCESTII VECHI",
    "ONISCANI",
    "OPRISESTI",
    "ORASA",
    "ORBENI",
    "OTELESTI",
    "PADURENI (BERESTI-BISTRITA)",
    "PADURENI (DAMIENESTI)",
    "PADURENI (FILIPENI)",
    "PADURENI (IZVORU BERHECIULUI)",
    "PAJISTEA",
    "PALANCA",
    "PALTINATA",
    "PALTINIS",
    "PANCESTI",
    "PANCESTI (ADJUD)",
    "PARAU BOGHII",
    "PARAVA",
    "PARGARESTI",
    "PARINCEA",
    "PARJOL",
    "PARVULESTI",
    "PERCHIU",
    "PETRESTI",
    "PETRICICA",
    "PLOPANA",
    "PLOPU (DARMANESTI)",
    "PLOPU (PODU TURCULUI)",
    "PODU TURCULUI",
    "PODURI",
    "POGLET",
    "POIANA (LIVEZI)",
    "POIANA (MOTOSENI)",
    "POIANA (NEGRI)",
    "POIANA NEGUSTORULUI",
    "POIANA SARATA",
    "POIENI (PARINCEA)",
    "POIENI (ROSIORI)",
    "POIENI (TARGU OCNA)",
    "POPENI",
    "POPESTI",
    "POPOIU",
    "PRADAIS",
    "PRAJA",
    "PRAJESTI (MAGIRESTI)",
    "PRAJOAIA",
    "PRALEA",
    "PRELUCI",
    "PRISACA",
    "PROHOZESTI",
    "PUSTIANA",
    "PUTINI",
    "PUTREDENI",
    "RACACIUNI",
    "RACATAU DE JOS",
    "RACATAU-RAZESI",
    "RACHITIS",
    "RACHITOASA",
    "RACOVA",
    "RACUSANA",
    "RADEANA",
    "RADOAIA",
    "RASTOACA",
    "RAZESU",
    "RECEA",
    "REPRIVAT",
    "ROMANESTI",
    "ROSIORI",
    "ROTARIA",
    "RUNCU",
    "RUSENII DE SUS",
    "RUSENII RAZESI",
    "SALATRUC",
    "SANDULENI",
    "SARATA (SOLONT)",
    "SARBI",
    "SASCUT",
    "SASCUT-SAT",
    "SATU NOU (COLONESTI)",
    "SATU NOU (LIPOVA)",
    "SATU NOU (ONCESTI)",
    "SATU NOU (PARGARESTI)",
    "SATU NOU (PARINCEA)",
    "SCARIGA",
    "SCARISOARA",
    "SCHINENI (SASCUT)",
    "SCHITU FRUMOASA",
    "SCORTENI",
    "SCURTA",
    "SCUTARU",
    "SEACA",
    "SENDRESTI",
    "SERPENI",
    "SESURI",
    "SLANIC-MOLDOVA",
    "SLOBOZIA (FILIPENI)",
    "SLOBOZIA (STANISESTI)",
    "SLOBOZIA NOUA",
    "SOCI",
    "SOHODOR",
    "SOLONT",
    "SOMUSCA",
    "SPRIA",
    "STANESTI",
    "STANISESTI",
    "STEFAN VODA",
    "STEJARU",
    "STRAMINOASA",
    "STRUGARI",
    "STUFU",
    "SULTA",
    "TAMASI",
    "TAMASOAIA",
    "TARATA",
    "TARDENII MARI",
    "TARGU OCNA",
    "TARHAUSI",
    "TARNITA",
    "TATARASTI",
    "TAULA",
    "TAVADARESTI",
    "TEIUS",
    "TEMELIA",
    "TEPOAIA",
    "TESCANI",
    "TIGANESTI",
    "TISA",
    "TISA-SILVESTRI",
    "TOCHILEA",
    "TOMOZIA",
    "TURLUIANU",
    "TUTA",
    "UNGURENI",
    "UNGURENI (TATARASTI)",
    "URSOAIA",
    "VALCELE (CORBASCA)",
    "VALCELE (TARGU OCNA)",
    "VALEA ARINILOR",
    "VALEA BOTULUI",
    "VALEA CASELOR",
    "VALEA HOGEI",
    "VALEA LUI ION",
    "VALEA LUPULUI",
    "VALEA MARE (COLONESTI)",
    "VALEA MARE (ROSIORI)",
    "VALEA MARULUI",
    "VALEA MERILOR",
    "VALEA MICA (CLEJA)",
    "VALEA MICA (ROSIORI)",
    "VALEA MOSNEAGULUI",
    "VALEA NACULUI",
    "VALEA SALCIEI",
    "VALEA SEACA",
    "VALEA SOSII",
    "VALENI (PARINCEA)",
    "VALENI (STANISESTI)",
    "VERSESTI",
    "VIFORENI",
    "VIISOARA (STEFAN CEL MARE)",
    "VIISOARA (TARGU TROTUS)",
    "VLADNIC",
    "VRANCENI",
    "VULTURENI",
    "ZAPODIA (COLONESTI)",
    "ZEMES",
    "ZLATARI"
  ],
  "BIHOR": [
    "ABRAM",
    "ABRAMUT",
    "ALBIS",
    "ALESD",
    "ALPAREA",
    "APATEU",
    "ASTILEU",
    "AUSEU",
    "BAILE 1 MAI",
    "BAILE FELIX",
    "BEIUS",
    "BETFIA",
    "BICACI",
    "BIHARIA",
    "BOROZEL",
    "BORS",
    "BORSA",
    "BUDUSLAU",
    "BUTANI",
    "CADEA",
    "CAUACEU",
    "CETARIU",
    "CHERIU",
    "CHIRALEU",
    "CHIRIBIS",
    "CHISIRID",
    "CHISTAG",
    "CIHEI",
    "CIUMEGHIU",
    "COHANI",
    "COPACEL",
    "CORDAU",
    "DELANI",
    "DIOSIG",
    "DRAGANESTI",
    "FANCICA",
    "FELCHERIU",
    "FENERIS",
    "FINIS",
    "FIZIS",
    "FUGHIU",
    "GEPIU",
    "GHEGHIE",
    "GHENETEA",
    "GIRISU DE CRIS",
    "GRADINARI",
    "GROSI",
    "HAIEU",
    "HIDISELU DE JOS",
    "HIDISELU DE SUS",
    "HODOS",
    "HOMOROG",
    "HUSASAU DE CRIS",
    "IANCA",
    "IANOSDA",
    "INAND",
    "INEU",
    "IOANIS",
    "LES",
    "LIVADA BEIUSULUI",
    "LIVADA DE BIHOR",
    "LUGASU DE JOS",
    "LUGASU DE SUS",
    "LUNCA",
    "MADARAS",
    "MAGESTI",
    "MARGHITA",
    "MIERSIG",
    "MIHAI BRAVU",
    "MIZIES",
    "NIMAIESTI",
    "NOJORID",
    "ORADEA",
    "ORTITEAG",
    "OSORHEI",
    "PACALESTI",
    "PALEU",
    "PALOTA",
    "PESTERE",
    "PESTIS",
    "PETRANI",
    "PETREASA",
    "PETREU",
    "POCOLA",
    "POIANA (TAUTEU)",
    "POIENII DE JOS",
    "POIETARI",
    "POSOLOACA",
    "RONTAU",
    "ROSIORI",
    "SABOLCIU",
    "SACADAT",
    "SACUENI",
    "SALARD",
    "SALDABAGIU DE MUNTE",
    "SALONTA",
    "SANMARTIN",
    "SANMARTIN DE BEIUS",
    "SANTANDREI",
    "SANTAUL MARE",
    "SANTAUL MIC",
    "SANTION",
    "SARAND",
    "SATU NOU",
    "SISTEREA",
    "SOIMUS",
    "STEI",
    "SUBPIATRA",
    "SUNCUIS",
    "SUNCUIUS",
    "SUPLACU DE BARCAU",
    "TALPE",
    "TAMASEU",
    "TARCAIA",
    "TARCEA",
    "TARIAN",
    "TAUTELEC",
    "TELEAC",
    "TELECHIU",
    "TIGANESTII DE BEIUS",
    "TILEAGD",
    "TINAUD",
    "TINCA",
    "TOBOLIU",
    "TOTORENI",
    "TULCA",
    "UILEACU DE CRIS",
    "UILEACU DE MUNTE",
    "URVIND",
    "VADU CRISULUI",
    "VALEA LUI MIHAI",
    "ADONI",
    "ALBESTI",
    "ALMASU MARE",
    "ALMASU MIC (BALC)",
    "ALMASU MIC (SARBI)",
    "ANT",
    "ARPASEL",
    "ATEAS",
    "AVRAM IANCU",
    "BAITA",
    "BAITA-PLAI",
    "BALAIA",
    "BALC",
    "BALENI",
    "BALNACA",
    "BALNACA-GROSI",
    "BARAJ LESU",
    "BATAR",
    "BEIUSELE",
    "BELEJENI",
    "BELFIR",
    "BERECHIU",
    "BEZNEA",
    "BICACEL",
    "BIRTIN",
    "BISTRA",
    "BOGEI",
    "BOIANU MARE",
    "BOIU",
    "BOROD",
    "BORUMLACA",
    "BORZ",
    "BOTEAN",
    "BRADET",
    "BRATCA",
    "BRATESTI",
    "BRIHENI",
    "BRUSTURI",
    "BRUSTURI (FINIS)",
    "BUCIUM",
    "BUCUROAIA",
    "BUDOI",
    "BUDUREASA",
    "BULZ",
    "BUNTESTI",
    "BURDA",
    "BURZUC",
    "CABESTI",
    "CACUCIU NOU",
    "CACUCIU VECHI",
    "CALACEA",
    "CALATANI",
    "CALATEA",
    "CALEA MARE",
    "CALUGARI",
    "CAMP",
    "CAMPANI",
    "CAMPANI DE POMEZEU",
    "CAMP-MOTI",
    "CAPALNA",
    "CARANDENI",
    "CARANZEL",
    "CARASAU",
    "CARPINET",
    "CAUASD",
    "CEFA",
    "CEICA",
    "CEISOARA",
    "CENALOS",
    "CETEA",
    "CHERECHIU",
    "CHERESIG",
    "CHESA",
    "CHESEREU",
    "CHET",
    "CHIJIC",
    "CHIOAG",
    "CHISCAU",
    "CHISLAZ",
    "CIOCAIA",
    "CIUHOI",
    "CIULESTI",
    "CIUTELEC",
    "COCIUBA MARE",
    "COCIUBA MICA",
    "CODRISORU",
    "CODRU",
    "COLESTI",
    "COPACENI",
    "CORBESTI",
    "CORBOAIA",
    "CORNISESTI",
    "CORNITEL",
    "COSDENI",
    "COTIGLET",
    "CRANCESTI",
    "CRESTUR",
    "CRESUIA",
    "CRISTIORU DE JOS",
    "CRISTIORU DE SUS",
    "CUBULCUT",
    "CUCUCENI",
    "CUIESD",
    "CURATELE",
    "CURTUISENI",
    "CUSUIUS",
    "CUZAP",
    "DAMIS",
    "DERNA",
    "DERNISOARA",
    "DICANESTI",
    "DIJIR",
    "DOBRESTI",
    "DOBRICIONESTI",
    "DOLEA",
    "DRAGESTI",
    "DRAGOTENI",
    "DUMBRAVA",
    "DUMBRAVANI",
    "DUMBRAVITA",
    "DUMBRAVITA DE CODRU",
    "DUSESTI",
    "FANATE",
    "FASCA",
    "FEGERNIC",
    "FEGERNICU NOU",
    "FERICE",
    "FOGLAS",
    "FONAU",
    "FORAU",
    "FOROSIG",
    "GALASENI",
    "GALOSPETREU",
    "GEPIS",
    "GHIDA",
    "GHIGHISENI",
    "GHIORAC",
    "GINTA",
    "GIRISU NEGRU",
    "GIULESTI",
    "GOILA",
    "GRUILUNG",
    "GURANI",
    "GURBEDIU",
    "GURBESTI (CABESTI)",
    "GURBESTI (SPINUS)",
    "HARSESTI",
    "HAUCESTI",
    "HIDIS",
    "HIDISEL",
    "HINCHIRIS",
    "HODIS",
    "HODISEL",
    "HOLOD",
    "HOTAR",
    "HOTAREL",
    "HUSASAU DE TINCA",
    "HUTA",
    "HUTA VOIVOZI",
    "INCESTI",
    "ITEU",
    "ITEU NOU",
    "IZBUC",
    "IZVOARELE",
    "JOSANI (CABESTI)",
    "JOSANI (MAGESTI)",
    "LACU SARAT",
    "LAZARENI",
    "LAZURI",
    "LAZURI DE BEIUS",
    "LEHECENI",
    "LELESTI",
    "LORANTA",
    "LORAU",
    "LUNCASPRIE",
    "LUNCSOARA",
    "LUPOAIA",
    "MAGURA",
    "MARGINE",
    "MARTIHAZ",
    "MEZIAD",
    "MIERAG",
    "MIERLAU",
    "MIHELEU",
    "MISCA",
    "MOTESTI",
    "MUNTENI",
    "NADAR",
    "NIUVED",
    "NUCET",
    "OGESTI",
    "OLCEA",
    "OLOSIG",
    "ORVISELE",
    "OSAND",
    "OTOMANI",
    "PADUREA NEAGRA",
    "PADURENI",
    "PAGAIA",
    "PANTASESTI",
    "PARHIDA",
    "PAULESTI",
    "PAUSA",
    "PETID",
    "PETRILENI",
    "PICLEU",
    "PIETROASA",
    "POCIOVELISTE",
    "POCLUSA DE BARCAU",
    "POCLUSA DE BEIUS",
    "POIANA (CRISTIORU DE JOS)",
    "POIANA TASAD",
    "POIENII DE SUS",
    "POMEZEU",
    "PONOARA",
    "POPESTI",
    "PRISACA",
    "RABAGANI",
    "RACAS",
    "RAPA",
    "REGHEA",
    "REMETEA",
    "REMETI",
    "RIENI",
    "ROGOZ",
    "ROHANI",
    "ROIT",
    "ROSIA",
    "ROTARESTI",
    "RUGEA",
    "SACA",
    "SACALASAU",
    "SACALASAU NOU",
    "SALACEA",
    "SALDABAGIU DE BARCAU",
    "SALDABAGIU MIC",
    "SALISTE",
    "SALISTE DE BEIUS",
    "SALISTE DE POMEZEU",
    "SALISTE DE VASCAU",
    "SAMBATA",
    "SANIOB",
    "SANLAZAR",
    "SANNICOLAU DE BEIUS",
    "SANNICOLAU DE MUNTE",
    "SANNICOLAU ROMAN",
    "SANTELEC",
    "SANTIMREU",
    "SARBESTI",
    "SARBI",
    "SARCAU",
    "SARSIG",
    "SATU BARBA",
    "SAUAIEU",
    "SAUCANI",
    "SAUD",
    "SEBIS",
    "SEGHISTE",
    "SERANI",
    "SERGHIS",
    "SFARNAS",
    "SIGHISTEL",
    "SILINDRU",
    "SIMIAN",
    "SINTEU",
    "SITANI",
    "SITITELEC",
    "SOCET",
    "SOHODOL",
    "SOIMI",
    "SPINUS",
    "SPINUS DE POMEZEU",
    "STANCESTI",
    "STRACOS",
    "SUDRIGIU",
    "SUIUG",
    "SUMUGIU",
    "SUPLACU DE TINCA",
    "SURDUC",
    "SURDUCEL",
    "SUSTIU",
    "SUSTUROGI",
    "TALPOS",
    "TAMASDA",
    "TARCAITA",
    "TARGUSOR",
    "TASAD",
    "TAUT",
    "TAUTEU",
    "TETCHEA",
    "TIGANESTII DE CRIS",
    "TILECUS",
    "TOMNATIC",
    "TOPA DE CRIS",
    "TOPA DE JOS",
    "TOPA DE SUS",
    "TOPESTI",
    "TRIA",
    "UCURIS",
    "UILEACU DE BEIUS",
    "URSAD",
    "URVIS DE BEIUS",
    "VAIDA",
    "VALANI DE POMEZEU",
    "VALANII DE BEIUS",
    "VALCELELE",
    "VALEA CERULUI",
    "VALEA CRISULUI",
    "VALEA DE JOS",
    "VALEA DE SUS",
    "VALEA IADULUI",
    "VALEA MARE DE CODRU",
    "VALEA MARE DE CRIS",
    "VALEA TARNEI",
    "VARASAU",
    "VARASENI",
    "VARCIOROG",
    "VARVIZ",
    "VARZARI",
    "VARZARII DE JOS",
    "VARZARII DE SUS",
    "VASAD",
    "VASCAU",
    "VIISOARA",
    "VINTERE",
    "VOIVOZI (POPESTI)",
    "VOIVOZI (SIMIAN)",
    "ZAVOIU",
    "ZECE HOTARE"
  ],
  "BISTRITA-NASAUD": [
    "ARCALIA",
    "BATA",
    "BISTRITA",
    "BISTRITA BARGAULUI FABRICI",
    "CEPARI",
    "CETATE",
    "CICEU-MIHAIESTI",
    "CRAINIMAT",
    "DOMNESTI",
    "DOROLEA",
    "DUMITRA",
    "DUMITRITA",
    "GHINDA",
    "JELNA",
    "JOSENII BARGAULUI",
    "LIVEZILE",
    "LIVIU REBREANU",
    "LUSCA",
    "NASAUD",
    "NETENI",
    "ORHEIU BISTRITEI",
    "PETRIS",
    "PETRU RARES",
    "RAGLA",
    "RETEAG",
    "RUSU BARGAULUI",
    "SARATA",
    "SARATEL",
    "SATU NOU",
    "SIEU-MAGHERUS",
    "SIGMIR",
    "SLATINITA",
    "TENTEA",
    "UNIREA",
    "URIU",
    "VIISOARA",
    "AGRIES",
    "AGRIESEL",
    "AGRISU DE JOS",
    "AGRISU DE SUS",
    "ALBESTII BISTRITEI",
    "ALUNISUL",
    "ANIES",
    "APATIU",
    "ARCHIUD",
    "ARDAN",
    "ARSITA",
    "BARLA",
    "BECLEAN",
    "BEUDIU",
    "BICHIGIU",
    "BIDIU",
    "BISTRITA BARGAULUI",
    "BLAJENII DE JOS",
    "BLAJENII DE SUS",
    "BORLEASA",
    "BOZIES",
    "BRANISTEA",
    "BRATENI",
    "BREAZA",
    "BRETEA",
    "BUDACU DE JOS",
    "BUDACU DE SUS",
    "BUDESTI",
    "BUDESTI-FANATE",
    "BUDURLENI",
    "BUDUS",
    "BUNGARD",
    "BUZA CATUN",
    "CAIANU MARE",
    "CAIANU MIC",
    "CAILA",
    "CAMP",
    "CHETIU",
    "CHINTELNIC",
    "CHIOCHIS",
    "CHIRALES",
    "CHIUZA",
    "CICEU-CORABIA",
    "CICEU-GIURGESTI",
    "CICEU-POIENI",
    "CIOSA",
    "CIREASI",
    "CIRESOAIA",
    "COASTA",
    "COCIU",
    "COLDAU",
    "COLIBITA",
    "COMLOD",
    "CORMAIA",
    "CORVINESTI",
    "COSBUC",
    "COSERIU",
    "CRISTESTII CICEULUI",
    "CRISTUR-SIEU",
    "CUSMA",
    "DEALU STEFANITEI",
    "DELURENI",
    "DIPSA",
    "DOBRIC",
    "DOBRICEL",
    "DRAGA",
    "DUMBRAVA (LIVEZILE)",
    "DUMBRAVA (NUSENI)",
    "DUMBRAVENI",
    "DUMBRAVITA",
    "DUPA DEAL",
    "ENCIU",
    "FANATE",
    "FANATELE SILIVASULUI",
    "FANTANELE",
    "FANTANITA",
    "FELDRU",
    "FELEAC",
    "FIAD",
    "FIGA",
    "FLORESTI",
    "GALATII BISTRITEI",
    "GERSA I",
    "GERSA II",
    "GHEMES",
    "GLEDIN",
    "HALMASAU",
    "HASMASU CICEULUI",
    "HERINA",
    "HIREAN",
    "ILISUA",
    "ILVA MARE",
    "ILVA MICA",
    "IVANEASA",
    "JEICA",
    "JIMBOR",
    "LA CURTE",
    "LECHINTA",
    "LELESTI",
    "LESU",
    "LUNCA",
    "LUNCA BORLESEI",
    "LUNCA ILVEI",
    "LUNCA LESULUI",
    "LUNCA SATEASCA",
    "MAGURA ILVEI",
    "MAGURELE",
    "MAIERU",
    "MALIN",
    "MALUT",
    "MANIC",
    "MARISELU",
    "MATEI",
    "MICESTII DE CAMPIE",
    "MIJLOCENII BARGAULUI",
    "MILAS",
    "MINTIU",
    "MIRES",
    "MITITEI",
    "MOCOD",
    "MOGOSENI",
    "MOLISET",
    "MONARIU",
    "MONOR",
    "MORUT",
    "MURESENII BARGAULUI",
    "NEGRILESTI",
    "NEPOS",
    "NIMIGEA",
    "NIMIGEA DE JOS",
    "NIMIGEA DE SUS",
    "NUSENI",
    "OARZINA",
    "OCNITA",
    "OROSFAIA",
    "PALTINEASA",
    "PARVA",
    "PERISOR",
    "PIATRA",
    "PIATRA FANTANELE",
    "PINTICU",
    "PODENII",
    "PODEREI",
    "PODIREI",
    "POIANA ILVEI",
    "POIENILE ZAGREI",
    "PORUMBENII",
    "POSMUS",
    "PRUNDU BARGAULUI",
    "PURCARETE",
    "RACATESU",
    "REBRA",
    "REBRISOARA",
    "RODNA",
    "ROMULI",
    "RUNCU SALVEI",
    "RUSTIOR",
    "RUSU DE JOS",
    "RUSU DE SUS",
    "SALCUTA",
    "SALVA",
    "SANGEORZ-BAI",
    "SANGEORZU NOU",
    "SANIACOB",
    "SANMIHAIU DE CAMPIE",
    "SANNICOARA",
    "SANT",
    "SANTIOANA",
    "SASARM",
    "SCOABE",
    "SEBIS",
    "SENDROAIA",
    "SESURI SPERMEZEU-VALE",
    "SIEU",
    "SIEU-ODORHEI",
    "SIEU-SFANTU",
    "SIEUT",
    "SILIVASU DE CAMPIE",
    "SIMIONESTI",
    "SINTEREAG",
    "SINTEREAG-GARA",
    "SIRIOARA",
    "SITA",
    "SOIMUS",
    "SOPTERIU",
    "SPERMEZEU",
    "STRAMBA",
    "STRUGURENI",
    "STUPINI",
    "SUPLAI",
    "SUSENII BARGAULUI",
    "TAGSORU",
    "TAGU",
    "TARLISUA",
    "TARPIU",
    "TAURE",
    "TEACA",
    "TELCISOR",
    "TELCIU",
    "TIGAU",
    "TIHA BARGAULUI",
    "TONCIU",
    "TUREAC",
    "URMENIS",
    "VALEA",
    "VALEA BORCUTULUI",
    "VALEA MAGHERUSULUI",
    "VALEA MARE (SANT)",
    "VALEA MARE (URMENIS)",
    "VALEA POENII",
    "VALEA VINULUI",
    "VERMES",
    "VIILE TECII",
    "VISUIA",
    "VITA",
    "ZAGRA",
    "ZORENI"
  ],
  "BOTOSANI": [
    "AGAFTON",
    "BAICENI",
    "BAISA",
    "BALTA ARSA",
    "BALUSENI",
    "BALUSENII NOI",
    "BATRANESTI",
    "BELCEA",
    "BLANDESTI",
    "BOLD",
    "BOTOSANI",
    "BRAESTI",
    "BREHUIESTI",
    "BUCECEA",
    "BURLA",
    "BURLESTI",
    "BUZENI",
    "CALINESTI (BUCECEA)",
    "CATAMARESTI",
    "CATAMARESTI-DEAL",
    "CERVICESTI",
    "CERVICESTI-DEAL",
    "CIRITEI",
    "CISMEA",
    "COSTESTI",
    "COSTINESTI",
    "COSULENI",
    "COTARGACI",
    "CUCORANI",
    "CURTESTI",
    "DAVIDOAIA",
    "DEALU CRUCII",
    "DIMITRIE CANTEMIR",
    "DOLINA",
    "DOROBANTI",
    "DOROHOI",
    "FLORESTI",
    "GALBENI",
    "GARBESTI",
    "GOROVEI",
    "HRISCANI",
    "HUDUM",
    "HUTANI",
    "ILISENI",
    "IONASENI (TRUSESTI)",
    "IORGA",
    "IPOTESTI",
    "IZVOARE",
    "LIVADA",
    "MAGHERA",
    "MANASTIREA DOAMNEI",
    "MANASTIRENI",
    "MANDRESTI (VLADENI)",
    "MANOLESTI",
    "MESTEACAN",
    "MIHAI EMINESCU",
    "MIHAI EMINESCU (GORBANESTI)",
    "MIHAIL KOGALNICEANU",
    "MITOC (LEORDA)",
    "MOARA JORII",
    "NICOLAE BALCESCU (COTUSCA)",
    "NICSENI",
    "NICULCEA",
    "ORASENI-DEAL",
    "ORASENI-VALE",
    "POIANA (BRAESTI)",
    "POPENI (BRAESTI)",
    "PRISACANI",
    "PROGRESUL",
    "RACHITI",
    "ROMA",
    "ROSIORI",
    "SARAFINESTI",
    "SAUCENITA",
    "SCHIT-ORASENI",
    "SCUTARI",
    "SENDRICENI",
    "SILISCANI",
    "SILISTEA",
    "SLOBOZIA (BROSCAUTI)",
    "SMARDAN",
    "SOCRUJENI",
    "SOLDANESTI",
    "SOROCENI",
    "STANCESTI",
    "STAUCENI",
    "SUPITCA",
    "TAUTESTI",
    "TIMUS",
    "TOCILENI",
    "UNGUROAIA",
    "UNTENI",
    "VALEA GRAJDULUI",
    "VICOLENI",
    "VICTORIA (STAUCENI)",
    "VIFORENI",
    "VITCANI",
    "VLADENI",
    "VULTURENI",
    "ZAICESTI",
    "ADASENI",
    "ALBA",
    "ALBESTI",
    "ARBOREA",
    "AUREL VLAICU",
    "AVRAM IANCU",
    "AVRAMENI",
    "BABICENI",
    "BADARAI",
    "BADIUTI",
    "BAJURA",
    "BALINTI",
    "BARANCA (CRISTINESTI)",
    "BARANCA (HUDESTI)",
    "BARSANESTI",
    "BASEU",
    "BERZA",
    "BIVOLARI",
    "BOBULESTI",
    "BODEASA",
    "BOGDANESTI",
    "BOHOGHINA",
    "BOROLEA",
    "BORZESTI",
    "BOSCOTENI",
    "BOZIENI",
    "BRATENI",
    "BROSCAUTI",
    "BROSTENI",
    "BUDA",
    "BUHACENI",
    "BUIMACENI",
    "CALARASI",
    "CALINESTI (CANDESTI)",
    "CALUGARENI",
    "CALUGARENII NOI",
    "CAMPENI",
    "CANDESTI",
    "CARAIMAN",
    "CARASA",
    "CERBU",
    "CERCHEJENI",
    "CERNESTI",
    "CHELIS",
    "CHISCARENI",
    "CHITOVENI",
    "CINGHINIIA",
    "CISMANESTI",
    "CODRENI",
    "CONCESTI",
    "COPALAU",
    "CORDARENI",
    "CORJAUTI",
    "CORLATENI",
    "CORNI",
    "COSTIUGENI",
    "COSULA",
    "COTU",
    "COTU MICULINTI",
    "COTUSCA",
    "CRASNALEUCA",
    "CRISTESTI",
    "CRISTINESTI",
    "CUCUTENI",
    "CUZA VODA",
    "CUZLAU",
    "DACIA",
    "DAMIDENI",
    "DAMILENI",
    "DANGENI",
    "DARABANI",
    "DEALU MARE",
    "DERSCA",
    "DIMACHENI",
    "DOBARCENI",
    "DOINA",
    "DORBINAUTI-HAPAI",
    "DRACSANI",
    "DRAGALINA (CRISTINESTI)",
    "DRAGALINA (HLIPICENI)",
    "DRAGUSENI",
    "DRAXINI",
    "DRISLEA",
    "DUMBRAVITA",
    "DUMENI",
    "DURNESTI",
    "DURNESTI (SANTA MARE)",
    "DURNESTI (UNGURENI)",
    "ESANCA",
    "FLAMANZI",
    "FLONDORA",
    "FRUMUSICA",
    "FUNDU HERTII",
    "GARBENI",
    "GEORGE COSBUC",
    "GEORGE ENESCU",
    "GHIRENI",
    "GORBANESTI",
    "GRIVITA",
    "GURANDA",
    "HANESTI",
    "HAVARNA",
    "HILISEU-CLOSCA",
    "HILISEU-CRISAN",
    "HILISEU-HORIA",
    "HLIPICENI",
    "HORIA",
    "HORLACENI",
    "HORODISTEA",
    "HUDESTI",
    "HULUB",
    "HULUBESTI",
    "IACOBENI",
    "IBANEASA",
    "IBANESTI",
    "ICHIMENI",
    "ICUSENI",
    "IEZER",
    "IONASENI (VARFU CAMPULUI)",
    "IURESTI",
    "JIJIA",
    "JOLDESTI",
    "LEHNESTI",
    "LEORDA",
    "LIBERTATEA",
    "LISMANITA",
    "LISNA",
    "LIVENI",
    "LOTURI",
    "LOTURI ENESCU",
    "LOZNA",
    "LUNCA",
    "LUNCA (VARFU CAMPULUI)",
    "LUPARIA",
    "MANDRESTI (UNGURENI)",
    "MANOLEASA",
    "MANOLEASA-PRUT",
    "MASCATENI",
    "MATEIENI",
    "MIHAI VITEAZU",
    "MIHAILENI",
    "MIHALASENI",
    "MILEANCA",
    "MILETIN",
    "MIORCANI",
    "MIRON COSTIN",
    "MITOC",
    "MLENAUTI",
    "MOVILA RUPTA",
    "MOVILENI",
    "MURGUTA",
    "NASTASE",
    "NEGRENI",
    "NEGRESTI",
    "NICHITENI",
    "NICOLAE BALCESCU (FLAMANZI)",
    "ONEAGA",
    "OROFTIANA",
    "PADURENI (COSULA)",
    "PADURENI (SENDRICENI)",
    "PALTINIS",
    "PANAITOAIA",
    "PARAU NEGRU",
    "PASATENI",
    "PAUN",
    "PETRICANI",
    "PLESANI",
    "PLEVNA",
    "PLOPENII MARI",
    "PLOPENII MICI",
    "PODENI",
    "PODRIGA",
    "POGORASTI",
    "POIANA (CRISTINESTI)",
    "POIANA (FLAMANZI)",
    "POIANA (VORONA)",
    "POMARLA",
    "POPENI (GEORGE ENESCU)",
    "POPOAIA",
    "PRAJENI",
    "PUSTOAIA",
    "PUTURENI",
    "RACOVAT",
    "RADAUTI-PRUT",
    "RADENI",
    "RANGHILESTI",
    "RANGHILESTI-DEAL",
    "RASCA",
    "RAUSENI",
    "RECIA-VERBIA",
    "REDIU (RADAUTI-PRUT)",
    "REDIU (RAUSENI)",
    "RIPICENI",
    "RIPICENII VECHI",
    "ROGOJESTI",
    "ROMANESTI",
    "ROMANESTI-VALE",
    "SADOVENI",
    "SANTA MARE",
    "SARATA",
    "SARATA-BASARAB",
    "SARATA-DRAGUSENI",
    "SARBI",
    "SAT NOU",
    "SAVENI",
    "SELISTEA",
    "SENDRENI",
    "SERPENITA",
    "SLOBOZIA (CORDARENI)",
    "SLOBOZIA (PALTINIS)",
    "SLOBOZIA HANESTI",
    "SLOBOZIA SILISCANI",
    "STANCA (GEORGE ENESCU)",
    "STANCA (STEFANESTI)",
    "STEFANESTI",
    "STEFANESTI-SAT",
    "STIUBIENI",
    "STOLNICENI",
    "STORESTI",
    "STRAHOTIN",
    "STRATENI",
    "STROIESTI",
    "SUHARAU",
    "SULITA",
    "TALPA",
    "TATARASENI",
    "TODIRENI",
    "TRUSESTI",
    "TUDOR VLADIMIRESCU (ALBESTI)",
    "TUDOR VLADIMIRESCU (AVRAMENI)",
    "TUDORA",
    "UNGURENI",
    "VACULESTI",
    "VALCELELE",
    "VANATORI",
    "VARFU CAMPULUI",
    "VATRA",
    "VICTORIA (HLIPICENI)",
    "VIISOARA",
    "VIISOARA MICA",
    "VLADENI (CORLATENI)",
    "VLADENI-DEAL",
    "VLASINESTI",
    "VORNICENI",
    "VORONA",
    "VORONA MARE",
    "VORONA-TEODORU",
    "ZAHORENI",
    "ZLATUNOAIA",
    "ZOITANI"
  ],
  "BRAILA": [
    "ALBINA",
    "BALDOVINESTI",
    "BRAILA",
    "BRATESU VECHI",
    "CAZASU",
    "CHISCANI",
    "COTU LUNG",
    "FAUREI",
    "FAUREI-SAT",
    "LACU SARAT",
    "MARTACESTI",
    "MUCHEA",
    "PIETROIU",
    "ROMANU",
    "SCORTARU VECHI",
    "SILISTEA",
    "SILISTRARU",
    "SURDILA-GRECI",
    "TUDOR VLADIMIRESCU",
    "VADENI",
    "VARSATURA",
    "ARICIU",
    "BARAGANUL",
    "BATOGU",
    "BERLESTI",
    "BERTESTII DE JOS",
    "BERTESTII DE SUS",
    "BOARCA",
    "BORDEI VERDE",
    "BRADEANCA",
    "BUMBACARI",
    "CAINENI-BAI",
    "CALDARUSA",
    "CHICHINETU",
    "CHIOIBASESTI",
    "CIOCILE",
    "CIRESU",
    "COLTEA",
    "COMANEASCA",
    "CONSTANTIN GABRIELESCU",
    "CONSTANTINESTI",
    "CORBENI",
    "CORBU NOU",
    "CORBU VECHI",
    "COTU MIHALEA",
    "CUSTURA",
    "CUZA VODA (SALCIA TUDOR)",
    "CUZA VODA (STANCUTA)",
    "DEDULESTI",
    "DESIRATI",
    "DROGU",
    "DUDESCU",
    "DUDESTI",
    "ESNA",
    "FILIPESTI",
    "FILIU",
    "FLORICA",
    "GALBENU",
    "GARA IANCA",
    "GAVANI",
    "GEMENELE",
    "GRADISTEA",
    "GROPENI",
    "GULIANCA",
    "GURA CALMATUI",
    "GURA GARLUTEI",
    "GURGUIETI",
    "HORIA",
    "IANCA",
    "IBRIANU",
    "INSURATEI",
    "IONESTI",
    "JIRLAU",
    "JUGUREANU",
    "LACU REZII",
    "LANURILE",
    "LATINU",
    "LISCOTEANCA",
    "MARALOIU",
    "MARU ROSU",
    "MAXINENI",
    "MIHAI BRAVU",
    "MIHAIL KOGALNICEANU (RAMNICELU)",
    "MIHAIL KOGALNICEANU (SUTESTI)",
    "MIRCEA VODA",
    "MOROTESTI",
    "MOVILA MIRESII",
    "NICOLAE BALCESCU",
    "NICOLESTI",
    "OANCEA",
    "ODAIENI",
    "OLANEASCA",
    "OPRISENESTI",
    "PANTECANI",
    "PERISORU",
    "PITULATI",
    "PLASOIU",
    "PLOPI",
    "PLOPU",
    "POLIZESTI",
    "PRIBEAGU",
    "RACOVITA",
    "RAMNICELU",
    "ROSIORI",
    "SALCIA",
    "SALCIA TUDOR",
    "SATUC",
    "SCARLATESTI",
    "SCORTARU NOU",
    "SIHLEANU",
    "SPIRU HARET",
    "STANCA",
    "STANCUTA",
    "SURDILA-GAISEANCA",
    "SUTESTI",
    "TARLELE FILIU",
    "TATARU",
    "TEPES VODA",
    "TICHILESTI",
    "TRAIAN",
    "TUFESTI",
    "ULMU",
    "UNIREA",
    "URLEASCA",
    "VALEA CALMATUIULUI",
    "VALEA CANEPII",
    "VAMESU",
    "VICTORIA",
    "VISANI",
    "VIZIRU",
    "VOINESTI",
    "VULTURENI",
    "ZAMFIRESTI",
    "ZAVOAIA"
  ],
  "BRASOV": [
    "BECLEAN",
    "BERIVOI",
    "BOD",
    "BOHOLT",
    "BRAN",
    "BRASOV",
    "BUDILA",
    "CALBOR",
    "CARPINIS",
    "CHEIA",
    "CINCSOR",
    "CODLEA",
    "COLONIA 1 MAI",
    "COLONIA BOD",
    "COLONIA RECONSTRUCTIA",
    "CRISTIAN",
    "DRIDIF",
    "DUMBRAVITA",
    "FAGARAS",
    "FELDIOARA",
    "GHIMBAV",
    "HALCHIU",
    "HARMAN",
    "HARSENI",
    "HUREZ",
    "ILENI",
    "LUNCA CALNICULUI",
    "LUTA",
    "MANDRA",
    "MOIECIU",
    "MOIECIU DE JOS",
    "MOIECIU DE SUS",
    "PALTIN",
    "PARAUL RECE",
    "PODU OLTULUI",
    "POIANA BRASOV",
    "POIANA MARULUI",
    "PREDEAL",
    "PREDELUT",
    "PREJMER",
    "PURCARENI",
    "RASNOV",
    "RASNOV ROMACRIL",
    "RAUSOR",
    "SACELE",
    "SANPETRU",
    "SASCIORI",
    "SATU NOU",
    "SAVASTRENI",
    "SERCAIA",
    "SIMON",
    "SOARS",
    "SOHODOL",
    "SONA",
    "STUPINII PREJMERULUI",
    "TARLUNGENI",
    "TIMISU DE JOS",
    "TIMISU DE SUS",
    "TODERITA",
    "TOHANU NOU",
    "VICTORIA",
    "VULCAN",
    "ZARNESTI",
    "ZIZIN",
    "ACRIS",
    "APATA",
    "ARINI",
    "AUGUSTIN",
    "BARCUT",
    "BEIA",
    "BOGATA OLTEANA",
    "BREAZA",
    "BUCIUM",
    "BUNESTI",
    "BUZAIEL",
    "CATA",
    "CINCU",
    "COBOR",
    "COMANA",
    "COMANA DE JOS",
    "COMANA DE SUS",
    "COPACEL",
    "CORBI",
    "CRIHALMA",
    "CRIT",
    "CRIZBAV",
    "CUCIULATA",
    "CUTUS",
    "DACIA",
    "DAISOARA",
    "DALGHIU",
    "DEJANI",
    "DOPCA",
    "DRAGUS",
    "DRAUSENI",
    "DRUMUL CARULUI",
    "FANTANA",
    "FELDIOARA (UCEA)",
    "FELMER",
    "FISER",
    "FUNDATA",
    "FUNDATICA",
    "GRANARI",
    "GRID",
    "GURA VAII",
    "HALMEAG",
    "HOGHIZ",
    "HOLBAV",
    "HOMOROD",
    "IASI",
    "IONESTI",
    "JIBERT",
    "JIMBOR",
    "LISA",
    "LOVNIC",
    "LUDISOR",
    "LUPSA",
    "MAGURA",
    "MAIERUS",
    "MALINIS",
    "MARGINENI",
    "MATEIAS",
    "MERCHEASA",
    "MESENDORF",
    "OHABA",
    "OLTET",
    "ORMENIS",
    "PALOS",
    "PARAU",
    "PERSANI",
    "PESTERA",
    "POJORTA",
    "RACOS",
    "RECEA",
    "ROADES",
    "RODBAV",
    "ROTBAV",
    "RUCAR",
    "RUPEA",
    "SAMBATA DE JOS",
    "SAMBATA DE SUS",
    "SEBES",
    "SELISTAT",
    "SERCAITA",
    "SINCA",
    "SINCA NOUA",
    "SINCA VECHE",
    "SIRNEA",
    "STATIUNEA CLIMATERICA SAMBATA",
    "TELIU",
    "TICUSU",
    "TICUSU NOU",
    "TICUSU VECHI",
    "TOARCLA",
    "UCEA",
    "UCEA DE JOS",
    "UCEA DE SUS",
    "UNGRA",
    "VAD",
    "VALCEA",
    "VALENI",
    "VAMA BUZAULUI",
    "VENETIA DE JOS",
    "VENETIA DE SUS",
    "VISCRI",
    "VISTEA",
    "VISTEA DE JOS",
    "VISTEA DE SUS",
    "VISTISOARA",
    "VLADENI",
    "VOILA",
    "VOIVODENI"
  ],
  "BUCURESTI": [
    "BUCURESTI"
  ],
  "BUZAU": [
    "ALICENI",
    "BABENI",
    "BAJANI",
    "BALHACU",
    "BALTARETI",
    "BARBUNCESTI",
    "BENTU",
    "BERCA",
    "BOBOC",
    "BORDUSANI",
    "BRADEANCA",
    "BUDISTENI",
    "BUZAU",
    "C.A. ROSETTI",
    "CALDARUSA",
    "CANDESTI",
    "CAPATANESTI",
    "CARLOMANESTI",
    "CERNATESTI",
    "CILIBIA",
    "CIOBANOAIA",
    "CLONDIRU",
    "COCONARI",
    "COLIBASI",
    "COMISOAIA",
    "COSTESTI",
    "COTATCU",
    "COTU CIORII",
    "DAMBROCA",
    "DEALU VIEI",
    "FOCSANEI",
    "FOTIN",
    "FUNDENI",
    "GALBINASI",
    "GARA BOBOCU",
    "GHERASENI",
    "GOMOESTI",
    "GRAJDANA",
    "GREBANU",
    "GROSANI",
    "GURA CALNAULUI",
    "GURA SARATII",
    "HOMESTI",
    "IZVORU DULCE (MEREI)",
    "LIPIA",
    "LIVADA",
    "LIVADA MICA",
    "MANASIA",
    "MANZU",
    "MARACINENI",
    "MATESTI",
    "MAXENU",
    "MEREI",
    "MIEREA",
    "NENCIU",
    "NENCIULESTI",
    "NISCOV",
    "ODAIA BANULUI",
    "OGRAZILE",
    "ORATIA",
    "OREAVUL",
    "PETRISORU",
    "PIETROASELE",
    "PIETROSU",
    "PLESCOI",
    "PLESESTI (BERCA)",
    "PLEVNA",
    "PODGORIA",
    "POGONELE",
    "POSTA (TOPLICENI)",
    "POSTA CALNAU",
    "POTARNICHESTI",
    "POTOCENI",
    "PUIESTII DE SUS",
    "RACOVITENI",
    "RADUCESTI",
    "RAMNICELU",
    "RAMNICU SARAT",
    "RUBLA",
    "RUSETU",
    "SAGEATA",
    "SAPOCA",
    "SARANGA",
    "SARATA",
    "SARATA-MONTEORU",
    "SASENII NOI",
    "SASENII PE VALE",
    "SASENII VECHI",
    "SATUC",
    "SCURTESTI",
    "SMEENI",
    "SORESTI",
    "SPATARU",
    "STALPU",
    "STANCESTI",
    "STIUBEI",
    "SUDITI (GHERASENI)",
    "SUDITI (POSTA CALNAU)",
    "TABACARI",
    "TABARASTI",
    "TINTESTI",
    "TOPLICENI",
    "ULMENI",
    "VADU PASII",
    "VALCELE",
    "VALEA NUCULUI",
    "VALEA PUTULUI MEREI",
    "VALEA RAMNICULUI",
    "VALEA SALCIILOR",
    "VERNESTI",
    "VIZIRENI",
    "VLADENI",
    "ZAPLAZI",
    "ZARNESTI",
    "ZARNESTII DE SLANIC",
    "ZIDURI",
    "ZILISTEANCA",
    "ZORESTI",
    "ALBESTI",
    "ALDENI",
    "ALEXANDRU ODOBESCU",
    "ALUNIS",
    "AMARA",
    "AMARU",
    "ANINI",
    "ARBANASI",
    "ARCANU",
    "BACENI",
    "BADENI",
    "BADILA",
    "BAESTI",
    "BAILE",
    "BALACEANU",
    "BALAIA",
    "BALANESTI",
    "BALTA ALBA",
    "BALTA TOCILA",
    "BALTAGARI",
    "BALTENI",
    "BANITA",
    "BARASTI",
    "BASCA CHIOJDULUI",
    "BASCA ROZILEI",
    "BASCENII DE JOS",
    "BASCENII DE SUS",
    "BATOGU",
    "BECENI",
    "BECIU",
    "BEGU",
    "BEILIC",
    "BERCESTI",
    "BESLII",
    "BISOCA",
    "BLAJANI",
    "BODINESTI",
    "BOLDU",
    "BOZIORU",
    "BRADEANU",
    "BRAESTI",
    "BRAGAREASA",
    "BRATILESTI",
    "BREAZA",
    "BREBU",
    "BUDA",
    "BUDA CRACIUNESTI",
    "BUDESTI",
    "BUDREA",
    "BUDUILE",
    "BUSTEA",
    "CALDARASTI",
    "CALDARUSEANCA",
    "CALEA CHIOJDULUI",
    "CALTESTI",
    "CALTUNA",
    "CALVINI",
    "CAMPENI",
    "CAMPULUNGEANCA",
    "CANESTI",
    "CAPU SATULUI",
    "CARAGELE",
    "CARATNAU DE JOS",
    "CARATNAU DE SUS",
    "CARLIGU MARE",
    "CARLIGU MIC",
    "CARPINISTEA",
    "CASOCA",
    "CASOTA",
    "CATIASU",
    "CATINA",
    "CEAIRU",
    "CHILIILE",
    "CHIOJDU",
    "CHIPERU",
    "CHIRLESTI",
    "CIOCANESTI",
    "CIORANCA",
    "CIRESU",
    "CISLAU",
    "CIUTA",
    "CLONDIRU DE SUS",
    "COCA-ANTIMIRESTI",
    "COCA-NICULESTI",
    "COCARCENI",
    "COCHIRLEANCA",
    "COJANU",
    "COLTANENI",
    "COLTENI",
    "COLTI",
    "COLTII DE JOS",
    "COLTU PIETRII",
    "CORBU (CATINA)",
    "CORBU (GLODEANU-SILISTEA)",
    "CORNEANU",
    "COSTIENI",
    "COSTOMIRU",
    "COTORCA",
    "COZIENI",
    "CRANG",
    "CREVELESTI",
    "CUCULEASA",
    "CURCANESTI",
    "CURMATURA",
    "DALMA",
    "DANULESTI",
    "DARA",
    "DASCALESTI",
    "DEDULESTI",
    "DELENI",
    "DOBRILESTI",
    "DOGARI",
    "DULBANU",
    "FANTANELE (MARGARITESTI)",
    "FANTANELE (NAENI)",
    "FATA LUI NAN",
    "FINTESTI",
    "FISICI",
    "FLORESTI",
    "FLORICA",
    "FRASINET",
    "FULGA",
    "FUNDATA",
    "FUNDATURILE",
    "FURTUNESTI",
    "GAGENI",
    "GARA CILIBIA",
    "GAVANELE",
    "GAVANESTI",
    "GHERGHEASA",
    "GHIOCARI",
    "GHIZDITA",
    "GLODEANU SARAT",
    "GLODEANU-SILISTEA",
    "GLODU-PETCARI",
    "GLODURILE",
    "GOICELU",
    "GOIDESTI",
    "GOLU GRABICINA",
    "GONTESTI",
    "GORANI",
    "GORNET",
    "GRABICINA DE JOS",
    "GRABICINA DE SUS",
    "GRECEANCA",
    "GRESIA",
    "GURA BADICULUI",
    "GURA BASCEI",
    "GURA DIMIENII",
    "GURA FAGETULUI",
    "GURA SIRIULUI",
    "GURA TEGHII",
    "GURA VAII",
    "HALES",
    "HELIADE RADULESCU",
    "ILEANA",
    "ISTRITA DE JOS",
    "IVANETU",
    "IZVOARELE",
    "IZVORANU",
    "IZVORU (COZIENI)",
    "IZVORU (TISAU)",
    "IZVORU DULCE (BECENI)",
    "JGHIAB",
    "JOSENI",
    "LACU",
    "LACU CU ANINI",
    "LACU SINAIA",
    "LACURILE",
    "LANURILE",
    "LARGU",
    "LEICULESTI",
    "LERA",
    "LIMPEZIS",
    "LIPANESCU",
    "LOPATAREASA",
    "LOPATARI",
    "LUCIU",
    "LUNCA (AMARU)",
    "LUNCA (C.A. ROSETTI)",
    "LUNCA (PATARLAGELE)",
    "LUNCA (PUIESTI)",
    "LUNCA FRUMOASA",
    "LUNCA JARISTEI",
    "LUNCA PRIPORULUI",
    "LUNCILE",
    "LUNGESTI",
    "MACRINA",
    "MAGURA",
    "MAGURICEA",
    "MANASTIREA",
    "MANASTIREA RATESTI",
    "MANZALESTI",
    "MARGARITESTI",
    "MARGARITI",
    "MARGINEANU",
    "MARUNTISU",
    "MIHAILESTI",
    "MITROPOLIA",
    "MLAJET",
    "MODRENI",
    "MOISICA",
    "MOSESTI",
    "MOVILA BANULUI",
    "MOVILA OII",
    "MOVILITA",
    "MUCESTI-DANULESTI",
    "MURGESTI",
    "MUSCEL",
    "MUSCELU CARAMANESTI",
    "MUSCELUSA",
    "NAENI",
    "NEGOSINA",
    "NEHOIASU",
    "NEHOIU",
    "NEMERTEA",
    "NICOLESTI",
    "NICULESTI",
    "NISTORESTI",
    "NUCU",
    "ODAILE",
    "OJASCA",
    "OLARI",
    "OLESESTI",
    "PACLELE",
    "PACURILE",
    "PADINA",
    "PADURENII",
    "PALICI",
    "PALTINENI",
    "PALTINIS",
    "PANATAU",
    "PARDOSI",
    "PARJOLESTI",
    "PARSCOV",
    "PARSCOVELU",
    "PATARLAGELE",
    "PESTRITU",
    "PETRACHESTI",
    "PIATRA ALBA",
    "PIETRARU",
    "PIETROASA MICA",
    "PINU",
    "PITULICEA",
    "PLAISOR",
    "PLAIU NUCULUI",
    "PLAVATU",
    "PLESCIOARA",
    "PLESESTI (PODGORIA)",
    "PLESI",
    "PLOPEASA",
    "PLOPI",
    "PLOSTINA",
    "PODU MUNCII",
    "POENITELE",
    "POGOANELE",
    "POIANA PLETARI",
    "POIANA VALCULUI",
    "POIENILE",
    "POLICIORI",
    "POSOBESTI",
    "POSTA (CILIBIA)",
    "POTECU",
    "PROSCA",
    "PRUNENI",
    "PUIESTI",
    "PUIESTII DE JOS",
    "PUNGA",
    "RAPILE",
    "RATESTI",
    "RECEA",
    "ROBEASCA",
    "ROBESTI",
    "ROSIORU",
    "RUGINOASA",
    "RUNCU",
    "RUSAVAT",
    "SAHATENI",
    "SALCIA",
    "SALCIOARA",
    "SARBESTI",
    "SARENI",
    "SARILE",
    "SARILE-CATUN",
    "SARULESTI",
    "SATU NOU (GLODEANU-SILISTEA)",
    "SATU NOU (MIHAILESTI)",
    "SATU VECHI",
    "SCAENI",
    "SCARISOARA",
    "SCARLATESTI",
    "SCOROSESTI",
    "SCORTEANCA",
    "SCORTOASA",
    "SCUTELNICI",
    "SECUIU",
    "SERGENT IONEL STEFAN",
    "SIBICIU DE JOS",
    "SIBICIU DE SUS",
    "SINDRILA",
    "SIRIU",
    "SLOBOZIA",
    "SMARDAN",
    "SMEESTI",
    "SPIDELE",
    "STANILA",
    "STAVARASTI",
    "STREZENI",
    "STROESTI",
    "SUCHEA",
    "TARCOV",
    "TARLELE",
    "TATARLIGU",
    "TATULESTI",
    "TEGA",
    "TEISU",
    "TERCA",
    "TISAU",
    "TOCILENI",
    "TOROPALESTI",
    "TRESTIA",
    "TRESTIENI",
    "TRESTIOARA (CHILIILE)",
    "TRESTIOARA (MANZALESTI)",
    "TRONARI",
    "TULBUREA",
    "UDATI-LUCIENI",
    "UDATI-MANZU",
    "ULMET",
    "UNGURIU",
    "URSOAIA",
    "VACAREASCA",
    "VADU OII",
    "VADU SORESTI",
    "VALCELELE",
    "VALEA BANULUI",
    "VALEA CATINEI",
    "VALEA COTOAREI",
    "VALEA FANTANEI",
    "VALEA LARGA",
    "VALEA LARGA-SARULESTI",
    "VALEA LUI LALU",
    "VALEA LUPULUI",
    "VALEA PARULUI",
    "VALEA PURCARULUI",
    "VALEA RATEI",
    "VALEA ROATEI",
    "VALEA SALCIEI",
    "VALEA SALCIEI-CATUN",
    "VALEA SCHIOPULUI",
    "VALEA SIBICIULUI",
    "VALEA STANEI",
    "VALEA STEFANULUI",
    "VALEA URSULUI",
    "VALEA VERZEI",
    "VALEA VIEI",
    "VALEANCA-VILANESTI",
    "VARF",
    "VARLAAM",
    "VARTEJU",
    "VAVALUCILE",
    "VIFORATA",
    "VINETISU",
    "VINTILA VODA",
    "VINTILEANCA",
    "VIPERESTI",
    "VISPESTI",
    "ZAHARESTI",
    "ZAPODIA",
    "ZELETIN",
    "ZOITA"
  ],
  "CALARASI": [
    "BELCIUGATELE",
    "CALARASI",
    "CALARASII VECHI",
    "CEACU",
    "CHIRNOGI",
    "CLATESTI",
    "COSLOGENI",
    "CUCUIETI",
    "CURCANI",
    "CUZA VODA",
    "FRUMUSANI",
    "FUNDENI (CALARASI)",
    "GALBINASI",
    "LEHLIU-GARA",
    "LIBERTATEA",
    "MITRENI",
    "MODELU",
    "OLTENITA",
    "ORASTI",
    "PADURISU",
    "PASAREA (CALARASI)",
    "PLATARESTI",
    "PODU PITARULUI",
    "POSTAVARI",
    "RADU NEGRU",
    "ROSETI",
    "SALCIOARA",
    "STANCEA",
    "STEFAN CEL MARE",
    "STOENESTI",
    "TONEA",
    "ULMENI",
    "VALEA POPII",
    "VALEA ROSIE",
    "ALEXANDRU ODOBESCU",
    "APROZI",
    "ARTARI",
    "BOGATA",
    "BOGDANA",
    "BORCEA",
    "BOSNEAGU",
    "BUCIUMENI",
    "BUDESTI",
    "BUZOENI",
    "CALARETI",
    "CANDEASCA",
    "CASCIOARELE",
    "CETATEA VECHE",
    "CHISELET",
    "CIOCANESTI",
    "CIULNITA-GARA",
    "COCONI",
    "COJESTI",
    "CONSTANTIN BRANCOVEANU",
    "COTOFANCA",
    "CRIVAT",
    "CUNESTI",
    "CURATESTI",
    "DALGA",
    "DALGA-GARA",
    "DANESTI",
    "DARVARI",
    "DICHISENI",
    "DOR MARUNT",
    "DOROBANTU",
    "DOROBANTU (PLATARESTI)",
    "DRAGALINA",
    "DRAGOS VODA",
    "DRAJNA NOUA",
    "FANTANA DOAMNEI",
    "FAUREI",
    "FLORICA",
    "FLOROAICA",
    "FRASINET",
    "FRASINETU DE JOS",
    "FUNDULEA",
    "GALATUI",
    "GALDAU",
    "GOSTILELE",
    "GRADISTEA",
    "GRUIU",
    "GURBANESTI",
    "IEZERU",
    "ILEANA",
    "INDEPENDENTA",
    "INFRATIREA",
    "JEGALIA",
    "LEHLIU",
    "LUICA",
    "LUPSANU",
    "LUPTATORI",
    "MANASTIREA",
    "MARCULESTI-GARA",
    "MARIUTA",
    "MATARAUA",
    "MIHAI VITEAZU",
    "NANA",
    "NEGOESTI",
    "NICOLAE BALCESCU",
    "NICOLAE BALCESCU (ALEXANDRU ODOBESCU)",
    "NUCETU",
    "NUCI",
    "OGORU",
    "OLTINA",
    "OSTROVU",
    "PAICU",
    "PELINU",
    "PERISORU",
    "PLEVNA",
    "PLUMBUITA",
    "PODARI",
    "POLCESTI",
    "POPESTI",
    "POTCOAVA",
    "PREASNA",
    "PROGRESU",
    "RADOVANU",
    "RADU VODA",
    "RASA",
    "RASURILE",
    "RAZOARELE",
    "RAZVANI",
    "SACELE",
    "SANDULITA",
    "SAPUNARI",
    "SARULESTI",
    "SARULESTI-GARA",
    "SATNOENI",
    "SATU NOU",
    "SEINOIU",
    "SILISTEA",
    "SOCOALELE",
    "SOHATU",
    "SOLACOLU",
    "SOLDANU",
    "SPANTOV",
    "STEFAN VODA",
    "STEFANESTI",
    "SULTANA",
    "TAMADAU MARE",
    "TAMADAU MIC",
    "TARICENI",
    "TUDOR VLADIMIRESCU",
    "ULMU",
    "UNIREA",
    "VALCELELE",
    "VALEA ARGOVEI",
    "VALEA PRESNEI",
    "VALEA RUSULUI",
    "VALEA SEACA",
    "VALEA STANII",
    "VARASTI",
    "VASILATI",
    "VISINII",
    "VLAD TEPES",
    "VLADICEASCA",
    "VLAICULESTI",
    "ZIMBRU"
  ],
  "CARAS-SEVERIN": [
    "BAILE HERCULANE",
    "BARZA",
    "BOCSA",
    "BOLVASNITA",
    "BORLOVA",
    "BRATOVA",
    "BUCHIN",
    "BUCOSNITA",
    "CALNIC",
    "CARANSEBES",
    "CARASOVA",
    "CICLENI",
    "CIUTA",
    "CORNUTEL",
    "CORONINI",
    "CUPTOARE (RESITA)",
    "DALCI",
    "DOMAN",
    "EZERIS",
    "GLIMBOCA",
    "GOLET",
    "GORUIA",
    "IABALCEA",
    "IAZ",
    "JUPA",
    "LIBORAJDEA",
    "LINDENFELD",
    "LUPAC",
    "MACESTI",
    "MACIOVA",
    "MOLDOVA NOUA",
    "MOLDOVA VECHE",
    "MOLDOVITA",
    "MONIOM",
    "NERMED",
    "OBREJA",
    "ORAVITA",
    "OTELU ROSU",
    "PADINA MATEI",
    "PALTINIS",
    "PECINISCA",
    "PLUGOVA",
    "POIANA",
    "POJEJENA",
    "PRISACA",
    "PRISIAN",
    "RADIMNA",
    "RESITA",
    "RUGI",
    "RUGINOSU",
    "SALBAGELU NOU",
    "SECU",
    "SFANTA ELENA",
    "SOCENI",
    "SUSCA",
    "TARNOVA",
    "TEROVA",
    "TOPLET",
    "TURNU RUIENI",
    "VALEA TIMISULUI",
    "VALISOARA",
    "VAR",
    "VODNIC",
    "ZERVESTI",
    "ZLAGNA",
    "44431",
    "AGADICI",
    "ANINA",
    "APADIA",
    "ARMENIS",
    "ARSURI",
    "BANIA",
    "BARBOSU",
    "BARZ",
    "BAUTAR",
    "BAZIAS",
    "BELOBRESCA",
    "BERLISTE",
    "BERZASCA",
    "BERZOVIA",
    "BIGAR",
    "BINIS",
    "BOGALTIN",
    "BOGODINT",
    "BOINA",
    "BOINITA",
    "BOJIA",
    "BORLOVENII NOI",
    "BORLOVENII VECHI",
    "BORUGI",
    "BOZOVICI",
    "BRADISORU DE JOS",
    "BREBU",
    "BREBU NOU",
    "BRESTELNIC",
    "BREZON",
    "BROSTENI",
    "BUCOVA",
    "CALINA",
    "CAMENA",
    "CAMENITA",
    "CAMPIA",
    "CANICEA",
    "CARBUNARI",
    "CARNECEA",
    "CARSA ROSIE",
    "CARSIE",
    "CAVARAN",
    "CICLOVA MONTANA",
    "CICLOVA ROMANA",
    "CIORTEA",
    "CIRESA",
    "CIRESEL",
    "CIUCHICI",
    "CIUDANOVITA",
    "CLOCOTICI",
    "COMORASTE",
    "CONSTANTIN DAICOVICIU",
    "COPACELE",
    "CORNEA",
    "CORNEREVA",
    "CORNISORU",
    "COSTIS",
    "COZIA",
    "COZLA",
    "CRACU ALMAJ",
    "CRACU MARE",
    "CRACU TEIULUI",
    "CRUSOVAT",
    "CRUSOVITA",
    "CUPTOARE (CORNEA)",
    "CURMATURA",
    "DALBOSET",
    "DELINESTI",
    "DEZESTI",
    "DIVICI",
    "DOBRAIA",
    "DOCLIN",
    "DOGNECEA",
    "DOLINA",
    "DOMASNEA",
    "DRENCOVA",
    "DRISTIE",
    "DULEU",
    "EFTIMIE MURGU",
    "ERSIG",
    "FARLIUG",
    "FENES",
    "FIZES",
    "FOROTIC",
    "FRASINIS",
    "GARANA",
    "GARBOVAT",
    "GARLISTE",
    "GARNIC",
    "GHERTENIS",
    "GIURGIOVA",
    "GLOBU CRAIOVEI",
    "GLOBURAU",
    "GORNEA",
    "GRADINARI",
    "GREONI",
    "GRUNI",
    "HORA MARE",
    "HORA MICA",
    "IABLANITA",
    "IAM",
    "IERTOF",
    "ILIDIA",
    "ILOVA",
    "INELET",
    "IZGAR",
    "IZVOR",
    "JITIN",
    "LAPUSNICEL",
    "LAPUSNICU MARE",
    "LESCOVITA",
    "LIUBCOVA",
    "LUCACEVAT",
    "LUNCA FLORII",
    "LUNCA ZAICII",
    "LUNCAVITA",
    "MACOVISTE (CIUCHICI)",
    "MACOVISTE (CORNEA)",
    "MAGURA",
    "MAL",
    "MARGA",
    "MARILA",
    "MARTINOVAT",
    "MARU",
    "MATNICU MARE",
    "MAURENI",
    "MEHADIA",
    "MEHADICA",
    "MERCINA",
    "MESTEACAN",
    "MILCOVENI",
    "MOCERIS",
    "NAIDAS",
    "NEGIUDIN",
    "NICOLINT",
    "OBITA",
    "OCNA DE FIER",
    "OGASU PODULUI",
    "OHABA-MATNIC",
    "OHABITA",
    "PARNEAURA",
    "PARVOVA",
    "PATAS",
    "PESTERE",
    "PETNIC",
    "PETRILOVA",
    "PETROSNITA",
    "PLOPU",
    "POGARA",
    "POGARA DE SUS",
    "POIANA LUNGA",
    "POIANA MARULUI",
    "POIENILE BOINEI",
    "PONEASCA",
    "POTOC",
    "PREVECIORI",
    "PRIGOR",
    "PRILIPET",
    "PRISACINA",
    "PRISLOP (CORNEREVA)",
    "PRISLOP (DALBOSET)",
    "PUTNA",
    "RACASDIA",
    "RACHITA",
    "RACHITOVA",
    "RAFNIC",
    "RAMNA",
    "RAVENSCA",
    "REMETEA-POGANICI",
    "RESITA MICA",
    "RUSCA",
    "RUSCA MONTANA",
    "RUSCHITA",
    "RUSOVA NOUA",
    "RUSOVA VECHE",
    "RUSTIN",
    "SACU",
    "SADOVA NOUA",
    "SADOVA VECHE",
    "SASCA MONTANA",
    "SASCA ROMANA",
    "SAT BATRAN",
    "SCAIUS",
    "SCARISOARA",
    "SECASENI",
    "SICHEVITA",
    "SLATINA-NERA",
    "SLATINA-TIMIS",
    "SOCOL",
    "SOCOLARI",
    "SOPOTU NOU",
    "SOPOTU VECHI",
    "SOSDEA",
    "STANCILOVA",
    "STEIERDORF",
    "STINAPARI",
    "STRENEAC",
    "STRUGASCA",
    "STUDENA",
    "SUB CRANG",
    "SUB MARGINE",
    "SUB PLAI",
    "SUMITA",
    "SURDUCU MARE",
    "TATU",
    "TEREGOVA",
    "TICVANIU MARE",
    "TICVANIU MIC",
    "TINCOVA",
    "TIROL",
    "TOPLA",
    "URCU",
    "VALEA BISTREI",
    "VALEA BOLVASNITA",
    "VALEA MARE",
    "VALEA MINISULUI",
    "VALEA OREVITA",
    "VALEA RACHITEI",
    "VALEA RAVENSCA",
    "VALEA ROSIE",
    "VALEA SICHEVITEI",
    "VALEADENI",
    "VALEAPAI",
    "VALIUG",
    "VAMA MARGA",
    "VARADIA",
    "VARCIOROVA",
    "VERENDIN",
    "VERMES",
    "VOISLOVA",
    "VRANI",
    "VRANIUT",
    "ZAGUJENI",
    "ZANOGI",
    "ZANOU",
    "ZASLOANE",
    "ZAVOI",
    "ZBEGU",
    "ZLATITA",
    "ZMOGOTIN",
    "ZOINA",
    "ZORILE",
    "ZORLENCIOR",
    "ZORLENTU MARE"
  ],
  "CLUJ": [
    "ANDICI",
    "APAHIDA",
    "BABDIU",
    "BACIU",
    "BADENI",
    "BAITA",
    "BICALATU",
    "BOGATA",
    "BOGATA DE JOS",
    "BOGATA DE SUS",
    "BOIAN",
    "BOLDUT",
    "BOLOGA",
    "BORZESTI",
    "BRAISORU",
    "BUNESTI",
    "CALNA",
    "CAMPIA TURZII",
    "CASEIU",
    "CATCAU",
    "CERBESTI",
    "CETAN",
    "CHINTENI",
    "CIURGAU",
    "CIURILA",
    "CLAPA",
    "CLUJ-NAPOCA",
    "CODOR",
    "COLONIA",
    "COPACENI",
    "COPLEAN",
    "CRAIRAT",
    "CREMENEA",
    "CURTUIUSU DEJULUI",
    "CUSTURA",
    "CUZDRIOARA",
    "DAMBU MARE",
    "DEJ",
    "DEZMIR",
    "DOMOSU",
    "ESCU",
    "FELEACU",
    "FIZESU GHERLII",
    "FLORESTI",
    "GARBAU DEJULUI",
    "GHEORGHIENI",
    "GHERLA",
    "GILAU",
    "GLIGORESTI",
    "GUGA",
    "HASDATE (GHERLA)",
    "HASDATE (SAVADISLA)",
    "HODISU",
    "HORLACEA",
    "HUEDIN",
    "IZVORU CRISULUI",
    "JICHISU DE JOS",
    "JICHISU DE SUS",
    "JURCA",
    "LUNA",
    "LUNA DE SUS",
    "LUNCANI",
    "MAIA",
    "MANASTIREA",
    "MANASTUREL",
    "MARTINESTI",
    "MICA",
    "MIHAI VITEAZU",
    "MINTIU GHERLII",
    "MORISTI",
    "MORTESTI",
    "NEARSOVA",
    "NICULA",
    "NIMA",
    "NIRES",
    "OCNA DEJULUI",
    "OLARIU",
    "PADUREA IACOBENI",
    "PADURENI (CHINTENI)",
    "PADURENI (CIURILA)",
    "PATA",
    "PESTERA",
    "PINTIC",
    "POIANA FRATII",
    "POPESTI",
    "PRUNIS",
    "REDIU",
    "RUGASESTI",
    "SALATIU",
    "SALATRUC",
    "SALICEA",
    "SANCRAIU",
    "SANDULESTI",
    "SANMARGHITA",
    "SANMARTIN (CHINTENI)",
    "SANNICOARA",
    "SARADIS",
    "SAULA",
    "SAVADISLA",
    "SICFA",
    "SOMCUTU MIC",
    "SUARAS",
    "SUB COASTA",
    "SUCEAGU",
    "TARPIU",
    "TAUTI",
    "TURDA",
    "TURENI",
    "UNGURAS",
    "URCA",
    "URISOR",
    "VAD",
    "VALCELE",
    "VALEA CALDA",
    "VALEA FLORILOR",
    "VALEA GARBOULUI",
    "VALEA LUI CATI",
    "VALEA LUNCII",
    "VALEA UNGURASULUI",
    "VALEA VADULUI",
    "VIISOARA",
    "VLAHA",
    "AGARBICIU",
    "AGHIRESU",
    "AGHIRESU-FABRICI",
    "AGRIS",
    "AITON",
    "ALUNIS",
    "ALUNISU",
    "ANTAS",
    "ARDEOVA",
    "ARGHISU",
    "ARUNCUTA",
    "ASCHILEU",
    "ASCHILEU MARE",
    "ASCHILEU MIC",
    "BABUTIU",
    "BADESTI",
    "BAGACIU",
    "BAGARA",
    "BAISOARA",
    "BALCESTI (BELIS)",
    "BALCESTI (CAPUSU MARE)",
    "BARAI",
    "BARLEA",
    "BATIN",
    "BEDECIU",
    "BELIS",
    "BERCHIESU",
    "BERINDU",
    "BICA",
    "BLIDARESTI",
    "BOBALNA",
    "BOCIU",
    "BODROG",
    "BOJ-CATUN",
    "BOJU",
    "BONT",
    "BONTIDA",
    "BORSA",
    "BORSA-CATUN",
    "BORSA-CRESTAIA",
    "BOTENI",
    "BUCEA",
    "BURU",
    "BUTENI",
    "BUZA",
    "CACOVA IERII",
    "CAIANU",
    "CAIANU MIC",
    "CAIANU-VAMA",
    "CALARASI",
    "CALARASI-GARA",
    "CALATA",
    "CALATELE",
    "CAMARASU",
    "CAMPENESTI",
    "CAPRIOARA",
    "CAPUSU MARE",
    "CAPUSU MIC",
    "CARA",
    "CASELE MICESTI",
    "CATALINA",
    "CATINA",
    "CEABA",
    "CEANU MARE",
    "CEANU MIC",
    "CERC",
    "CHEIA",
    "CHESAU",
    "CHIDEA",
    "CHIRIS",
    "CHIUIESTI",
    "CIUBANCA",
    "CIUBANCUTA",
    "CIUCEA",
    "CIULENI",
    "CIUMAFAIA",
    "COASTA",
    "COJOCNA",
    "COMORATA",
    "COMSESTI",
    "COPRU",
    "CORNENI",
    "CORNESTI",
    "CORNESTI (GARBAU)",
    "CORNESTI (MIHAI VITEAZU)",
    "CORPADEA",
    "CORUSU",
    "CRAESTI",
    "CRISENI",
    "CRISTOREL",
    "CUBLESU SOMESAN",
    "CUTCA",
    "DABACA",
    "DAMBURILE",
    "DANCU",
    "DANGAU MARE",
    "DANGAU MIC",
    "DARJA",
    "DAROT",
    "DEALU BOTII",
    "DEALU MARE",
    "DEALU NEGRU",
    "DELENI",
    "DEUSU",
    "DIVICIORII MARI",
    "DIVICIORII MICI",
    "DORNA",
    "DOROLTU",
    "DOSU BRICII",
    "DOSU NAPULUI",
    "DRETEA",
    "DUMBRAVA",
    "ELCIU",
    "FAGETU IERII",
    "FALCA",
    "FANATE",
    "FAURENI",
    "FEIURDENI",
    "FELDIOARA",
    "FILEA DE JOS",
    "FILEA DE SUS",
    "FINCIU",
    "FINISEL",
    "FODORA",
    "FRASINET",
    "FRATA",
    "FUNDATURA",
    "GADALIN",
    "GARBAU",
    "GEACA",
    "GHIRISU ROMAN",
    "GHIROLT",
    "GIULA",
    "GIURCUTA DE JOS",
    "GIURCUTA DE SUS",
    "HAGAU",
    "HODAI-BOIAN",
    "HODAIE",
    "HUCI",
    "HUTA",
    "IACOBENI",
    "IARA",
    "ICLOD",
    "ICLOZEL",
    "IGRITIA",
    "INUCU",
    "IURIU DE CAMPIE",
    "JUC-HERGHELIE",
    "JUCU",
    "JUCU DE MIJLOC",
    "JUCU DE SUS",
    "LACU",
    "LAPUSTESTI",
    "LEGHIA",
    "LEGII",
    "LEURDA",
    "LITA",
    "LITENI",
    "LIVADA (ICLOD)",
    "LIVADA (PETRESTII DE JOS)",
    "LOBODAS",
    "LUJERDIU",
    "LUNA DE JOS",
    "LUNCA BONTULUI",
    "LUNCA VISAGULUI",
    "LUNGESTI",
    "MACAU",
    "MACICASU",
    "MAGOAJA",
    "MAGURA IERII",
    "MAGURI",
    "MAGURI-RACATAU",
    "MAHAL",
    "MANASTIRENI",
    "MANASTURU ROMANESC",
    "MARCESTI",
    "MARGAU",
    "MARISEL",
    "MASCA",
    "MERA",
    "MICESTI",
    "MIHAIESTI",
    "MOARA DE PADURE",
    "MOCIU",
    "MOLDOVENESTI",
    "MORAU",
    "MORLACA",
    "MUNCEL",
    "MUNTELE BAISORII",
    "MUNTELE BOCULUI",
    "MUNTELE CACOVEI",
    "MUNTELE FILII",
    "MUNTELE RECE",
    "MUNTELE SACELULUI",
    "MURESENII DE CAMPIE",
    "NADASELU",
    "NADASU",
    "NAOIU",
    "NASAL",
    "NEGRENI",
    "OAS",
    "OCOLISEL",
    "ORMAN",
    "OSOI",
    "OSORHEL",
    "PADURENII (MINTIU GHERLII)",
    "PADURENII (TRITENII DE JOS)",
    "PAGLISA",
    "PALATCA",
    "PANICENI",
    "PANTICEU",
    "PETEA",
    "PETRESTI",
    "PETRESTII DE JOS",
    "PETRESTII DE MIJLOC",
    "PETRESTII DE SUS",
    "PIETROASA",
    "PLAIESTI",
    "PLAIURI",
    "PLOPI",
    "PLOSCOS",
    "PODENI",
    "POIANA HOREA",
    "POIENI",
    "PRELUCELE",
    "PRUNENI",
    "PRUNI",
    "PUINI",
    "PUSTUTA",
    "RACHITELE",
    "RADAIA",
    "RASCRUCI",
    "RAZBUNENI",
    "RAZOARE(JUD CLUJ)",
    "RECEA-CRISTUR",
    "RISCA",
    "ROGOJEL",
    "ROSIENI",
    "ROTUNDA",
    "SACALAIA",
    "SACEL",
    "SACUIEU",
    "SALISCA",
    "SALISTE",
    "SALISTEA NOUA",
    "SALISTEA VECHE",
    "SAMBOIENI",
    "SAMBOLENI",
    "SANMARTIN",
    "SANPAUL",
    "SANTEJUDE",
    "SANTEJUDE-VALE",
    "SANTIOANA",
    "SARATA",
    "SARDU",
    "SATU LUNG",
    "SAVA",
    "SCRIND-FRASINET",
    "SIC",
    "SIGAU",
    "SILIVAS",
    "SMIDA",
    "SOIMENI",
    "SOMESU CALD",
    "SOMESU RECE",
    "SOPORU DE CAMPIE",
    "STARCU",
    "STEJERIS",
    "STOIANA",
    "STOLNA",
    "STRAJA (CAPUSU MARE)",
    "STRAJA (COJOCNA)",
    "STRAMBU",
    "STRUCUT",
    "SUATU",
    "SUCUTARD",
    "SUMURDUCU",
    "SURDUC",
    "SUTU",
    "TAGA",
    "TARGUSOR",
    "TARNITA",
    "TAUSENI",
    "TICU",
    "TICU-COLONIE",
    "TIOCU DE JOS",
    "TIOCU DE SUS",
    "TIOLTIUR",
    "TOPA MICA",
    "TRANISU",
    "TRITENII DE JOS",
    "TRITENII DE SUS",
    "TRITENII-HOTAR",
    "TUREA",
    "TURMASI",
    "VAIDA-CAMARAS",
    "VALCELELE",
    "VALE",
    "VALEA AGRISULUI",
    "VALEA CASEIELULUI",
    "VALEA CIRESOII",
    "VALEA DRAGANULUI",
    "VALEA GROSILOR",
    "VALEA IERII",
    "VALEA LUI OPRIS",
    "VALENI (CAIANU)",
    "VALENI (CALATELE)",
    "VALISOARA",
    "VANATORI",
    "VECHEA",
    "VISAGU",
    "VISEA",
    "VISTEA",
    "VULTURENI",
    "ZORENII DE VALE"
  ],
  "CONSTANTA": [
    "2 MAI",
    "AGIGEA",
    "ARSA",
    "AURORA",
    "CERNAVODA",
    "CIOBANU",
    "COCHIRLENI",
    "CONSTANTA",
    "CUMPANA",
    "CUZA VODA",
    "DUNAREA",
    "EFORIE",
    "EFORIE NORD",
    "EFORIE SUD",
    "FACLIA",
    "HARSOVA",
    "JUPITER",
    "LAZU",
    "LIMANU",
    "LUMINA",
    "LUMINITA",
    "MAMAIA",
    "MAMAIA-SAT",
    "MANGALIA",
    "MEDGIDIA",
    "MIRCEA VODA",
    "NAVODARI",
    "NAVODARI TABARA",
    "NEPTUN",
    "OLIMP",
    "OVIDIU",
    "PALAZU MARE",
    "SALIGNY",
    "SANATORIUL AGIGEA",
    "SATU NOU (MIRCEA VODA)",
    "SATURN",
    "SEIMENI",
    "SEIMENII MICI",
    "SILISTEA",
    "SIMINOC",
    "STATIUNEA ZOOLOGICA MARINA AGIGEA",
    "STEFAN CEL MARE",
    "VADU OII",
    "VALEA DACILOR",
    "VALU LUI TRAIAN",
    "VAMA VECHE",
    "VENUS",
    "44431",
    "ABRUD",
    "ADAMCLISI",
    "ALBESTI",
    "ALIMAN",
    "ALMALAU",
    "AMZACEA",
    "BALTAGESTI",
    "BANEASA",
    "BARAGANU",
    "BIRUINTA",
    "BREBENI",
    "BUGEAC",
    "CALUGARENI",
    "CANLIA",
    "CAPIDAVA",
    "CARVAN",
    "CASCIOARELE",
    "CASIAN",
    "CASICEA",
    "CASTELU",
    "CERCHEZU",
    "CETATEA",
    "CHEIA",
    "CHIRNOGENI",
    "CIOBANITA",
    "CIOCARLIA",
    "CIOCARLIA DE SUS",
    "CLOSCA",
    "COBADIN",
    "COGEALAC",
    "COMANA",
    "CONACU",
    "CORBU",
    "COROANA",
    "COSLUGEA",
    "COSTINESTI",
    "COTU VAII",
    "CRANGU",
    "CREDINTA",
    "CRISAN",
    "CRUCEA",
    "CUIUGIUC",
    "CULMEA",
    "CURCANI",
    "DARABANI",
    "DELENI",
    "DOBROMIR",
    "DOBROMIRU DIN DEAL",
    "DOROBANTU",
    "DROPIA",
    "DULCESTI",
    "DULGHERU",
    "DUMBRAVENI",
    "DUNARENI",
    "ESECHIOI",
    "FANTANA MARE",
    "FANTANELE",
    "FAUREI",
    "FLORIILE",
    "FURNICA",
    "GALBIORI",
    "GALITA",
    "GARLICIU",
    "GARLITA",
    "GENERAL SCARISOREANU",
    "GHERGHINA",
    "GHINDARESTI",
    "GORUNI",
    "GRADINA",
    "GRANICERU",
    "GURA DOBROGEI",
    "HAGIENI",
    "HATEG",
    "HORIA",
    "INDEPENDENTA",
    "ION CORVIN",
    "ISTRIA",
    "IVRINEZU MARE",
    "IVRINEZU MIC",
    "IZVOARELE",
    "IZVORU MARE",
    "LANURILE",
    "LESPEZI",
    "LIPNITA",
    "MAGURA",
    "MERENI",
    "MIHAI VITEAZU",
    "MIHAIL KOGALNICEANU",
    "MIORITA",
    "MIREASA",
    "MIRISTEA",
    "MOSNENI",
    "MOVILA VERDE",
    "MOVILITA",
    "MURFATLAR",
    "NAZARCEA",
    "NEGRESTI",
    "NEGRU VODA",
    "NEGURENI",
    "NICOLAE BALCESCU",
    "NISIPARI",
    "NISTORESTI",
    "NUNTASI",
    "OITUZ",
    "OLTENI",
    "OLTINA",
    "OSMANCEA",
    "OSTROV",
    "PADURENI",
    "PALAZU MIC",
    "PANTELIMON",
    "PANTELIMON DE JOS",
    "PECINEAGA",
    "PELINU",
    "PESTERA",
    "PETROSANI",
    "PIATRA",
    "PIETRENI",
    "PLOPENI",
    "POARTA ALBA",
    "POIANA",
    "POTARNICHEA",
    "RAMNICU DE JOS",
    "RAMNICU DE SUS",
    "RARISTEA",
    "RASOVA",
    "RAZOARELE",
    "REMUS OPREANU",
    "RUNCU",
    "SACELE",
    "SARAIU",
    "SATU NOU (OLTINA)",
    "SCHITU",
    "SIBIOARA",
    "SINOIE",
    "SIPOTELE",
    "SIRIU",
    "STEJARU",
    "STRAJA",
    "STRUNGA",
    "STUPINA",
    "TARGUSOR",
    "TARIVERDE",
    "TATARU",
    "TECHIRGHIOL",
    "TEPES VODA",
    "TIBRINU",
    "TICHILESTI",
    "TOPALU",
    "TOPRAISAR",
    "TORTOMAN",
    "TRAIAN",
    "TUDOR VLADIMIRESCU",
    "TUFANI",
    "TUZLA",
    "URLUIA",
    "VADU",
    "VALCELELE",
    "VALENI",
    "VANATORI",
    "VARTOP",
    "VETERANU",
    "VIILE",
    "VIISOARA",
    "VIROAGA",
    "VLAHII",
    "VULTURU",
    "ZORILE"
  ],
  "COVASNA": [
    "ALBIS",
    "ALUNGENI",
    "ARACI",
    "ARCUS",
    "BODOC",
    "CASINU MIC",
    "CATALINA",
    "CERNAT",
    "CHICHIS",
    "CHILIENI",
    "COSENI",
    "ILIENI",
    "LISNAU",
    "LUNGA",
    "MOACSA",
    "OZUN",
    "PETRICENI",
    "POIAN",
    "RECI",
    "SANCRAIU",
    "SANTIONLUNCA",
    "SANZIENI",
    "SFANTU GHEORGHE",
    "TARGU SECUIESC",
    "TINOASA",
    "TURIA",
    "VALCELE",
    "VALEA CRISULUI",
    "VALEA SEACA",
    "AITA MARE",
    "AITA MEDIE",
    "AITA SEACA",
    "ANGHELUS",
    "ANINOASA",
    "ARIUSD",
    "BACEL",
    "BAILE BALVANYOS",
    "BARAOLT",
    "BARCANI",
    "BATANI",
    "BATANII MARI",
    "BATANII MICI",
    "BELANI",
    "BELIN",
    "BELIN-VALE",
    "BIBORTENI",
    "BICFALAU",
    "BITA",
    "BIXAD",
    "BODOS",
    "BOROSNEU MARE",
    "BOROSNEU MIC",
    "BRADET",
    "BRADUT",
    "BRATES",
    "BRETCU",
    "CALNIC",
    "CAPENI",
    "CARPINENII",
    "CHIURUS",
    "COMANDAU",
    "COVASNA",
    "CRASNA",
    "DALNIC",
    "DOBARLAU",
    "DOBOLII DE JOS",
    "DOBOLII DE SUS",
    "DOBOSENI",
    "ESTELNIC",
    "FILIA",
    "FLOROAIA",
    "FOTOS",
    "GHELINTA",
    "GHIDFALAU",
    "HAGHIG",
    "HARALE",
    "HATUICA",
    "HERCULIAN",
    "HETEA",
    "HILIB",
    "IARAS",
    "ICAFALAU",
    "IMENI",
    "INTORSURA BUZAULUI",
    "LADAUTI",
    "LEMNIA",
    "LET",
    "LISNAU-VALE",
    "LUNCA MARCUSULUI",
    "LUNCA OZUNULUI",
    "LUTOASA",
    "MAGHERUS",
    "MALNAS",
    "MALNAS-BAI",
    "MARCUS",
    "MARCUSA",
    "MARTANUS",
    "MARTINENI",
    "MERENI",
    "MERISOR",
    "MICFALAU",
    "MICLOSOARA",
    "OITUZ",
    "OJDULA",
    "OLTENI",
    "OZUNCA-BAI",
    "PACHIA",
    "PADURENI",
    "PAPAUTI",
    "PETENI",
    "RACOSUL DE SUS",
    "SACIOVA",
    "SARAMAS",
    "SCRADOASA",
    "SITA BUZAULUI",
    "SURCEA",
    "TALISOARA",
    "TAMASFALAU",
    "TELECHIA",
    "TUFALAU",
    "VALEA DOBARLAULUI",
    "VALEA MARE",
    "VALEA MICA",
    "VALEA SCURTA",
    "VALEA ZALANULUI",
    "VARGHIS",
    "ZABALA",
    "ZABRATAU",
    "ZAGON",
    "ZALAN",
    "ZOLTAN"
  ],
  "DAMBOVITA": [
    "ADANCA",
    "ANINOASA",
    "ANINOSANI",
    "BADULESTI",
    "BALENI",
    "BALENI-ROMANI",
    "BALENI-SARBI",
    "BARACENI",
    "BELA",
    "BEREVOESTI",
    "BOBOCI",
    "BOLOVANI",
    "BRANESTI",
    "BRATESTII DE JOS",
    "BROSTENI (PRODULESTI)",
    "BUCSANI",
    "BUNGETU",
    "BURDUCA",
    "CALUGARENI (CONTESTI)",
    "CAPRIORU",
    "CATANELE",
    "CATUNU (SALCIOARA)",
    "CAZACI",
    "CHIRCA",
    "CIOCANESTI",
    "COCANI",
    "COLANU",
    "COMISANI",
    "COSTESTII DIN DEAL",
    "CRACIUNESTI",
    "CRANGURILE",
    "CRANGURILE DE JOS",
    "CRANGURILE DE SUS",
    "CREVEDIA",
    "CUCUTENI",
    "CUZA VODA",
    "DARZA",
    "DECINDENI",
    "DIACONESTI",
    "DIMOIU",
    "DOBRA",
    "DOICESTI",
    "DRAGAESTI-PAMANTENI",
    "DRAGAESTI-UNGURENI",
    "DRAGODANA",
    "DRAGODANESTI",
    "DRAGOMIRESTI",
    "DUMBRAVA",
    "FIENI",
    "GAESTI",
    "GEANGOESTI",
    "GHEBOIENI",
    "GLODENI",
    "GLODENI (PUCIOASA)",
    "GORGOTA",
    "GULIA",
    "GURA OCNITEI",
    "GURA VULCANEI",
    "GUSOIU",
    "HABENI",
    "HELESTEU",
    "I. L. CARAGIALE",
    "ILFOVENI",
    "IONESTI",
    "IZVOARELE",
    "LACULETE",
    "LACULETE-GARA",
    "LAZURI",
    "LIVEZILE (GLODENI)",
    "LUCIANCA",
    "LUNCA (MOROENI)",
    "MALU MIERII",
    "MALURILE",
    "MANASTIREA (COBIA)",
    "MANASTIREA (CREVEDIA)",
    "MANESTI",
    "MARCESTI",
    "MATASARU",
    "MATRACA",
    "MERII",
    "MIJA",
    "MIRCEA VODA",
    "MOTAIENI",
    "MOVILA (NICULESTI)",
    "NISIPURILE",
    "NUCET",
    "OCHIURI",
    "OCNITA",
    "ODAIA TURCULUI",
    "OLTENI (LUCIENI)",
    "PERSINARI",
    "PETRESTI",
    "PICIOR DE MUNTE",
    "POROINICA",
    "PRIBOIU (BRANESTI)",
    "PRIBOIU (TATARANI)",
    "PRISEACA",
    "PRODULESTI",
    "PUCIOASA",
    "PUCIOASA-SAT",
    "PUTU CU SALCIE",
    "RACOVITA",
    "RANCACIOV",
    "RATESTI",
    "RATOAIA",
    "RAZVAD",
    "SACUENI",
    "SAMURCASI",
    "SATENI",
    "SERBANEASA",
    "SILISTEA (LUCIENI)",
    "SOTANGA",
    "STRAOSTI",
    "TARGOVISTE",
    "TARTASESTI",
    "TATARANI",
    "TEIS",
    "TETCOIU",
    "TITU",
    "TOCULESTI",
    "UDRESTI",
    "ULMI",
    "UNGURENI (DRAGOMIRESTI)",
    "VACARESTI",
    "VALEA VOIEVOZILOR",
    "VIFORATA",
    "VIISOARA",
    "VULCANA-PANDELE",
    "ALUNIS",
    "ALUNISU",
    "BACESTI",
    "BADENI",
    "BALANESTI",
    "BALDANA",
    "BALTENI",
    "BANESTI",
    "BARBUCEANU",
    "BARBULETU",
    "BECHINESTI",
    "BEZDEAD",
    "BILCIURESTI",
    "BLIDARI",
    "BOTENI",
    "BRADATEL",
    "BRANCOVEANU",
    "BRANISTEA",
    "BREBU",
    "BREZOAELE",
    "BREZOAIA",
    "BROSTENI (BEZDEAD)",
    "BROSTENI (VISINA)",
    "BUCIUMENI",
    "BUJOREANCA",
    "BUMBUIA",
    "BUTIMANU",
    "BUTOIU DE JOS",
    "BUTOIU DE SUS",
    "CALUGARENI (COBIA)",
    "CANDESTI",
    "CANDESTI-DEAL",
    "CANDESTI-VALE",
    "CAPSUNA",
    "CAPU COASTEI",
    "CARLANESTI",
    "CATUNU (CORNESTI)",
    "CETATUIA",
    "CIOCANARI",
    "COADA IZVORULUI",
    "COBIA",
    "COBIUTA",
    "COJASCA",
    "COJOCARU",
    "COJOIU",
    "COLACU",
    "COLIBASI",
    "CONTESTI",
    "COPACENI",
    "CORBII MARI",
    "CORNATELU",
    "CORNESTI",
    "CORNI",
    "COSTESTI",
    "COSTESTII DIN VALE",
    "COSTISATA",
    "CRANGASI",
    "CRETU",
    "CRETULESTI",
    "CRICOVU DULCE",
    "CRISTEASCA",
    "CRIVATU",
    "CROITORI",
    "CROVU",
    "CUPARU",
    "DAMBOVICIOARA",
    "DARMANESTI",
    "DEALU FRUMOS",
    "DEALU MARE",
    "DECINDEA",
    "DOBRESTI",
    "DOSPINESTI",
    "DUPA DEAL",
    "FAGETU",
    "FANTANELE",
    "FERESTRE",
    "FETENI",
    "FIERBINTI",
    "FINTA",
    "FINTA MARE",
    "FINTA VECHE",
    "FRASIN-DEAL",
    "FRASINU",
    "FRASIN-VALE",
    "FUSEA",
    "GAMANESTI",
    "GARLENI",
    "GEMENEA-BRATULESTI",
    "GHEBOAIA",
    "GHERGANI",
    "GHERGHESTI",
    "GHERGHITESTI",
    "GHIMPATI",
    "GHINESTI",
    "GHIRDOVENI",
    "GLOD",
    "GLOGOVEANU",
    "GRECI",
    "GROZAVESTI",
    "GURA BARBULETULUI",
    "GURA FOII",
    "GURA SUTII",
    "HAGIOAICA",
    "HANU LUI PALA",
    "HODARASTI",
    "HULUBESTI",
    "IAZU",
    "IBRIANU",
    "IEDERA",
    "IEDERA DE JOS",
    "IEDERA DE SUS",
    "IZVORU (VALEA LUNGA)",
    "IZVORU (VISINA)",
    "JUGURENI",
    "LIVEZILE (VALEA MARE)",
    "LUCIENI",
    "LUDESTI",
    "LUNCA (VOINESTI)",
    "LUNGULETU",
    "MAGURA (BEZDEAD)",
    "MAGURA (HULUBESTI)",
    "MALU CU FLORI",
    "MANASTIOARA",
    "MANGA",
    "MANJINA",
    "MARGINENII DE SUS",
    "MARUNTISU",
    "MAVRODIN",
    "MEISOARE",
    "MERENI (CONTESTI)",
    "MERENI (TITU)",
    "MERISORU",
    "MESTEACAN",
    "MICLOSANII MARI",
    "MICLOSANII MICI",
    "MICULESTI",
    "MILOSARI",
    "MISLEA",
    "MIULESTI",
    "MOARA DIN GROAPA",
    "MOARA NOUA",
    "MOGOSANI",
    "MOGOSESTI",
    "MORENI",
    "MOROENI",
    "MORTENI",
    "MOSIA MICA",
    "MOVILA (SALCIOARA)",
    "MUSCEL",
    "NEAJLOVU",
    "NICOLAESTI",
    "NICULESTI",
    "ODOBESTI",
    "OLTENI (ULIESTI)",
    "ONCESTI",
    "OREASCA",
    "PADURENI",
    "PATROAIA-DEAL",
    "PATROAIA-VALE",
    "PETRESTI (CORBII MARI)",
    "PIATRA",
    "PIETRARI",
    "PIETROSITA",
    "PITARU",
    "PLOPU",
    "PODU CORBENCII",
    "PODU CRISTINII",
    "PODU RIZII",
    "POIANA",
    "POIENITA",
    "POSTARNACU",
    "POTLOGENI-DEAL",
    "POTLOGENI-VALE",
    "POTLOGI",
    "POTOCELU",
    "PUCHENI",
    "PUCHENI (MOROENI)",
    "PUNTEA DE GRECI",
    "RACARI",
    "RACIU",
    "RAGU",
    "RASCAETI",
    "RAU ALB",
    "RAU ALB DE JOS",
    "RAU ALB DE SUS",
    "ROMANESTI",
    "RUNCU",
    "SABIESTI",
    "SALCIOARA",
    "SALCIOARA (MATASARU)",
    "SALCUTA",
    "SARU",
    "SATU NOU",
    "SAVESTI",
    "SCHEIU DE JOS",
    "SCHEIU DE SUS",
    "SCHELA",
    "SELARU",
    "SERDANU",
    "SILISTEA (RUNCU)",
    "SIPOT",
    "SLOBOZIA",
    "SLOBOZIA MOARA",
    "SPERIETENI",
    "STANESTI",
    "STATESTI",
    "STAVROPOLIA",
    "STRATONESTI",
    "STUBEIE TISA",
    "SUDULENI",
    "SULTANU",
    "SUSENI-SOCETU",
    "SUTA SEACA",
    "SUVITA",
    "TELESTI",
    "TOMSANI",
    "TUNARI",
    "ULIESTI",
    "ULMETU",
    "UNGURENI (BUTIMANU)",
    "UNGURENI (CORBII MARI)",
    "UNGURENI (CORNESTI)",
    "URSEIU",
    "URZICEANCA",
    "VADU STANCHII",
    "VALEA",
    "VALEA CASELOR",
    "VALEA DADEI",
    "VALEA LARGA",
    "VALEA LEURZII",
    "VALEA LUI DAN",
    "VALEA LUNGA",
    "VALEA LUNGA-CRICOV",
    "VALEA LUNGA-GORGOTA",
    "VALEA LUNGA-OGREA",
    "VALEA MARE",
    "VALEA MARE (CANDESTI)",
    "VALEA MARE (VALEA LUNGA)",
    "VALEA MORII",
    "VALENI-DAMBOVITA",
    "VARFURENI",
    "VARFURI",
    "VISINA",
    "VISINESTI",
    "VIZURESTI",
    "VLADENI",
    "VLASCENI",
    "VOIA",
    "VOINESTI",
    "VULCANA DE SUS",
    "VULCANA-BAI",
    "VULTUREANCA",
    "ZAVOIU",
    "ZIDURILE"
  ],
  "DOLJ": [
    "ALBESTI",
    "ALMAJ",
    "BADOSI",
    "BAILESTI",
    "BALTA",
    "BALTA VERDE",
    "BASARABI",
    "BECHET (ORODEL)",
    "BEHARCA",
    "BOGEA",
    "BOJOIU",
    "BRADESTI",
    "BRALOSTITA",
    "BRANISTE (FILIASI)",
    "BRANISTE (PODARI)",
    "BRATOVOESTI",
    "BREASTA",
    "BUCOVAT",
    "CALAFAT",
    "CALOPAR",
    "CARCEA",
    "CARLIGEI",
    "CETATUIA (CIOROIASI)",
    "CIUPERCENII NOI",
    "CIUPERCENII VECHI",
    "COSERENI",
    "COSOVENI",
    "COTU",
    "CRAIOVA",
    "CRIVA",
    "CROVNA",
    "DABULENI",
    "DELENI",
    "DESA",
    "DIOSTI",
    "DUDOVICESTI",
    "DUTULESTI",
    "FACAI",
    "FAGET",
    "FILIASI",
    "FLORESTI",
    "FRATOSTITA",
    "GARLESTI",
    "GHERCESTI",
    "GHINDENI",
    "GHIZDAVESTI",
    "GOGOSU",
    "GOIESTI",
    "GOLENTI",
    "GURA VAII",
    "HUNIA",
    "ISALNITA",
    "ITALIENI",
    "IZVOR",
    "JIENI",
    "JIUL",
    "LACRITA MARE",
    "LACRITA MICA",
    "LANGA",
    "LAZU",
    "LEAMNA DE JOS",
    "LEAMNA DE SUS",
    "LESILE",
    "LIVEZI",
    "LUNCSORU",
    "MAGLAVIT",
    "MALAESTI",
    "MALU MARE",
    "MAROTINU DE JOS",
    "MIHAITA",
    "MILESTI",
    "MILOVAN",
    "MISCHII",
    "MLECANESTI",
    "MOFLENI",
    "MOGOSESTI",
    "MORENI",
    "MOTOCI",
    "NEGOIESTI",
    "OBEDIN",
    "OHABA",
    "PALILULA",
    "PIELESTI",
    "PISCU VECHI",
    "PISCULET",
    "PODARI",
    "POIANA MARE",
    "POPANZALESTI",
    "POPOVENI",
    "POTMELTU",
    "PREAJBA",
    "PREAJBA DE JOS",
    "PRUNET",
    "RACARII DE JOS",
    "RACARII DE SUS",
    "RADOMIR",
    "RADOVAN",
    "ROBANESTI",
    "ROBANESTII DE JOS",
    "ROBANESTII DE SUS",
    "ROMANESTI",
    "ROSIENI",
    "SARBATOAREA",
    "SCAESTI",
    "SCHITU",
    "SECUI",
    "SFIRCEA",
    "SIMNICU DE JOS",
    "SIMNICU DE SUS",
    "SMARDAN",
    "TATOMIRESTI",
    "TEASC",
    "TUGLUI",
    "TUNARII VECHI",
    "UNGURENI",
    "UNGURENII MICI",
    "URECHESTI",
    "USCACI",
    "VALEA LUI PATRU",
    "VALEA LUNGULUI",
    "VLADIMIR",
    "ADANCATA",
    "AFUMATI",
    "ALMAJEL",
    "AMARASTI",
    "AMARASTII DE JOS",
    "AMARASTII DE SUS",
    "APELE VII",
    "ARGETOAIA",
    "BALASAN",
    "BALOTA DE JOS",
    "BALOTA DE SUS",
    "BARANU",
    "BARBOI",
    "BARCA",
    "BASCOV",
    "BAZDANA",
    "BECHET",
    "BELCINU",
    "BELOT",
    "BENESTI",
    "BERBESU",
    "BISTRET",
    "BISTRETU NOU",
    "BOBEANU",
    "BODAIESTI",
    "BODAIESTII DE SUS",
    "BOOVENI",
    "BOTOSESTI-PAIA",
    "BOURENI",
    "BRABETI",
    "BRABOVA",
    "BRADESTII BATRANI",
    "BRANDUSA",
    "BRANISTE (DANETI)",
    "BUCICANI",
    "BUCOVICIOR",
    "BUJOR",
    "BULZESTI",
    "BUSTENI",
    "BUSU",
    "BUSULETU",
    "BUZDUC",
    "CACIULATESTI",
    "CACIULATU",
    "CALARASI",
    "CALINESTI",
    "CALUGAREI",
    "CAMPENI",
    "CARAIMAN",
    "CARAULA",
    "CARNA",
    "CARPEN",
    "CARSTOVANI",
    "CARUIA",
    "CASTRANOVA",
    "CASTRELE TRAIANE",
    "CATANE",
    "CATANELE NOI",
    "CELARU",
    "CERAT",
    "CERNAT",
    "CERNATESTI",
    "CETATE",
    "CETATUIA (VELA)",
    "CHIASU",
    "CIOCANELE",
    "CIOCANESTI(CARACAL)",
    "CIOROIASI",
    "CIOROIU NOU",
    "CIUTURA",
    "CLEANOV",
    "COMANICEA",
    "COMOSTENI",
    "CORLATE",
    "CORNETU",
    "CORNITA",
    "CORNU",
    "COTOFENII DIN DOS",
    "COTOFENII DIN FATA",
    "COVEI",
    "CURMATURA",
    "DANETI",
    "DESNATUI",
    "DOBRESTI",
    "DOBRIDOR",
    "DOBROMIRA",
    "DOBROTESTI",
    "DOMNU TUDOR",
    "DRAGOAIA",
    "DRAGOTESTI",
    "DRANIC",
    "FANTANELE (RADOVAN)",
    "FANTANELE (TESLUI)",
    "FANTANI",
    "FARCAS",
    "FILARET",
    "FLORAN",
    "FOISOR",
    "FRASIN",
    "FRATILA",
    "GABRU",
    "GAIA",
    "GALICEA MARE",
    "GALICIUICA",
    "GANGIOVA",
    "GEBLESTI",
    "GEOROCEL",
    "GEOROCU MARE",
    "GHIDICI",
    "GIGHERA",
    "GIUBEGA",
    "GIURGITA",
    "GODENI",
    "GOGOSESTI",
    "GOGOSITA",
    "GOICEA",
    "GOLFIN",
    "GOLUMBELU",
    "GOLUMBU",
    "GRADISTEA",
    "GRECESTI",
    "GROPANELE",
    "GRUITA",
    "GUBAUCEA",
    "GURA RACULUI",
    "HOREZU-POENARI",
    "INFRATIREA",
    "INTORSURA",
    "IORDACHESTI",
    "IZVOARE",
    "LEORDOASA",
    "LEU",
    "LIPOVU",
    "LIPOVU DE SUS",
    "LISTEAVA",
    "LOCUSTENI",
    "MACESU DE JOS",
    "MACESU DE SUS",
    "MALAICA",
    "MALUMNIC",
    "MARACINELE",
    "MAROTINU DE SUS",
    "MARSANI",
    "MARZA",
    "MELINESTI",
    "METEU",
    "MOFLESTI",
    "MOSNA",
    "MOSNENI",
    "MOTATEI",
    "MOTATEI-GARA",
    "MUERENI",
    "MUIERUSU",
    "MURGASI",
    "MURTA",
    "NEDEIA",
    "NEGOI",
    "NISIPURI",
    "NISTOI",
    "NOVAC",
    "OCOLNA",
    "ODOLENI",
    "ORODEL",
    "OSTROVENI",
    "PADEA",
    "PANAGHIA",
    "PERENI",
    "PERISOR",
    "PICATURILE",
    "PIETROAIA",
    "PIORESTI",
    "PIRIA",
    "PISCANI",
    "PISCU LUNG",
    "PISCU NOU",
    "PISCU SADOVEI",
    "PLENITA",
    "PLESOI",
    "PLOPSOR",
    "PLOPU-AMARASTI",
    "PLOSCA",
    "PLOSTINA",
    "POIANA FANTANII",
    "POIENILE",
    "POMETESTI",
    "POPEASA",
    "POPESTI",
    "PRAPOR",
    "PREAJBA DE PADURE",
    "PREDESTI",
    "PREDESTII MICI",
    "PREJOI",
    "PUTINEI",
    "PUTURI",
    "RACHITA DE JOS",
    "RACHITA DE SUS",
    "RACOVITA",
    "RASNICU BATRAN",
    "RASNICU OGHIAN",
    "RAST",
    "ROJISTE",
    "RUPTURILE",
    "SADOVA",
    "SALCIA",
    "SALCUTA",
    "SALCUTA (CALOPAR)",
    "SALISTE",
    "SAPATA",
    "SARATA",
    "SARSCA",
    "SEACA DE CAMP",
    "SEACA DE PADURE",
    "SECU",
    "SECULESTI",
    "SEGARCEA",
    "SEGLET",
    "SILISTEA CRUCII",
    "SITOAIA",
    "SMADOVICIOARA DE SECU",
    "SOCENI",
    "SOPOT",
    "SORENI",
    "SPINENI",
    "STEFANEL",
    "STIUBEI",
    "STOICESTI",
    "SUHARU",
    "SUMANDRA",
    "TALPAS",
    "TAMBURESTI",
    "TANDARA",
    "TARNAVA",
    "TARTAL",
    "TEASCU DIN DEAL",
    "TEIU",
    "TENCANAU",
    "TERPEZITA",
    "TESLUI",
    "TIU",
    "TOCENI",
    "TUNARII NOI",
    "UNIREA",
    "URDINITA",
    "URIENI",
    "URSOAIA",
    "URZICA MARE",
    "URZICUTA",
    "VALEA FANTANILOR",
    "VALEA MARE",
    "VALEA MUIERII DE JOS",
    "VALEA STANCIULUI",
    "VARTOP",
    "VARVOR",
    "VARVORU DE JOS",
    "VELA",
    "VELENI",
    "VELESTI",
    "VERBICIOARA",
    "VERBITA",
    "VIISOARA",
    "VIISOARA-MOSNENI",
    "VOITA",
    "ZANOAGA (DOLJ)",
    "ZAVAL",
    "ZLATARI",
    "ZVORSCA"
  ],
  "GALATI": [
    "BALTARETI",
    "CISMELE",
    "COSMESTI",
    "COSTI",
    "DOBRINESTI",
    "DRAGANESTI",
    "FRUNZEASCA",
    "FURCENII NOI",
    "FURCENII VECHI",
    "GALATI",
    "MALU ALB",
    "MOVILENI",
    "MOVILENI (SENDRENI)",
    "MUNTENI",
    "NICORESTI",
    "SARBI",
    "SENDRENI",
    "SMARDAN",
    "TECUCI",
    "TIGANESTI",
    "UNGURENI",
    "VANATORI",
    "ADAM",
    "ALDESTI",
    "BALABANESTI",
    "BALASESTI",
    "BALENI",
    "BALINTESTI",
    "BANEASA",
    "BARCEA",
    "BERESTI",
    "BERESTI-MERIA",
    "BLANZI",
    "BRAHASESTI",
    "BRANESTI",
    "BRANISTEA",
    "BRANISTEA (NICORESTI)",
    "BRATULESTI",
    "BUCESTI",
    "BUCIUMENI",
    "BURSUCANI",
    "CALMATUI",
    "CARAPCESTI",
    "CARLOMANESTI",
    "CAUIESTI",
    "CAVADINESTI",
    "CERTESTI",
    "CHIRAFTEI",
    "CIORASTI",
    "CIURESTI",
    "CIURESTII NOI",
    "COASTA LUPEI",
    "COMANESTI",
    "CONDREA",
    "CORCIOVENI",
    "CORNI",
    "COROD",
    "COSITENI",
    "COSMESTII-VALE",
    "COSTACHE NEGRI",
    "COTOROAIA",
    "CRAIESTI",
    "CRANGENI",
    "CRUCEANU",
    "CUCA",
    "CUDALBI",
    "CUZA VODA",
    "DRAGUSENI",
    "FANTANELE",
    "FANTANI",
    "FARTANESTI",
    "FOLTESTI",
    "FRUMUSITA",
    "FUNDEANU",
    "FUNDENI",
    "FUNDENII NOI",
    "GANESTI",
    "GARA BERHECI",
    "GARA GHIDIGENI",
    "GARBOVAT",
    "GEFU",
    "GHIDIGENI",
    "GHINGHESTI",
    "GOHOR",
    "GRIVITA",
    "GROZAVESTI",
    "GURA GARBOVATULUI",
    "HANTESTI",
    "HANU CONACHI",
    "HUSTIU",
    "IJDILENI",
    "INDEPENDENTA",
    "IONASESTI",
    "IREASCA",
    "IVESTI",
    "IZVOARELE",
    "JORASTI",
    "LIESTI",
    "LIESTI (PRIPONESTI)",
    "LOZOVA",
    "LUNCA",
    "LUNGESTI",
    "LUNGOCI",
    "LUPELE",
    "MACISENI",
    "MALURENI",
    "MANDRESTI",
    "MASTACANI",
    "MATCA",
    "MIHAIL KOGALNICEANU",
    "MOSCU",
    "NAMOLOASA",
    "NAMOLOASA-SAT",
    "NARTESTI",
    "NEGREA",
    "NEGRILESTI",
    "NICOPOLE",
    "OANCEA",
    "ODAIA MANOLACHE",
    "ONCIU",
    "PECHEA",
    "PISCU",
    "PISCU CORBULUI",
    "PLESA",
    "PLEVNA",
    "PODOLENI",
    "POIANA",
    "POSTA",
    "PRIPONESTI",
    "PRIPONESTII DE JOS",
    "PRODANESTI",
    "PUPEZENI",
    "PURICANI",
    "RADESTI",
    "REDIU",
    "ROGOJENI",
    "ROSCANI",
    "SALCIA",
    "SASENI",
    "SATU NOU",
    "SCANTEIESTI",
    "SCHELA",
    "SERBESTII VECHI",
    "SILISTEA",
    "SIPOTE",
    "SIVITA",
    "SLIVNA",
    "SLOBOZIA BLANEASA",
    "SLOBOZIA CONACHI",
    "SLOBOZIA CORNI",
    "SLOBOZIA OANCEA",
    "SMULTI",
    "STIETESTI",
    "STOICANI",
    "SUCEVENI",
    "SUHURLUI",
    "TALPIGI",
    "TAMAOANI",
    "TAPLAU",
    "TARGU BUJOR",
    "TATARCA",
    "TECUCELU SEC",
    "TEPU",
    "TEPU DE SUS",
    "TOFLEA",
    "TORCESTI",
    "TRAIAN",
    "TUDOR VLADIMIRESCU",
    "TULUCESTI",
    "UMBRARESTI",
    "UMBRARESTI (TARGU BUJOR)",
    "UMBRARESTI-DEAL",
    "URLESTI",
    "VADENI",
    "VALEA MARULUI",
    "VAMES",
    "VARLEZI",
    "VASILE ALECSANDRI",
    "VIILE",
    "VISINA",
    "VIZURESTI",
    "VLADESTI",
    "ZARNESTI",
    "ZIMBRU"
  ],
  "GIURGIU": [
    "ADUNATII-COPACENI",
    "ANGHELESTI",
    "BACU",
    "BALANU",
    "BOLINTIN-DEAL",
    "BOLINTIN-VALE",
    "BRANISTEA",
    "BUCSANI",
    "BULBUCATA",
    "BUTURUGENI",
    "CARPENISU",
    "CARTOJANI",
    "CASCIOARELE (GAISENI)",
    "CASCIOARELE (ULMI)",
    "CETATEA",
    "CLEJANI",
    "COMASCA",
    "CORBEANCA (GIURGIU)",
    "COSOBA",
    "CREVEDIA MARE",
    "CREVEDIA MICA",
    "CRIVINA",
    "DAIA",
    "DARASTI-VLASCA",
    "DEALU",
    "DIMITRIE CANTEMIR",
    "DOBRENI",
    "DRAGANEASCA",
    "DRAGANESCU",
    "DRAGHICEANU",
    "FLORESTI",
    "FLORESTI-STOENESTI",
    "FRASINU",
    "FRATESTI",
    "GAISEANCA",
    "GAISENI",
    "GHIONEA",
    "GIURGIU",
    "GOLEASCA",
    "GRADINARI",
    "HOBAIA",
    "HODIVOAIA",
    "ICOANA",
    "IEPURESTI",
    "IZVORU (VANATORII MICI)",
    "JOITA",
    "LETCA NOUA",
    "MALU SPART",
    "MIHAI VODA",
    "MIHAILESTI",
    "MOGOSESTI",
    "MOSTENI",
    "OBEDENI",
    "OGREZENI",
    "OINACU",
    "ONCESTI",
    "PADURENI",
    "PALANCA",
    "PETRU RARES",
    "PLOPSORU",
    "PODU DOAMNEI",
    "PODU ILFOVATULUI",
    "POENARI",
    "POIANA LUI STANGA",
    "POPESTI",
    "POSTA",
    "RALESTI",
    "REMUS",
    "ROATA DE JOS",
    "SABARENI",
    "SINGURENI",
    "SLOBOZIA",
    "TANTAVA",
    "TRESTIENI",
    "UIESTI",
    "ULMI",
    "VADU LAT",
    "VALEA PLOPILOR",
    "VANATORII MARI",
    "VANATORII MICI",
    "VARLAAM",
    "VEDEA",
    "ZADARICIU",
    "ZORILE",
    "BANEASA",
    "BANESTI",
    "BILA",
    "BRANISTARI",
    "BUDENI",
    "CALUGARENI",
    "CAMINEASCA",
    "CAMPURELU",
    "CARAPANCEA",
    "CETATUIA",
    "CHIRCULESTI",
    "CHIRIACU",
    "COLIBASI",
    "COMANA",
    "COPACIU",
    "COTENI",
    "CRANGURI",
    "CRUCEA DE PIATRA",
    "CUCURUZU",
    "CUPELE",
    "FACAU",
    "FALASTOACA",
    "GAUJANI",
    "GHIMPATI",
    "GHIZDARU",
    "GOGOSARI",
    "GORNENI",
    "GOSTINARI",
    "GOSTINU",
    "GRADISTEA",
    "GREACA",
    "HERASTI",
    "HOTARELE",
    "HULUBESTI",
    "IANCULESTI",
    "ISVOARELE (HOTARELE)",
    "IZVOARELE",
    "IZVORU (GOGOSARI)",
    "LETCA VECHE",
    "MALU",
    "MARSA",
    "MIHAI BRAVU",
    "MILCOVATU",
    "MILOSESTI",
    "MIRAU",
    "MIRONESTI",
    "NAIPU",
    "NEAJLOVU",
    "NOVACI",
    "PIETRELE",
    "PIETRISU",
    "PODISOR",
    "PODU POPA NAE",
    "PRIBOIU",
    "PRUNDU",
    "PUIENI",
    "PUTINEIU",
    "PUTU GRECI",
    "RADU VODA",
    "RASUCENI",
    "ROATA MICA",
    "SADINA",
    "SATU NOU",
    "SCHITU",
    "SFANTU GHEORGHE (BANEASA)",
    "SFANTU GHEORGHE (CREVEDIA MARE)",
    "STALPU",
    "STANESTI",
    "STEJARU",
    "STEREA",
    "STOENESTI",
    "STOENESTI (FLORESTI-STOENESTI)",
    "SUSENI",
    "TEISORI",
    "TEIUSU",
    "TOMULESTI",
    "TOPORU",
    "UZUNU",
    "VALCELELE",
    "VALEA BUJORULUI",
    "VALEA DRAGULUI",
    "VALTER MARACINEANU",
    "VARASTI",
    "VIERU",
    "VLAD TEPES",
    "VLASIN",
    "ZBOIU"
  ],
  "GORJ": [
    "ALEXENI",
    "ARJOCI",
    "ARSENI",
    "ARSURI",
    "BALANESTI",
    "BALANI",
    "BALESTI",
    "BALTISOARA",
    "BARCACIU",
    "BARSESTI",
    "BARZA",
    "BLAHNITA DE JOS",
    "BLAHNITA DE SUS",
    "BOBU",
    "BOCA",
    "BOROSTENI",
    "BOTOROGI",
    "BRANESTI",
    "BUCUREASA",
    "BUDIENI",
    "BUDUHALA",
    "BUMBESTI-JIU",
    "CALESTI",
    "CALNICU DE SUS",
    "CANEPESTI",
    "CARBESTI",
    "CARTIU",
    "CATUNELE",
    "CEAURU",
    "CERATU DE COPACIOASA",
    "CLESNESTI",
    "COPACIOASA",
    "CORNESTI",
    "CURPEN",
    "CURTISOARA",
    "DAMBOVA",
    "DANESTI",
    "DEALU POMILOR",
    "DOBRITA",
    "DRAGOENI",
    "DRAGUTESTI",
    "FRANCESTI",
    "FRATESTI",
    "GALESOAIA",
    "GAMANI",
    "GLOGOVA",
    "GORNACEL",
    "GRUI",
    "HORASTI",
    "HOREZU",
    "IASI-GORJ",
    "IEZURENI",
    "INSURATEI",
    "IORMANESTI",
    "LAZARESTI",
    "LAZURI",
    "LELESTI",
    "LEURDA",
    "LINTEA",
    "LUPOAIA",
    "LUPOITA",
    "MAGHERESTI",
    "MAZAROI",
    "MERFULESTI",
    "MICULESTI",
    "MOGOSANI",
    "MOTRU",
    "NOVACI",
    "OBARSIA",
    "OBREJA",
    "OHABA",
    "OLTEANU",
    "PAJISTELE",
    "PARAU",
    "PETRESTI",
    "PISTESTII DIN DEAL",
    "PLOSTINA",
    "POJOGENI",
    "POLATA",
    "PREAJBA MARE",
    "RACHITI",
    "RASOVA",
    "RASOVITA",
    "ROMANESTI",
    "ROSIUTA",
    "ROVINARI",
    "RUGI",
    "RUNCU",
    "SAMARINESTI",
    "SAMBOTIN",
    "SANATESTI",
    "SASA",
    "SATU NOU",
    "SCHELA",
    "SCOARTA",
    "SEUCA",
    "SLIVILESTI",
    "SLOBOZIA",
    "STANESTI",
    "STEIC",
    "STEJEREI",
    "STOLOJANI",
    "STROIESTI",
    "SUSENI",
    "TALPASESTI",
    "TALVESTI",
    "TAMASESTI",
    "TARCULESTI",
    "TARGU CARBUNESTI",
    "TARGU JIU",
    "TEHOMIR",
    "TELESTI",
    "TETILA",
    "TURCINESTI",
    "UNGURENI",
    "URECHESTI",
    "URSATI",
    "VACAREA",
    "VAIDEI",
    "VALARI",
    "VALCEAUA",
    "VALCELE",
    "VALEA MANASTIRII",
    "VALEA MOTRULUI",
    "VALEA PERILOR",
    "VALEA POIENII",
    "VALENI",
    "VOINIGESTI",
    "VOITESTII DIN DEAL",
    "VOITESTII DIN VALE",
    "ALBENI",
    "ALIMPESTI",
    "ALUNIS",
    "ANDREESTI",
    "ANINISU DIN DEAL",
    "ANINISU DIN VALE",
    "ANINOASA",
    "APA NEAGRA",
    "ARCANI",
    "ARPADIA",
    "ARTANU",
    "BACESTI",
    "BADESTI",
    "BAIA DE FIER",
    "BALACESTI",
    "BALCESTI",
    "BALOSANI",
    "BALTA",
    "BALTENI",
    "BANIU",
    "BARBATESTI",
    "BARZEIU",
    "BARZEIU DE GILORT",
    "BAZAVANI",
    "BECHENI",
    "BENGESTI",
    "BENGESTI-CIOCADIA",
    "BERCESTI",
    "BERLESTI",
    "BIBESTI",
    "BIBULESTI",
    "BIRCII",
    "BLIDARI",
    "BOBAIA",
    "BOBOIESTI",
    "BOHOREL",
    "BOIA",
    "BOJINU",
    "BOLBOASA",
    "BOLBOCESTI",
    "BOLBOSI",
    "BORASCU",
    "BRADET",
    "BRADETEL",
    "BRADICENI",
    "BRATESTI",
    "BRATUIA",
    "BREBENEI",
    "BROSTENI",
    "BROSTENII DE SUS",
    "BUCSANA",
    "BULBUCENI",
    "BUMBESTI-PITIC",
    "BURLANI",
    "BUSTUCHIN",
    "BUSUIOCI",
    "BUZESTI",
    "CALAPARU",
    "CALCESTI",
    "CALNIC",
    "CALUGAREASA",
    "CALUGARENI",
    "CAMPOFENI",
    "CAMPU MARE",
    "CAMUIESTI",
    "CAPRENI",
    "CAPU DEALULUI",
    "CARBUNESTI-SAT",
    "CARCIU",
    "CARLIGEI",
    "CARPINIS",
    "CELEI",
    "CEPLEA",
    "CERNADIA",
    "CERNA-SAT",
    "CETATEA",
    "CHICIORA",
    "CHILIU",
    "CIOCADIA",
    "CIONTI",
    "CIORARI",
    "CIUPERCENI",
    "CIUPERCENII DE OLTET",
    "CLOSANI",
    "COCORENI",
    "COCOROVA",
    "COJANI",
    "COJMANESTI",
    "COLIBASI",
    "COLTESTI",
    "CONDEIESTI",
    "CORNETU",
    "COROBAI",
    "CORSORU",
    "COSTENI",
    "COSTESTI",
    "COVRIGI",
    "CRASNA",
    "CRASNA DIN DEAL",
    "CRETESTI",
    "CROICI",
    "CRUSET",
    "CURSARU",
    "CURTEANA",
    "DANCIULESTI",
    "DEALU LEULUI",
    "DEALU SPIREI",
    "DEALU VIEI",
    "DEALU VIILOR",
    "DELENI",
    "DIDILESTI",
    "DOBRANA",
    "DOLCESTI",
    "DOSENI",
    "DRAGOIESTI",
    "DRAGOTESTI",
    "DUCULESTI",
    "DUMBRAVENI",
    "FANTANELE",
    "FARCASESTI",
    "FARCASESTI-MOSNENI",
    "FLORESTENI",
    "FLORESTI",
    "FRASIN",
    "FRUMUSEI",
    "FRUNZA",
    "GALCESTI",
    "GARBOVU",
    "GAVANESTI",
    "GILORTU",
    "GLODENI",
    "GODINESTI",
    "GORNOVITA",
    "GROSEREA",
    "GURA SUSITEI",
    "GURA-MENTI",
    "GURENI",
    "HAIESTI",
    "HALANGESTI",
    "HIRISESTI",
    "HOBITA",
    "HODOREASCA",
    "HOTAROASA",
    "HUREZANI",
    "IGIROSU",
    "ILIESTI",
    "IONESTI",
    "ISVARNA",
    "IZVOARELE",
    "JERISTEA",
    "JILTU",
    "JUPANESTI",
    "LARGA",
    "LICURICI",
    "LIHULESTI",
    "LOGRESTI",
    "LOGRESTI-MOSTENI",
    "MACESU",
    "MAIAG",
    "MARINESTI",
    "MARU",
    "MATASARI",
    "MENTII DIN DOS",
    "MICLOSU",
    "MIELUSEI",
    "MIEREA",
    "MIERICEAUA",
    "MILUTA",
    "MIROSLOVENI",
    "MOI",
    "MOTORGI",
    "MOTRU SEC",
    "MURGESTI",
    "MURGILESTI",
    "MUSCULESTI",
    "MUSETESTI",
    "NAMETE",
    "NEGOIESTI",
    "NEGOMIR",
    "NEGRENI",
    "NISTORESTI",
    "NUCETU",
    "OHABA-JIU",
    "OLARI",
    "ORZESTI",
    "ORZU",
    "PADES",
    "PAISANI",
    "PALTINU",
    "PARAU BOIA",
    "PARAU DE PRIPOR",
    "PARAU DE VALE",
    "PARAU VIU",
    "PARVULESTI",
    "PEGENI",
    "PESTEANA DE JOS",
    "PESTEANA-JIU",
    "PESTEANA-VULCAN",
    "PESTISANI",
    "PETRACHEI",
    "PICU",
    "PIEPTANI",
    "PINOASA",
    "PISCOIU",
    "PISCURI",
    "PLESA",
    "PLOPSORU",
    "PLOPU",
    "POCIOVALISTEA",
    "POCRUIA",
    "POIANA",
    "POIANA-SECIURI",
    "POIENARI",
    "POIENITA",
    "POJARU",
    "POLOVRAGI",
    "POPESTI",
    "POPESTI-STEJARI",
    "PRIGORIA",
    "PRIPORU",
    "PRUNESTI",
    "PURCARU",
    "RACI",
    "RACOTI",
    "RACOVITA",
    "RADINESTI",
    "RADOSI",
    "RANCA",
    "RAPA",
    "RATEZ",
    "ROGOJEL",
    "ROGOJENI",
    "ROSIA DE AMARADIA",
    "ROSIA-JIU",
    "RUGET",
    "RUNCUREL",
    "SACELU",
    "SARBESTI",
    "SARDANESTI",
    "SAULESTI",
    "SCORUSU",
    "SCRADA",
    "SCURTU",
    "SEACA",
    "SECIURILE",
    "SIACU",
    "SIPOTU",
    "SITESTI",
    "SITOAIA",
    "SLAMNESTI",
    "SLAVUTA",
    "SOCU",
    "SOHODOL",
    "SOMANESTI",
    "SPAHII",
    "STANCESTI",
    "STANCESTI-LARGA",
    "STEFANESTI",
    "STEJARI",
    "STEJARU",
    "STERPOAIA",
    "STIUCANI",
    "STOINA",
    "STRAMBA-JIU",
    "STRAMBA-VULCAN",
    "STRAMTU",
    "SURA",
    "TANTARENI",
    "TARGU LOGRESTI",
    "TICLENI",
    "TIMISENI",
    "TIRIOI",
    "TISMANA",
    "TOIAGA",
    "TOPESTI",
    "TOTEA",
    "TOTEA DE HUREZANI",
    "TRESTIOARA",
    "TROCANI",
    "TURBUREA",
    "TURCENI",
    "ULMET",
    "URDA DE JOS",
    "URDA DE SUS",
    "URDARI",
    "URSOAIA",
    "VAGIULESTI",
    "VAIENI",
    "VALEA",
    "VALEA BISERICII",
    "VALEA CU APA",
    "VALEA DESULUI",
    "VALEA MARE",
    "VALEA MICA",
    "VALEA POJARULUI",
    "VALEA RACILOR",
    "VALEA VIEI",
    "VALUTA",
    "VANATA",
    "VARTOPU",
    "VIDIN",
    "VIERSANI",
    "VLADIMIR",
    "VLADULENI",
    "ZAICOIU",
    "ZORLESTI",
    "ZORZILA"
  ],
  "HARGHITA": [
    "ALEXANDRITA",
    "ALUNIS",
    "ARMASENI",
    "ARMASENII NOI",
    "ARVATENI",
    "AVRAMESTI",
    "BADENI",
    "BAILE HOMOROD",
    "BANCU",
    "BARZAVA",
    "BENTID",
    "BETA",
    "BETESTI",
    "BISERICANI",
    "BODOGAIA",
    "BORZONT",
    "BRADESTI",
    "BULGARENI",
    "CADACIU MARE",
    "CADACIU MIC",
    "CALIMANEL",
    "CAPALNITA",
    "CARTA",
    "CECHESTI",
    "CETATUIA",
    "CHEDIA MARE",
    "CHEDIA MICA",
    "CHILENI",
    "CHINUSU",
    "CIARACIO",
    "CIBA",
    "CICEU",
    "CIRESENI",
    "CIUCANI",
    "CIUCSANGEORGIU",
    "CIUMANI",
    "COBATESTI",
    "COZMENI",
    "CRACIUNEL",
    "CRISTURU SECUIESC",
    "DANESTI",
    "DARJIU",
    "DEALU",
    "DEJUTIU",
    "DELNITA",
    "DITRAU",
    "DOBENI",
    "ELISENI",
    "FANCEL",
    "FELICENI",
    "FILIAS",
    "FITOD",
    "FORTENI",
    "FRUMOASA",
    "GHEORGHENI",
    "GHIDUT",
    "GOAGIU",
    "HARGHITA-BAI",
    "HOGHIA",
    "IASU",
    "IGHIU",
    "INEU",
    "JIGODIN-BAI",
    "JOSENI",
    "LAZAREA",
    "LELICENI",
    "LIBAN",
    "LOCODENI",
    "LUNCANI",
    "LUPENI",
    "LUTITA",
    "MADARAS",
    "MAGHERUS",
    "MARTINIS",
    "MATISENI",
    "MEDISORU MARE",
    "MEDISORU MIC",
    "MIERCUREA-CIUC",
    "MIHAILENI",
    "MIHAILENI (SIMONESTI)",
    "MISENTEA",
    "MOGLANESTI",
    "MORARENI",
    "MUGENI",
    "NADEJDEA",
    "NICOLENI",
    "NICOLESTI (FRUMOASA)",
    "NICOLESTI (ULIES)",
    "OBRANESTI",
    "OCLAND",
    "ODORHEIU SECUIESC",
    "OTENI",
    "PALTINIS",
    "PAULENI",
    "PAULENI-CIUC",
    "PETECU",
    "PLOPIS",
    "POLONITA",
    "PORUMBENI",
    "PORUMBENII MARI",
    "PORUMBENII MICI",
    "RACU",
    "RARES",
    "RUGANESTI",
    "SACEL",
    "SANCRAI",
    "SANCRAIENI",
    "SANMARTIN",
    "SANPAUL",
    "SANSIMION",
    "SANTIMBRU",
    "SARMAS",
    "SASVERES",
    "SATU MARE",
    "SATU MIC",
    "SATU NOU (OCLAND)",
    "SECUIENI",
    "SENETEA",
    "SICLOD",
    "SICULENI",
    "SIMONESTI",
    "SINEU",
    "SOIMENI",
    "SUB CETATE",
    "SUSENI",
    "TAIETURA",
    "TAMASU",
    "TARNOVITA",
    "TAURENI",
    "TELEAC",
    "TIBOD",
    "TOPLITA",
    "TURDENI",
    "UILAC",
    "ULCANI",
    "ULIES",
    "VACARESTI",
    "VAGANI",
    "VALE",
    "VALEA ROTUNDA",
    "VALEA STRAMBA",
    "VALENI",
    "VARGATAC",
    "VASILENI",
    "VISAFOLIO",
    "VLAHITA",
    "VOSLABENI",
    "VRABIA",
    "ZAPODEA",
    "ZENCANI",
    "ZETEA",
    "ALDEA",
    "ANDREENI",
    "ATIA",
    "ATID",
    "BAILE CHIRUI",
    "BAILE TUSNAD",
    "BALAN",
    "BARATCOS",
    "BECAS",
    "BILBOR",
    "BORSEC",
    "BUCIN (JOSENI)",
    "BUCIN (PRAID)",
    "CALNACI",
    "CALONDA",
    "CALUGARENI",
    "CAPU CORBULUI",
    "CARPITUS",
    "CASINU NOU",
    "CEHETEL",
    "CIOBANIS",
    "COMANESTI",
    "COMIAT",
    "CORBU",
    "CORUND",
    "COTORMANI",
    "COVACIPETER",
    "CRISENI",
    "CUSMED",
    "DAIA",
    "DEALU ARMANULUI",
    "DESAG",
    "DUDA",
    "EGHERSEC",
    "FAGETEL (FRUMOASA)",
    "FAGETEL (REMETEA)",
    "FANTANA BRAZILOR",
    "FILPEA",
    "FIRTANUS",
    "FIRTUSU",
    "FUNDOAIA",
    "GALAUTAS",
    "GALAUTAS-PARAU",
    "GHIPES",
    "GHIURCHE",
    "HAGOTA",
    "HODOSA",
    "HOSASAU",
    "IACOBENI",
    "IMPER",
    "INLACENI",
    "IZVOARE",
    "IZVORU MURESULUI",
    "IZVORUL TROTUSULUI",
    "JOLOTCA",
    "LACU ROSU",
    "LAZARESTI",
    "LAZ-FIRTANUS",
    "LAZ-SOIMUS",
    "LIVEZI",
    "LUETA",
    "LUNCA DE JOS",
    "LUNCA DE SUS",
    "MARTONCA",
    "MERESTI",
    "MINELE LUETA",
    "MUJNA",
    "NUTENI",
    "OCNA DE JOS",
    "OCNA DE SUS",
    "ORASENI",
    "PALTINIS-CIUC",
    "PETRENI",
    "PINTIC",
    "PLAIESII DE JOS",
    "PLAIESII DE SUS",
    "PLATONESTI",
    "POIANA FAGULUI",
    "POIANA TARNAVEI",
    "POTIOND",
    "PRAID",
    "PRELUCA",
    "PUNTEA LUPULUI",
    "RACHITIS",
    "RECEA",
    "REMETEA",
    "RUNC",
    "SANCEL",
    "SANDOMINIC",
    "SANTIMBRU-BAI",
    "SATU NOU (RACU)",
    "SECU",
    "SICASAU",
    "SOIMUSU MARE",
    "SOIMUSU MIC",
    "SUBCETATE",
    "TARCESTI",
    "TENGHELER",
    "TOLESENI",
    "TOMESTI",
    "TULGHES",
    "TUSNAD",
    "TUSNADU NOU",
    "VALEA BOROS",
    "VALEA CAPELEI",
    "VALEA GARBEA",
    "VALEA INTUNECOASA",
    "VALEA LUI ANTALOC",
    "VALEA LUI PAVEL",
    "VALEA RECE",
    "VALEA UGRA",
    "VALEA UZULUI",
    "VARSAG",
    "VIDACUT"
  ],
  "HUNEDOARA": [
    "ALMASU MIC",
    "ALMASU SEC",
    "ANINOASA",
    "ARCHIA",
    "BACIA",
    "BAIESTI",
    "BALATA",
    "BALOMIR",
    "BANITA",
    "BANPOTOC",
    "BARASTII HATEGULUI",
    "BARBURA",
    "BARCEA MARE",
    "BARCEA MICA",
    "BARSAU",
    "BATALAR",
    "BEJAN",
    "BERCU",
    "BOBALNA",
    "BOHOLT",
    "BOS",
    "BRAD",
    "BRANISCA",
    "BREAZOVA",
    "BRETEA STREIULUI",
    "BRETELIN",
    "BUCIUM-ORLEA",
    "CAINELU DE JOS",
    "CALAN",
    "CALANU MIC",
    "CAOI",
    "CARJITI",
    "CARPINIS",
    "CASTAU",
    "CHERGHES",
    "CHIMINDIA",
    "CHISCADAGA",
    "CHITID",
    "CIMPA",
    "CINCIS-CERNA",
    "CIOCLOVINA",
    "CIOPEIA",
    "COPACI",
    "COVRAGIU",
    "COZIA",
    "CRAGUIS",
    "CRISTUR",
    "CRIVADIA",
    "DALJA MARE",
    "DALJA MICA",
    "DEVA",
    "FARCADIN",
    "FIZES",
    "FORNADIA",
    "GENERAL BERTHELOT",
    "GEOAGIU",
    "GHELARI",
    "GRID",
    "GROS",
    "HARAU",
    "HASDAT",
    "HATEG",
    "HEREPEIA",
    "HUNEDOARA",
    "ISCRONI",
    "IZVOARELE",
    "JIET",
    "JIU-PAROSENI",
    "JOSANI",
    "LAPUSNIC",
    "LESNIC",
    "LIVEZI",
    "LUNCA (BAITA)",
    "LUPENI",
    "MAGURA-TOPLITA",
    "MANERAU",
    "MERISOR (BANITA)",
    "MINTIA",
    "NADASTIA DE JOS",
    "NADASTIA DE SUS",
    "NALATVAD",
    "NANDRU",
    "NOJAG",
    "OCOLISU MARE",
    "OHABA DE SUB PIATRA",
    "OHABA STREIULUI",
    "ORASTIE",
    "PACLISA",
    "PAULIS",
    "PESTERA (PETROSANI)",
    "PESTERA (SALASU DE SUS)",
    "PESTISU MARE",
    "PESTISU MIC",
    "PETRENI",
    "PETRILA",
    "PETROSANI",
    "PLOPI",
    "POPESTI",
    "PRICAZ",
    "RACASTIA",
    "RAPAS",
    "RAPOLTEL",
    "RAPOLTU MARE",
    "RASCOALA",
    "REEA",
    "ROVINA (BRANISCA)",
    "RUSI",
    "RUSOR",
    "SACEL",
    "SALATRUC",
    "SANCRAI",
    "SANPETRU",
    "SANTAMARIA DE PIATRA",
    "SANTAMARIA-ORLEA",
    "SANTANDREI",
    "SANTUHALM",
    "SAULESTI",
    "SILVASU DE JOS",
    "SIMERIA",
    "SIMERIA VECHE",
    "SLATINIOARA",
    "SOIMUS",
    "SPINI",
    "STOIENEASA",
    "STREI",
    "STREI-SACEL",
    "STREISANGEORGIU",
    "SUBCETATE",
    "TAMASASA",
    "TAMPA",
    "TARNAVA",
    "TELIUCU INFERIOR",
    "TELIUCU SUPERIOR",
    "TIRICI",
    "TOPLITA",
    "TOPLITA MURESULUI",
    "TOTESTI",
    "TOTIA",
    "TURDAS",
    "TUSTEA",
    "URICANI",
    "UROI",
    "URSICI",
    "VADU",
    "VALEA DALJII",
    "VALEA NANDRULUI",
    "VALEA SANGEORGIULUI",
    "VETEL",
    "VULCAN",
    "ABUCEA",
    "ALMASEL",
    "ALMAS-SALISTE",
    "ALMASU MIC DE MUNTE",
    "ALUN (BOSOROD)",
    "ALUN (BUNILA)",
    "ARANIES",
    "ARDEU",
    "AUREL VLAICU",
    "BACAIA",
    "BACEA",
    "BAIA DE CRIS",
    "BAITA",
    "BALDOVIN",
    "BALSA",
    "BARASTII ILIEI",
    "BARU",
    "BARZA",
    "BASARABASA",
    "BASTEA",
    "BATIZ",
    "BATRANA",
    "BEJAN-TARNAVITA",
    "BERIU",
    "BIRTIN",
    "BLAJENI",
    "BLAJENI-VULCAN",
    "BOBAIA",
    "BOCSA MARE",
    "BOCSA MICA",
    "BOIA BARZII",
    "BOITA",
    "BOIU",
    "BOIU DE JOS",
    "BOIU DE SUS",
    "BOSOROD",
    "BOZ",
    "BOZES",
    "BRADATEL",
    "BRASEU",
    "BRAZI",
    "BRAZNIC",
    "BRETEA MURESANA",
    "BRETEA ROMANA",
    "BROTUNA",
    "BUCES",
    "BUCES-VULCAN",
    "BUCIUM",
    "BUCURESCI",
    "BUJORU",
    "BULZESTII DE JOS",
    "BULZESTII DE SUS",
    "BUNESTI",
    "BUNILA",
    "BURJUC",
    "CABESTI",
    "CAINELU DE SUS",
    "CAMPU LUI NEAG",
    "CAMPURI DE SUS",
    "CAMPURI-SURDUC",
    "CARACI",
    "CARASTAU",
    "CARMAZANESTI",
    "CARNESTI",
    "CAZANESTI",
    "CERBAL",
    "CERBIA",
    "CERISOR",
    "CERNISOARA FLORESE",
    "CERTEJU DE JOS",
    "CERTEJU DE SUS",
    "CIGMAU",
    "CIULA MARE",
    "CIULA MICA",
    "CIULPAZ",
    "CIUMITA",
    "CIUNGANI",
    "CIUNGU MARE",
    "CLOPOTIVA",
    "COAJA",
    "COROIESTI",
    "COSESTI",
    "COSTESTI",
    "COSTESTI-DEAL",
    "CRACIUNESTI",
    "CRIS",
    "CRISAN",
    "CRISCIOR",
    "CRIVA",
    "CUCUIS",
    "CUIES",
    "CURECHIU",
    "CURPENII SILVASULUI",
    "CUTIN",
    "DABACA",
    "DANCU MARE",
    "DANCU MIC",
    "DANULESTI",
    "DEALU BABII",
    "DEALU MARE",
    "DEALU MIC",
    "DELENI",
    "DENSUS",
    "DOBRA",
    "DOBROT",
    "DRAGU-BRAD",
    "DUDESTI",
    "DUMBRAVA",
    "DUMBRAVA DE JOS",
    "DUMBRAVA DE SUS",
    "DUMBRAVITA",
    "DUMESTI",
    "DUPAPIATRA",
    "FAGETEL",
    "FANTANA",
    "FATA ROSIE",
    "FEDERI",
    "FEREGI",
    "FINTOAG",
    "FIZESTI",
    "FOLT",
    "FURCSOARA",
    "GALATI",
    "GALBINA",
    "GANTAGA",
    "GELMAR",
    "GEOAGIU-BAI",
    "GIALACUTA",
    "GIURGESTI",
    "GLODGHILESTI",
    "GODINESTI",
    "GOLES",
    "GOTESTI",
    "GOTHATEA",
    "GOVAJDIA",
    "GRADISTEA DE MUNTE",
    "GRIND",
    "GROHOT",
    "GROHOTELE",
    "GROSURI",
    "GURA BORDULUI",
    "GURASADA",
    "HARTAGANI",
    "HASDAU",
    "HATAGEL",
    "HOBITA",
    "HOBITA-GRADISTE",
    "HOLDEA",
    "HOMOROD",
    "HONDOL",
    "ILIA",
    "JELEDINTI",
    "LAPUGIU DE JOS",
    "LAPUGIU DE SUS",
    "LASAU",
    "LEAUT",
    "LELESE",
    "LIVADA",
    "LIVADIA",
    "LUDESTII DE JOS",
    "LUDESTII DE SUS",
    "LUNCA (BAIA DE CRIS)",
    "LUNCA CERNII DE JOS",
    "LUNCA CERNII DE SUS",
    "LUNCANI",
    "LUNCOIU DE JOS",
    "LUNCOIU DE SUS",
    "LUNCSOARA",
    "MACEU",
    "MADA",
    "MAGURA",
    "MAGURENI",
    "MALAIESTI",
    "MARTINESTI",
    "MERIA",
    "MERISOR (BUCURESCI)",
    "MERISORU DE MUNTE",
    "MERMEZEU-VALENI",
    "MESTEACAN (BRAD)",
    "MESTEACAN (RACHITOVA)",
    "MICANESTI",
    "MIHAIESTI",
    "MIHAILENI",
    "MOSORU",
    "MUNCELU MARE",
    "MUNCELU MIC",
    "NEGOIU",
    "NUCSOARA",
    "OBARSA",
    "OCISOR",
    "OCIU",
    "OCOLISU MIC",
    "OHABA",
    "OHABA-PONOR",
    "OHABA-SIBISEL",
    "OPRISESTI",
    "ORASTIOARA DE JOS",
    "ORASTIOARA DE SUS",
    "ORMINDEA",
    "OSTROV",
    "OSTROVEL",
    "OSTROVU MIC",
    "PANC",
    "PANC-SALISTE",
    "PAROS",
    "PAUCINESTI",
    "PAULESTI",
    "PESTEANA",
    "PESTENITA",
    "PESTERA (BAITA)",
    "PETRESTI",
    "PETROS",
    "PIATRA",
    "PISCHINTI",
    "PLAI",
    "PLOP",
    "PODELE",
    "POGANESTI",
    "POIANA",
    "POIANA RACHITELII",
    "POIENI (BERIU)",
    "POIENI (DENSUS)",
    "POIENITA",
    "POIENITA TOMII",
    "POIENITA VOINII",
    "POJOGA",
    "PONOR",
    "POTINGANI",
    "PRAVALENI",
    "PRIHODISTE (BOSOROD)",
    "PRIHODISTE (VATA DE JOS)",
    "PUI",
    "RACHITAUA",
    "RACHITOVA",
    "RADULESTI",
    "RAU ALB",
    "RAU BARBAT",
    "RAU DE MORI",
    "RAU MIC",
    "RENGHET",
    "RET",
    "RIBICIOARA",
    "RIBITA",
    "RISCA",
    "RISCULITA",
    "ROMOS",
    "ROMOSEL",
    "ROSCANI",
    "ROSIA",
    "ROVINA (BUCURESCI)",
    "RUDA",
    "RUDA-BRAD",
    "RUNCSOR",
    "RUNCU MARE",
    "RUNCU MIC",
    "RUSESTI",
    "SACAMAS",
    "SACARAMB",
    "SALASU DE JOS",
    "SALASU DE SUS",
    "SALCIVA",
    "SALISTE",
    "SALISTIOARA",
    "SARBI",
    "SARMIZEGETUSA",
    "SERECA",
    "SEREL",
    "SESURI",
    "SIBISEL (BERIU)",
    "SIBISEL (RAU DE MORI)",
    "SILVASU DE SUS",
    "SOCET",
    "SOHODOL",
    "STANCESTI",
    "STANCESTI-OHABA",
    "STANCULESTI",
    "STANIJA",
    "STAUINI",
    "STEI",
    "STEIA",
    "STEJAREL",
    "STRETEA",
    "SULIGHETE",
    "SUSENI",
    "TAMASESTI",
    "TARATEL",
    "TARNAVA DE CRIS",
    "TARNAVITA",
    "TARNITA",
    "TARSA",
    "TATARASTI",
    "TATARASTII DE CRIS",
    "TEBEA",
    "TECHEREU",
    "TEIU",
    "TICERA",
    "TISA",
    "TIULESTI",
    "TOMESTI",
    "TOMNATEC",
    "TRESTIA",
    "TURMAS",
    "UIBARESTI",
    "ULIES",
    "ULM",
    "UNCIUC",
    "URIC",
    "VADU DOBRII",
    "VAIDEI",
    "VALARI",
    "VALCELE",
    "VALCELELE BUNE",
    "VALCELUTA",
    "VALEA",
    "VALEA ARSULUI",
    "VALEA BABII",
    "VALEA BRADULUI",
    "VALEA DE BRAZI",
    "VALEA LUNGA",
    "VALEA LUPULUI",
    "VALEA MARE DE CRIS",
    "VALEA POIENII",
    "VALENI (BAIA DE CRIS)",
    "VALENI (GEOAGIU)",
    "VALIOARA",
    "VALISOARA",
    "VALISOARA (BALSA)",
    "VARMAGA",
    "VATA DE JOS",
    "VATA DE SUS",
    "VICA",
    "VISCA",
    "VOIA",
    "VORTA",
    "ZAM",
    "ZAVOI",
    "ZDRAPTI",
    "ZEICANI"
  ],
  "IALOMITA": [
    "ALEXENI",
    "AMARA",
    "AMARA NOUA",
    "ANDRASESTI",
    "ARMASESTI",
    "BALACIU",
    "BARAGANU",
    "BARBULESTI",
    "BORA",
    "BORANESTI",
    "BORDUSANI",
    "BROSTENI",
    "BUCU",
    "BULIGA",
    "CEGANI",
    "CIOCARLIA",
    "CIULNITA",
    "COPUZU",
    "COSAMBESTI",
    "COSERENI",
    "COTORCA",
    "CRASANII DE JOS",
    "CRASANII DE SUS",
    "FETESTI",
    "FETESTI-GARA",
    "FUNDATA",
    "GHEORGHE LAZAR",
    "GIMBASANI",
    "GURA VAII",
    "ION GHICA",
    "IVANESTI",
    "JILAVELE",
    "LILIECI",
    "MALTEZI",
    "MALU ROSU",
    "MANASIA",
    "MARCULESTI",
    "MISLEANU",
    "MOLDOVENI",
    "NENISORI",
    "OGRADA",
    "ORBOESTI",
    "PALTINISU",
    "PATRU FRATI",
    "PERIETI",
    "POIANA",
    "SARATENI",
    "SFANTU GHEORGHE",
    "SINTESTI",
    "SLATIOARELE",
    "SLOBOZIA",
    "SLOBOZIA NOUA",
    "STEJARU",
    "STELNICA",
    "TANDAREI",
    "URZICENI",
    "VLASCA",
    "ADANCATA",
    "ALBESTI",
    "AXINTELE",
    "BARBATESCU",
    "BARCANESTI",
    "BATALURI",
    "BITINA-PAMANTENI",
    "BITINA-UNGURENI",
    "BORDUSELU",
    "BOTENI",
    "BRAZII",
    "BUCSA",
    "BUESTI",
    "BUTOIU",
    "CATRUNESTI",
    "CAZANESTI",
    "CHIROIU-PAMANTENI",
    "CHIROIU-SATU NOU",
    "CHIROIU-UNGURENI",
    "CIOCHINA",
    "COCORA",
    "COLELIA",
    "CONDEESTI",
    "CRUNTI",
    "DRAGOESTI",
    "DRIDU",
    "DRIDU-SNAGOV",
    "DUMITRESTI",
    "FACAENI",
    "FIERBINTII DE JOS",
    "FIERBINTII DE SUS",
    "FIERBINTI-TARG",
    "FRATILESTI",
    "GARBOVI",
    "GHEORGHE DOJA",
    "GIURGENI",
    "GRECII DE JOS",
    "GRINDASI",
    "GRINDU",
    "GRIVITA",
    "GURA IALOMITEI",
    "HAGIENI",
    "HAGIESTI",
    "HORIA",
    "IAZU",
    "ION ROATA",
    "LACUSTENI",
    "LIVEDEA",
    "LUCIU",
    "MAIA",
    "MALU",
    "MARSILIENI",
    "MIHAIL KOGALNICEANU",
    "MILOSESTI",
    "MIRCEA CEL BATRAN",
    "MOVILA",
    "MOVILEANCA",
    "MOVILITA",
    "MUNTENI-BUZAU",
    "MURGEANCA",
    "NICOLESTI",
    "OREZU",
    "PIERSICA",
    "PLATONESTI",
    "PROGRESU",
    "RASI",
    "RASIMNICEA",
    "RETEZATU",
    "REVIGA",
    "ROSIORI",
    "ROVINE",
    "SALCIOARA",
    "SAVENI",
    "SCANTEIA",
    "SINESTI",
    "SMIRNA",
    "SUDITI",
    "TOVARASIA",
    "TRAIAN",
    "VALEA BISERICII",
    "VALEA CIORII",
    "VALEA MACRISULUI",
    "VLADENI"
  ],
  "IASI": [
    "ALBESTI",
    "ARONEANU",
    "BAHLUIU",
    "BAICENI (CUCUTENI)",
    "BALCIU",
    "BALS",
    "BALTATI",
    "BANU",
    "BARBATESTI",
    "BARNOVA",
    "BLAGESTI",
    "BOGDANESTI",
    "BOGONOS",
    "BOSIA",
    "BOSTENI (PASCANI)",
    "BOURENI (BALS)",
    "BRAESTI",
    "BRATESTI",
    "BRATULENI",
    "BRATULESTI",
    "BREAZU",
    "BUDA (BRAESTI)",
    "BUDAI",
    "BUDESTI",
    "BUZNEA",
    "CARBUNARI",
    "CARLIG",
    "CERCU",
    "CHICEREA",
    "CHILISOAIA",
    "CHIPERESTI",
    "CILIBIU",
    "CIOCARLESTI",
    "CIURBESTI",
    "CIUREA",
    "COADA STANCII",
    "COGEASCA",
    "COMARNA",
    "CONTESTI",
    "CORCODEL",
    "CORNESTI",
    "COSITENI",
    "COSTESTI",
    "COTARGACI",
    "COTNARI",
    "COTU LUI IVAN",
    "COTU MORII",
    "COZMESTI (STOLNICENI-PRAJESCU)",
    "CRISTESTI (BRAESTI)",
    "CRISTESTI (HOLBOCA)",
    "CRIVESTI (STRUNGA)",
    "CUCOVA",
    "CUCUTENI",
    "CUCUTENI (LETCANI)",
    "CURAGAU",
    "CURATURI",
    "CUZA VODA (POPRICANI)",
    "DADESTI",
    "DANCAS",
    "DANCU",
    "DOROBANT",
    "DUMBRAVA (CIUREA)",
    "DUMBRAVITA",
    "DUMESTI",
    "DUMITRESTII GALATII",
    "FARCASENI",
    "FILIASI",
    "GANESTI",
    "GASTESTI",
    "GAURENI",
    "GIURGESTI",
    "GOLAIESTI",
    "GORUNI",
    "GRADINARI",
    "GRAJDURI",
    "GURA VAII",
    "HABASESTI",
    "HADAMBU",
    "HARLAU",
    "HARMANESTI",
    "HARMANESTII NOI",
    "HARMANESTII VECHI",
    "HLINCEA",
    "HOISESTI",
    "HOLBOCA",
    "HORLESTI",
    "HORLESTI (REDIU)",
    "HORPAZ",
    "IASI",
    "ICUSENI",
    "ION NECULCE",
    "IZVOARELE",
    "LETCANI",
    "LUNCA (GRAJDURI)",
    "LUNCA (PASCANI)",
    "LUNCA CETATUII",
    "LUNGANI",
    "LUNGANI (VOINESTI)",
    "MADARJESTI",
    "MANJESTI",
    "MANZATESTI",
    "MEDELENI",
    "MIROSLAVA",
    "MOGOSESTI",
    "MOIMESTI",
    "MORENI",
    "OPRISENI",
    "ORZENI",
    "OSOI (COMARNA)",
    "PADURENI (GRAJDURI)",
    "PASCANI",
    "PAUN",
    "PICIORU LUPULUI",
    "PIETRARIA",
    "POCREACA",
    "PODISU",
    "PODU JIJIEI",
    "POIANA (SCHITU DUCA)",
    "POIANA CU CETATE",
    "POIENI",
    "POPRICANI",
    "PRIGORENI",
    "PROSELNICI",
    "RACHITENI",
    "RAZBOIENI",
    "REDIU",
    "REDIU (BRAESTI)",
    "REDIU (RUGINOASA)",
    "REDIU ALDEI",
    "REDIU MITROPOLIEI",
    "RUGINOASA",
    "RUSENII NOI",
    "RUSENII VECHI",
    "SACARESTI",
    "SARCA",
    "SATU NOU (SCHITU DUCA)",
    "SCHITU DUCA",
    "SCHITU STAVNIC",
    "SCOPOSENI (HORLESTI)",
    "SLOBOZIA (CIUREA)",
    "SLOBOZIA (SCHITU DUCA)",
    "SLOBOZIA (VOINESTI)",
    "SODOMENI",
    "SOROGARI",
    "STANCA (COMARNA)",
    "STOLNICENI-PRAJESCU",
    "STRUNGA",
    "TARGU FRUMOS",
    "TAUTESTI",
    "TIPILESTI",
    "TODIREL",
    "TOMESTI",
    "TOPILE",
    "TUTORA",
    "URICANI",
    "URSOAIA",
    "VALEA ADANCA",
    "VALEA LUNGA",
    "VALEA LUPULUI",
    "VALEA OILOR",
    "VALEA SATULUI",
    "VALEA SEACA",
    "VALEA URSULUI",
    "VANATORI (POPRICANI)",
    "VISAN",
    "VLADICENI",
    "VOCOTESTI",
    "VOINESTI",
    "VOROVESTI",
    "VULTURI",
    "ALEXANDRU CEL BUN",
    "ALEXANDRU I. CUZA",
    "ALEXENI",
    "ALEXESTI",
    "ANDRIESENI",
    "ARAMA",
    "AVANTU",
    "BACU",
    "BADENI",
    "BAICENI (TODIRESTI)",
    "BALTENI",
    "BALUSESTI",
    "BARLESTI",
    "BELCESTI",
    "BEREZLOGI",
    "BIVOLARI",
    "BLAGA",
    "BOATCA",
    "BOCNITA",
    "BODESTI",
    "BOHOTIN",
    "BOJILA",
    "BOLDESTI",
    "BOROSESTI",
    "BOROSOAIA",
    "BORSA",
    "BOURENI (MOTCA)",
    "BRADICESTI",
    "BRAN",
    "BROSTENI",
    "BUDA (LESPEZI)",
    "BUHAENI",
    "BUHALNITA",
    "BULBUCANI",
    "BURSUC-DEAL",
    "BURSUC-VALE",
    "BURUIENESTI",
    "BUTEA",
    "BUZDUG",
    "CARJOAIA",
    "CARNICENI",
    "CAUESTI",
    "CEPLENITA",
    "CHISCARENI",
    "CIOCA-BOCA",
    "CIOHORANI",
    "CIORTESTI",
    "CIRESENI",
    "COARNELE CAPREI",
    "COASTA MAGURII",
    "COLTU CORNII",
    "COROPCENI",
    "COSTULENI",
    "COVASNA",
    "COZIA",
    "COZMESTI",
    "CRISTESTI",
    "CRIVESTI (VANATORI)",
    "CRUCEA",
    "CUZA VODA (IPATELE)",
    "DAGATA",
    "DELENI",
    "DELENI (CIORTESTI)",
    "DOBROVAT",
    "DOLHESTI",
    "DOMNITA",
    "DOROSCANI",
    "DRAGANESTI",
    "DRAGUSENI",
    "DUMBRAVA (LESPEZI)",
    "ERBICENI",
    "FAGAT",
    "FANTANELE",
    "FANTANELE (ANDRIESENI)",
    "FEDELESENI",
    "FEREDENI",
    "FETESTI",
    "FOCURI",
    "FORASTI",
    "FRASULENI",
    "FRENCIUGI",
    "FRUMUSICA",
    "GARBESTI",
    "GLAVANESTI",
    "GLODENII GANDULUI",
    "GOESTI",
    "GORBAN",
    "GRIESTI",
    "GROPNITA",
    "GROZESTI",
    "GURA BADILITEI",
    "GURA BOHOTIN",
    "HALAUCESTI",
    "HALCENI",
    "HANDRESTI",
    "HARMANEASA",
    "HARPASESTI",
    "HARTOAPE",
    "HECI",
    "HELESTENI",
    "HERMEZIU",
    "HILITA",
    "HODORA",
    "HOLM",
    "HOMITA",
    "HORODISTEA",
    "HUMOSU",
    "IACOBENI",
    "IAZU NOU",
    "IAZU VECHI",
    "IEPURENI (ANDRIESENI)",
    "IEPURENI (MOVILENI)",
    "IORCANI",
    "IOSUPENI",
    "IPATELE",
    "ISAIIA",
    "IUGANI",
    "JIGORENI",
    "KOGALNICENI",
    "LARGA-JIJIA",
    "LEAHU-NACU",
    "LESPEZI",
    "LITENI",
    "LUCENI",
    "LUNCA RATES",
    "LUNCASI",
    "LUPARIA",
    "MACARESTI",
    "MADARJAC",
    "MALAESTI",
    "MANASTIREA",
    "MAXUT",
    "MICLAUSENI",
    "MIHAIL KOGALNICEANU",
    "MIRCESTI",
    "MIRONEASA",
    "MIROSLOVESTI",
    "MITESTI (MIROSLOVESTI)",
    "MITOC",
    "MOARA CIORNEI",
    "MOGOSESTI-SIRET",
    "MOSNA",
    "MOTCA",
    "MOVILENI",
    "MOVILENI (HELESTENI)",
    "MUNCELU DE SUS",
    "MUNTENI",
    "OBOROCENI",
    "OBRIJENI",
    "ONESTI",
    "OPROAIA",
    "OSOI (SINESTI)",
    "OTELENI",
    "PADURENI (POPESTI)",
    "PARCOVACI",
    "PAUSESTI",
    "PERIENI",
    "PETRESTI",
    "PETROSICA",
    "PIETRIS",
    "PIETROSU",
    "PISCU RUSULUI",
    "PLUGARI",
    "PODOLENII DE JOS",
    "PODOLENII DE SUS",
    "PODU HAGIULUI",
    "PODU ILOAIEI",
    "POIANA (DELENI)",
    "POIANA DE SUS",
    "POIANA MANASTIRII",
    "POIANA MARULUI",
    "POIANA SCHEII",
    "POIENILE",
    "POPESTI",
    "POTANGENI",
    "PRISACANI",
    "PROBOTA",
    "RADENI",
    "RADUCANENI",
    "RASBOIENI",
    "RECEA",
    "REDIU (SCANTEIA)",
    "ROMANESTI",
    "ROSCANI",
    "ROSU",
    "ROTARIA",
    "RUNCU",
    "SALAGENI",
    "SANGERI",
    "SATU NOU (BELCESTI)",
    "SATU NOU (SCHEIA)",
    "SATU NOU (SIRETEL)",
    "SAVENI",
    "SCANTEIA",
    "SCHEIA",
    "SCHEIA (AL.I CUZA)",
    "SCHITU HADAMBULUI",
    "SCOBALTENI",
    "SCOBINTI",
    "SCOPOSENI (GORBAN)",
    "SCULENI",
    "SENDRENI",
    "SERBESTI",
    "SINESTI",
    "SIPOTE",
    "SIRETEL",
    "SLOBOZIA (DELENI)",
    "SLOBOZIA (SIRETEL)",
    "SOCI",
    "SOLONET",
    "SPINENI",
    "SPINOASA",
    "SPRANCEANA",
    "STANCA (VICTORIA)",
    "STEJARII",
    "STICLARIA",
    "STORNESTI",
    "STROESTI",
    "SUHULET",
    "TABARA",
    "TANSA",
    "TANSA (BELCESTI)",
    "TARNITA",
    "TATARUSI",
    "TIBANA",
    "TIBANESTI",
    "TIGANASI",
    "TODIRESTI",
    "TOTOESTI",
    "TRAIAN",
    "TRIFESTI",
    "TUDOR VLADIMIRESCU",
    "TUFESTII DE SUS",
    "TUNGUJEI",
    "UDA",
    "ULMI",
    "UNGHENI",
    "URSARESTI",
    "URSITA",
    "VADU VEJEI",
    "VALCELELE",
    "VALCICA",
    "VALEA RACULUI",
    "VALENII",
    "VAMA",
    "VANATORI",
    "VASCANI",
    "VERSENI (MIROSLOVESTI)",
    "VICTORIA",
    "VLADENI",
    "VLADNICUT",
    "VLADOMIRA",
    "VOLINTIRESTI",
    "ZABOLOTENI",
    "ZAGAVIA",
    "ZBERENI",
    "ZBEROAIA",
    "ZECE PRAJINI",
    "ZLODICA",
    "ZMEU"
  ],
  "ILFOV": [
    "1 DECEMBRIE",
    "AFUMATI",
    "ALUNISU",
    "BALACEANCA",
    "BALOTESTI",
    "BALTENI",
    "BERCENI",
    "BRAGADIRU",
    "BRANESTI",
    "BUDA",
    "BUFTEA",
    "CACIULATI",
    "CALDARARU",
    "CATELU",
    "CERNICA",
    "CHIAJNA",
    "CHITILA",
    "CIOFLICENI",
    "CIOLPANI",
    "CIOROGARLA",
    "CLINCENI",
    "COPACENI",
    "CORBEANCA",
    "CORNETU",
    "COZIENI",
    "CRETESTI",
    "CRETULEASCA",
    "DARASTI-ILFOV",
    "DARVARI IF",
    "DASCALU",
    "DIMIENI",
    "DOBROESTI",
    "DOMNESTI",
    "DRAGOMIRESTI-DEAL",
    "DRAGOMIRESTI-VALE",
    "DUDU",
    "DUMBRAVENI",
    "DUMITRANA",
    "FUNDENI",
    "GAGU",
    "GANEASA",
    "GHERMANESTI",
    "GLINA",
    "GRADISTEA",
    "GRUIU",
    "ISLAZ",
    "JILAVA",
    "LIPIA",
    "LUPARIA",
    "MAGURELE",
    "MAINEASCA",
    "MANOLACHE",
    "MOARA DOMNEASCA",
    "MOARA VLASIEI",
    "MOGOSOAIA",
    "ODAILE",
    "OLTENI",
    "ORDOREANU",
    "OSTRATU",
    "OTOPENI",
    "PANTELIMON",
    "PASAREA(ILFOV)",
    "PERIS",
    "PETRACHIOAIA",
    "PETRESTI",
    "PIPERA (VOLUNTARI)",
    "PITEASCA",
    "POPESTI-LEORDENI",
    "POSTA",
    "PRUNI",
    "ROSU",
    "RUDENI",
    "RUNCU",
    "SAFTICA",
    "SILISTEA SNAGOVULUI",
    "SINDRILITA",
    "SINTESTI",
    "SNAGOV",
    "STEFANESTII DE JOS",
    "STEFANESTII DE SUS",
    "SURLARI",
    "TAMASI",
    "TANCABESTI",
    "TANGANU",
    "TEGHES",
    "TUNARI",
    "VANATORI",
    "VARTEJU",
    "VIDRA",
    "VLADICEASCA",
    "VOLUNTARI",
    "ZURBAUA",
    "BALTA NEAGRA",
    "BUCIUMENI",
    "BURIAS",
    "IZVORANI",
    "MERII PETCHII",
    "MICSUNESTII MARI",
    "MICSUNESTI-MOARA",
    "NUCI",
    "PISCU",
    "SANTU-FLORESTI",
    "SITARU",
    "VADU ANEI"
  ],
  "MARAMURES": [
    "ARIESU DE PADURE",
    "BAIA MARE",
    "BAIA SPRIE",
    "BAITA",
    "BARGAU",
    "BARSANA",
    "BERBESTI",
    "BERINTA",
    "BOCICOIU MARE",
    "BOZANTA MARE",
    "BOZANTA MICA",
    "BUSAG",
    "CAMPULUNG LA TISA",
    "CARBUNARI",
    "CATALINA",
    "CETATELE",
    "CHECHIS",
    "CICARLAU",
    "COAS",
    "COLTAU",
    "COLTIREA",
    "CORUIA",
    "CRACIUNESTI",
    "CULCEA",
    "DUMBRAVITA",
    "FERESTI",
    "GIULESTI",
    "GROSI",
    "HANDALU ILBEI",
    "HIDEAGA",
    "IAPA",
    "ILBA",
    "LAPUSEL",
    "LAZU BACIULUI",
    "LUNCA LA TISA",
    "MERISOR",
    "MOCIRA",
    "MOGOSESTI",
    "NANESTI",
    "NEGREIA",
    "NISTRU",
    "OCNA SUGATAG",
    "OCOLIS",
    "ONCESTI",
    "PRIBILESTI",
    "RECEA",
    "RUS",
    "SABISA",
    "SACALASENI",
    "SARASAU",
    "SASAR",
    "SATU NOU DE JOS",
    "SATU NOU DE SUS",
    "SATULUNG",
    "SEINI",
    "SIGHETU MARMATIEI",
    "SISESTI",
    "SUGAU",
    "SURDESTI",
    "TAUTII DE SUS",
    "TAUTII-MAGHERAUS",
    "TISA",
    "UNGURAS",
    "VADU IZEI",
    "VALEA CUFUNDOASA",
    "VALEA HOTARULUI",
    "VALEA STEJARULUI",
    "VIILE APEI",
    "ARDUSAT",
    "ARDUZEL",
    "ARIESU DE CAMP",
    "ARINIS",
    "ASPRA",
    "ASUAJU DE JOS",
    "ASUAJU DE SUS",
    "BABA",
    "BAILE BORSA",
    "BAITA DE SUB CODRU",
    "BAIUT",
    "BASESTI",
    "BERCHEZ",
    "BERCHEZOAIA",
    "BICAZ",
    "BISTRA",
    "BLIDARI",
    "BOCICOEL",
    "BOGDAN VODA",
    "BOIERENI",
    "BOIU MARE",
    "BONTAIENI",
    "BORCUT",
    "BORSA",
    "BOTIZA",
    "BREB",
    "BREBENI",
    "BUCIUMI",
    "BUDESTI",
    "BUTEASA",
    "BUZESTI",
    "CALINESTI",
    "CARPINIS",
    "CAVNIC",
    "CERNESTI",
    "CHELINTA",
    "CHIUZBAIA",
    "CIOCOTIS",
    "CIOLT",
    "CIUTA",
    "CODRU BUTESII",
    "COPALNIC",
    "COPALNIC-DEAL",
    "COPALNIC-MANASTUR",
    "CORNESTI",
    "CORNI",
    "COROIENI",
    "COSTENI",
    "COSTIUI",
    "CRASNA VISEULUI",
    "CUFOAIA",
    "CUPSENI",
    "CURTUIUSU MARE",
    "CURTUIUSU MIC",
    "DAMACUSENI",
    "DANESTI",
    "DANESTII CHIOARULUI",
    "DEALU CORBULUI",
    "DEALU MARE",
    "DESESTI",
    "DOBRICU LAPUSULUI",
    "DRAGHIA",
    "DRAGOMIRESTI",
    "DUMBRAVA",
    "DURUSA",
    "FANATE",
    "FANTANELE",
    "FARCASA",
    "FAURESTI",
    "FERICEA",
    "FERSIG",
    "FINTEUSU MARE",
    "FINTEUSU MIC",
    "FIRIZA",
    "FRANCENII BOIULUI",
    "GARDANI",
    "GLOD",
    "GROAPE",
    "GROSII TIBLESULUI",
    "HARNICESTI",
    "HOTENI",
    "HOVRILA",
    "IADARA",
    "IEUD",
    "INAU",
    "INTRERAURI",
    "IZVOARELE",
    "JUGASTRENI",
    "LAPUS",
    "LARGA",
    "LASCHIA",
    "LEORDINA",
    "LIBOTIN",
    "LUCACESTI",
    "MAGURENI",
    "MANASTIREA",
    "MANAU",
    "MARA",
    "MESTEACAN",
    "MIRESU MARE",
    "MOISEI",
    "OARTA DE JOS",
    "OARTA DE SUS",
    "ODESTI",
    "ORTITA",
    "PETERITEA",
    "PETROVA",
    "PIATRA",
    "PLOPIS",
    "POIANA BOTIZII",
    "POIENILE DE SUB MUNTE",
    "POIENILE IZEI",
    "POSTA",
    "PRELUCA NOUA",
    "PRELUCA VECHE",
    "PRISLOP",
    "RAZOARE",
    "REMECIOARA",
    "REMETEA CHIOARULUI",
    "REMETI",
    "REMETI PE SOMES",
    "REPEDEA",
    "RODINA",
    "ROGOZ",
    "ROHIA",
    "RONA DE JOS",
    "RONA DE SUS",
    "ROZAVLEA",
    "RUSCOVA",
    "RUSOR",
    "SACEL",
    "SALISTE",
    "SALISTEA DE SUS",
    "SALNITA",
    "SALSIG",
    "SALTA",
    "SAPANTA",
    "SARBI (BUDESTI)",
    "SARBI (FARCASA)",
    "SAT-SUGATAG",
    "SIEU",
    "SINDRESTI",
    "SLATIOARA",
    "SOMCUTA MARE",
    "SOMES-UILEAC",
    "STEJERA",
    "STOICENI",
    "STRAMBU-BAIUT",
    "STRAMTURA",
    "STREMT",
    "SUCIU DE JOS",
    "SUCIU DE SUS",
    "TAMAIA",
    "TAMASESTI",
    "TARGU LAPUS",
    "TECEU MIC",
    "TICAU",
    "TOHAT",
    "TRESTIA",
    "TULGHIES",
    "ULMENI",
    "ULMOASA",
    "UNGURENI",
    "URMENIS",
    "VAD",
    "VALEA CHIOARULUI",
    "VALEA NEAGRA",
    "VALEA VISEULUI",
    "VALENI",
    "VALENII LAPUSULUI",
    "VALENII SOMCUTEI",
    "VARAI",
    "VICEA",
    "VIMA MARE",
    "VIMA MICA",
    "VISEU DE JOS",
    "VISEU DE MIJLOC",
    "VISEU DE SUS"
  ],
  "MEHEDINTI": [
    "23-AUGUST",
    "ALUNISUL",
    "ARVATESTI",
    "BADITESTI",
    "BAHNA",
    "BAIA NOUA",
    "BALOTA",
    "BALOTESTI",
    "BALTANELE (GRECI)",
    "BALTANELE (PRUNISOR)",
    "BARDA",
    "BISTRITA",
    "BOBAITA",
    "BREZNITA-OCOL",
    "BUDANESTI",
    "BUICESTI",
    "CARJEI",
    "CELNATA",
    "CERNETI",
    "CERVENITA",
    "CIOVARNASANI",
    "COCOROVA",
    "COLIBASI",
    "DEDOVITA NOUA",
    "DEDOVITA VECHE",
    "DROBETA-TURNU SEVERIN",
    "DUDASU",
    "DUDASU SCHELEI",
    "DUMBRAVITA",
    "ERGHEVITA",
    "ESELNITA",
    "GARNITA",
    "GHELMEGIOAIA",
    "GOVODARVA",
    "GURA MOTRULUI",
    "GURA VAII",
    "GUTU",
    "HALANGA",
    "HINOVA",
    "IGIROASA",
    "ILOVITA",
    "IZVORU BARZII",
    "JIDOSTITA",
    "LAZU",
    "LUMNIC",
    "MAGHERU",
    "MALOVAT",
    "MARMANU",
    "MIJARCA",
    "NEGRESTI",
    "OPRANESTI",
    "ORSOVA",
    "OSTROVU CORBULUI",
    "PARLAGELE",
    "PETRIS",
    "POROINA",
    "PRIBOIESTI",
    "PRUNARU",
    "PUTINEI",
    "RASCOLESTI",
    "ROSIA",
    "SCHELA CLADOVEI",
    "SCHINTEIESTI",
    "SCHITU TOPOLNITEI",
    "SELISTENI",
    "SIMIAN",
    "SIPOTU (POROINA MARE)",
    "SUSITA (BREZNITA-OCOL)",
    "VALEA COPCII",
    "ADUNATII TEIULUI",
    "ALBULESTI",
    "ALMAJEL",
    "ARGINESTI",
    "AURORA",
    "BACLES",
    "BAIA DE ARAMA",
    "BALA",
    "BALA DE SUS",
    "BALACITA",
    "BALTA",
    "BALTA VERDE",
    "BALUTA",
    "BALVANESTI",
    "BALVANESTII DE JOS",
    "BARAIACU",
    "BARLOGENI",
    "BATOTI",
    "BIBAN",
    "BISTRETU",
    "BLIDARU",
    "BOCENI",
    "BOROGEA",
    "BRAGLEASA",
    "BRANISTEA",
    "BRANZENI",
    "BRATESUL",
    "BRATILOVU",
    "BRATIVOESTI",
    "BREBINA",
    "BRETA",
    "BREZNICIOARA",
    "BREZNITA-MOTRU",
    "BROSTENI",
    "BUCURA",
    "BUICANI",
    "BUNOAICA",
    "BURILA MARE",
    "BURILA MICA",
    "BUSESTI",
    "BUTOIESTI",
    "CALINESTII DE JOS",
    "CALINESTII DE SUS",
    "CAMPU MARE",
    "CAPATANESTI",
    "CARAMIDARU",
    "CARCENI",
    "CARSU",
    "CAZANESTI",
    "CEARANGU",
    "CEPTURENI",
    "CERANGANUL",
    "CERNAIA",
    "CERNA-VARF",
    "CIOCHIUTA",
    "CIOROBORENI",
    "CIRESU",
    "COADA CORNETULUI",
    "COLARET",
    "COMANDA",
    "COMANESTI",
    "COPACIOASA",
    "CORCOVA",
    "CORDUN",
    "CORLATEL",
    "CORZU",
    "COSOVAT",
    "COSTESTI",
    "COTOROAIA",
    "COZIA",
    "CRACU LUNG",
    "CRACU MUNTELUI",
    "CRAGUESTI",
    "CRAINICI",
    "CREMENEA",
    "CRIVINA",
    "CROICA",
    "CUJMIR",
    "CUJMIRU MIC",
    "DALBOCITA",
    "DALMA",
    "DANCEU",
    "DARVARI",
    "DEALU MARE",
    "DELENI",
    "DELURENI",
    "DEVESEL",
    "DOBRA",
    "DRAGHESTI",
    "DRAGOTESTI",
    "DRINCEA",
    "DUBOVA",
    "DUMBRAVA",
    "DUMBRAVA DE JOS",
    "DUMBRAVA DE MIJLOC",
    "DUMBRAVA DE SUS",
    "DUNAREA MICA",
    "EIBENTHAL",
    "ERCEA",
    "FANTANA DOMNEASCA",
    "FANTANILE NEGRE",
    "FATA CREMENII",
    "FATA MOTRULUI",
    "FAUROAIA",
    "FIRIZU",
    "FLORESTI",
    "GARBOVATU DE JOS",
    "GARBOVATU DE SUS",
    "GARDANEASA",
    "GARDOAIA",
    "GARLA MARE",
    "GEMENI",
    "GHEORGHESTI",
    "GIURA",
    "GIURGIANI",
    "GOANTA",
    "GODEANU",
    "GODEANU (OBARSIA-CLOSANI)",
    "GOGOSU",
    "GOLINEASA",
    "GORNENTI",
    "GORNOVITA",
    "GRECI",
    "GROZESTI",
    "GRUIA",
    "GVARDINITA",
    "HIGIU",
    "HOTARANI",
    "HURDUCESTI",
    "HUSNICIOARA",
    "IABLANITA",
    "ILOVAT",
    "ILOVU",
    "IMOASA",
    "ISVERNA",
    "IUPCA",
    "IZIMSA",
    "IZVOARELE",
    "IZVORALU",
    "IZVORALU DE JOS",
    "IZVORU ANESTILOR",
    "IZVORU FRUMOS",
    "JIANA",
    "JIANA MARE",
    "JIANA VECHE",
    "JIGNITA",
    "JIROV",
    "JUGASTRU",
    "JUPANESTI",
    "LAC",
    "LIVEZI",
    "LIVEZILE",
    "LUDU",
    "LUNCA BANULUI",
    "LUNCSOARA",
    "LUPSA DE JOS",
    "LUPSA DE SUS",
    "MAGURELE",
    "MALARISCA",
    "MANU",
    "MARASESTI",
    "MARGA",
    "MARU ROSU",
    "MENTI",
    "MERIS",
    "MOISESTI",
    "MOLANI",
    "MOSNENI",
    "MOTRULENI",
    "NADANOVA",
    "NEGOESTI",
    "NEGRUSA",
    "NEVATU",
    "NICOLAE BALCESCU",
    "NOAPTESA",
    "OBARSIA DE CAMP",
    "OBARSIA-CLOSANI",
    "OHABA",
    "OLTEANCA",
    "OPRISOR",
    "OREVITA MARE",
    "OSTROVU MARE",
    "PADINA",
    "PADINA MARE",
    "PADINA MICA",
    "PALTINISU",
    "PARVULESTI",
    "PASARANI",
    "PATULELE",
    "PAUNESTI",
    "PAVAT",
    "PERI",
    "PESTEANA",
    "PESTENUTA",
    "PETRA",
    "PISTRITA",
    "PLAI",
    "PLOPI",
    "PLUTA",
    "PODENI",
    "PODU GROSULUI",
    "POIANA",
    "POIANA GRUII",
    "PONOARELE",
    "POROINA MARE",
    "POROINITA",
    "PORTILE DE FIER II",
    "POSTA VECHE",
    "PREJNA",
    "PRISACEAUA",
    "PRISTOL",
    "PROITESTI",
    "PRUNISOR",
    "PUNGHINA",
    "PUSCASU",
    "RACOVA",
    "RADUTESTI",
    "RAICULESTI",
    "RECEA",
    "ROCSORENI",
    "ROGOVA",
    "ROSIORI",
    "RUDINA",
    "RUNCUSORU",
    "RUPTURA",
    "SALATRUC",
    "SALCIA",
    "SARDANESTI",
    "SATU MARE",
    "SATU NOU",
    "SCAPAU",
    "SCORILA",
    "SELISTEA",
    "SELISTIUTA",
    "SEVERINESTI",
    "SFODEA",
    "SIPOTU (PONOARELE)",
    "SIROCA",
    "SISESTI",
    "SLASOMA",
    "SLATINICU MARE",
    "SLATINICU MIC",
    "SMADOVITA",
    "SOVARNA",
    "SPERLESTI",
    "STANCESTI",
    "STANESTI",
    "STANGACEAUA",
    "STEJARU",
    "STIGNITA",
    "STIRCOVITA",
    "STREHAIA",
    "STROESTI",
    "STUDINA",
    "SUHARU",
    "SUSITA (GROZESTI)",
    "SVINITA",
    "TALAPANU",
    "TAMNA",
    "TANTARU",
    "TARSA",
    "TIGANASI",
    "TISMANA",
    "TITERLESTI",
    "TITIRIGI",
    "TRAIAN",
    "TURTABA",
    "VALEA ANILOR",
    "VALEA BUNA",
    "VALEA COSUSTEI",
    "VALEA IZVORULUI",
    "VALEA MARCULUI",
    "VALEA PETRII",
    "VALEA TEIULUI",
    "VALEA URSULUI (PONOARELE)",
    "VALEA URSULUI (TAMNA)",
    "VANATORI",
    "VANJU MARE",
    "VANJULET",
    "VARODIA",
    "VIASU",
    "VIDIMIRESTI",
    "VISINA",
    "VLADAIA",
    "VLADASESTI",
    "VLADICA",
    "VOLOIAC",
    "VOLOICEL",
    "VRANCEA",
    "VRATA",
    "ZEGAIA",
    "ZEGUJANI"
  ],
  "MURES": [
    "ABUS",
    "ACATARI",
    "ADRIAN",
    "ALBESTI",
    "APALINA",
    "APOLD",
    "ATINTIS",
    "AUREL VLAICU",
    "AVRAMESTI",
    "BARDESTI",
    "BARZA",
    "BEICA DE JOS",
    "BEICA DE SUS",
    "BERGHIA",
    "BICHIS",
    "BOGATA (MURES)",
    "BOIU",
    "BOTORCA",
    "BOZENI",
    "BRANCOVENESTI",
    "BREAZA",
    "BUDIU MIC",
    "CAMPENITA",
    "CAPETI",
    "CECALACA",
    "CERGHIZEL",
    "CHETANI",
    "CHIBED",
    "CHINARI",
    "CINTA",
    "CIOARGA",
    "CIPAIENI",
    "CIPAU",
    "CIURGAU (MURES)",
    "COASTA GRINDULUI",
    "CORDOS",
    "CORUNCA",
    "COTUS",
    "CRISTESTI",
    "CUCERDEA",
    "CUCI",
    "CUIESD",
    "CURTENI",
    "CUSTELNIC",
    "DALU",
    "DAMBAU",
    "DANES",
    "DEDRAD",
    "DELENI (IDECIU DE JOS)",
    "DELENI (POGACEAUA)",
    "DELENII",
    "DILEU NOU",
    "DILEU VECHI",
    "DUMBRAVIOARA",
    "ERNEI",
    "FANATE (TAURENI)",
    "FANTANELE",
    "FILPISU MARE",
    "FOI",
    "FUNDATURA",
    "GAIESTI",
    "GAMBUT",
    "GANESTI",
    "GAURA SANGERULUI",
    "GHEJA",
    "GHEORGHE DOJA",
    "GHIDASTEU",
    "GHINDARI",
    "GIULUS",
    "GIURGIS",
    "GORENI",
    "GORNESTI",
    "GRINDENI",
    "GRUISOR",
    "HABIC",
    "HADARENI",
    "HETIUR",
    "IDECIU DE JOS",
    "IDECIU DE SUS",
    "IDICEL",
    "IERNUT",
    "IERNUTENI",
    "ILIESI",
    "ISTIHAZA",
    "IVANESTI",
    "JABENITA",
    "LASCUD",
    "LAURENI",
    "LINT",
    "LIVEZENI",
    "LUDUS",
    "LUIERIU",
    "LUNCA MURESULUI",
    "MADARASENI",
    "MAIAD",
    "MATRICI",
    "MERISORU",
    "MIERCUREA NIRAJULUI",
    "MOARA DE JOS",
    "MORESTI",
    "NADASA",
    "NANDRA",
    "NAZNA",
    "NICOLESTI",
    "OGRA",
    "OROSIA",
    "OZD",
    "PANET",
    "PAPIU ILARIAN",
    "PERIS",
    "PETELEA",
    "PETRILACA",
    "POIENITA",
    "PORUMBAC",
    "PORUMBENI",
    "RACAMET",
    "RANTA",
    "RECEA",
    "REGHIN",
    "REMETEA",
    "RORA",
    "ROSIORI",
    "SACADAT",
    "SAES",
    "SANCRAIU DE MURES",
    "SANGEORGIU DE MURES",
    "SANGEORGIU DE PADURE",
    "SANGER",
    "SANGERU DE PADURE",
    "SANIACOB",
    "SANISOR",
    "SANPAUL",
    "SANTANA DE MURES",
    "SANTIOANA DE MURES",
    "SANTU",
    "SANVASII",
    "SARATENI",
    "SARDU NIRAJULUI",
    "SATU NOU (GHEORGHE DOJA)",
    "SELEUS (DANES)",
    "SEUCA",
    "SEULIA DE MURES",
    "SIGHISOARA",
    "SOLOVASTRU",
    "SOROMICLEA",
    "SOVATA",
    "STEFANEACA",
    "STEJARENII",
    "SUSENI",
    "SUVEICA",
    "TABLASENI",
    "TAMPA",
    "TARGU MURES",
    "TARNAVENI",
    "TAU",
    "TAURENI",
    "TOFALAU",
    "TOPA",
    "TREI SATE",
    "UNGHENI",
    "VADAS",
    "VAIDEIU",
    "VALEA ALBESTIULUI",
    "VALURENI",
    "VANATORI",
    "VENCHI",
    "VIDRASAU",
    "VIILOR",
    "VOINICENI",
    "ZAPODEA",
    "ABUD",
    "ADAMUS",
    "ADRIANU MARE",
    "ADRIANU MIC",
    "AGRISTEU",
    "ALUNIS",
    "ANDRENEASA",
    "ANGOFA",
    "ARCHITA",
    "ARSITA",
    "BAGACIU",
    "BAHNEA",
    "BAITA",
    "BALA",
    "BALAUSERI",
    "BALDA",
    "BAND",
    "BARA",
    "BARBOSI",
    "BARLIBAS",
    "BARLIBASOAIA",
    "BATOS",
    "BEDENI",
    "BERENI",
    "BERNADEA",
    "BEU",
    "BEZID",
    "BEZIDU NOU",
    "BICASU",
    "BISTRA MURESULUI",
    "BLIDIREASA",
    "BOBOHALMA",
    "BOLINTINENI",
    "BOLOGAIA",
    "BORD",
    "BORDOSIU",
    "BORZIA",
    "BOTEI",
    "BOTEZ",
    "BOZED",
    "BRADETELU",
    "BUJOR",
    "BUJOR-HODAIE",
    "CACIULATA",
    "CACUCIU",
    "CALIMANESTI",
    "CALUGARENI",
    "CALUSERI",
    "CAMPU CETATII",
    "CANDU",
    "CAPALNA DE SUS",
    "CAPUSU DE CAMPIE",
    "CASVA",
    "CEIE",
    "CERGHID",
    "CEUAS",
    "CEUASU DE CAMPIE",
    "CHENDU",
    "CHIHERU DE JOS",
    "CHIHERU DE SUS",
    "CHINCIUS",
    "CHIRILEU",
    "CHISALITA",
    "CIBU",
    "CIOBOTANI",
    "CIRETEA",
    "CIRHAGAU",
    "CIULEA",
    "CLOASTERF",
    "COASTA MARE",
    "COMORI",
    "CORBESTI",
    "CORNESTI (ADAMUS)",
    "CORNESTI (CRACIUNESTI)",
    "COROI",
    "COROISANMARTIN",
    "COTORINAU",
    "COZMA",
    "CRACIUNESTI",
    "CRAIESTI",
    "CRAIESTI (ADAMUS)",
    "CRIS",
    "CULPIU",
    "CUND",
    "CURETE",
    "DAIA (APOLD)",
    "DAIA (BAHNEA)",
    "DAMBU",
    "DAMIENI",
    "DATASENI",
    "DEAG",
    "DEAJ",
    "DEDA",
    "DOBRA",
    "DRACULEA BANDULUI",
    "DROJDII",
    "DUBISTEA DE PADURE",
    "DULCEA",
    "DUMBRAVA",
    "DUMITRENI",
    "DUMITRESTII",
    "DUPA DEAL (CUCI)",
    "DUPA DEAL (ICLANZEL)",
    "ERCEA",
    "EREMIENI",
    "EREMITU",
    "FANATE (BAND)",
    "FANATE (FARAGAU)",
    "FANATE (ICLANZEL)",
    "FANATELE CAPUSULUI",
    "FANATELE MADARASULUI",
    "FANATELE SOCOLULUI",
    "FANTANA BABII",
    "FARAGAU",
    "FELEAG",
    "FILEA",
    "FILITELNIC",
    "FILPISU MIC",
    "FITCAU",
    "FRUNZENI",
    "FUNDOAIA",
    "GALAOAIA",
    "GALATENI",
    "GALESTI",
    "GHINESTI",
    "GLAJARIE",
    "GLODENI",
    "GOGAN",
    "GRADINI",
    "GRAUSORUL",
    "GREBENISU DE CAMPIE",
    "GROAPA RADAII",
    "GURGHIU",
    "HAGAU",
    "HARANGLAB",
    "HARTAU",
    "HEREPEA",
    "HERGHELIA",
    "HODAC",
    "HODAIA",
    "HODOSA",
    "IARA DE MURES",
    "IBANESTI",
    "IBANESTI-PADURE",
    "ICLAND",
    "ICLANDU MARE",
    "ICLANZEL",
    "IDICEL-PADURE",
    "IDICIU",
    "IDRIFAIA",
    "IHOD",
    "ILIENI",
    "ILIOARA",
    "IOD",
    "ISLA",
    "ISTAN-TAU",
    "JACODU",
    "JACU",
    "LAPUSNA",
    "LARGA (GURGHIU)",
    "LARGA (SARMASU)",
    "LASLAU MARE",
    "LASLAU MIC",
    "LECHINCIOARA",
    "LECHINTA",
    "LEFAIA",
    "LENIS",
    "LEORDENI",
    "LEORINTA",
    "LEORINTA-SAULIA",
    "LEPINDEA",
    "LOGIG",
    "LOTU",
    "LUNCA",
    "LUNCA BRADULUI",
    "MACICASESTI",
    "MADARAS",
    "MAGHERANI",
    "MAGHERUS",
    "MAIA",
    "MAIORESTI",
    "MALAESTI",
    "MALDAOCI",
    "MALEA",
    "MARASESTI",
    "MARCULENI",
    "MERISOR",
    "MESTERA",
    "MICA",
    "MIHAI VITEAZU",
    "MIHESU DE CAMPIE",
    "MILASEL",
    "MIRIGIOAIA",
    "MITRESTI",
    "MOGOAIA",
    "MOISA",
    "MORARENI",
    "MORUT",
    "MOSUNI",
    "MURA MARE",
    "MURA MICA",
    "MURENI",
    "MURGESTI",
    "NADES",
    "NEAGRA",
    "NEAUA",
    "NEGRENII DE CAMPIE",
    "NIMA MILASELULUI",
    "NIMA RACIULUI",
    "OARBA DE MURES",
    "OBARSIE",
    "ODRIHEI",
    "ONUCA",
    "ORMENIS",
    "OROIU",
    "ORSOVA",
    "ORSOVA-PADURE",
    "PACURENI",
    "PADUREA",
    "PADURENI",
    "PAINGENI",
    "PARAU CRUCII (POGACEAUA)",
    "PARAU CRUCII (RACIU)",
    "PARAU MARE",
    "PASARENI",
    "PAUCISOARA",
    "PAULOAIA",
    "PETEA",
    "PETRILACA DE MURES",
    "PIETRIS",
    "PIPEA",
    "POARTA",
    "PODURI",
    "POGACEAUA",
    "PRIPOARE",
    "PUSTA",
    "RACIU",
    "RAPA DE JOS",
    "RASTOLITA",
    "RAZOARE",
    "RIGMANI",
    "ROTENI",
    "ROUA",
    "RUSII-MUNTI",
    "SABED",
    "SACALU DE PADURE",
    "SACARENI",
    "SALARD",
    "SALASURI",
    "SALCUD",
    "SAMBRIAS",
    "SANDRU",
    "SANGEORGIU DE CAMPIE",
    "SANMARGHITA",
    "SANMARTINU DE CAMPIE",
    "SANMIHAI DE PADURE",
    "SANPETRU DE CAMPIE",
    "SANSIMION",
    "SANTIOANA",
    "SAPARTOC",
    "SARMASEL",
    "SARMASEL-GARA",
    "SARMASU",
    "SASCHIZ",
    "SATU NOU (SANPETRU DE CAMPIE)",
    "SAULIA",
    "SAULITA",
    "SAUSA",
    "SCURTA",
    "SEBES",
    "SELEUS (ZAGAR)",
    "SENEREUS",
    "SERBENI",
    "SFANTU GHEORGHE",
    "SICELE",
    "SILEA NIRAJULUI",
    "SINCAI",
    "SINCAI-FANATE",
    "SOARD",
    "SOCOLU DE CAMPIE",
    "SOIMUS",
    "SOLOCMA",
    "SOMOSTELNIC",
    "STANCENI",
    "STEFANCA",
    "STEJERIS",
    "SUB PADURE",
    "SUPLAC",
    "TELEAC",
    "TIGMANDRU",
    "TIPTELNIC",
    "TIREU",
    "TIRIMIA",
    "TIRIMIOARA",
    "TISIEU",
    "TITIANA",
    "TOACA",
    "TOLDAL",
    "TONCIU",
    "TORBA",
    "TROITA",
    "TUSINU",
    "UILA",
    "ULIES",
    "URICEA",
    "URISIU DE JOS",
    "URISIU DE SUS",
    "URSOAIA",
    "VADU",
    "VAIDACUTA",
    "VALEA",
    "VALEA DAII",
    "VALEA FRATIEI",
    "VALEA GLODULUI",
    "VALEA ICLANDULUI",
    "VALEA IZVOARELOR",
    "VALEA LARGA",
    "VALEA MARE",
    "VALEA PADURII",
    "VALEA RECE",
    "VALEA SANMARTINULUI",
    "VALEA SANPETRULUI (GREBENISU DE CAMPIE)",
    "VALEA SANPETRULUI (POGACEAUA)",
    "VALEA SAPARTOCULUI",
    "VALEA SASULUI",
    "VALEA SEACA",
    "VALEA SURII",
    "VALEA ULIESULUI",
    "VALEA UNGURULUI",
    "VALEA URIESULUI",
    "VALENI",
    "VALENII",
    "VALENII DE MURES",
    "VALISOARA",
    "VARGATA",
    "VATAVA",
    "VETA",
    "VETCA",
    "VIFOROASA",
    "VIISOARA",
    "VISINELU",
    "VOIVODENI",
    "VULCAN",
    "ZAGAR",
    "ZAU DE CAMPIE",
    "ZIMTI"
  ],
  "NEAMT": [
    "ADJUDENI",
    "AGAPIA",
    "AGAPIA-MANASTIRE",
    "ALEXANDRU CEL BUN",
    "BALTATESTI",
    "BARA",
    "BASTA",
    "BICAZ",
    "BISTRITA",
    "BLEBEA",
    "BODESTI",
    "BODESTII DE JOS",
    "BOGZESTI",
    "BORLESTI",
    "BURUIENESTI",
    "CAPSA",
    "CASARIA",
    "CHINTINICI",
    "CIRITEI",
    "CORDUN",
    "COTU VAMES",
    "CRACAOANI",
    "CRACAUL NEGRU",
    "CURECHISTEA",
    "CUT",
    "DELENI",
    "DOAMNA",
    "DOBRENI",
    "DODENI",
    "DOLJESTI",
    "DULCESTI",
    "DUMBRAVA",
    "DUMBRAVA ROSIE",
    "DUMBRAVA-DEAL",
    "FILIOARA",
    "GADINTI",
    "GHERAESTI",
    "GHINDAOANI",
    "GIULESTI",
    "HAMZOAIA",
    "HORIA",
    "HUMULESTI",
    "HUMULESTII NOI",
    "ION CREANGA",
    "IZVOARE (DUMBRAVA ROSIE)",
    "IZVORU",
    "LUTCA",
    "MAGAZIA",
    "MIRON COSTIN",
    "MUNCELU",
    "NEGRESTI",
    "NEMTISOR",
    "NETEZI",
    "OANTU",
    "OGLINZI",
    "OSLOBENI",
    "PANGARACIOR",
    "PANGARATI",
    "PIATRA-NEAMT",
    "PILDESTI",
    "PLAIESU",
    "POIANA (DULCESTI)",
    "POIANA (PANGARATI)",
    "PRELUCA",
    "RADENI",
    "RAUCESTI",
    "RECEA",
    "ROMAN",
    "ROSIORI",
    "ROTUNDA",
    "ROZNOV",
    "RUSENI (BORLESTI)",
    "SABAOANI",
    "SACALUSESTI",
    "SAGNA",
    "SARATA",
    "SAVINESTI",
    "SCARICICA",
    "SECUIENI",
    "SECUIENII NOI",
    "SIMIONESTI",
    "SLOBOZIA (BOGHICEA)",
    "SLOBOZIA (ROZNOV)",
    "SOVOAIA",
    "STEJARU (ION CREANGA)",
    "STEJARU (PANGARATI)",
    "STRAJA",
    "TAMASENI",
    "TARCAU",
    "TARGU NEAMT",
    "TASCA",
    "TETCANI",
    "TRAIAN (SABAOANI)",
    "TRIFESTI",
    "TURTURESTI",
    "UNCESTI",
    "VADURELE (ALEXANDRU CEL BUN)",
    "VADURI",
    "VALEA ARINI",
    "VALEA SEACA",
    "VALENI (PIATRA-NEAMT)",
    "VANATORI-NEAMT",
    "VARATEC",
    "VIISOARA",
    "VULPASESTI",
    "ZVORANESTI",
    "AGARCIA",
    "ALMAS",
    "ARAMESTI",
    "ARAMOAIA",
    "ARDELUTA",
    "AVERESTI",
    "BAHNA",
    "BAHNA MARE",
    "BAHNISOARA",
    "BALANESTI",
    "BALUSESTI (DOCHIA)",
    "BALUSESTI (ICUSESTI)",
    "BANEASA",
    "BARATCA",
    "BARCANESTI",
    "BARGAUANI",
    "BARJOVENI",
    "BARTICESTI",
    "BATRANESTI",
    "BETESTI",
    "BICAZ-CHEI",
    "BICAZU ARDELEAN",
    "BISERICANI",
    "BISTRICIOARA",
    "BOBOIESTI",
    "BOGHICEA",
    "BOISTEA",
    "BORCA",
    "BORDEA",
    "BORNIS",
    "BORSENI",
    "BOTESTI",
    "BOTESTI (GIROV)",
    "BOZIENI",
    "BOZIENII DE SUS",
    "BRADU",
    "BRASAUTI",
    "BRATES",
    "BREAZA",
    "BRITCANI",
    "BROSTENI",
    "BRUSTURI",
    "BUCIUM",
    "BUDESTI",
    "BUHALNITA",
    "BUHONCA",
    "BUNGHI",
    "BUSMEI",
    "BUTNARESTI",
    "CACIULESTI",
    "CALUGARENI",
    "CANDESTI",
    "CARLIG",
    "CARLIGI",
    "CAUSENI",
    "CAZACI",
    "CEAHLAU",
    "CERTIENI",
    "CHICEREA",
    "CHILIA",
    "CHILII",
    "CHIRITENI",
    "CIORNEI",
    "CIUREA",
    "CLIMESTI",
    "CORHANA",
    "CORNI",
    "COSTISA",
    "CRAIESTI",
    "CUCI",
    "CUEJDIU",
    "DAMUC",
    "DANESTI",
    "DARLOAIA",
    "DAVID",
    "DAVIDENI",
    "DOCHIA",
    "DOINA",
    "DOLHESTI",
    "DORNESTI",
    "DRAGANESTI",
    "DRAGOMIRESTI",
    "DRAGOVA",
    "DREPTU",
    "DURAU",
    "DUSESTI",
    "FARCASA",
    "FAUREI",
    "FRUMOSU",
    "FRUNZENI",
    "GALU",
    "GARCINA",
    "GHERAESTII NOI",
    "GHIDION",
    "GHIGOIESTI",
    "GIROV",
    "GIURGENI",
    "GORUN",
    "GOSMANI",
    "GRINTIES",
    "GROSI",
    "GROZAVESTI",
    "GRUMAZESTI",
    "GURA VAII",
    "HANGU",
    "HANUL ANCUTEI",
    "HARTESTI",
    "HARTOP",
    "HLAPESTI",
    "HOCIUNGI",
    "HOISESTI",
    "HOLM",
    "HOMICENI",
    "HUISUREZ",
    "ICUSESTI",
    "INGARESTI",
    "ITRINESTI",
    "IUCSA",
    "IVANES",
    "IZVOARE (BAHNA)",
    "IZVORU ALB",
    "IZVORU MUNTELUI",
    "LEGHIN",
    "LILIAC",
    "LINSESTI",
    "LUMINIS",
    "LUNCA (BORCA)",
    "LUNCA (ONICENI)",
    "LUNCA (VANATORI-NEAMT)",
    "LUNCA MOLDOVEI",
    "MADEI",
    "MANASTIREA NEAMT",
    "MANOAIA",
    "MARGINENI",
    "MARMURENI",
    "MASTACAN (BORLESTI)",
    "MESTEACAN",
    "MICSUNESTI",
    "MITOCU BALAN",
    "MOLDOVENI",
    "MORENI",
    "MUNCELU DE JOS",
    "MUNTENI",
    "NEAGRA",
    "NECHIT",
    "NEGRESTI (BARA)",
    "NEGRITESTI",
    "NEGULESTI",
    "NISIPORESTI",
    "NISTRIA",
    "ONICENI",
    "PADURENI",
    "PANCESTI",
    "PARAUL CARJEI",
    "PARAUL FAGULUI",
    "PARAUL MARE",
    "PARAUL PANTEI",
    "PASTRAVENI",
    "PATALIGENI",
    "PATRICHENI",
    "PETRICANI",
    "PETRU VODA",
    "PIATRA SOIMULUI",
    "PIETROSU",
    "PIPIRIG",
    "PLUGARI",
    "PLUTON",
    "PODOLENI",
    "POIANA (BRUSTURI)",
    "POIANA (GRINTIES)",
    "POIANA (NEGRESTI)",
    "POIANA CRACAOANI",
    "POIANA HUMEI",
    "POIANA LARGULUI",
    "POIANA TEIULUI",
    "POIENARI",
    "POIENI",
    "POIENILE OANCEI",
    "POLOBOC",
    "POPESTI (FARCASA)",
    "POPESTI (GIROV)",
    "POTOCI",
    "PRAJESTI",
    "PREUTESTI",
    "PUSTIETA",
    "RASCA",
    "RAZBOIENI",
    "RAZBOIENII DE JOS",
    "REDIU",
    "REDIU (BARA)",
    "ROCNA",
    "ROMANI",
    "ROSENI",
    "RUGINESTI",
    "RUGINOASA",
    "RUSENI (POIANA TEIULUI)",
    "SABASA",
    "SACALENI",
    "SAVESTI",
    "SAVINESTI (POIANA TEIULUI)",
    "SCHITU TARCAU",
    "SECU",
    "SILISTEA",
    "SOCEA",
    "SOCI (BORCA)",
    "SOCI (STEFAN CEL MARE)",
    "SOIMARESTI",
    "SOLCA",
    "SPIRIDONESTI",
    "STANCA",
    "STANITA",
    "STEFAN CEL MARE",
    "STEJARU (FARCASA)",
    "TABARA",
    "TALPA",
    "TALPALAI",
    "TARDENII MICI",
    "TARPESTI",
    "TARZIA",
    "TAZLAU",
    "TELEC",
    "TIBUCANI",
    "TIBUCANII DE JOS",
    "TICOS",
    "TICOS-FLOAREA",
    "TIMISESTI",
    "TODIRENI",
    "TOLICI",
    "TOPOLICENI",
    "TOPOLITA",
    "TOTOIESTI",
    "TRAIAN (ZANESTI)",
    "TREI FANTANI",
    "TUPILATI",
    "TUTCANII DIN DEAL",
    "TUTCANII DIN VALE",
    "UNGHENI",
    "UNGHI",
    "URECHENI",
    "VAD",
    "VADURELE (CANDESTI)",
    "VALEA ALBA",
    "VALEA ENEI",
    "VALEA MARE",
    "VALEA URSULUI",
    "VALENI",
    "VEJA",
    "VERSESTI",
    "VLADICENI",
    "VLADNICELE",
    "ZANESTI"
  ],
  "OLT": [
    "ARVATEASCA",
    "BALS",
    "BALTENI",
    "BARCA",
    "BREBENI",
    "BUCINISU MIC",
    "BUICESTI",
    "BUSCA",
    "CAMPU MARE",
    "CAMPU PARULUI",
    "CARACAL",
    "CAZANESTI",
    "CEPESTI",
    "CEZIENI",
    "CHILII",
    "CIOFLANU",
    "CIOROIU",
    "CIREASOV",
    "COLIBASI",
    "COMANCA",
    "COMANITA",
    "CORABIA",
    "CORLATESTI",
    "CRIVA DE JOS",
    "CRIVA DE SUS",
    "CUCUETI",
    "CUNGREA",
    "CURTISOARA",
    "DEVESELU",
    "DOANCA",
    "DOBA",
    "DOBROSLOVENI",
    "DOBROTINET",
    "DRAGANESTI-OLT",
    "DRAGHICENI",
    "DRANOVATU",
    "DUMITRESTI",
    "ENOSESTI",
    "FARCASELE",
    "FARCASU DE JOS",
    "FRASINET-GARA",
    "FRASINETU",
    "GANEASA",
    "GARCOV",
    "GHIMPATI",
    "GIUVARASTI",
    "GRADINARI",
    "GRADISTEA",
    "GRECI (SCHITU)",
    "GROZAVESTI",
    "GURA PADINII",
    "HOTARANI",
    "IBANESTI",
    "IZBICENI",
    "IZVORU",
    "LIICENI",
    "LINIA DIN VALE",
    "MAMURA",
    "MILCOV",
    "MILCOVU DIN DEAL",
    "MILCOVU DIN VALE",
    "OLTISORU",
    "ORLEA",
    "ORLEA NOUA",
    "OTESTII DE JOS",
    "PERETU",
    "PESTRA",
    "PETCULESTI",
    "PIATRA",
    "PIATRA-OLT",
    "PIETRIS",
    "PIETRISU",
    "PLESOIU",
    "POGANU",
    "POTOPINU",
    "PRISEACA",
    "PROASPETI",
    "RAITIU",
    "RECEA",
    "REDEA",
    "REDISOARA",
    "RESCA",
    "RESCUTA",
    "ROSIENII MICI",
    "ROTUNDA",
    "RUNCU MARE",
    "SALCIA",
    "SALTANESTI",
    "SATU NOU (GRADINARI)",
    "SATU NOU (GURA PADINII)",
    "SCHITU DELENI",
    "SLATINA",
    "SLATIOARA",
    "STEJARU",
    "STOENESTI",
    "STREJESTI",
    "STREJESTII DE SUS",
    "TABONU",
    "TRAIAN",
    "TURIA",
    "ULMI",
    "URIA",
    "URSA",
    "VALEA FETEI",
    "VALEA LUI ALB",
    "VALEA MARE",
    "VALEA SOARELUI",
    "VANESTI",
    "VARTOPU",
    "VERGULEASA",
    "VLADILA",
    "VLADILA NOUA",
    "VULTURESTI",
    "ZANOAGA (OLT)",
    "ZORLEASCA",
    "AFUMATI",
    "ALBESTI",
    "ALIMANESTI",
    "ALUNISU",
    "ARCESTI",
    "ARCESTI-COT",
    "BABICIU",
    "BACEA",
    "BAGESTI",
    "BALANESTI",
    "BALDOVINESTI",
    "BALEASA",
    "BALTATI",
    "BARASTI",
    "BARASTI (COLONESTI)",
    "BARASTI (MORUNGLAV)",
    "BARASTII DE CEPTURI",
    "BARASTII DE VEDE",
    "BARBALAI",
    "BARCANESTI",
    "BARSESTII DE SUS",
    "BARZA",
    "BARZA (TUFENI)",
    "BATARENI",
    "BATIA",
    "BECHET",
    "BECULESTI",
    "BELGUN",
    "BERIA DE JOS",
    "BERIA DE SUS",
    "BERINDEI",
    "BIRCII",
    "BISTRITA NOUA",
    "BLAJ",
    "BOBICESTI",
    "BOBU",
    "BONDREA",
    "BOROESTI",
    "BRANCOVENI",
    "BRANET",
    "BRASTAVATU",
    "BROSTENI",
    "BUCINISU",
    "BULIMANU",
    "BURDULESTI",
    "BUTA",
    "BUTOI",
    "BUZESTI",
    "CALINESTI",
    "CALUI",
    "CANDELESTI",
    "CARLOGANI (OLT)",
    "CARSTANI",
    "CATANELE",
    "CEPARI",
    "CERBENI",
    "CHELBESTI",
    "CHERLESTII DIN DEAL",
    "CHERLESTII MOSTENI",
    "CHILIA",
    "CHINTESTI",
    "CHITEASCA",
    "CILIENI",
    "CIOCANESTI (SLATINA)",
    "CIORACA",
    "CIOROIASU",
    "CIURESTI",
    "COCORASTI",
    "COJGAREI",
    "COLONESTI",
    "COMANESTI",
    "COMANI",
    "CONSTANTINESTI",
    "CORBENI",
    "CORBU",
    "CORBU (TESLUI)",
    "CORNATELU",
    "COTEANA",
    "COTENI",
    "COTORBESTI",
    "CRACIUNEI",
    "CRAMPOIA",
    "CRETI",
    "CRUSOVU",
    "CURTISOARA (DOBRETU)",
    "CUZA VODA",
    "DAMBURILE",
    "DANEASA",
    "DAVIDESTI",
    "DEJESTI",
    "DELENI",
    "DIENCI",
    "DOBRETU",
    "DOBRICENI",
    "DOBROTEASA",
    "DOBRUN",
    "DONESTI",
    "FAGETELU",
    "FALCOIU",
    "FLORU",
    "FRUNZARU",
    "GAVANESTI",
    "GHIMPETENI",
    "GHIMPETENII NOI",
    "GHIOSANI",
    "GOSTAVATU",
    "GOVORA",
    "GRADINILE",
    "GRECI (OSICA DE SUS)",
    "GREERESTI",
    "GROJDIBODU",
    "GROPSANI",
    "GRUIU",
    "GUBANDRU",
    "GUESTI",
    "GURA CALUIU",
    "HOREZU",
    "HOTARU",
    "IANCA",
    "IANCA NOUA",
    "IANCU JIANU",
    "ICOANA",
    "IONICESTI",
    "IPOTESTI",
    "ISACI",
    "IZVOARELE",
    "JIENI",
    "JITARU",
    "LAUNELE",
    "LAZARESTI",
    "LELEASCA",
    "LEOTESTI",
    "LISA",
    "LUNCA",
    "MAGURA (PERIETI)",
    "MAGURA (TATULESTI)",
    "MALU ROSU",
    "MANDRA",
    "MANULESTI",
    "MARDALE",
    "MARGARITESTI",
    "MARGHENI",
    "MARGINENI-SLOBOZIA",
    "MARUNTEI",
    "MARUNTEI (COLONESTI)",
    "MERENI",
    "MIERLESTII DE SUS",
    "MIERLICESTI",
    "MIESTI",
    "MIHAESTI",
    "MIHAILESTI-POPESTI",
    "MILCOVENI",
    "MIRCESTI",
    "MIRILA",
    "MOGOSESTI",
    "MOMAIU",
    "MORUNESTI",
    "MORUNGLAV",
    "MOSTENI",
    "MOTOESTI",
    "MOVILENI",
    "NAVARGENI",
    "NEGRENI",
    "NICOLAE TITULESCU",
    "OBARSIA",
    "OBARSIA NOUA",
    "OBOGA",
    "OCIOGI",
    "OLARI",
    "OPORELU",
    "OPTASANI",
    "OPTASI",
    "OPTASI-MAGURA",
    "OSICA DE JOS",
    "OSICA DE SUS",
    "OSTROV",
    "OTESTII DE SUS",
    "PARSCOVENI",
    "PERIETI",
    "PESCARESTI",
    "PIELCANI",
    "PISCANI",
    "PLAVICEANCA",
    "PLAVICENI",
    "PLOPSORELU",
    "POBORU",
    "POIANA",
    "POIANA MARE",
    "POPESTI (BARASTI)",
    "POPESTI (VALENI)",
    "POTCOAVA",
    "POTCOAVA-FALCOENI",
    "POTELU",
    "POTLOGENI",
    "PREOTESTI",
    "PRISACA",
    "PROFA",
    "RACOVITA",
    "RADESTI",
    "RADOMIRESTI",
    "ROMANA",
    "ROSIENII MARI",
    "RUDARI",
    "RUSANESTI",
    "RUSANESTII DE SUS",
    "RUSCIORI",
    "SAMBURESTI",
    "SARBII-MAGURA",
    "SCARISOARA",
    "SCHITU",
    "SCHITU DIN DEAL",
    "SCHITU DIN VALE",
    "SCORBURA",
    "SCORNICESTI",
    "SEACA",
    "SEACA (POBORU)",
    "SERBANESTI",
    "SERBANESTII DE SUS",
    "SIMNICENI",
    "SINESTI",
    "SLAVENI",
    "SOPARLITA",
    "SPATARU",
    "SPINENI",
    "SPRANCENATA",
    "STANULEASA (SAMBURESTI)",
    "STANULEASA (VITOMIRESTI)",
    "STAVARU",
    "STEFAN CEL MARE",
    "STOBORASTI",
    "STOICANESTI",
    "STRUGURELU",
    "STUDINA",
    "STUDINITA",
    "STUPINA",
    "SUICA",
    "SURPENI",
    "TABACI",
    "TATULESTI",
    "TEIS",
    "TEIUS",
    "TEIUSU",
    "TESLUI",
    "TIA MARE",
    "TIRISNEAG",
    "TOMENI",
    "TONESTI (LELEASCA)",
    "TONESTI (SAMBURESTI)",
    "TOPANA",
    "TREPTENI",
    "TRUFINESTI",
    "TUDOR VLADIMIRESCU",
    "TUFARU",
    "TUFENI",
    "ULMET",
    "UNGURENI",
    "URSI",
    "URSOAIA",
    "URZICA",
    "VADASTRA",
    "VADASTRITA",
    "VALCELE",
    "VALCELELE DE SUS",
    "VALEA MERILOR",
    "VALEA SATULUI",
    "VALENI",
    "VALENI (BRANCOVENI)",
    "VINETI",
    "VISINA",
    "VISINA NOUA",
    "VITANESTI",
    "VITOMIRESTI",
    "VLADULENI",
    "VLAICI",
    "VLANGARESTI",
    "VOINEASA",
    "VULPENI",
    "VULPESTI"
  ],
  "PRAHOVA": [
    "ALBESTI-MURU",
    "ALBESTI-PALEOLOGU",
    "ANTOFILOAIA",
    "ARICESTII RAHTIVANI",
    "ARIONESTII NOI",
    "ARIONESTII VECHI",
    "ARVA",
    "BAICOI",
    "BANESTI",
    "BARCANESTI",
    "BATESTI",
    "BERCENI",
    "BIGHILIN",
    "BLEJOI",
    "BOBICESTI",
    "BOBOLIA",
    "BODESTI",
    "BOLDESTI-SCAENI",
    "BORDENII MICI",
    "BRATESTI",
    "BRAZI",
    "BRAZII DE JOS",
    "BRAZII DE SUS",
    "BREAZA",
    "BREBU",
    "BUCHILASI",
    "BUCOV",
    "BUDA (ARICESTII RAHTIVANI)",
    "BUDA (RAFOV)",
    "BUGHEA DE JOS",
    "BUGHEA DE SUS",
    "CAMPINA",
    "CAP ROSU",
    "CARTIERUL DAMBU",
    "CATINA",
    "CATUNU (BERCENI)",
    "CATUNU (DRAJNA)",
    "CERASU",
    "CERNESTI",
    "CHESNOIU",
    "CHICIURENI",
    "CHIRITESTI",
    "CHITORANI",
    "CIOCENI",
    "CIOCRAC",
    "COADA IZVORULUI",
    "COADA MALULUI",
    "COCEANA",
    "COCORASTII CAPLII",
    "COCORASTII COLT",
    "COCORASTII GRIND",
    "COCOSESTI",
    "COLCEAG",
    "COLONIA BRAZI",
    "COLTU DE JOS",
    "CORLATESTI",
    "CORNU",
    "CORNU DE JOS (CORNU)",
    "CORNU DE SUS (CORNU)",
    "COSLEGI",
    "COSMINA DE JOS",
    "COSMINELE",
    "COTOFENESTI",
    "CRIVINA",
    "DAMBU",
    "DARVARI",
    "DITESTI",
    "DRAGANEASA",
    "DRAJNA",
    "DRAJNA DE JOS",
    "DRAJNA DE SUS",
    "DUMBRAVESTI",
    "FACAIENI",
    "FAGET",
    "FAGETU",
    "FANARI (GHERGHITA)",
    "FEFELEI",
    "FILIPESTII DE PADURE",
    "FILIPESTII DE TARG",
    "FLORESTI",
    "FUNDENI",
    "GAGENI",
    "GALMEIA",
    "GHEABA",
    "GHIGHIU",
    "GHIOCEL",
    "GHIOLDUM",
    "GLOD",
    "GOGA",
    "GORGOTA",
    "GORUNA",
    "GROSANI",
    "GURA BELIEI",
    "GURA VITIOAREI",
    "HABUD",
    "HARSA",
    "HOMORACIU",
    "IAZU",
    "IRIMESTI",
    "IZVOARELE",
    "JERCALAI",
    "LILIESTI",
    "LIPANESTI",
    "LIVADEA",
    "LOLOIASCA",
    "LUNCA MARE",
    "LUNCA PRAHOVEI",
    "MAGURELE",
    "MAGURENI",
    "MALAESTII DE SUS",
    "MALAIESTI",
    "MALU VANAT",
    "MANECIU",
    "MANECIU-PAMANTENI",
    "MANECIU-UNGURENI",
    "MARUNTIS",
    "MINIERI",
    "MIROSLAVESTI",
    "MISLEA",
    "MIZIL",
    "MOARA",
    "MOARA DOMNEASCA",
    "MOARA NOUA",
    "NEDELEA",
    "NEGOIESTI",
    "NISIPOASA",
    "NISTORESTI",
    "NUCSOARA DE JOS",
    "NUCSOARA DE SUS",
    "ODAILE",
    "OGRETIN",
    "OLTENI",
    "PALANCA",
    "PANTAZI",
    "PAREPA-RUSANI",
    "PAULESTI",
    "PAULESTII NOI",
    "PERSUNARI (MANESTI)",
    "PIATRA (DRAJNA)",
    "PIETROSANI",
    "PISCULESTI",
    "PITIGOI",
    "PLAI",
    "PLEASA",
    "PLOIESTI",
    "PLOIESTIORI",
    "PLOPENI",
    "PLOPENI (DUMBRAVESTI)",
    "PLOPU",
    "PODU URSULUI",
    "PODURILE",
    "POIANA CAMPINA",
    "POIANA COPACENI",
    "POIANA MIERLEI",
    "POIANA TRESTIEI",
    "POIANA VARBILAU",
    "POIENILE",
    "POPESTI (BRAZI)",
    "POSADA",
    "POSESTI",
    "POSESTII-PAMANTENI",
    "POSESTII-UNGURENI",
    "PRAJANI",
    "PREDEAL-SARARI",
    "PREDESTI",
    "PUCHENII MARI",
    "PUCHENII MICI",
    "PUCHENII-MOSNENI",
    "PUSCASI",
    "RACHIERI",
    "RADILA",
    "RAFOV",
    "RAGMAN",
    "ROMANESTI",
    "SARARI",
    "SARATEL",
    "SATU NOU (BABA ANA)",
    "SATU NOU (LIPANESTI)",
    "SATUCU",
    "SCHELA",
    "SCHIAU (URLATI)",
    "SCHIAU (VALEA CALUGAREASCA)",
    "SCHIULESTI",
    "SCORTENI",
    "SECIU",
    "SICRITA",
    "SINAIA",
    "SIPOTU",
    "SIRNA",
    "SLAVU",
    "SOTRILE",
    "STANCESTI",
    "STEJARU",
    "STOENESTI",
    "STREJNICU",
    "STUBEIU",
    "TANTARENI",
    "TARGSORU NOU",
    "TARGSORU VECHI",
    "TARICENI",
    "TATARANI",
    "TEISANI",
    "TELEGA",
    "TINOSU",
    "TINTEA",
    "TUFENI",
    "TULBUREA",
    "TULBUREA-VALENI",
    "URLATI",
    "URLETA",
    "VALCELELE",
    "VALEA BOBULUI",
    "VALEA BORULUI",
    "VALEA BRADETULUI",
    "VALEA CALUGAREASCA",
    "VALEA CRANGULUI",
    "VALEA DULCE",
    "VALEA LARGA",
    "VALEA LESPEZII",
    "VALEA MANTEI",
    "VALEA MIEILOR",
    "VALEA NICOVANI",
    "VALEA NUCETULUI",
    "VALEA ORLEI",
    "VALEA PIETREI",
    "VALEA PLOPULUI",
    "VALEA POIENII",
    "VALEA POPII",
    "VALEA SEMAN",
    "VALEA STALPULUI",
    "VALEA STUPINII",
    "VALEA URLOII",
    "VALEA URSOII",
    "VALENII DE MUNTE",
    "VARBILAU",
    "VARNITA",
    "VITIOARA DE SUS",
    "ZAHANAUA",
    "ZAMBROAIA",
    "ZAMFIRA",
    "ADUNATI",
    "ALBINARI",
    "ALUNIS",
    "APOSTOLACHE",
    "ARICESTII ZELETIN",
    "AZUGA",
    "BABA ANA",
    "BALTA DOAMNEI",
    "BALTESTI",
    "BALTITA",
    "BARA",
    "BARAITARU",
    "BARZILA",
    "BATRANI",
    "BELCIUG",
    "BERTEA",
    "BOBOCI",
    "BOGDANESTI",
    "BOLDESTI",
    "BOLDESTI-GRADISTEA",
    "BORDENII MARI",
    "BOSILCESTI",
    "BOZIENI",
    "BRADET",
    "BRATASANCA",
    "BREAZA DE JOS",
    "BREAZA DE SUS",
    "BREBU MANASTIREI",
    "BREBU MEGIESESC",
    "BUSTENARI",
    "BUSTENI",
    "BUTUCI",
    "BUZOTA",
    "CALINESTI",
    "CALUGARENI",
    "CARBUNARI",
    "CARBUNESTI",
    "CARJARI",
    "CEPTURA",
    "CEPTURA DE JOS",
    "CEPTURA DE SUS",
    "CHEIA",
    "CHERBA",
    "CHIOJDEANCA",
    "CIORANI",
    "CIORANII DE JOS",
    "CIORANII DE SUS",
    "CIRESANU",
    "CIUPELNITA",
    "COCORASTII MISLII",
    "COMARNIC",
    "CONDURATU",
    "CORNU DE JOS (DRAGANESTI)",
    "CORNU DE SUS (DUMBRAVA)",
    "COSERELE",
    "COSMINA DE SUS",
    "COSTENI",
    "CRANGURILE",
    "CUIB",
    "CURCUBEU",
    "CURMATURA",
    "DOBROTA",
    "DOFTANA",
    "DRAGANESTI",
    "DRAGHICESTI",
    "DUMBRAVA",
    "EZENI",
    "FANARI (GORGOTA)",
    "FANTANELE",
    "FRASINET",
    "FULGA",
    "FULGA DE JOS",
    "FULGA DE SUS",
    "GAVANEL",
    "GHERGHITA",
    "GHINOAICA",
    "GHIOSESTI",
    "GOGEASCA",
    "GORNET",
    "GORNET-CRICOV",
    "GRADISTEA",
    "GRESIA",
    "GURA CRIVATULUI",
    "GURA VADULUI",
    "HATCARAU",
    "INDEPENDENTA",
    "INOTESTI",
    "IORDACHEANU",
    "IZESTI",
    "IZVORU",
    "JUGURENI",
    "LACU TURCULUI",
    "LAPOS",
    "LAPOSEL",
    "LOPATNITA",
    "LUTU ROSU",
    "MAGULA",
    "MAGURA",
    "MALAESTII DE JOS",
    "MALAMUC",
    "MALU ROSU",
    "MANASTIREA SUZANA",
    "MANESTI",
    "MARGINEA PADURII",
    "MARGINENII DE JOS",
    "MARLOGEA",
    "MATITA",
    "MEHEDINTA",
    "MELICESTI",
    "MERDEALA",
    "MERI",
    "MIRESU MARE",
    "MIRESU MIC",
    "MOCESTI",
    "NEVESTEASCA",
    "NOVACESTI",
    "NUCET (CHIOJDEANCA)",
    "NUCET (GORNET)",
    "OCINA DE JOS",
    "OCINA DE SUS",
    "OLARI",
    "OLARII VECHI",
    "OLOGENI",
    "ORZOAIA DE JOS",
    "ORZOAIA DE SUS",
    "OSTROVU",
    "PACURETI",
    "PACURI",
    "PERSUNARI (GURA VADULUI)",
    "PIATRA (MANESTI)",
    "PIATRA (PROVITA DE JOS)",
    "PIATRA MICA",
    "PIETRICEAUA",
    "PIETRICICA",
    "PIETRISU",
    "PIORESTI",
    "PLAIETU",
    "PLAIU (PROVITA DE SUS)",
    "PLAIU (TALEA)",
    "PLAIU CAMPINEI",
    "PLAIU CORNULUI",
    "PLAVIA",
    "PODENII NOI",
    "PODENII VECHI",
    "PODGORIA",
    "PODU CHEII",
    "PODU CORBULUI",
    "PODU LUI GALBEN",
    "PODU LUNG",
    "PODU VADULUI",
    "PODU VALENI",
    "POIANA",
    "POIANA MARE",
    "POIANA TAPULUI",
    "POIENARII APOSTOLI",
    "POIENARII BURCHII",
    "POIENARII VECHI",
    "POIENARII-RALI",
    "POPESTI (PODENII NOI)",
    "POTIGRAFU",
    "PREDEAL(PREDEAL SARARI)",
    "PRISEACA",
    "PROVITA DE JOS",
    "PROVITA DE SUS",
    "RAHOVA",
    "ROTAREA",
    "ROTARI",
    "SALCIA",
    "SALCIILE",
    "SALCIOARA",
    "SANGERU",
    "SARCA",
    "SATU DE SUS",
    "SCURTESTI",
    "SECARIA",
    "SECIURI",
    "SFACARU",
    "SFARLEANCA",
    "SILISTEA",
    "SILISTEA DEALULUI",
    "SLANIC",
    "SLON",
    "SOIMARI",
    "SOIMESTI",
    "STARCHIOJD",
    "STEFESTI",
    "STRAOSTI",
    "SURANI",
    "SURDESTI",
    "TALEA",
    "TARCULESTI",
    "TARLESTI",
    "TARSORENI",
    "TATARAI",
    "TATARU",
    "TESILA",
    "TIPARESTI",
    "TISA",
    "TOHANI",
    "TOMSANI",
    "TONTESTI",
    "TRAISTENI",
    "TRENU",
    "TRESTIENII DE JOS",
    "TRESTIENII DE SUS",
    "TRESTIOARA",
    "TUFANI",
    "UDRESTI",
    "UNGURENI (FANTANELE)",
    "UNGURENI (FILIPESTII DE TARG)",
    "UNGURENI (GHERGHITA)",
    "VADU PARULUI",
    "VADU SAPAT",
    "VALCANESTI",
    "VALEA ANEI",
    "VALEA BRADULUI",
    "VALEA CRICOVULUI",
    "VALEA CUCULUI",
    "VALEA DOFTANEI",
    "VALEA OPRII",
    "VALEA SCHEILOR",
    "VALEA SCREZII",
    "VALEA SEACA",
    "VALEA TARSEI",
    "VALEA TOCII",
    "VALEA UNGHIULUI",
    "VARBILA",
    "VARFURILE",
    "VISTIERU",
    "ZALHANAUA",
    "ZANOAGA",
    "ZMEURET"
  ],
  "SALAJ": [
    "AGHIRES",
    "CIUMARNA",
    "CRISENI",
    "DUMUSLAU",
    "FILDU DE JOS",
    "GARCEIU",
    "HERECLEAN",
    "ORTELEC",
    "PANIC",
    "STANA (ZALAU)",
    "TETISU",
    "TREZNEA",
    "ZALAU",
    "ADALIN",
    "AGRIJ",
    "ALEUS",
    "ALMASU",
    "ALUNIS",
    "ARCHID",
    "BABENI",
    "BABIU",
    "BADACIN",
    "BADON",
    "BAICA",
    "BALAN",
    "BAN",
    "BANISOR",
    "BARSA",
    "BARSAU MARE",
    "BARSAUTA",
    "BENESAT",
    "BERCEA",
    "BEZDED",
    "BIC",
    "BILGHEZ",
    "BIUSA",
    "BIZUSA-BAI",
    "BOBOTA",
    "BOCSA",
    "BOCSITA",
    "BODIA",
    "BOGDANA",
    "BOGHIS",
    "BORLA",
    "BORZA",
    "BOZIES",
    "BOZNA",
    "BRAGLEZ",
    "BREBI",
    "BRUSTURI",
    "BUCIUMI",
    "BULGARI",
    "BUZAS",
    "CALACEA",
    "CAMAR",
    "CAMPIA",
    "CAPALNA",
    "CARASTELEC",
    "CEACA",
    "CEHEI",
    "CEHU SILVANIEI",
    "CERISA",
    "CERNUC",
    "CHECHIS",
    "CHENDREA",
    "CHENDREMAL",
    "CHEUD",
    "CHICHISA",
    "CHIESD",
    "CHILIOARA",
    "CHIZENI",
    "CIGLEAN",
    "CIOCMANI",
    "CIULA",
    "CIURENI",
    "CIZER",
    "CLIT",
    "COLONIA SIGHETU SILVANIEI",
    "CORMENIS",
    "COSEIU",
    "COSNICIU DE JOS",
    "COSNICIU DE SUS",
    "COZLA",
    "CRASNA",
    "CREACA",
    "CRISTELEC",
    "CRISTOLT",
    "CRISTOLTEL",
    "CRISTUR-CRISENI",
    "CUBLESU",
    "CUCEU",
    "CUCIULAT",
    "CUTIS",
    "CUZAPLAC",
    "DABICENI",
    "DEJA",
    "DELENI",
    "DERSIDA",
    "DIOSOD",
    "DOBA",
    "DOBRIN",
    "DOBROCINA",
    "DOH",
    "DOLHENI",
    "DOLU",
    "DOMNIN",
    "DRAGU",
    "DRIGHIU",
    "FABRICA",
    "FAGETU",
    "FALCUSA",
    "FANTANELE",
    "FANTANELE-RUS",
    "FETINDIA",
    "FILDU DE MIJLOC",
    "FILDU DE SUS",
    "FIRMINIS",
    "FIZES",
    "FODORA (GALGAU)",
    "FRANCENII DE PIATRA",
    "FUFEZ",
    "GALASENI",
    "GALGAU",
    "GALGAU ALMASULUI",
    "GALPAIA",
    "GARBOU",
    "GIURTELECU SIMLEULUI",
    "GLOD",
    "GOSTILA",
    "GURA VLADESEI",
    "GURUSLAU",
    "HALMASD",
    "HASMAS",
    "HIDA",
    "HOROATU CEHULUI",
    "HOROATU CRASNEI",
    "HUREZ",
    "HUSENI",
    "HUSIA",
    "HUTA",
    "IAZ",
    "ILEANDA",
    "ILISUA",
    "INAU",
    "IP",
    "JAC",
    "JEBUCU",
    "JIBOU",
    "LAZURI",
    "LEMNIU",
    "LESMIR",
    "LETCA",
    "LOMPIRT",
    "LOZNA",
    "LUMINISU",
    "LUPOAIA",
    "MAERISTE",
    "MAGURA",
    "MAL",
    "MALADIA",
    "MALENI",
    "MARCA",
    "MARCA-HUTA",
    "MARIN",
    "MESESENII DE JOS",
    "MESESENII DE SUS",
    "MESTEACANU",
    "MIERTA",
    "MILUANI",
    "MINEU",
    "MIRSID",
    "MOIAD",
    "MOIGRAD-POROLISSUM",
    "MOTIS",
    "MUNCEL",
    "NADIS",
    "NAIMON",
    "NAPRADEA",
    "NEGRENI",
    "NOTIG",
    "NUSFALAU",
    "PADURENI",
    "PADURIS",
    "PAUSA",
    "PECEIU",
    "PERICEI",
    "PERICEIU MIC",
    "PERII VADULUI",
    "PETRINDU",
    "PETRINZEL",
    "PIROSA",
    "PLESCA",
    "PLOPIS",
    "POARTA SALAJULUI",
    "PODISU",
    "POIANA BLENCHII",
    "POIANA MAGURA",
    "POIANA ONTII",
    "POIENITA",
    "POPENI",
    "POPTELEAC",
    "PORT",
    "PRELUCI",
    "PREOTEASA",
    "PRIA",
    "PRODANESTI",
    "PURCARET",
    "PUSTA",
    "RACAS",
    "RASTOCI",
    "RASTOLT",
    "RASTOLTU DESERT",
    "RATIN",
    "RATOVEI",
    "RECEA",
    "RECEA MICA",
    "ROGNA",
    "ROMANASI",
    "ROMITA",
    "RONA",
    "RUGINOASA",
    "RUS",
    "SAG",
    "SALAJENI",
    "SALATIG",
    "SAMSUD",
    "SANCRAIU ALMASULUI",
    "SANCRAIU SILVANIEI",
    "SANGEORGIU DE MESES",
    "SANMIHAIU ALMASULUI",
    "SANPETRU ALMASULUI",
    "SANTA MARIA",
    "SARBI",
    "SARMASAG",
    "SASA",
    "SEREDEIU",
    "SFARAS",
    "SICI",
    "SIGHETU SILVANIEI",
    "SIMISNA",
    "SIMLEU SILVANIEI",
    "SOIMUS",
    "SOIMUSENI",
    "SOLOMON",
    "SOLONA",
    "SOMES-GURUSLAU",
    "SOMES-ODORHEI",
    "STANA (HUEDIN)",
    "STARCIU",
    "STOBORU",
    "STUPINI",
    "SUB CETATE",
    "SUMAL",
    "SURDUC",
    "SUTORU",
    "TAMASA",
    "TARMURE",
    "TAUDU",
    "TESTIOARA",
    "TIHAU",
    "TOPLITA",
    "TRANIS",
    "TRESTIA",
    "TURBUTA",
    "TUSA",
    "UGRUTIU",
    "UILEACU SIMLEULUI",
    "ULCIUG",
    "VADURELE",
    "VALCAU DE JOS",
    "VALCAU DE SUS",
    "VALEA CIURENILOR",
    "VALEA HRANEI",
    "VALEA LESULUI",
    "VALEA LOZNEI",
    "VALEA LUNGA",
    "VALEA POMILOR",
    "VALENI",
    "VALISOARA",
    "VAR",
    "VARSOLT",
    "VARTESCA",
    "VERVEGHIU",
    "VIILE JACULUI",
    "VOIVODENI",
    "ZALHA",
    "ZALNOC",
    "ZAUAN",
    "ZAUAN-BAI",
    "ZIMBOR"
  ],
  "SATU-MARE": [
    "ACIUA",
    "ALIZA",
    "AMATI",
    "AMBUD",
    "ARDUD",
    "ATEA",
    "BERINDAN",
    "BERVENI",
    "BOTIZ",
    "CAMIN",
    "CAPLENI",
    "CAREI",
    "CHISAU",
    "CIONCHESTI",
    "CUCU",
    "DECEBAL",
    "DOROLT",
    "ETENI",
    "FOIENI",
    "GANAS",
    "GHENCI",
    "GHILESTI",
    "GHIROLT",
    "IANCULESTI",
    "LAZURI",
    "LUCACENI",
    "MADARAS",
    "MARTINESTI",
    "MOFTINU MIC",
    "NISIPENI",
    "NOROIENI",
    "ODOREU",
    "PAULESTI",
    "PETEA",
    "PETRESTI",
    "RADULESTI",
    "RATIU",
    "SATMAREL",
    "SATU MARE",
    "TIREAM",
    "URZICENI",
    "URZICENI-PADURE",
    "VAGAS",
    "VALEA MORII",
    "VANATORESTI",
    "VETIS",
    "ACAS",
    "ADRIAN",
    "ADY ENDRE",
    "AGRIS",
    "ALICENI",
    "ANDRID",
    "APA",
    "APATEU",
    "ARDUD-VII",
    "BABA NOVAC",
    "BABASESTI",
    "BABESTI",
    "BABTA",
    "BARSAU",
    "BARSAU DE JOS",
    "BARSAU DE SUS",
    "BATARCI",
    "BECHENI",
    "BELTIUG",
    "BERCU",
    "BERCU NOU",
    "BEREA",
    "BICAU",
    "BIXAD",
    "BLAJA",
    "BOCICAU",
    "BOGDAND",
    "BOGHIS",
    "BOINESTI",
    "BOLDA",
    "BORLESTI",
    "CALINESTI-OAS",
    "CAMARZANA",
    "CARASEU",
    "CAUAS",
    "CEAN",
    "CEHAL",
    "CEHALUT",
    "CERTEZE",
    "CHEGEA",
    "CHEREUSA",
    "CHILIA",
    "CIDREAG",
    "CIG",
    "CIUMESTI",
    "CIUPERCENI",
    "COCA",
    "COMLAUSA",
    "COROD",
    "CORUND",
    "CRAIDOROLT",
    "CRISENI",
    "CRUCISOR",
    "CULCIU",
    "CULCIU MARE",
    "CULCIU MIC",
    "CUTA",
    "DACIA",
    "DARA",
    "DINDESTI",
    "DINDESTIU MIC",
    "DOBA",
    "DOBOLT",
    "DOBRA",
    "DOMANESTI",
    "DRAGUSENI",
    "DUMBRAVA",
    "ERIU-SANCRAI",
    "GELU",
    "GERAUSA",
    "GHERTA MARE",
    "GHERTA MICA",
    "GHILVACI",
    "GHIRISA",
    "GIOROCUTA",
    "GIUNGI",
    "GIURTELECU HODODULUI",
    "HALMEU",
    "HALMEU-VII",
    "HODISA",
    "HODOD",
    "HOMOROADE",
    "HOMORODU DE JOS",
    "HOMORODU DE MIJLOC",
    "HOMORODU DE SUS",
    "HOREA",
    "HOTOAN",
    "HRIP",
    "HUREZU MARE",
    "HUTA-CERTEZE",
    "IEGHERISTE",
    "IOJIB",
    "IRINA",
    "ISTRAU",
    "LECHINTA",
    "LELEI",
    "LIPAU",
    "LIVADA",
    "LIVADA MICA",
    "LUNA",
    "LUNCA APEI",
    "MARIUS",
    "MARNA NOUA",
    "MEDIES-RATURI",
    "MEDIESU AURIT",
    "MEDIES-VII",
    "MEDISA",
    "MESTEACAN",
    "MICULA",
    "MICULA NOUA",
    "MIHAIENI",
    "MOFTIN",
    "MOFTINU MARE",
    "MOISENI",
    "NADISU HODODULUI",
    "NECOPOI",
    "NEGRESTI-OAS",
    "OAR",
    "ORASU NOU",
    "ORASU NOU-VII",
    "ORBAU",
    "PASUNEA MARE",
    "PAULIAN",
    "PELES",
    "PELISOR",
    "PETIN",
    "PIR",
    "PIRU NOU",
    "PISCARI",
    "PISCOLT",
    "POIANA CODRULUI",
    "POMI",
    "PORTITA",
    "PORUMBESTI",
    "POTAU",
    "PRILOG",
    "PRILOG-VII",
    "RACOVA",
    "RACSA",
    "RACSA-VII",
    "RATESTI",
    "REMETEA OASULUI",
    "RESIGHEA",
    "ROMANESTI",
    "ROSIORI",
    "RUSENI",
    "SACASENI",
    "SAI",
    "SANDRA",
    "SANISLAU",
    "SANMICLAUS",
    "SANTAU",
    "SARATURA",
    "SARAUAD",
    "SARVAZEL",
    "SATU MIC",
    "SAUCA",
    "SCARISOARA NOUA",
    "SECHERESA",
    "SER",
    "SILVAS",
    "SIRLAU",
    "SOCOND",
    "SOCONZEL",
    "SOLDUBA",
    "SOMESENI",
    "STANA",
    "SUDURAU",
    "SUPUR",
    "SUPURU DE JOS",
    "SUPURU DE SUS",
    "TAMASENI",
    "TARNA MARE",
    "TARSOLT",
    "TASNAD",
    "TATARESTI",
    "TEGHEA",
    "TEREBESTI",
    "TIREAC",
    "TRAIAN",
    "TRIP",
    "TUR",
    "TURT",
    "TURT-BAI",
    "TURULUNG",
    "TURULUNG-VII",
    "UNIMAT",
    "VALEA SEACA",
    "VALEA VINULUI",
    "VAMA",
    "VEZENDIU",
    "VIILE SATU MARE",
    "VIISOARA"
  ],
  "SIBIU": [
    "ALMA",
    "AVRIG",
    "BAZNA",
    "BLAJEL",
    "BOITA",
    "BRADU",
    "BRATEIU",
    "BUNGARD",
    "CASOLT",
    "CISNADIE",
    "CISNADIOARA",
    "COLONIA TALMACIU",
    "COLONIA TARNAVA",
    "COPSA MICA",
    "CRISTIAN",
    "DAIA",
    "DARLOS",
    "IGHISU NOU",
    "LAZARET",
    "MARSA",
    "MEDIAS",
    "MOHU",
    "NOU",
    "POPLACA",
    "RACOVITA",
    "ROSIA",
    "RUSCIORI",
    "SADU",
    "SALISTE",
    "SEBESU DE JOS",
    "SEBESU DE SUS",
    "SELIMBAR",
    "SIBIU",
    "SURA MARE",
    "SURA MICA",
    "TALMACEL",
    "TALMACIU",
    "TARNAVA",
    "TURNU ROSU",
    "VALEA LUNGA",
    "VESTEM",
    "ACILIU",
    "AGARBICIU",
    "AGNITA",
    "ALAMOR",
    "ALBI",
    "ALMA VII",
    "ALTINA",
    "AMNAS",
    "APOLDU DE JOS",
    "APOLDU DE SUS",
    "APOS",
    "ARMENI",
    "ARPASU DE JOS",
    "ARPASU DE SUS",
    "ATEL",
    "AXENTE SEVER",
    "BALEA LAC",
    "BARGHIS",
    "BENESTI",
    "BIERTAN",
    "BOARTA",
    "BOGATU ROMAN",
    "BOIAN",
    "BRADENI",
    "BROSTENI",
    "BRUIU",
    "BUIA",
    "BUZD",
    "CARTA",
    "CARTISOARA",
    "CHESLER",
    "CHIRPAR",
    "COLUN",
    "COPSA MARE",
    "CORNATEL",
    "COVES",
    "CRINT",
    "CURCIU",
    "DEALU FRUMOS",
    "DOBARCA",
    "DUMBRAVENI",
    "DUPUS",
    "ERNEA",
    "FANTANELE",
    "FLORESTI",
    "FOFELDEA",
    "GALES",
    "GHERDEAL",
    "GHIJASA DE JOS",
    "GHIJASA DE SUS",
    "GIACAS",
    "GLAMBOACA",
    "GURA RAULUI",
    "GUSU",
    "HAMBA",
    "HASAG",
    "HOGHILAG",
    "HOSMAN",
    "IACOBENI",
    "IGHISU VECHI",
    "ILIMBAV",
    "JINA",
    "LASLEA",
    "LOAMNES",
    "LOTRIOARA",
    "LUDOS",
    "MAG",
    "MALANCRAV",
    "MANDRA",
    "MARPOD",
    "MERGHINDEAL",
    "METIS",
    "MICASASA",
    "MIERCUREA SIBIULUI",
    "MIGHINDOALA",
    "MIHAILENI",
    "MOARDAS",
    "MOSNA",
    "MOTIS",
    "MOVILE",
    "NEMSA",
    "NETUS",
    "NOCRICH",
    "NOISTAT",
    "NOU ROMAN",
    "NOU SASESC",
    "NUCET",
    "OCNA SIBIULUI",
    "ORLAT",
    "PADURENI",
    "PALTIN",
    "PALTINIS",
    "PAUCA",
    "PAUCEA",
    "PELISOR",
    "PETIS",
    "POIANA SIBIULUI",
    "POIENITA",
    "PORUMBACU DE JOS",
    "PORUMBACU DE SUS",
    "PRESACA",
    "PRISLOP",
    "PROD",
    "RASINARI",
    "RAU SADULUI",
    "RAVASEL",
    "RETIS",
    "RICHIS",
    "ROANDOLA",
    "ROD",
    "ROMANESTI",
    "RUJA",
    "RUSI",
    "SACADATE",
    "SACEL",
    "SADINCA",
    "SALCAU",
    "SANGATIN",
    "SARATA",
    "SAROS PE TARNAVE",
    "SASAUS",
    "SCOREIU",
    "SEICA MARE",
    "SEICA MICA",
    "SIBIEL",
    "SLIMNIC",
    "SMIG",
    "SOALA",
    "SOMARTIN",
    "SOROSTIN",
    "STEJARISU",
    "STENEA",
    "TAPU",
    "TELINE",
    "TICHINDEAL",
    "TILISCA",
    "TOPARCEA",
    "VALCHID",
    "VALE",
    "VALEA VIILOR",
    "VALENI",
    "VARD",
    "VECERD",
    "VELT",
    "VESEUD (CHIRPAR)",
    "VESEUD (SLIMNIC)",
    "VURPAR",
    "ZLAGNA"
  ],
  "SUCEAVA": [
    "ADANCATA",
    "ARGESTRU",
    "ARGHIRA",
    "BADEUTI",
    "BAHNA ARIN",
    "BAIA",
    "BARASTI",
    "BASARABI",
    "BERCHISESTI",
    "BOGATA",
    "BOSANCI",
    "BRAIESTI",
    "BUCSOAIA",
    "BULAI",
    "BUNESTI",
    "BURLA",
    "CALINESTI (DARMANESTI)",
    "CALINESTI (SERBAUTI)",
    "CALINESTI-VASILACHE",
    "CALUGARENI",
    "CAMARZANI",
    "CAMPULUNG MOLDOVENESC",
    "CAPU CAMPULUI",
    "CAPU CODRULUI",
    "CLIMAUTI",
    "CLIT",
    "CORLATA",
    "CORNU LUNCII",
    "COSTISA",
    "COTU BAII",
    "CUMPARATURA",
    "DARMANESTI",
    "DORNA CANDRENILOR",
    "DORNA-ARINI",
    "DORNESTI",
    "DOROTEIA",
    "DRAGOMIRNA",
    "DUMBRAVA (CORNU LUNCII)",
    "DUMBRAVITA",
    "FALTICENI",
    "FANTANA MARE",
    "FETESTI",
    "FLORINTA",
    "FRASIN",
    "FRATAUTII NOI",
    "FRATAUTII VECHI",
    "FRUMOASA",
    "GALANESTI",
    "GARA",
    "GARA LEU",
    "GHEORGHITENI",
    "GROAPA VLADICHII",
    "GURA HUMORULUI",
    "HARTOP",
    "HORODNIC",
    "HORODNIC DE JOS",
    "HORODNIC DE SUS",
    "HURJUIENI",
    "IAZ",
    "IPOTESTI",
    "LAMASENI",
    "LEUCUSESTI",
    "LIPOVENI",
    "LISAURA",
    "LITENI (MOARA)",
    "LUNCUSOARA",
    "MANASTIOARA (SIRET)",
    "MANASTIREA HUMORULUI",
    "MANEUTI",
    "MARGINEA",
    "MARITEI",
    "MARITEIA MICA",
    "MERENI",
    "MESTECANIS",
    "MIHOVENI",
    "MILISAUTI",
    "MIRONU",
    "MITOCASI",
    "MITOCU DRAGOMIRNEI",
    "MOARA",
    "MOARA CARP",
    "MOARA NICA",
    "MOLID",
    "PALTINOASA",
    "PATRAUTI",
    "PETIA",
    "PLESA",
    "PLOPENI",
    "PLUTONITA",
    "POCOLENI",
    "PODENI",
    "POIANA MICULUI",
    "PRAXIA",
    "PREUTESTI",
    "PRISACA DORNEI",
    "RADASENI",
    "RADAUTI",
    "RASCA (SUCEAVA)",
    "ROSU",
    "RUSII-MANASTIOARA",
    "SALCEA",
    "SASCA NOUA",
    "SATU MARE",
    "SCHEIA",
    "SECURICENI",
    "SFANTU ILIE",
    "SIRET",
    "SOLDANESTI",
    "SPATARESTI",
    "STROIESTI",
    "SUCEAVA",
    "TIBENI",
    "TISAUTI",
    "TODIRENI",
    "TREI MOVILE",
    "UNCESTI",
    "VADU MOLDOVEI",
    "VARFU DEALULUI",
    "VATRA DORNEI",
    "VOITINEL",
    "VOLOVAT",
    "VORONET",
    "ANTOCENI",
    "ARBORE",
    "ARGEL",
    "ARTARI",
    "BADRAGI",
    "BAINET",
    "BAISESTI",
    "BALACEANA",
    "BALCAUTI",
    "BALINESTI",
    "BANCESTI",
    "BANESTI",
    "BENIA",
    "BERESTI",
    "BILCA",
    "BIVOLARIA",
    "BOBEICA",
    "BODNARENI",
    "BOGDANESTI",
    "BOROAIA",
    "BOTESTI",
    "BOTOS",
    "BOTOSANA",
    "BOTOSANITA MARE",
    "BOTOSANITA MICA",
    "BOTUS",
    "BOTUSEL",
    "BOURA",
    "BRADATEL",
    "BRANISTEA",
    "BRASCA",
    "BREAZA",
    "BREAZA DE SUS",
    "BRODINA",
    "BRODINA (IZVOARELE SUCEVEI)",
    "BRODINA DE JOS",
    "BROSTENI",
    "BROSTENI (DRAGUSENI)",
    "BUDA (RASCA)",
    "BUDA (ZVORISTEA)",
    "BUDENI",
    "BURSUCENI",
    "CACICA",
    "CAJVANA",
    "CALAFINDESTI",
    "CARLIBABA",
    "CARLIBABA NOUA",
    "CASOI",
    "CATRINARI",
    "CHILISENI",
    "CHIRIL",
    "CIOCANESTI",
    "CIOMARTAN",
    "CIPRIAN PORUMBESCU",
    "CIUMARNA",
    "CIUMULESTI",
    "CODRU",
    "COJOCARENI",
    "COJOCI",
    "COLACU",
    "COMANESTI",
    "CORNI",
    "COROCAIESTI",
    "CORPACI",
    "COSNA",
    "COSTANA",
    "COSTILEVA",
    "COTARGASI",
    "COTU DOBEI",
    "COVERCA",
    "COZANESTI",
    "CRUCEA",
    "CUNUNSCHI",
    "DANILA",
    "DARMOXA",
    "DEALU",
    "DEALU FLORENI",
    "DEIA",
    "DELENI",
    "DELNITA",
    "DELUT",
    "DEMACUSA",
    "DOLHASCA",
    "DOLHESTI",
    "DOLHESTII MARI",
    "DOLHESTII MICI",
    "DORNISOARA",
    "DRAGOIASA",
    "DRAGOIESTI",
    "DRAGOSA",
    "DRAGUSENI",
    "DUBIUSCA",
    "DUMBRAVA (GRANICESTI)",
    "DUMBRAVENI",
    "DUMBRAVENI (RASCA)",
    "EHRESTE",
    "FALCAU",
    "FANTANELE",
    "FORASTI",
    "FRASIN (BROSTENI)",
    "FRUMOSU",
    "FUNDU MOLDOVEI",
    "GAINESTI",
    "GEMENEA",
    "GIULESTI",
    "GIURGESTI",
    "GLODU",
    "GRAMESTI",
    "GRANICESTI",
    "GRIGORESTI",
    "GROPENI",
    "GULIA",
    "GURA HAITII",
    "GURA PUTNEI",
    "GURA SOLCII",
    "HALEASA",
    "HANCEA",
    "HANTESTI",
    "HERLA",
    "HOLDA",
    "HOLDITA",
    "HORODNICENI",
    "HREATCA",
    "HUMORENI",
    "HUSI",
    "IACOBENI",
    "IACOBESTI",
    "IASLOVAT",
    "IEDU",
    "IESLE",
    "ILISESTI",
    "IONEASA",
    "IZVOARELE SUCEVEI",
    "JACOTA",
    "JAHALIA",
    "LITENI",
    "LUCACESTI",
    "LUNCA (MILISAUTI)",
    "LUNCA (ZAMOSTEA)",
    "LUNGENI",
    "LUPCINA",
    "MAGURA",
    "MAIDAN",
    "MALINI",
    "MANASTIOARA (UDESTI)",
    "MANOLEA",
    "MAZANAESTI",
    "MERESTI",
    "MESTECENI",
    "MIHAIESTI",
    "MOISA",
    "MOLDOVA-SULITA",
    "MOLDOVITA",
    "MOVILENI",
    "MUSENITA",
    "NEAGRA",
    "NEAGRA SARULUI",
    "NEGOSTINA",
    "NEGRILEASA",
    "NICANI",
    "NIGOTESTI",
    "NISIPITU",
    "NOROCU",
    "OBCINA",
    "ONICENI",
    "ORTOAIA",
    "OSOI",
    "OSTRA",
    "PADURENI",
    "PAISENI",
    "PALTIN",
    "PALTINIS",
    "PALTINU",
    "PANACI",
    "PARAIE",
    "PARAU NEGREI",
    "PARHAUTI",
    "PARTESTII DE JOS",
    "PARTESTII DE SUS",
    "PIETROASA",
    "PILUGANI",
    "PLAI",
    "PLAIU SARULUI",
    "PLAVALARI",
    "PLESESTI",
    "PODU COSNEI",
    "POIANA (DOLHASCA)",
    "POIANA (ZVORISTEA)",
    "POIANA MARULUI",
    "POIANA NEGRII",
    "POIANA STAMPEI",
    "POIENARI",
    "POIENI-SOLCA",
    "POIENI-SUCEAVA",
    "POJORATA",
    "PRALENI",
    "PRELIPCA",
    "PROBOTA",
    "PUTNA",
    "RACOVA",
    "RASCA (MOLDOVITA)",
    "RAUTENI",
    "REUSENI",
    "ROMANESTI (GRANICESTI)",
    "ROSCANI",
    "ROSIORI",
    "ROTOPANESTI",
    "ROTUNDA",
    "RUDESTI",
    "RUNCU",
    "RUSCA",
    "RUSI",
    "SACUTA",
    "SADAU",
    "SADOVA",
    "SALAGENI",
    "SARGHIESTI",
    "SARISOR",
    "SARISORU MARE",
    "SARU BUCOVINEI",
    "SARU DORNEI",
    "SASCA MARE",
    "SASCA MICA",
    "SATU MARE (CRUCEA)",
    "SERBANESTI",
    "SERBAUTI",
    "SES",
    "SESURI",
    "SILISTEA",
    "SILISTEA NOUA",
    "SIMINICEA",
    "SINCA",
    "SLATINA",
    "SLATIOARA (RASCA)",
    "SLATIOARA (STULPICANI)",
    "SLOBOZIA (FANTANELE)",
    "SLOBOZIA (ZVORISTEA)",
    "SLOBOZIA SUCEVEI",
    "SMIDA UNGURENILOR",
    "SOLCA",
    "SOLONET",
    "SOLONETU NOU",
    "STAMATE",
    "STANCA",
    "STANCUTA",
    "STIRBAT",
    "STRAJA",
    "STRAMTURA",
    "STULPICANI",
    "SUCEVITA",
    "SUNATORI",
    "TARNA MARE",
    "TARNICIOARA",
    "TATARU",
    "TAUTESTI",
    "TESNA (COSNA)",
    "TESNA (POIANA STAMPEI)",
    "TIBAU",
    "TODIRESTI",
    "TOLESTI",
    "UDESTI",
    "UIDESTI",
    "ULMA",
    "VADU NEGRILESEI",
    "VALCELELE",
    "VALEA BANCULUI",
    "VALEA BOUREI",
    "VALEA GLODULUI",
    "VALEA MOLDOVEI",
    "VALEA POIENEI",
    "VALEA PUTNEI",
    "VALEA STANEI",
    "VALENI-STANISOARA",
    "VAMA",
    "VARATEC",
    "VARVATA",
    "VASCAUTI",
    "VATRA MOLDOVITEI",
    "VERBIA",
    "VERCICANI",
    "VERESTI",
    "VICOVU DE JOS",
    "VICOVU DE SUS",
    "VICSANI",
    "VOIEVODEASA",
    "VORNICENII MARI",
    "VORNICENII MICI",
    "VULTURESTI",
    "ZAHARESTI",
    "ZALOMESTRA",
    "ZAMOSTEA",
    "ZVORISTEA"
  ],
  "TELEORMAN": [
    "ALEXANDRIA",
    "BASCOVENI",
    "BEIU",
    "BELCIUG",
    "BRANCENI",
    "BRATCOVU",
    "BREBINA",
    "BUJORU (CALMATUIU)",
    "BUTCULESTI",
    "BUZESCU",
    "CALOMFIRESTI",
    "CARLOMANU",
    "CERNETU",
    "CIUPERCENI",
    "CLANITA",
    "COSOTENI",
    "DRAGANESTI DE VEDE",
    "GURUIENI",
    "ISLAZ",
    "LICURICIU",
    "LITA",
    "LUNCA",
    "MAGURA",
    "MALDAENI",
    "MERI",
    "MOLDOVENI",
    "NANOV",
    "NEGRENII DE SUS",
    "NENCIULESTI",
    "OLTEANCA",
    "PAULEASCA",
    "PERETU",
    "PLEASOV",
    "POIANA",
    "POROSCHIA",
    "PRUNDU",
    "PURANI (VITANESTI)",
    "RADULESTI",
    "ROSIORI DE VEDE",
    "SCRIOASTEA",
    "SEACA",
    "SECARA",
    "SEGARCEA-DEAL",
    "SEGARCEA-VALE",
    "SOIMU",
    "STEJARU (CRANGENI)",
    "TIGANESTI",
    "TRAIAN",
    "TURNU MAGURELE",
    "UDA-PACIUREA",
    "VACARESTI",
    "VACENI",
    "VALEA PARULUI",
    "VATRA",
    "VEDEA",
    "VIDELE",
    "VIILE",
    "VITANESTI",
    "ZIMNICEA",
    "ZIMNICELE",
    "ZLATA",
    "ALBENI",
    "ALBESTI",
    "ANTONESTI",
    "BABAITA",
    "BACALESTI",
    "BACIU",
    "BADULEASA",
    "BALACI",
    "BALDOVINESTI",
    "BALTA SARATA",
    "BANEASA",
    "BANOV",
    "BARSESTII DE JOS",
    "BECIU",
    "BEUCA",
    "BLEJESTI",
    "BOGDANA",
    "BOTOROAGA",
    "BRAGADIRU",
    "BRANCOVEANCA",
    "BRATASANI",
    "BRATESTI",
    "BROSTEANCA",
    "BUJORENI",
    "BUJORU",
    "BURDENI",
    "BUTESTI",
    "CALINESTI",
    "CALMATUIU",
    "CALMATUIU DE SUS",
    "CALUGARU",
    "CARAVANETI",
    "CATUNU",
    "CERVENIA",
    "CETATEA",
    "CIOLANESTI",
    "CIOLANESTII DIN DEAL",
    "CIOLANESTII DIN VALE",
    "CIUPERCENI (COSMESTI)",
    "CIURARI",
    "CIURARI-DEAL",
    "COMOARA",
    "CONTESTI",
    "COPACEANCA",
    "COSMESTI",
    "COSOAIA",
    "CRANGENI",
    "CRANGU",
    "CREVENICU",
    "CUCUETI",
    "DARVAS",
    "DEPARATI",
    "DIDESTI",
    "DOBRENI",
    "DOBROTESTI",
    "DOROBANTU",
    "DRACEA",
    "DRACESTI",
    "DRACSANI",
    "DRACSENEI",
    "DRAGANESTI-VLASCA",
    "DRAGHINESTI",
    "DUDU",
    "DULCEANCA",
    "DULCENI",
    "FANTANELE",
    "FLORICA",
    "FRASINET",
    "FRUMOASA",
    "FURCULESTI",
    "GALATENI",
    "GARAGAU",
    "GARDESTI",
    "GRADISTEANCA",
    "GRATIA",
    "GRESIA",
    "INSURATEI",
    "IONASCU",
    "IZVOARELE",
    "LACENI",
    "LADA",
    "LINIA COSTII",
    "LISA",
    "LUDANEASCA",
    "MAGURA CU LILIAC",
    "MARITA",
    "MARZANESTI",
    "MAVRODIN",
    "MERENI",
    "MERENII DE JOS",
    "MERENII DE SUS",
    "MERISANI (BABAITA)",
    "MERISANI (DOBROTESTI)",
    "MOSTENI",
    "MOSTENI (FURCULESTI)",
    "NASTURELU",
    "NAVODARI",
    "NECSESTI",
    "NEGRENI",
    "NEGRENII-OSEBITI",
    "NEGRILESTI",
    "NICOLAE BALCESCU",
    "OBARTU",
    "ODOBEASCA",
    "OLTENI",
    "ORBEASCA",
    "ORBEASCA DE JOS",
    "ORBEASCA DE SUS",
    "PARU ROTUND",
    "PERII BROSTENI",
    "PIATRA",
    "PIETROSANI",
    "PLOPI",
    "PLOPII-SLAVITESTI",
    "PLOSCA",
    "POENI",
    "PREAJBA",
    "PRUNARU",
    "PURANI (SILISTEA)",
    "PURANII DE SUS",
    "PUTINEIU",
    "RADOIESTI",
    "RADOIESTI-DEAL",
    "RADOIESTI-VALE",
    "RASMIRESTI",
    "ROTARESTI",
    "SACENI",
    "SAELELE",
    "SALCIA",
    "SARBENI",
    "SARBENII DE JOS",
    "SATU NOU",
    "SATUL VECHI",
    "SCHITU POIENARI",
    "SCURTU MARE",
    "SCURTU-SLAVESTI",
    "SERICU",
    "SFINTESTI",
    "SILISTEA",
    "SILISTEA (VITANESTI)",
    "SILISTEA GUMESTI",
    "SILISTEA MICA",
    "SLAVESTI",
    "SLOBOZIA MANDRA",
    "SMARDAN",
    "SMARDIOASA",
    "SOCETU",
    "SPATAREI",
    "STEFENI",
    "STEJARU",
    "STOROBANEASA",
    "SUHAIA",
    "TALPA",
    "TALPA POSTEI",
    "TALPA-BASCOVENI",
    "TALPA-OGRAZILE",
    "TARNAVA",
    "TATARASTII DE JOS",
    "TATARASTII DE SUS",
    "TAVARLAU",
    "TECUCI",
    "TELEORMANU",
    "TRIVALEA-MOSTENI",
    "TROIANUL",
    "TUDOR VLADIMIRESCU",
    "TUNARI",
    "UDA-CLOCOCIOV",
    "UDENI",
    "UDUPU",
    "ULMENI",
    "URLUIU",
    "VALEA CIRESULUI",
    "VALEA POSTEI",
    "VANATORI",
    "VARTOAPE",
    "VARTOAPELE DE JOS",
    "VARTOAPELE DE SUS",
    "VATASI",
    "VIISOARA",
    "VOIEVODA",
    "ZAMBREASCA"
  ],
  "TIMIS": [
    "ALBINA",
    "BAZOS",
    "BAZOSU NOU",
    "BECICHERECU MIC",
    "BEREGSAU MARE",
    "BEREGSAU MIC",
    "BOLDUR",
    "BUCOVAT (REMETEA MARE)",
    "CARANI",
    "CERNETEAZ",
    "CHEVERESU MARE",
    "CHISODA",
    "COLONIA BULGARA",
    "CORNESTI",
    "COSTEIU",
    "COVACI",
    "DAROVA",
    "DINIAS",
    "DRAGOIESTI",
    "DRAGSINA",
    "DUDESTII NOI",
    "DUMBRAVITA",
    "GAVOJDIA",
    "GHIRODA",
    "GIARMATA",
    "GIARMATA-VII",
    "GIROC",
    "HERENDESTI",
    "HEZERIS",
    "HODOS (DAROVA)",
    "HONORICI",
    "IANOVA",
    "IZVIN",
    "JABAR",
    "JEBEL",
    "LUGOJ",
    "LUGOJEL",
    "MAGURI",
    "MOSNITA NOUA",
    "MOSNITA VECHE",
    "MURANI",
    "ORTISOARA",
    "PADURENI (JEBEL)",
    "PARTA",
    "PARU",
    "PECIU NOU",
    "PINI",
    "PISCHIA",
    "RECAS",
    "REMETEA MARE",
    "RUDICICA",
    "SACALAZ",
    "SACOSU TURCESC",
    "SAG",
    "SANANDREI",
    "SANMIHAIU GERMAN",
    "SANMIHAIU ROMAN",
    "SANNICOLAU MARE",
    "TAPIA",
    "TIMISOARA",
    "TIPARI",
    "ULIUC",
    "UNIP",
    "URSENI",
    "UTVIN",
    "VALEA LUNGA ROMANA",
    "VICTOR VLAD DELAMARINA",
    "ALIOS",
    "ALTRINGEN",
    "BABSA",
    "BACOVA",
    "BALINT",
    "BALOSESTI",
    "BANLOC",
    "BARA",
    "BARATEAZ",
    "BARNA",
    "BATESTI",
    "BEBA VECHE",
    "BEGHEIU MIC",
    "BELINT",
    "BENCECU DE JOS",
    "BENCECU DE SUS",
    "BERECUTA",
    "BERINI",
    "BETHAUSEN",
    "BICHIGI",
    "BILED",
    "BIRDA",
    "BLAJOVA",
    "BOBDA",
    "BODO",
    "BOGDA",
    "BOTESTI",
    "BOTINESTI",
    "BRANESTI",
    "BREAZOVA",
    "BRESTEA",
    "BRESTOVAT",
    "BUCOVAT (DUMBRAVA)",
    "BULGARUS",
    "BULZA",
    "BUNEA MARE",
    "BUNEA MICA",
    "BUTIN",
    "BUZAD",
    "BUZIAS",
    "CADAR",
    "CALACEA",
    "CAPAT",
    "CARPINIS",
    "CEBZA",
    "CENAD",
    "CENEI",
    "CERNA",
    "CHARLOTENBURG",
    "CHECEA",
    "CHECHES",
    "CHEGLEVICI",
    "CHERESTUR",
    "CHIZATAU",
    "CIACOVA",
    "CIRESU",
    "CIRESU MIC",
    "CLADOVA",
    "CLICIOVA",
    "CLOPODIA",
    "COLONIA FABRICII",
    "COLONIA MICA",
    "COMEAT",
    "COMLOSU MARE",
    "COMLOSU MIC",
    "COSARII",
    "COSAVA",
    "COSEVITA",
    "COSTEIU DE SUS",
    "CRAI NOU",
    "CRALOVAT",
    "CRICIOVA",
    "CRIVINA",
    "CRIVINA DE SUS",
    "CRIVOBARA",
    "CRUCENI",
    "CURTEA",
    "CUTINA",
    "DEJAN",
    "DENTA",
    "DETA",
    "DOBRESTI",
    "DOLAT",
    "DRAGOMIRESTI",
    "DRAGSINESTI",
    "DRINOVA",
    "DUBESTI",
    "DUBOZ",
    "DUDESTII VECHI",
    "DUMBRAVA",
    "FADIMAC",
    "FAGET",
    "FARASESTI",
    "FARDEA",
    "FERENDIA",
    "FIBIS",
    "FICATAR",
    "FOENI",
    "FOLEA",
    "GAD",
    "GAIU MIC",
    "GATAIA",
    "GELU",
    "GHERMAN",
    "GHILAD",
    "GHIZELA",
    "GIERA",
    "GIULVAZ",
    "GLADNA MONTANA",
    "GLADNA ROMANA",
    "GOTTLOB",
    "GRABAT",
    "GRANICERII",
    "GROSI",
    "GRUNI",
    "HAUZESTI",
    "HERNEACOVA",
    "HISIAS",
    "HITIAS",
    "HODONI",
    "HODOS (BRESTOVAT)",
    "HOMOJDIA",
    "ICLODA",
    "ICTAR-BUDINTI",
    "IECEA MARE",
    "IECEA MICA",
    "IERSNIC",
    "IGRIS",
    "IOHANISFELD",
    "IOSIF",
    "IOSIFALAU",
    "IVANDA",
    "JAMU MARE",
    "JDIOARA",
    "JENA",
    "JIMBOLIA",
    "JUPANESTI",
    "JUPANI",
    "JURESTI",
    "LAPUSNIC",
    "LATUNAS",
    "LENAUHEIM",
    "LEUCUSESTI",
    "LIEBLING",
    "LIVEZILE",
    "LOVRIN",
    "LUCARET",
    "LUNCANII DE JOS",
    "LUNCANII DE SUS",
    "LUNGA",
    "MACEDONIA",
    "MANASTIRE",
    "MANASTIUR",
    "MARGINA",
    "MASLOC",
    "MATNICU MIC",
    "MORAVITA",
    "NADAS",
    "NADRAG",
    "NEMESESTI",
    "NERAU",
    "NEVRINCEA",
    "NITCHIDORF",
    "OBAD",
    "OFSENITA",
    "OHABA LUNGA",
    "OHABA ROMANA",
    "OHABA-FORGACI",
    "OLOSAG",
    "OPATITA",
    "OTELEC",
    "OTVESTI",
    "PADURANI",
    "PADURENI (VICTOR VLAD DELAMARINA)",
    "PANIOVA",
    "PARTOS",
    "PERCOSOVA",
    "PERIAM",
    "PESAC",
    "PETROASA MARE",
    "PETROMAN",
    "PETROVASELO",
    "PIETROASA",
    "POGANESTI",
    "POIENI",
    "PORDEANU",
    "POVARGINA",
    "PUSTINIS",
    "RACHITA",
    "RACOVITA",
    "RADMANESTI",
    "RAUTI",
    "REMETEA MICA",
    "REMETEA-LUNCA",
    "ROMANESTI",
    "ROVINITA MARE",
    "ROVINITA MICA",
    "RUDNA",
    "SACENI",
    "SACOSU MARE",
    "SALBAGEL",
    "SALCIUA NOUA",
    "SANDRA",
    "SANGEORGE",
    "SANMARTINU MAGHIAR",
    "SANMARTINU SARBESC",
    "SANOVITA",
    "SANPETRU MARE",
    "SANPETRU MIC",
    "SARAVALE",
    "SARAZANI",
    "SARBOVA",
    "SATCHINEZ",
    "SCULIA",
    "SECAS",
    "SECEANI",
    "SEMLACU MARE",
    "SEMLACU MIC",
    "SILAGIU",
    "SINERSIG",
    "SINTAR",
    "SINTESTI",
    "SIPET",
    "SOCA",
    "SPATA",
    "STAMORA GERMANA",
    "STAMORA ROMANA",
    "STANCIOVA",
    "STIUCA",
    "SUDRIAS",
    "SURDUCU MIC",
    "SUSANI",
    "SUSTRA",
    "TARGOVISTE",
    "TEMERESTI",
    "TEREMIA MARE",
    "TEREMIA MICA",
    "TES",
    "TOAGER",
    "TOMESTI",
    "TOMNATIC",
    "TOPLA",
    "TOPOLOVATU MARE",
    "TOPOLOVATU MIC",
    "TORMAC",
    "TRAIAN VUIA",
    "UIHEI",
    "UIVAR",
    "VALCANI",
    "VARIAS",
    "VISAG",
    "VIZEJDIA",
    "VIZMA",
    "VOITEG",
    "VUCOVA",
    "ZGRIBESTI",
    "ZOLT",
    "ZORANI"
  ],
  "TULCEA": [
    "AGIGHIOL",
    "CASLITA",
    "CATALOI",
    "FRECATEI",
    "MALCOCI",
    "MIHAIL KOGALNICEANU",
    "MINERI",
    "NALBANT",
    "NUFARU",
    "PARCHES",
    "SOMOVA",
    "TUDOR VLADIMIRESCU",
    "TULCEA",
    "ALBA",
    "ARDEALU",
    "ATMAGEA",
    "BABADAG",
    "BAIA",
    "BALABANCEA",
    "BALTENII DE JOS",
    "BALTENII DE SUS",
    "BEIDAUD",
    "BESTEPE",
    "C.A. ROSETTI",
    "CALFA",
    "CAMENA",
    "CAPRIOARA",
    "CARAORMAN",
    "CARCALIU",
    "CARDON",
    "CARJELARI",
    "CASIMCEA",
    "CAUGAGIA",
    "CEAMURLIA DE JOS",
    "CEAMURLIA DE SUS",
    "CEATALCHIOI",
    "CERBU",
    "CERNA",
    "CHILIA VECHE",
    "CISMEAUA NOUA",
    "CIUCUROVA",
    "CLOSCA",
    "COLINA",
    "CORUGEA",
    "CRISAN",
    "DAENI",
    "DOROBANTU",
    "DUNAVATU DE JOS",
    "DUNAVATU DE SUS",
    "ENISALA",
    "FAGARASU NOU",
    "FANTANA MARE",
    "FANTANA OILOR",
    "FLORESTI",
    "GARVAN",
    "GENERAL PRAPORGESCU",
    "GORGOVA",
    "GRECI",
    "GRINDU",
    "HAIDAR",
    "HAMCEARCA",
    "HORIA",
    "I.C. BRATIANU",
    "IAZURILE",
    "ILGANII DE JOS",
    "ILGANII DE SUS",
    "ISACCEA",
    "IULIA",
    "IZVOARELE",
    "JIJILA",
    "JURILOVCA",
    "LASTUNI",
    "LETEA",
    "LUMINITA",
    "LUNCA",
    "LUNCAVITA",
    "MACIN",
    "MAGURELE",
    "MAHMUDIA",
    "MALIUC",
    "MESTERU",
    "MIHAI BRAVU",
    "MILA 23",
    "MINA ALTAN TEPE",
    "MIRCEA VODA",
    "MURIGHIOL",
    "NEATARNAREA",
    "NICOLAE BALCESCU",
    "NICULITEL",
    "NIFON",
    "OSTROV",
    "OSTROV TATARU",
    "PANDURU",
    "PARDINA",
    "PARTIZANI",
    "PATLAGEANCA",
    "PECENEAGA",
    "PERIPRAVA",
    "PIATRA",
    "PLAURU",
    "PLOPUL",
    "POSTA",
    "RACHELU",
    "RAHMAN",
    "RANDUNICA",
    "RAZBOIENI",
    "REVARSAREA",
    "SABANGIA",
    "SALCENI",
    "SALCIOARA",
    "SAMBATA NOUA",
    "SARICHIOI",
    "SARIGHIOL DE DEAL",
    "SARINASUF",
    "SATU NOU",
    "SFANTU GHEORGHE",
    "SFISTOFCA",
    "SLAVA CERCHEZA",
    "SLAVA RUSA",
    "SMARDAN",
    "STANCA",
    "STEJARU",
    "SULINA",
    "TATANIR",
    "TELITA",
    "TICHILESTI",
    "TOPOLOG",
    "TRAIAN",
    "TRESTENIC",
    "TURCOAIA",
    "TURDA",
    "UZLINA",
    "VACARENI",
    "VALEA NUCARILOR",
    "VALEA TEILOR",
    "VASILE ALECSANDRI",
    "VICTORIA",
    "VISINA",
    "VISTERNA",
    "VULTURU",
    "ZEBIL"
  ],
  "VALCEA": [
    "ARANGHEL",
    "ARICIOAIA",
    "ARSANCA",
    "BABENI",
    "BABUESTI",
    "BAILE GOVORA",
    "BAILE OLANESTI",
    "BALCESTI",
    "BALTATENI",
    "BALUTOAIA",
    "BARBUCENI",
    "BARCANELE",
    "BARLOGU",
    "BARSESTI (BUDESTI)",
    "BARSESTI (MIHAESTI)",
    "BARZA",
    "BARZESTI",
    "BERBESTI",
    "BERCIOIU",
    "BERISLAVESTI",
    "BLIDARI",
    "BODESTI (BARBATESTI)",
    "BOGDANESTI (BUJORENI)",
    "BOGDANESTI (OTESANI)",
    "BONCIU",
    "BOROSESTI",
    "BRADISOR",
    "BRATIA DIN DEAL",
    "BRATIA DIN VALE",
    "BREZOI",
    "BUCSANI",
    "BUDA",
    "BUDESTI",
    "BUJORENI",
    "BULETA",
    "BUNESTI",
    "BUZDUGAN",
    "CACIULATA",
    "CALIMANESTI",
    "CALINA",
    "CAPU DEALULUI",
    "CAZANESTI (RAMNICU VALCEA)",
    "CERNA",
    "CERNELELE",
    "CETATEAUA",
    "CIORASTI",
    "COASTA (PAUSESTI-MAGLASI)",
    "COASTA CERBULUI",
    "COASTA MARE",
    "COCORU",
    "CONDOIESTI",
    "COPACELU",
    "CORBU",
    "CORNET",
    "COSANI",
    "COSOTA",
    "COSTESTI",
    "CRETENI",
    "CURATURILE",
    "DAESTI",
    "DANGESTI",
    "DEALU MALULUI",
    "DEALU MARE (GALICEA)",
    "DEALU MARE (IONESTI)",
    "DELURENI (IONESTI)",
    "DEZROBITI",
    "DRAGANESTI (BREZOI)",
    "DRAGASANI",
    "DUMBRAVESTI",
    "FACAI",
    "FEDELESOIU",
    "FIRESTI",
    "FIRIJBA",
    "FISCALIA",
    "FOLESTII DE JOS",
    "FOLESTII DE SUS",
    "FOTESTI",
    "FRANCESTI",
    "FRANCESTI-COASTA",
    "GALICEA",
    "GATEJESTI",
    "GENUNENI",
    "GOLOTRENI",
    "GORUNESTI (SLATIOARA)",
    "GOVORA",
    "GUGUIANCA",
    "GURA SUHASULUI",
    "GURA VAII",
    "GURISOARA",
    "GUSOENI",
    "HOREZU",
    "HOTARELE",
    "IFRIMESTI",
    "IONESTI",
    "IZVORASU",
    "IZVORU",
    "IZVORU RECE (VAIDEENI)",
    "JIBLEA NOUA",
    "JIBLEA VECHE",
    "LINIA (BUDESTI)",
    "LIVADIA",
    "LUNCA (BUJORENI)",
    "LUNCA (OCNELE MARI)",
    "MAGURA",
    "MAGURENI",
    "MALDARESTI",
    "MALDARESTII DE JOS",
    "MALU ALB",
    "MALU VARTOP",
    "MANAILESTI",
    "MARCEA",
    "MARITA",
    "MAZILI",
    "MIHAESTI",
    "MILOSTEA",
    "MIRESTI",
    "MITROFANI",
    "MOGESTI",
    "MOSTENI",
    "MRENESTI",
    "MUEREASCA",
    "MUNTENI",
    "NEGRENI",
    "NEGRULESTI",
    "NEMOIU",
    "OCNELE MARI",
    "OCNITA",
    "OLANESTI",
    "OLTENI",
    "ORLESTI",
    "OSTROVENI",
    "OTESANI",
    "PADURETU",
    "PATESTI",
    "PAUSA",
    "PAUSESTI",
    "PAUSESTI-MAGLASI",
    "PAUSESTI-OTASAU",
    "PIETRARI",
    "PIETRARI (PAUSESTI-MAGLASI)",
    "PIETRARII DE SUS",
    "PISCU PIETREI",
    "PRAJILA",
    "PRIBA",
    "PRIPOARA",
    "PRIPORU",
    "PROCOPOAIA",
    "PRODANESTI",
    "PRUNDENI",
    "RACOVITA (BUDESTI)",
    "RADACINESTI",
    "RAMESTI (HOREZU)",
    "RAMNICU VALCEA",
    "RAPANESTI",
    "RAURENI",
    "ROMANI",
    "ROMANII DE JOS",
    "ROMANII DE SUS",
    "ROSOVENI",
    "RUDA",
    "RUGETU (MIHAESTI)",
    "RUGETU (SLATIOARA)",
    "SALATRUCEL",
    "SANBOTIN",
    "SCARISOARA",
    "SCAUENI",
    "SEACA (CALIMANESTI)",
    "SERBANESTI (PAUSESTI)",
    "SERBANESTI (STEFANESTI)",
    "SILEA",
    "SIRINEASA",
    "SLATIOARA",
    "SLATIOARELE",
    "SLAVITESTI",
    "SOLICESTI",
    "SPARLENI",
    "STEFANESTI",
    "STOENESTI",
    "STOLNICENI",
    "STREMINOASA",
    "STUPAREI",
    "SUB DEAL",
    "SURPATELE",
    "SUTESTI",
    "TANASESTI",
    "TATARANI",
    "TEICA",
    "TEIU",
    "TELECHESTI",
    "TIGHINA",
    "TISA",
    "TITIRECI",
    "TOMSANI",
    "TROIAN",
    "TRUNDIN",
    "ULMETEL",
    "URSANI",
    "VAIDEENI",
    "VALEA CASELOR (DRAGASANI)",
    "VALEA CHEII",
    "VALEA LUI STAN",
    "VALEA MARE (BABENI)",
    "VALENI (PAUSESTI)",
    "VARATICA",
    "VARATICI",
    "VERDEA",
    "VIISOARA",
    "VLADESTI",
    "VLADUCENI",
    "VOICESTI",
    "VOICESTII DIN VALE",
    "VULPUESTI",
    "ZARNENI",
    "ZAVIDENI",
    "ZLATAREI",
    "AFANATA",
    "ALDESTI",
    "ALUNU",
    "AMARASTI",
    "ANDREIESTI",
    "ANINOASA",
    "ARMASESTI",
    "AURESTI",
    "AVRAMESTI",
    "BABENI-OLTETU",
    "BADENI",
    "BAIASA",
    "BAIASU",
    "BAJENARI",
    "BALACIU",
    "BALOMIREASA",
    "BALOTA",
    "BALSOARA",
    "BALTENI",
    "BANESTI",
    "BANTESTI",
    "BARBARIGENI",
    "BARBATESTI",
    "BARCANESTI",
    "BAROIU",
    "BARSOIU",
    "BATASANI",
    "BECSANI",
    "BENESTI",
    "BERBESTI (LALOSU)",
    "BERESTI",
    "BISTRITA",
    "BLANOIU",
    "BLEJANI",
    "BOCSA",
    "BODESTI (ALUNU)",
    "BOGDANESTI (TOMSANI)",
    "BOISOARA",
    "BONDOCI",
    "BOTORANI",
    "BRADU-CLOCOTICI",
    "BRATOVESTI",
    "BROSTENI",
    "BUCIUMENI",
    "BUDELE",
    "BUDESTI (DICULESTI)",
    "BUDURASTI",
    "BULAGEI",
    "BUMBUESTI",
    "BUNGETANI",
    "BURDALESTI",
    "BUTANU",
    "CAINENI",
    "CAINENII MARI",
    "CAINENII MICI",
    "CALIGI",
    "CALINESTI",
    "CARCADIESTI",
    "CARLOGANI (VALCEA)",
    "CARSTANESTI",
    "CASA VECHE",
    "CATETU",
    "CAZANESTI (GHIOROIU)",
    "CAZANESTI (MILCOIU)",
    "CERETU",
    "CERMEGESTI (LADESTI)",
    "CERMEGESTI (PESCEANA)",
    "CERNISOARA",
    "CHEIA",
    "CHERASTI",
    "CHICENI",
    "CHIRCULESTI",
    "CHIRICESTI",
    "CIOBOTI",
    "CIOCALTEI",
    "CIOCANARI",
    "CIOPONESTI",
    "CIORTESTI",
    "CIRESU",
    "CIRESUL",
    "CIUCHETI",
    "CIUMAGI",
    "CIUNGETU",
    "CIUTESTI",
    "COASTA (GOLESTI)",
    "COLELIA",
    "COLTESTI",
    "COMANCA",
    "CONTEA",
    "COPACENI",
    "COPACENI (RACOVITA)",
    "CORBII DIN VALE",
    "CRANGU",
    "CREMENARI",
    "CUCESTI",
    "CUCI",
    "CUCOIU",
    "CUCULESTI",
    "CUENI",
    "CURTEA",
    "DAESTI (POPESTI)",
    "DAMTENI",
    "DANCAI",
    "DANICEI",
    "DEALU ALUNIS",
    "DEALU BISERICII",
    "DEALU CORNI",
    "DEALU GLAMEIA",
    "DEALU LAUNELE",
    "DEALU MARE (GUSOENI)",
    "DEALU SCHEIULUI",
    "DEALU VALENI",
    "DEJOI",
    "DELENI",
    "DELURENI (STOILESTI)",
    "DELURENI (VALEA MARE)",
    "DIACONESTI",
    "DIANU",
    "DICULESTI",
    "DIMULESTI",
    "DOBRESTI",
    "DOBRICEA",
    "DOBRICENI",
    "DOBRUSA",
    "DOSU RAULUI",
    "DOZESTI",
    "DRAGANESTI (GOLESTI)",
    "DRAGANU",
    "DRAGIOIU",
    "DRAGOESTI",
    "DRAGULESTI",
    "DUMBRAVA",
    "FARTATESTI",
    "FAURESTI",
    "FAURESTI (ZATRENI)",
    "FRASINA",
    "FUMURENI",
    "FUNDATURA",
    "GAGENI",
    "GAINESTI",
    "GALTOFANI",
    "GANESTI",
    "GANTULEI",
    "GARNICET",
    "GARNICETU",
    "GAUJANI",
    "GAVANESTI",
    "GEAMANA (DRAGOESTI)",
    "GEAMANA (STOILESTI)",
    "GHINDARI",
    "GHIOBESTI",
    "GHIOROIU",
    "GIBESTI",
    "GINERICA",
    "GIULESTI",
    "GIULESTII DE SUS",
    "GIURGIUVENI",
    "GIUROIU",
    "GLAVILE",
    "GLODU",
    "GOLESTI",
    "GORUNESTI (BALCESTI)",
    "GRADISTEA",
    "GREBLESTI",
    "GRECI",
    "GROPENI",
    "GROSI",
    "GRUIERI",
    "GRUIU",
    "GRUIU LUPULUI",
    "GURA CRUCILOR",
    "GURGUIATA",
    "GUSOIANCA",
    "HERASTI",
    "HOTARASA",
    "HOTAROAIA",
    "IACOVILE",
    "IGOIU",
    "ILACIU",
    "IRIMESTI",
    "IZBASESTI",
    "IZVORU RECE (STOILESTI)",
    "JAROSTEA",
    "LACUSTENI",
    "LACUSTENII DE JOS",
    "LACUSTENII DE SUS",
    "LADESTI",
    "LALOSU",
    "LAPUSATA",
    "LAUNELE DE JOS",
    "LINIA (GRADISTEA)",
    "LINIA DEALULUI",
    "LINIA HANULUI",
    "LINIA PE VALE",
    "LIVEZI",
    "LUNGESTI",
    "LUPOAIA",
    "LUPUIESTI",
    "MACIUCA",
    "MACIUCENI",
    "MADULARI",
    "MADULARI (CERNISOARA)",
    "MALAIA",
    "MALDARESTI (LADESTI)",
    "MALDARESTI (MACIUCA)",
    "MALU",
    "MAMU",
    "MANEASA",
    "MANGURENI",
    "MANICEA",
    "MARCUSU",
    "MARGINENI",
    "MARICESTI",
    "MATEESTI",
    "MAZARARU",
    "MECEA",
    "MEIENI",
    "MERESESTI",
    "MIEREA",
    "MIJATI",
    "MIJLOCU",
    "MILCOIU",
    "MILESTI",
    "MLACENI",
    "MODOIA",
    "MOGOSESTI",
    "MOLOGESTI",
    "MOSOROASA",
    "MUEREASCA DE SUS",
    "NEGHINESTI",
    "NEGRAIA",
    "NENCIULESTI",
    "NETESTI",
    "NICOLAE BALCESCU",
    "NICOLESTI",
    "NISIPI",
    "OBARSIA",
    "OBISLAVU",
    "OBOGENI",
    "OBROCESTI",
    "OCRACU",
    "OLANU",
    "OLTEANCA (GLAVILE)",
    "OLTEANCA (LADESTI)",
    "OLTETANI",
    "OLTETU",
    "OPATESTI",
    "OTETELISU",
    "OVESELU",
    "PADINA",
    "PALANGA",
    "PARAIENII DE JOS",
    "PARAIENII DE MIJLOC",
    "PARAIENII DE SUS",
    "PARAUSANI",
    "PASAREI",
    "PASCOAIA",
    "PASCULESTI",
    "PERISANI",
    "PERTESTI",
    "PESCEANA",
    "PIETRENI",
    "PIETRISU",
    "PIETROASA",
    "PISCU MARE",
    "PISCU SCOARTEI",
    "PLEASA",
    "PLESESTI",
    "PLESOIU (LIVEZI)",
    "PLESOIU (NICOLAE BALCESCU)",
    "PODENI",
    "POENARI",
    "POENITA",
    "POIANA",
    "POIENARI",
    "POJOGI-CERNA",
    "POPESTI",
    "POPESTI (FARTATESTI)",
    "POPESTI (GOLESTI)",
    "POPESTI (LADESTI)",
    "POPESTI (MACIUCA)",
    "POPESTI (NICOLAE BALCESCU)",
    "POPESTI (SINESTI)",
    "POPESTI (STOENESTI)",
    "POPESTI (TETOIU)",
    "PORTARESTI",
    "PREDESTI",
    "PREOTESTI",
    "PRILOAGE",
    "PRIPOARE",
    "PROIENI",
    "RACOVITA",
    "RACU",
    "RAMESTI (SUSANI)",
    "RAPA CARAMIZII",
    "RATALESTI",
    "RAU VADULUI",
    "ROBAIA",
    "ROBESTI",
    "ROESTI",
    "ROESTI (PESCEANA)",
    "ROMANESTI",
    "ROSIA",
    "ROSIILE",
    "ROSIOARA",
    "ROTARASTI",
    "RUNCU",
    "RUSANESTI",
    "SAIOCI",
    "SALISTEA",
    "SARBI",
    "SARSANESTI",
    "SARULESTI",
    "SASCIOARA",
    "SATU POIENI",
    "SCAIOSI",
    "SCHITU",
    "SCORUSU",
    "SCUNDU",
    "SEACA (SALATRUCEL)",
    "SECIU",
    "SERBANEASA",
    "SERBANESTI (LAPUSATA)",
    "SERBANESTI (SALATRUCEL)",
    "SINESTI",
    "SNAMANA",
    "SOTANI",
    "SPINU",
    "STANCULESTI",
    "STANESTI",
    "STANESTI (STOILESTI)",
    "STANESTI-LUNCA",
    "STANOMIRU",
    "STEFANESTI (MACIUCA)",
    "STIRBESTI",
    "STOENESTI (BERISLAVESTI)",
    "STOICANESTI",
    "STOICULESTI",
    "STOILESTI",
    "STRACHINESTI",
    "STROESTI",
    "SUIESTI",
    "SURDOIU",
    "SURICARU",
    "SURPATI",
    "SUSANI",
    "SUSENI",
    "SUTA",
    "TANISLAVI",
    "TARGU GANGULESTI",
    "TEIUL",
    "TEIUSU",
    "TEPESTI",
    "TEPSENARI",
    "TETOIU",
    "TINA",
    "TITESTI",
    "TUFANII",
    "TULEI-CAMPENI",
    "TURBUREA",
    "TURCESTI",
    "TUTULESTI",
    "TUTURU",
    "UDRESTI",
    "ULMETU",
    "URSI (POPESTI)",
    "URSI (STOILESTI)",
    "URSOAIA",
    "URZICA",
    "USUREI",
    "VALEA ALUNISULUI",
    "VALEA BABEI",
    "VALEA BALCEASCA",
    "VALEA CASELOR (POPESTI)",
    "VALEA GRADISTEI",
    "VALEA LUNGA",
    "VALEA MACESULUI",
    "VALEA MARE",
    "VALEA MARE (BERBESTI)",
    "VALEA RAULUI",
    "VALEA SCHEIULUI",
    "VALEA URSULUI",
    "VALEA VALENI",
    "VALEA VIEI",
    "VALENI (ZATRENI)",
    "VARATECI",
    "VARLENI",
    "VATASESTI",
    "VETELU",
    "VLADULESTI",
    "VOICULEASA",
    "VOINEASA",
    "VOINESITA",
    "ZARNESTI",
    "ZATRENI",
    "ZATRENII DE SUS",
    "ZAVOIENI",
    "ZGUBEA",
    "ZMEURATU"
  ],
  "VASLUI": [
    "ALBESTI (DELESTI)",
    "ARSITA",
    "BACAOANI",
    "BADEANA",
    "BAHNARI",
    "BALESTI",
    "BALTENI",
    "BALTENI-DEAL",
    "BARLAD",
    "BEJENESTI",
    "BENESTI",
    "BOBESTI",
    "BOGDANA-VOLOSENI",
    "BOUSORI",
    "BRODOC",
    "BROSTENI",
    "BUDESTI",
    "BULBOACA",
    "BUTUCARIA",
    "CANTALARESTI",
    "CAPOTESTI",
    "CAPUSNENI",
    "CHERSACOSU",
    "CHETRESTI",
    "CHITOC",
    "CIOFENI",
    "CIORTOLOM",
    "CODRENI",
    "CORBU",
    "COSCA",
    "CRANG",
    "CRASNA",
    "CRASNASENI",
    "CRETESTI",
    "CRETESTII DE SUS",
    "DAVIDESTI",
    "DEALU MARE",
    "DELEA",
    "DELESTI",
    "DOBROSLOVESTI",
    "DRUJESTI",
    "DUDA",
    "DUDA-EPURENI",
    "EPURENI (DUDA-EPURENI)",
    "FANTANA BLANARULUI",
    "FUNDATURA (DELESTI)",
    "FUNDU VAII (LIPOVAT)",
    "GAVANU",
    "GIURGESTI",
    "GRIVITA",
    "GURA VAII",
    "HARSOVA",
    "HUSI",
    "IVANESTI (PADURENI)",
    "IVESTI",
    "LAZA",
    "LEOSTI (PADURENI)",
    "LIPOVAT",
    "LUNCA BANULUI",
    "MACRESTI (ZAPODENI)",
    "MARASENI",
    "MOARA DOMNEASCA",
    "MOARA GRECILOR",
    "MUNTENII DE JOS",
    "MUNTENII DE SUS",
    "OLTENESTI",
    "OPRISITA",
    "PADURENI",
    "PERIENI",
    "POIANA LUI ALEXA",
    "POIENESTI",
    "POPENI (ZORLENI)",
    "PORTARI",
    "PUSCASI",
    "RADUCANI",
    "RASNITA",
    "REDIU (VASLUI)",
    "RUSCA",
    "SARATU",
    "SATU NOU (CRETESTI)",
    "SATU NOU (MUNTENII DE SUS)",
    "SATU NOU (SOLESTI)",
    "SAUCA",
    "SECUIA",
    "SIMILA",
    "SOLESTI",
    "STANILESTI",
    "STEFAN CEL MARE",
    "STROIESTI",
    "TANACU",
    "TEISORU",
    "TODIRENI",
    "TRESTIANA",
    "TUTOVA",
    "URLATI",
    "VALEA GRECULUI",
    "VALEA LUI BOSIE",
    "VALEA TARGULUI",
    "VALENI",
    "VALENI (PADURENI)",
    "VASLUI",
    "VIISOARA (VASLUI)",
    "VIZURENI",
    "ZAPODENI",
    "ZIZINCA",
    "ZORLENI",
    "1 DECEMBRIE",
    "ALBESTI",
    "ALBINA",
    "ALBITA",
    "ALEXANDRU VLAHUTA",
    "ARMASENI (BACESTI)",
    "ARMASENI (BUNESTI-AVERESTI)",
    "ARMASOAIA",
    "ARSURA",
    "AVERESTI",
    "AVRAMESTI",
    "BABUSA",
    "BABUTA",
    "BACANI",
    "BACESTI",
    "BAILE DRANCENI",
    "BALTATENI",
    "BALTATI",
    "BANCA",
    "BANCESTI",
    "BARBOSI",
    "BARLALESTI",
    "BARTALUS-MOCANI",
    "BARTALUS-RAZESI",
    "BARZESTI",
    "BELCESTI",
    "BELZENI",
    "BEREASA",
    "BEREZENI",
    "BLAGESTI",
    "BLESCA",
    "BOGDANA",
    "BOGDANESTI",
    "BOGDANESTI (FALCIU)",
    "BOGDANITA",
    "BOGESTI",
    "BOLATI",
    "BORODESTI",
    "BOTESTI",
    "BOTOAIA",
    "BOTOI",
    "BOZIA",
    "BRADESTI",
    "BRAHASOAIA",
    "BROSCOSESTI",
    "BUDA (ALEXANDRU VLAHUTA)",
    "BUDA (BOGDANESTI)",
    "BUDA (OSESTI)",
    "BUDU CANTEMIR",
    "BUHAIESTI",
    "BUMBATA",
    "BUNESTI",
    "BUNESTI-AVERESTI",
    "BURSUCI",
    "BUSCATA",
    "CALIMANESTI",
    "CALUGARENI",
    "CARJA",
    "CARJOANI",
    "CARTIBASI",
    "CAZANESTI",
    "CEPESTI",
    "CETATUIA",
    "CHETROSU",
    "CHILIENI",
    "CHIRCESTI",
    "CHITCANI",
    "CIOATELE",
    "CIOCANI",
    "CIUPERCA",
    "CODAESTI",
    "CONDREA",
    "COPACEANA",
    "CORNI-ALBESTI",
    "COROBANESTI",
    "CORODESTI",
    "COROIESTI",
    "COROIESTI (BOGDANITA)",
    "COROIESTII DE SUS",
    "COROIU",
    "COSESTI",
    "COSTESTI",
    "COTIC",
    "COZMESTI",
    "CRACIUNESTI",
    "CRANGU NOU",
    "CRISTESTI",
    "CRIVESTI",
    "CUJBA",
    "CURSESTI-DEAL",
    "CURSESTI-VALE",
    "CURTENI",
    "DANESTI",
    "DEALU SECARII",
    "DELENI",
    "DELENI (HOCENI)",
    "DIMITRIE CANTEMIR",
    "DINGA",
    "DOAGELE",
    "DOCANEASA",
    "DOCANI",
    "DODESTI",
    "DRACSENI (REBRICEA)",
    "DRAGESTI",
    "DRAGOMANESTI",
    "DRAGOMIRESTI",
    "DRANCENI",
    "DRAXENI (GHERGHESTI)",
    "DUMASCA",
    "DUMBRAVENI",
    "DUMESTI",
    "DUMESTII VECHI",
    "EMIL RACOVITA",
    "EPURENI",
    "FALCIU",
    "FANTANELE",
    "FASTACI",
    "FEDESTI",
    "FERESTI",
    "FLORENI",
    "FLORESTI",
    "FOCSA",
    "FOCSEASCA",
    "FRASINU",
    "FRUNTISENI",
    "FULGU",
    "FUNDATURA (ARSURA)",
    "FUNDATURA MARE",
    "FUNDATURA MICA",
    "FUNDU VAII (POIENESTI)",
    "GAGESTI",
    "GALTESTI",
    "GANESTI",
    "GARA BANCA",
    "GARA DOCANEASA",
    "GARA ROSIESTI",
    "GARA TALASMAN",
    "GARCENI",
    "GARDESTI",
    "GHERGHELEU",
    "GHERGHESTI",
    "GHERMANESTI (BANCA)",
    "GHERMANESTI (DRANCENI)",
    "GHICANI",
    "GHIREASCA",
    "GIURCANI",
    "GLODENI",
    "GRAJDENI",
    "GRUMEZOAIA",
    "GUGESTI",
    "GURA ALBESTI",
    "GURA IDRICI",
    "GUSITEI",
    "HALARESTI",
    "HALTA DODESTI",
    "HARSOVENI",
    "HOCENI",
    "HORDILESTI",
    "HORGA",
    "HOROIATA",
    "HREASCA",
    "HUC",
    "HUPCA",
    "HURDUGI",
    "IANA",
    "IAZ",
    "IBANESTI",
    "IDRICI",
    "IEZER",
    "IEZEREL",
    "IGESTI",
    "IVANESTI",
    "JIGALIA",
    "LACU BABEI",
    "LALESTI",
    "LATESTI",
    "LAZU",
    "LEOSTI (TATARANI)",
    "LUNCA",
    "LUNCA VECHE",
    "LUPESTI",
    "MACRESTI (REBRICEA)",
    "MALAIESTI",
    "MALUSTENI",
    "MANASTIREA (DELESTI)",
    "MANASTIREA (MALUSTENI)",
    "MANJESTI",
    "MANTU",
    "MANZATESTI",
    "MANZATI",
    "MARASESTI",
    "MASCUREI",
    "MICLESTI",
    "MICLESTI (BANCA)",
    "MIHAIL KOGALNICEANU",
    "MIRCESTI",
    "MIRENI",
    "MITOC",
    "MORARENI",
    "MORENI",
    "MOVILENI",
    "MUNTENESTI",
    "MURGENI",
    "MUSATA",
    "NEGRESTI",
    "OBARSENI (VINDEREI)",
    "OBARSENI (VOINESTI)",
    "OBARSENII LINGURARI",
    "ODAIA BOGDANA",
    "ODAIA BURSUCANI",
    "ORGOIESTI",
    "OSESTI",
    "OTELENI",
    "OTETOAIA",
    "PACURARESTI",
    "PADURENI (OSESTI)",
    "PAHNA",
    "PAHNESTI",
    "PALTINIS",
    "PARPANITA",
    "PARVESTI",
    "PEICANI",
    "PLOPENI",
    "PLOPI",
    "PLOPOASA",
    "PLOTONESTI",
    "POCHIDIA",
    "PODENI",
    "PODU OPRII",
    "PODU PETRIS",
    "POGANA",
    "POGANESTI",
    "POGONESTI",
    "POIANA",
    "POIANA PIETREI",
    "POIENESTI-DEAL",
    "POLOCIN",
    "POPENI (GAGESTI)",
    "POPESTI (DRAGOMIRESTI)",
    "POPESTI (MICLESTI)",
    "POSTA ELAN",
    "PRIBESTI",
    "PROTOPOPESTI",
    "PUIESTI",
    "PUNGESTI",
    "PUNTISENI",
    "PUTU OLARULUI",
    "RACOVA",
    "RACOVITA",
    "RADAESTI",
    "RADENI",
    "RADESTI",
    "RADUIESTI",
    "RAFAILA",
    "RAIU",
    "RANCENI",
    "RANZESTI",
    "RAPSA",
    "RASCANI (DANESTI)",
    "RASCANI (SULETEA)",
    "RASESTI",
    "RATESU CUZEI",
    "REBRICEA",
    "RECEA",
    "REDIU (HOCENI)",
    "REDIU (ROSIESTI)",
    "REDIU GALIAN",
    "ROSIESTI",
    "ROSIORI",
    "ROTARI",
    "RUGARIA",
    "RUSI",
    "SALCENI",
    "SALCIOARA",
    "SARATENI",
    "SARBI",
    "SASOVA",
    "SATU NOU (BANCA)",
    "SATU NOU (BEREZENI)",
    "SATU NOU (POCHIDIA)",
    "SCHINENI",
    "SCHINETEA",
    "SCHITU",
    "SEMENEA",
    "SERBOTESTI",
    "SILISTEA (IANA)",
    "SILISTEA (PUNGESTI)",
    "SILISTEA (TODIRESTI)",
    "SIMILISOARA",
    "SIPENI",
    "SISCANI",
    "SLOBOZIA",
    "SOCI",
    "SOFIENI",
    "SOFRONESTI",
    "SOPARLENI",
    "STANCASENI",
    "STEJARU",
    "STIOBORANI",
    "STOISESTI",
    "STRAMTURA-MITOC",
    "STUHULET",
    "SUCEVENI",
    "SULETEA",
    "SUSENI",
    "TABALAIESTI",
    "TACUTA",
    "TALPIGENI",
    "TARZII",
    "TATARANI",
    "TATARANI (DANESTI)",
    "TATOMIRESTI",
    "TELEJNA",
    "TIBANESTII BUHLII",
    "TIFU",
    "TODIRESTI",
    "TOMESTI",
    "TOMSA",
    "TOPORASTI",
    "TROHAN",
    "TUFESTII DE JOS",
    "TULESTI",
    "TUNSESTI",
    "TUPILATI",
    "TUTCANI",
    "ULEA",
    "UNCESTI",
    "UNTESTI",
    "URDESTI",
    "URICARI",
    "URSOAIA",
    "VADURILE",
    "VALCELE",
    "VALEA LUI DARIE",
    "VALEA LUNGA",
    "VALEA LUPULUI",
    "VALEA MARE (DUMESTI)",
    "VALEA MARE (IVANESTI)",
    "VALEA MARE (NEGRESTI)",
    "VALEA OANEI",
    "VALEA POPII",
    "VALEA SEACA",
    "VALEA SILISTEI",
    "VALENI (VIISOARA)",
    "VERDES",
    "VETRISOAIA",
    "VIISOARA",
    "VIISOARA (TODIRESTI)",
    "VILTOTESTI",
    "VINDEREI",
    "VINETESTI",
    "VISINARI",
    "VLADESTI",
    "VLADIA",
    "VOINESTI",
    "VOINESTI (VULTURESTI)",
    "VOVRIESTI",
    "VULPASENI",
    "VULTURESTI",
    "VUTCANI",
    "ZGURA"
  ],
  "VRANCEA": [
    "ADJUD",
    "ADJUDU VECHI",
    "ALEXANDRU VLAHUTA",
    "ARMENI",
    "ARVA",
    "BALTA RATEI",
    "BATINESTI",
    "BECIU",
    "BILIESTI",
    "BIZIGHESTI",
    "BLIDARI (CARLIGELE)",
    "BLIDARI (DUMITRESTI)",
    "BOLOTESTI",
    "BONTESTI",
    "BOTARLAU",
    "BROSTENI",
    "BUDESTI",
    "BURCIOAIA",
    "CAMPINEANCA",
    "CANDESTI",
    "CARLIGELE",
    "CEARDAC",
    "CIUSLEA",
    "COROTENI",
    "COTESTI",
    "CRUCEA DE JOS",
    "CRUCEA DE SUS",
    "DALHAUTI",
    "DOAGA",
    "DOMNESTI-SAT",
    "DOMNESTI-TARG",
    "DRAGOSLOVENI (DUMBRAVENI)",
    "DUMBRAVA (PANCIU)",
    "DUMBRAVA (POIANA CRISTEI)",
    "DUMBRAVENI",
    "FARAOANELE",
    "FAUREI",
    "FOCSANI",
    "GAGESTI",
    "GAROAFA",
    "GOLESTI",
    "GOLESTII DE SUS",
    "GOLOGANU",
    "GUGESTI",
    "IGESTI",
    "IVANCESTI",
    "JARISTEA",
    "JILISTE",
    "JORASTI",
    "LAMOTESTI",
    "LESPEZI",
    "LIESTI",
    "MANDRESTI-MOLDOVA",
    "MANDRESTI-MUNTENI",
    "MARASESTI",
    "MILCOVUL",
    "MIRCESTII NOI",
    "MIRCESTII VECHI",
    "MODRUZENI",
    "NEICU",
    "OBREJITA",
    "ODOBESTI",
    "OLTENI",
    "PADURENI (JARISTEA)",
    "PANCIU",
    "PATRASCANI",
    "PAUNESTI",
    "PETRESTI",
    "PIETROASA (BOLOTESTI)",
    "PIETROASA (CAMPINEANCA)",
    "PIETROASA (VARTESCOIU)",
    "PITULUSA",
    "POIANA CRISTEI",
    "POPESTI",
    "PRECISTANU",
    "PUFESTI",
    "PUTNA",
    "RACHITASU",
    "RACHITOSU",
    "RADULESTI",
    "RAMNICEANCA",
    "RASTOACA",
    "ROSIOARA",
    "RUGINESTI",
    "SATU NOU (PANCIU)",
    "SIRETU",
    "SISCANI",
    "SLOBOZIA BRADULUI",
    "SLOBOZIA CIORASTI",
    "SURAIA",
    "TERCHESTI",
    "TISITA",
    "UNIREA",
    "URECHESTI",
    "VALCELE",
    "VALEA BECIULUI",
    "VALEA COTESTI",
    "VALENI (RUGINESTI)",
    "VANATORI",
    "VARSATURA",
    "VARTESCOIU",
    "VOETIN",
    "ANDREIASU DE JOS",
    "ANDREIASU DE SUS",
    "ANGHELESTI",
    "ARGEA",
    "ARSITA",
    "BAHNELE",
    "BALANESTI",
    "BALESTI",
    "BARSESTI",
    "BATCARI",
    "BELCIUGELE",
    "BICESTII DE JOS",
    "BICESTII DE SUS",
    "BICHESTI",
    "BODESTI",
    "BOGHESTI",
    "BOGHESTII DE SUS",
    "BOGZA",
    "BORDEASCA NOUA",
    "BORDEASCA VECHE",
    "BORDESTI",
    "BORDESTII DE JOS",
    "BRADACESTI",
    "BRADETU",
    "BUDA",
    "BURCA",
    "CAIATA",
    "CALIENII NOI",
    "CALIENII VECHI",
    "CALIMANEASA",
    "CALIMANESTI",
    "CAMPURI",
    "CARSOCHESTI-CORABITA",
    "CATAUTI",
    "CERBU",
    "CHIOJDENI",
    "CHIRICANI",
    "CHITCANI",
    "CIOLANESTI",
    "CIORANI",
    "CIORASTI",
    "CLIPICESTI",
    "COCOSARI",
    "CODRESTI",
    "COLACU",
    "COPACESTI",
    "CORBITA",
    "CORNETU",
    "COSTISA (HOMOCEA)",
    "COSTISA (TANASOAIA)",
    "COSTISA DE SUS",
    "COVRAG",
    "COZA",
    "DEALU CUCULUI",
    "DEALU LUNG",
    "DEALU SARII",
    "DIOCHETI-REDIU",
    "DRAGOSLOVENI (SOVEJA)",
    "DUMITRESTI",
    "DUMITRESTII DE SUS",
    "DUMITRESTII-FATA",
    "DUPA MAGURA",
    "FAGETU",
    "FARCAS",
    "FELDIOARA",
    "FETESTI",
    "FETIG",
    "FITIONESTI",
    "FRECATEI",
    "GALBENI",
    "GALOIESTI",
    "GHEBARI",
    "GHIMICESTI",
    "GOGOIU",
    "GRESU",
    "GROAPA TUFEI",
    "GURA CALITEI",
    "GURA VAII",
    "HANGULESTI",
    "HARET",
    "HAULISCA",
    "HOLBANESTI",
    "HOMOCEA",
    "HOTARU",
    "IRESTI",
    "IUGANI",
    "IZVOARELE",
    "JGHEABURI",
    "JITIA",
    "JITIA DE JOS",
    "LACU LUI BABAN",
    "LARGASENI",
    "LASTUNI",
    "LEPSA",
    "LIVADA",
    "LIVEZILE",
    "LOJNITA",
    "LUNCILE",
    "LUPOAIA",
    "MAGURA",
    "MAHRIU",
    "MAICANESTI",
    "MALURI",
    "MANASTIOARA",
    "MARACINI",
    "MARASTI",
    "MARTINESTI",
    "MATACINA",
    "MERA",
    "MESTEACANU",
    "MIHALCENI",
    "MILCOVEL",
    "MORARESTI",
    "MOTNAU",
    "MOVILITA",
    "MUNCEI",
    "MUNCELU",
    "NANESTI",
    "NARUJA",
    "NECULELE",
    "NEGRILESTI",
    "NEREJU",
    "NEREJU MIC",
    "NISTORESTI",
    "OCHESESTI",
    "ODOBASCA",
    "OLARENI",
    "OLESESTI",
    "OREAVU",
    "PADURENI (MARASESTI)",
    "PADURENI (TAMBOESTI)",
    "PALTIN",
    "PAULESTI",
    "PAVALARI",
    "PETREANU",
    "PIETROASA (TAMBOESTI)",
    "PISCU RADULUI",
    "PISCU REGHIULUI",
    "PLACINTENI",
    "PLESESTI",
    "PLOPU",
    "PLOSCUTENI",
    "PLOSTINA",
    "PODU LACULUI",
    "PODU NARUJEI",
    "PODU SCHIOPULUI",
    "PODU STOICA",
    "PODURI",
    "PODURILE",
    "POENILE",
    "POIANA",
    "POIANA STOICHII",
    "POIENITA",
    "PRAHUDA",
    "PRISACA",
    "PRISECANI",
    "RACOASA",
    "RADACINESTI",
    "RAIUTI",
    "RAMNICENI",
    "RASCA",
    "REBEGARI",
    "REGHIU",
    "REPEDEA",
    "ROMANESTI",
    "ROSCARI",
    "ROTILESTII MARI",
    "ROTILESTII MICI",
    "RUCARENI",
    "RUGET",
    "SAHASTRU",
    "SALCIA NOUA",
    "SALCIA VECHE",
    "SARBI",
    "SATU NOU (CIORASTI)",
    "SCAFARI",
    "SCANTEIA",
    "SECIU",
    "SERBANESTI",
    "SERBESTI",
    "SIHLEA",
    "SIMINOC",
    "SINDRILARI",
    "SLIMNIC",
    "SLOBOZIA BOTESTI",
    "SOTARCARI",
    "SOVEJA",
    "SPATAREASA",
    "SPINESTI",
    "SPULBER",
    "STRAJESCU",
    "STRAOANE",
    "STUPINA",
    "TABUCESTI",
    "TAMBOESTI",
    "TANASARI",
    "TANASOAIA",
    "TARATU",
    "TATARANU",
    "TATARU",
    "TEPA",
    "TICHIRIS",
    "TIFESTI",
    "TINOASA",
    "TIPAU",
    "TITILA",
    "TOJANII DE JOS",
    "TOJANII DE SUS",
    "TOPESTI",
    "TRESTIA",
    "TRESTIENI",
    "TROTUSANU",
    "TULBUREA",
    "TULNICI",
    "TUTU",
    "UNGURENI",
    "URSOAIA",
    "VADU ROSCA",
    "VAJAITOAREA",
    "VALCANI",
    "VALCELELE",
    "VALEA MICA",
    "VALEA MILCOVULUI",
    "VALEA NEAGRA",
    "VALEA SARII",
    "VALENI (MOVILITA)",
    "VALENI (STRAOANE)",
    "VARNITA",
    "VERDEA",
    "VETRESTI-HERASTRAU",
    "VIDRA",
    "VIISOARA (PAUNESTI)",
    "VIISOARA (VIDRA)",
    "VINTILEASCA",
    "VITANESTI",
    "VITANESTII DE SUB MAGURA",
    "VIZANTEA MANASTIREASCA",
    "VIZANTEA RAZASEASCA",
    "VIZANTEA-LIVEZI",
    "VLADNICU DE JOS",
    "VLADNICU DE SUS",
    "VOLOSCANI",
    "VRANCIOAIA",
    "VULCANEASA",
    "VULTURU"
  ]
}
