import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid
} from '@material-ui/core';
import AccountProfile from '../components/account/AccountProfile';
import AccountProfileDetails from '../components/account/AccountProfileDetails';
import CompanyDetails from '../components/company/CompanyDetails';
import { useSelector, useDispatch } from 'react-redux';
import { userSelector, userClearState } from '../features/UserSlice';
import { toast } from 'react-toastify';
import Can from '../can';
import Loading from '../components/Loading';

const Account = () => {
  const dispatch = useDispatch();
  const { user, isError, errorMessage } = useSelector(userSelector);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
    return () => {
      dispatch(userClearState());
    }
  }, [isError, errorMessage, dispatch]);

  if (!user) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Account</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item lg={4} md={6} xs={12}>
              <AccountProfile user={user} />
            </Grid>
            <Grid item lg={8} md={6} xs={12}>
              <AccountProfileDetails user={user} />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
            </Grid>
            {Can('view', 'shop') && (
              <Grid item lg={8} md={6} xs={12}>
                <CompanyDetails user={user} />
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </>
  )
};

export default Account;
