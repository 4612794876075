import {
  Box,
  Button,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export const StyledTitle = (props) => {
  return (
    <Typography
      color="primary"
      variant="h4"
      style={{ textTransform: 'uppercase' }} 
      {...props}
    >
      {props.children}
    </Typography>
  );
};

const ProductCategoryListToolbar = (props) => {
  const { t } = useTranslation();

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button>
          {t("Import")}
        </Button>
        <Button
          color="primary"
          variant="contained"
          sx={{ mx: 1 }}
        >
          {t("Export")}
        </Button>
      </Box>
    </Box>
  )
}

export default ProductCategoryListToolbar;
