import React, { useState }  from 'react';
import {
  Box,
  Container,
  Typography,
} from '@material-ui/core';
import Dialog from '../../components/Dialog';
import Confirm from '../../components/Confirm';
import DataGridFilterable from '../../components/utils/DataGridFilterable';
import InventoryManageForm from '../../components/inventory/InventoryManageForm';
import { useTranslation } from 'react-i18next';

const InventoryListResults = (props) => {
  const {
    rows,
    columns,
    searchText,
    requestSearch,
    openAddDialog,
    mode,
    open,
    handleClose,
    inventoryId,
    confirmOpen,
    handleConfirmClose,
    confirmMessage,
    handleConfirmAction,
  } = props;
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        <Box>
          <DataGridFilterable
            addButtonText={t('Add Inventory')}
            openAddDialog={openAddDialog}
            searchText={searchText}
            requestSearch={requestSearch}
            rows={rows}
            columns={columns}
            autoHeight
            disableColumnFilter
            disableColumnMenu
            disableSelectionOnClick
            density="comfortable"
          />
        </Box>
        <Dialog
          title={mode === 'add' ? t('Add Inventory') : t('Edit Inventory')}
          open={open}
          handleClose={handleClose}
          maxWidth="lg"
          fullWidth={true}
        >
          <InventoryManageForm
            handleClose={handleClose}
            mode={mode}
            inventoryId={inventoryId}
            setErrorDialogOpen={setErrorDialogOpen}
          />
        </Dialog>
        <Confirm
          open={confirmOpen}
          handleClose={handleConfirmClose}
          message={confirmMessage}
          handleConfirmAction={handleConfirmAction}
        />
        <Dialog 
          title="Cannot delete row." 
          open={errorDialogOpen} 
          handleClose={() => setErrorDialogOpen(false)}
          maxWidth="xs"
          fullWidth
        >
           <Typography color="error">You must have at least one product.</Typography>
        </Dialog>

      </Container>
    </Box>
  );
}

export default InventoryListResults;
