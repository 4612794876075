import React from 'react';
import {
  Box,
  Container,
} from '@material-ui/core';
import Dialog from '../../Dialog';
import Confirm from '../../Confirm';
import DataGridFilterable from '../../utils/DataGridFilterable';
import ProductCategoryManageForm from './ProductCategoryManageForm';

const ProductCategoryListResults = (props) => {
  const {
    rows,
    columns,
    searchText,
    requestSearch,
    openAddDialog,
    mode,
    open,
    handleClose,
    productCategoryId,
    confirmOpen,
    handleConfirmClose,
    confirmMessage,
    handleConfirmAction,
    isDeleting,
  } = props;

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        <Box>
          <DataGridFilterable
            addButtonText="Add Product Category"
            openAddDialog={openAddDialog}
            searchText={searchText}
            requestSearch={requestSearch}
            rows={rows}
            columns={columns}
            autoHeight
            disableColumnFilter
            disableColumnMenu
            disableSelectionOnClick
            density="comfortable"
          />
        </Box>
        <Dialog
          title={mode === 'add' ? "Add product category" : "Edit product category"}
          open={open}
          handleClose={handleClose}
        >
          <ProductCategoryManageForm
            handleClose={handleClose}
            mode={mode}
            productCategoryId={productCategoryId}
          />
        </Dialog>
        <Confirm
          open={confirmOpen}
          handleClose={handleConfirmClose}
          message={confirmMessage}
          handleConfirmAction={handleConfirmAction}
          isDeleting={isDeleting}
        />
      </Container>
    </Box>
  );
}

export default ProductCategoryListResults;
