import jwtDecode from 'jwt-decode';

class userService {
  isAuthTokenValid = () => {
    const token = this.getToken();
    if (!token) {
      return false;
    }

    if (!this.isTokenActive()) {
      return false;
    }

    return true;
  };

  isTokenActive = () => {
    const decoded = jwtDecode(this.getToken());
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn('Session has expired. Please log in...');
      return false;
    }
    return true;
  };

  isAuthenticated = () => {
    if (this.isAuthTokenValid()) {
      return true;
    }

    this.logout();
    return false;
  };

  logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("id");
  };

  getJobTitleFromRoles = (roles) => {

    if(!roles) {
      return 'User';
    }

    if (roles.includes('ROLE_ADMIN')) {
      return 'Admin';
    }
    if (roles.includes('ROLE_SHOP')) {
      return 'Owner';
    }
    if (roles.includes('ROLE_MANAGER')) {
      return 'Manager';
    }
    if (roles.includes('ROLE_DRIVER')) {
      return 'Driver';
    }
    if (roles.includes('ROLE_KITCHEN')) {
      return 'Kitchen Staff';
    }
    if (roles.includes('ROLE_CALLCENTER')) {
      return 'Call Center Staff';
    }
    if (roles.includes('ROLE_WAITER')) {
      return 'Waiter';
    }

    return 'User'
  }

  getUserId = () => {
    return localStorage.getItem('id');
  }

  getToken = () => {
    return localStorage.getItem('token');
  }

  getDepartmentFromRoles = (roles) => {
    if (roles.includes('ROLE_SHOP')) {
      return 'Owner';
    }
    if (roles.includes('ROLE_MANAGER')) {
      return 'Management';
    }
    if (roles.includes('ROLE_DRIVER')) {
      return 'Delivery';
    }
    if (roles.includes('ROLE_CALLCENTER')) {
      return 'Call center';
    }
    if (roles.includes('ROLE_KITCHEN')) {
      return 'Kitchen';
    }

    return 'User'
  }

  getDecodedToken = () => {
    const token = this.getToken();
    if (!token) return null;
    
    try {
      return jwtDecode(token);
    } catch (error) {
      console.error('Error decoding token', error);
      return null;
    }
  };

  isAdmin = () => {
    return this.getDecodedToken() ? (this.getDecodedToken().roles && this.getDecodedToken().roles.includes('ROLE_ADMIN')) : false;
  };

  isShop = () => {
    return this.getDecodedToken() ? (this.getDecodedToken().roles && this.getDecodedToken().roles.includes('ROLE_SHOP')) : false;
  };

  isWaiter = () => {
    return this.getDecodedToken() ? (this.getDecodedToken().roles && this.getDecodedToken().roles.includes('ROLE_WAITER')) : false;
  };
}

const instance = new userService();

export default instance;
