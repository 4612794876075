import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Grid,
  Typography,
} from '@material-ui/core';
import Chip from '@mui/material/Chip';
import { getStatuses, addStatus, getStatus, updateStatus, adminSelector } from '../../../features/AdminSlice';
import { toast } from 'react-toastify';
import { CirclePicker } from 'react-color';
import Loading from '../../Loading';
import { useTranslation } from 'react-i18next';
import { kebabCase } from 'lodash';

const StatusManageForm = (props) => {
  const { t } = useTranslation();
  const {handleClose, mode, statusId} = props;
  const dispatch = useDispatch();
  const { status } = useSelector(adminSelector);
  const [initialValues, setInitialValues] = useState({
    name: '',
    color: '',
    slug: '',
  });
  const validationSchema = {
    name: Yup.string().max(255).required(t('Name is required')),
  };

  useEffect(() => {
    if (mode === 'edit' && statusId) {
      dispatch(getStatus(statusId));
    }
  }, [dispatch, mode, statusId]);

  useEffect(() => {
    if (mode === 'edit' && status) {
      setInitialValues({
        name: t(status.name),
        color: status.color,
        slug: status.slug,
      });
    } 
  }, [status, mode, t]);

  const handleSubmit = (data, formikData) => {
    if (mode === 'add') {
      dispatch(addStatus(data)).then(response => {
        if (!response.error) {
          toast.success(t('Data added successfully'), {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          handleClose();
          dispatch(getStatuses());
        } else {
          toast.error(t(response.payload), {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
        }
      });
    } else {
      data.id = status.id;
      dispatch(updateStatus(data)).then((response) => {
        if (!response.error) {
          toast.success(t('Data updated successfully'), {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          handleClose();
          dispatch(getStatuses());
        } else {
          toast.error(t(response.payload), {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
        }
      });
    }
    formikData.setSubmitting(false);
  };

  const handleNameChange = (event, setFieldValue) => {
    const nameValue = event.target.value;
    const slugValue = kebabCase(nameValue);
    setFieldValue('name', nameValue);
    setFieldValue('slug', slugValue); 
  };

  if (mode === 'edit' && !status) {
    return <Loading />;
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={Yup.object().shape(validationSchema)}
            onSubmit={(data, formikData) => handleSubmit(data, formikData)}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
            }) => (
              <Form id={mode}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label={t("Name")}
                      margin="dense"
                      name="name"
                      onBlur={handleBlur}
                      onChange={(e) => handleNameChange(e, setFieldValue)}
                      value={values.name}
                      variant="outlined"
                    />
                  </Grid>                  
                  <Grid item xs={3}>
                    <Typography
                      color="textPrimary"
                      variant="h5"
                    >
                      {t("Pick a color:")}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Chip label={values.name} sx={{ bgcolor: values.color }} />
                  </Grid>
                  <Grid item xs={6}>
                    <CirclePicker color={values.color} onChange={(color, event) => setFieldValue('color', color !== null ? color.hex : initialValues.color)} />
                  </Grid>
                </Grid>
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {t("Save")}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
}

export default StatusManageForm;
