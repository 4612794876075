import { useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  ListSubheader
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  Settings as SettingsIcon,
  Users as UsersIcon,
  User as UserIcon,
  Home as HomeIcon
} from 'react-feather';
import NavItem from './NavItem';
import { logoutUser, uploadAvatar } from '../features/UserSlice';
import { useDispatch } from 'react-redux';
import UserService from '../services';
import Can from '../can';
import { useTranslation } from 'react-i18next';

const AdminDashboardSidebar = ({ onMobileClose, openMobile, user }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const items = [
    {
      title: t('Overview'),
      type: 'subheader',
      icon: ''
    },
    {
      href: '/app/admin/dashboard',
      icon: BarChartIcon,
      title: t('Dashboard'),
      visible: true,
    },
    {
      title: t('Management'),
      type: 'subheader',
      icon: ''
    },
    {
      href: '/app/admin/shops',
      icon: HomeIcon,
      title: t('Shops'),
      visible: Can('view', 'all'),
    },
    {
      href: '/app/admin/users',
      icon: UsersIcon,
      title: t('Users'),
      visible: Can('view', 'all'),
    },
    {
      href: '/app/admin/account',
      icon: UserIcon,
      title: t('Account'),
      visible: Can('view', 'all'),
    },
    {
      href: '/app/admin/settings',
      icon: SettingsIcon,
      title: t('Settings'),
      visible: true,
    },
  ];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname, openMobile, onMobileClose]);

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate('/login', { replace: true });
  }

  const handleChange = event => {
    if (event.target.files.length) {
      const file = event.target.files[0];
      dispatch(uploadAvatar(file));
    }
  };

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        {Can('manage', 'ownUser') ? (
          <Avatar
            component={RouterLink}
            src={user.avatar}
            sx={{
              cursor: 'pointer',
              width: 64,
              height: 64
            }}
            to={Can('view', 'all') ? "/app/admin/account" : "/app/account"}
          />
        ) : (
          <Button
            onChange={handleChange}
            component="label"
            sx={{
              borderRadius: 10
            }}
          >
            <input type="file" hidden accept="image/*" />
            <Avatar
              src={user.avatar}
              sx={{
                width: 64,
                height: 64
              }}
            />
          </Button>
        )}
        <Typography
          color="textPrimary"
          variant="h5"
        >
          {user.firstName} {user.lastName}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {t(UserService.getJobTitleFromRoles(user.roles))}
        </Typography>
        <Button sx={{ pt: 1 }} onClick={handleLogout}>
          {t('Logout')}
        </Button>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item, key) =>
            item.type && item.type === 'subheader' ? (
              <ListSubheader key={key}>{item.title}</ListSubheader>
            ) : item.visible ? (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
              />
            ) : null
          )}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden xlDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

AdminDashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

AdminDashboardSidebar.defaultProps = {
  onMobileClose: () => {
  },
  openMobile: false
};

export default AdminDashboardSidebar;
