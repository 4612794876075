import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import AccountProfilePassword from '../components/account/AccountProfilePassword';
import ShopSettings from '../components/settings/ShopSettings';
import Can from '../can';
import Areas from 'src/components/admin/settings/Areas';
import Stands from 'src/components/admin/settings/Stands';
import Units from 'src/components/admin/settings/Units';
import Vats from 'src/components/admin/settings/Vats';

const SettingsView = () => (
  <>
    <Helmet>
      <title>Settings</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth="lg">

        <AccountProfilePassword />

        {Can('manage', 'shop') && (
          <>
            <ShopSettings />

            <Areas />

            <Stands />

            <Units />

            <Vats />

          </>
        )}
        
      </Container>
    </Box>
  </>
);

export default SettingsView;
