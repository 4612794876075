import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Toolbar, Box, Typography } from '@material-ui/core';
import Logo from './Logo';

const MainNavbar = (props) => (
  <AppBar
    elevation={0}
    {...props}
  >
    <Toolbar sx={{ height: 64 }}>
      <RouterLink to='/'>
        <Logo />
      </RouterLink>
      <Box sx={{ flexGrow: 1 }} />
      <RouterLink to='/login'>
        <Typography sx={{ mr: 2 }}>Login</Typography>
      </RouterLink>
      {/* <RouterLink to="/register">
        <Typography>
          Register
        </Typography>
      </RouterLink> */}
    </Toolbar>
  </AppBar>
);

export default MainNavbar;
