import { Navigate } from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout';
import MainLayout from './components/MainLayout';
import Account from './pages/Account';
import CustomerList from './pages/CustomerList';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import ProductList from './pages/ProductList';
import Register from './pages/Register';
import Settings from './pages/Settings';
import AdminSettings from './pages/admin/AdminSettings';
import Home from './pages/Home';
import ForgotPassword from './pages/ForgotPassword';
import Users from './pages/Users';
import Can from './can';
import AdminLayout from './components/AdminLayout';
import AdminShops from './pages/admin/AdminShops';
import AdminShopCards from './pages/admin/AdminShopCards';
// import Reports from './pages/Reports';
import ProductCategoryList from './pages/ProductCategoryList';
import InventoryList from './pages/InventoryList';
import InventoryProductList from './pages/InventoryProductList';
import RecipeList from './pages/RecipeList';
import RecipeProductList from './pages/RecipeProductList';
import OrderStandProducts from './components/order/OrderStandProducts';
import OrderStandCategoies from './components/order/OrderStandCategories';
import OrderStandFeaturedProducts from './components/order/OrderStandFeaturedProducts';
import OrderStandDetails from './components/order/OrderStandDetails';
import OrderManage from './components/order/OrderManage';

const routes = (isAuthenticated) => [
  {
    path: 'app/admin',
    element: isAuthenticated ? (
      Can('view', 'all') ? (
        <AdminLayout />
      ) : (
        <Navigate to='/app/dashboard' />
      )
    ) : (
      <Navigate to='/login' />
    ),
    children: [
      { path: 'account', element: <Account /> },
      { path: 'customers', element: <CustomerList /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'settings', element: <AdminSettings /> },
      {
        path: 'shops/:shopId/products/:parentId/:childId',
        element: <ProductList />,
      },
      {
        path: 'shops/:shopId/products/:parentId',
        element: <ProductCategoryList />,
      },
      {
        path: 'shops/:shopId/recipes/:recipeId',
        element: <RecipeProductList />,
      },
      {
        path: 'shops/:shopId/inventories/:inventoryId',
        element: <InventoryProductList />,
      },
      { path: 'shops/:shopId/recipes', element: <RecipeList /> },
      { path: 'shops/:shopId/inventories', element: <InventoryList /> },
      { path: 'shops/:shopId', element: <AdminShopCards /> },
      { path: 'shops', element: <AdminShops /> },
      { path: 'users', element: <Users /> },
      { path: '*', element: <Navigate to='/404' /> },
      // { path: 'reports', element: <Reports /> }
    ],
  },
  {
    path: 'app',
    element: isAuthenticated ? (
      Can('view', 'all') ? (
        <Navigate to='/app/admin/dashboard' />
      ) : (
        <DashboardLayout />
      )
    ) : (
      <Navigate to='/login' />
    ),
    children: [
      Can('view', 'ownUser') ? { path: 'account', element: <Account /> } : {},
      { path: 'customers', element: <CustomerList /> },
      { path: 'dashboard', element: <Dashboard /> },
      // Can('view', 'reports') ? { path: 'reports', element: <Reports /> } : {},
      { path: 'products/:parentId/:childId', element: <ProductList /> },
      { path: 'products/:parentId', element: <ProductCategoryList /> },
      { path: 'stands/:standId', element: <OrderStandCategoies /> },
      {
        path: 'stands/:standId/categories/:parentId',
        element: <OrderStandProducts />,
      },
      {
        path: 'stands/:standId/orders/:orderId/categories/:parentId',
        element: <OrderStandProducts />,
      },
      {
        path: 'stands/:standId/categories/:parentId/:childId',
        element: <OrderStandFeaturedProducts />,
      },
      {
        path: 'stands/:standId/orders/:orderId/categories/:parentId/:childId',
        element: <OrderStandFeaturedProducts />,
      },
      { path: 'stands/:standId/checkout', element: <OrderStandDetails /> },
      { path: 'stands/:standId/orders/:orderId', element: <OrderManage /> },
      { path: 'inventories', element: <InventoryList /> },
      { path: 'inventories/:inventoryId', element: <InventoryProductList /> },
      { path: 'recipes', element: <RecipeList /> },
      { path: 'recipes/:recipeId', element: <RecipeProductList /> },
      { path: 'settings', element: <Settings /> },
      Can('view', 'users') ? { path: 'users', element: <Users /> } : {},
      { path: '*', element: <Navigate to='/404' /> },
    ],
  },
  {
    path: '/',
    element: isAuthenticated ? (
      Can('view', 'all') ? (
        <Navigate to='/app/admin/dashboard' />
      ) : (
        <Navigate to='/app/dashboard' />
      )
    ) : (
      <MainLayout />
    ),
    children: [
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Home /> },
      { path: 'forgot-password/*', element: <ForgotPassword /> },
      { path: '*', element: <Navigate to='/404' /> },
    ],
  },
];

export default routes;
