import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, FieldArray } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Grid,
  Autocomplete,
} from '@material-ui/core';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  shopSelector,
  getProducts,
  getProductsByShopIdForAdmin,
  getUnits,
  customCreateRecipe,
} from '../../features/ShopSlice';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { userSelector } from 'src/features/UserSlice';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import UserService from 'src/services';

const RecipeManageForm = (props) => {
  const { t } = useTranslation();
  const { handleClose, mode } = props;
  const { products, units } = useSelector(shopSelector);
  const { user } = useSelector(userSelector);
  const dispatch = useDispatch();
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const isAdmin = UserService.isAdmin();
  const { shopId } = useParams();
  const initialValues = {
    active: true,
    name: '',
    rows: [{ product: '', unit: '', qty: '', active: true }],
  };

  const safeInitialValues = {
    active: initialValues.active,
    name: initialValues.name || '',
    rows: initialValues.rows.map((row) => ({
      product: row.product || '',
      unit: row.unit || '',
      qty: row.qty || '',
      active: row.active || true,
    })),
  };

  const emptyRowValues = {
    product: '',
    unit: '',
    qty: '',
    active: true,
  };

  const validationSchema = {
    name: Yup.string().max(255).required(t('Name is required')),
    rows: Yup.array().of(
      Yup.object().shape({
        product: Yup.string().max(255).required(t('Product is required')),
        qty: Yup.number()
          .typeError(t('You must specify a number'))
          .required(t('Quantity is required')),
      })
    ),
  };

  useEffect(() => {
    if (shopId && isAdmin) {
      dispatch(getProductsByShopIdForAdmin(shopId));
    } else {
      dispatch(getProducts());
    }
    dispatch(getUnits());
  }, [dispatch, shopId, isAdmin]);

  const handleSubmit = (data, formikData) => {
    if (mode === 'add') {
      data.shop = isAdmin ? shopId : user.shop.id;
      data.rows.forEach((row, index) => {
        row.productId = selectedProductIds[index];
      });
      dispatch(customCreateRecipe(data)).then((response) => {
        if (!response.error) {
          toast.success(t('Data added successfully'), {
            position: 'bottom-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          handleClose();
        } else {
          toast.error(t(response.payload), {
            position: 'bottom-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
        }
      });
    }
    formikData.setSubmitting(false);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <Container maxWidth='sm'>
          <Formik
            initialValues={safeInitialValues}
            enableReinitialize={true}
            validationSchema={Yup.object().shape(validationSchema)}
            onSubmit={(data, formikData) => handleSubmit(data, formikData)}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
            }) => (
              <Form id='add-recipe'>
                <Grid
                  item
                  xs={5}
                  sx={{ mb: 5 }}
                >
                  <TextField
                    fullWidth
                    label={t('Name')}
                    margin='dense'
                    name='name'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    variant='outlined'
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Grid>

                <Grid
                  container
                  spacing={1}
                >
                  <FieldArray name='rows'>
                    {({ remove, push }) => (
                      <>
                        <Grid
                          container
                          spacing={1}
                        >
                          <Grid
                            item
                            xs={12}
                          >
                            <Button
                              onClick={() => push(emptyRowValues)}
                              color='primary'
                              variant='contained'
                              sx={{ mb: 1.5 }}
                            >
                              + {t('Add Product')}
                            </Button>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          spacing={1}
                        >
                          {values.rows.map((row, index) => (
                            <React.Fragment key={index}>
                              <Grid
                                item
                                xs={4}
                              >
                                <Autocomplete
                                  sx={{ mt: 1, mb: 0.5 }}
                                  freeSolo
                                  name={`rows[${index}].product`}
                                  value={
                                    values.rows &&
                                    values.rows[index] &&
                                    values.rows[index].product
                                      ? _.find(products, {
                                          '@id': values.rows[index].product,
                                        })
                                      : { '@id': '', name: '' }
                                  }
                                  options={products}
                                  getOptionLabel={(option) => option.name}
                                  onChange={(e, value) => {
                                    setFieldValue(
                                      `rows[${index}].product`,
                                      value !== null
                                        ? value['@id']
                                        : values.rows[index].product
                                    );

                                    if (value) {
                                      const selectedProductId = value.id;
                                      setSelectedProductIds((prevIds) => {
                                        const updatedIds = [...prevIds];
                                        updatedIds[index] = selectedProductId;
                                        return updatedIds;
                                      });

                                      const selectedProductUnitId =
                                        value.unit['@id'];
                                      const foundUnit = _.find(units, {
                                        '@id': selectedProductUnitId,
                                      });

                                      if (foundUnit) {
                                        setFieldValue(
                                          `rows[${index}].unit`,
                                          foundUnit['@id']
                                        );
                                      }
                                    }
                                  }}
                                  includeInputInList
                                  onOpen={handleBlur}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={t('Product')}
                                      name={`rows[${index}].product`}
                                      error={Boolean(
                                        touched.rows &&
                                          touched.rows[index] &&
                                          touched.rows[index].product &&
                                          errors.rows &&
                                          errors.rows[index] &&
                                          errors.rows[index].product
                                      )}
                                      helperText={
                                        touched.rows &&
                                        touched.rows[index] &&
                                        touched.rows[index].product &&
                                        errors.rows &&
                                        errors.rows[index] &&
                                        errors.rows[index].product
                                      }
                                    />
                                  )}
                                />
                              </Grid>

                              <Grid
                                item
                                xs={3}
                              >
                                <TextField
                                  fullWidth
                                  label={t('Unit')}
                                  margin='dense'
                                  value={
                                    values.rows &&
                                    values.rows[index] &&
                                    values.rows[index].unit
                                      ? _.find(units, {
                                          '@id': values.rows[index].unit,
                                        }).name
                                      : ''
                                  }
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  variant='outlined'
                                />
                              </Grid>

                              <Grid
                                item
                                xs={4}
                              >
                                <TextField
                                  fullWidth
                                  label={t('Quantity')}
                                  margin='dense'
                                  name={`rows[${index}].qty`}
                                  error={Boolean(
                                    touched.rows &&
                                      touched.rows[index] &&
                                      touched.rows[index].qty &&
                                      errors.rows &&
                                      errors.rows[index] &&
                                      errors.rows[index].qty
                                  )}
                                  helperText={
                                    touched.rows &&
                                    touched.rows[index] &&
                                    touched.rows[index].qty &&
                                    errors.rows &&
                                    errors.rows[index] &&
                                    errors.rows[index].qty
                                  }
                                  onBlur={handleBlur}
                                  value={values.rows[index].qty}
                                  onChange={handleChange}
                                  variant='outlined'
                                  type='number'
                                />
                              </Grid>

                              <Grid
                                item
                                xs={1}
                              >
                                <Box
                                  display='flex'
                                  alignItems='center'
                                  height='100%'
                                  ml={3}
                                >
                                  <GridActionsCellItem
                                    icon={
                                      <DeleteForeverIcon
                                        style={{ fontSize: 30 }}
                                      />
                                    }
                                    label='Delete'
                                    onClick={() => {
                                      if (values.rows.length > 1) {
                                        remove(index);
                                      } else {
                                        props.setErrorDialogOpen(true);
                                      }
                                    }}
                                    color='error'
                                    aria-label='delete'
                                  />
                                </Box>
                              </Grid>
                            </React.Fragment>
                          ))}
                        </Grid>
                      </>
                    )}
                  </FieldArray>
                </Grid>

                <Box sx={{ py: 2, display: 'flex', justifyContent: 'center' }}>
                  <Button
                    color='primary'
                    disabled={isSubmitting}
                    type='submit'
                    variant='contained'
                  >
                    {t('Save')}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default RecipeManageForm;
