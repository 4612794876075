import { configureStore } from '@reduxjs/toolkit';
import { userSlice } from "./features/UserSlice";
import { shopSlice } from "./features/ShopSlice";
import { adminSlice } from "./features/AdminSlice";
import { orderSlice } from './features/OrderSlice';

export default configureStore({
  reducer: {
    user: userSlice.reducer,
    shop: shopSlice.reducer,
    admin: adminSlice.reducer,
    order: orderSlice.reducer
  },
});
