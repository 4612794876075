import React, { useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { loginUser, getUser, userSelector } from '../features/UserSlice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Can from '../can';
import { useTranslation } from 'react-i18next';


const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isFetching, isSuccess, isLoginSuccess, isError, errorMessage, id } = useSelector(
    userSelector
  );

  const handleSubmit = (data) => {
    dispatch(loginUser(data)).then(() => dispatch(getUser()));
  };

  useEffect(() => {
    if (isLoginSuccess) {
      if (isSuccess) {
        Can('view', 'all') ? navigate('/app/admin/dashboard', { replace: true }) : navigate('/app/dashboard', { replace: true });
      }
    }

    if (isError) {
      toast.error(t(errorMessage), {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  // eslint-disable-next-line
  }, [dispatch, isSuccess, isLoginSuccess, isError, navigate, id, t]);

  return (
    <>
      <Helmet>
        <title>{t('Login')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email(t('Must be a valid email')).max(255).required(t('Email is required')),
              password: Yup.string().max(255).required(t('Password is required'))
            })}
            onSubmit={(data) => {
              handleSubmit(data);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 1 }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    {t("Sign in")}
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label={t("Email Address")}
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label={t("Password")}
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting && isFetching}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {t("Sign in now")}
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: 'flex'
                  }}
                >
                  <Typography
                    color="textSecondary"
                    variant="body1"
                    sx={{
                      flexGrow: 1
                    }}
                  >
                    {t("Don't have an account?")}
                    {' '}
                    <Link component={RouterLink} to="/register" variant="h6" underline="hover">
                      {t("Register")}
                    </Link>
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    <Link component={RouterLink} to="/forgot-password" variant="h6" underline="hover">
                      {t("Forgot password?")}
                    </Link>
                  </Typography>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Login;
