import {
  Box,
  Button,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const CustomerListToolbar = (props) => {
  const { t } = useTranslation();

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button>
          {t("Import")}
        </Button>
        <Button
          color="primary"
          variant="contained"
          sx={{ mx: 1 }}
        >
          {t("Export")}
        </Button>
      </Box>
    </Box>
  )
}

export default CustomerListToolbar;
