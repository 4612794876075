import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  Divider,
  Button,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import UserService from 'src/services';
import { useDispatch, useSelector } from 'react-redux';
import { getStatuses, adminSelector } from '../features/AdminSlice';
import { shopSelector, customGetAreas } from '../features/ShopSlice';
import { userSelector } from 'src/features/UserSlice';
import OrderStand from 'src/components/order/OrderStand';
import Loading from 'src/components/Loading';

const Dashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAdmin = UserService.isAdmin();
  const { areas, isFetching } = useSelector(shopSelector);
  const { user } = useSelector(userSelector);
  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedOrderStands, setSelectedOrderStands] = useState([]);
  const { statuses } = useSelector(adminSelector);

  useEffect(() => {
    dispatch(customGetAreas());
    dispatch(getStatuses());
  }, [dispatch]);

  useEffect(() => {
    if (areas && areas.length > 0) {
      setSelectedArea(areas[0]);
      setSelectedOrderStands(areas[0].stands || []);
    }
  }, [areas]);

  const handleAreaClick = (area) => {
    setSelectedArea(area);
    setSelectedOrderStands(area.stands || []);
  };

  if (user && user.shop && (!areas || areas.length === 0)) {
    return (
      <>
        <Helmet>
          <title>Dashboard</title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3,
          }}
        >
          <Container maxWidth={false}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
              >
                {isAdmin ? (
                  <Card sx={{ height: '100%' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100px',
                        width: '100%',
                      }}
                    >
                      <Typography
                        variant='h3'
                        component='div'
                        sx={{ fontWeight: 'bold' }}
                      >
                        {t('This is the dashboard for admin.')}
                      </Typography>
                    </Box>
                  </Card>
                ) : (
                  <Card sx={{ height: '100%' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '300px',
                        width: '100%',
                      }}
                    >
                      <Typography
                        variant='h3'
                        component='div'
                        sx={{ fontWeight: 'bold' }}
                      >
                        {t('There are no areas on this shop.')}
                      </Typography>
                    </Box>
                  </Card>
                )}
              </Grid>
            </Grid>
          </Container>
        </Box>
      </>
    );
  }

  if (
    !user ||
    !user.shop ||
    !areas ||
    !areas[0] ||
    !selectedArea ||
    !statuses ||
    isFetching
  ) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              {!isAdmin ? (
                <Card
                  sx={{
                    height: '100%',
                  }}
                >
                  <Grid
                    container
                    spacing={0}
                    sx={{ flexWrap: 'wrap' }}
                  >
                    {areas.map((area) => (
                      <Grid
                        item
                        xs={areas.length < 5 ? 12 / areas.length : 4}
                        key={area.id}
                      >
                        <Button
                          sx={{
                            padding: '10px',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            borderRadius: '0 !important',
                            backgroundColor:
                              selectedArea.id === area.id ? 'grey' : '#f5f5f5',
                            border: '1px solid #e0e0e0',
                            '&:hover': {
                              backgroundColor:
                                selectedArea.id === area.id
                                  ? 'grey'
                                  : '#e0e0e0',
                              borderColor: '#e0e0e0',
                            },
                            '&:focus': {
                              backgroundColor:
                                selectedArea.id === area.id
                                  ? 'grey'
                                  : '#f5f5f5 !important',
                              borderColor: '#e0e0e0',
                            },
                          }}
                          variant={
                            selectedArea.id === area.id
                              ? 'contained'
                              : 'outlined'
                          }
                          color='primary'
                          fullWidth
                          onClick={() => handleAreaClick(area)}
                        >
                          {area.name}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                  <Divider />

                  <CardContent>
                    <Grid
                      container
                      spacing={0}
                      sx={{
                        justifyContent: 'space-between',
                      }}
                    >
                      {selectedOrderStands.length === 0 ? (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '300px',
                            width: '100%',
                          }}
                        >
                          <Typography
                            variant='h3'
                            component='div'
                            sx={{ fontWeight: 'bold' }}
                          >
                            {t('No Stands in this Area.')}
                          </Typography>
                        </Box>
                      ) : (
                        [...selectedOrderStands]
                          .toSorted((a, b) => a.id - b.id)
                          .map((orderStand) => (
                            <Grid
                              item
                              xs={6}
                              md={2}
                              sm={2}
                              key={orderStand.id}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <OrderStand
                                id={orderStand.id}
                                name={orderStand.name}
                                seats={orderStand.seats}
                                status={orderStand.status}
                                statuses={statuses}
                                order={
                                  orderStand.orders &&
                                  orderStand.orders.length > 0
                                    ? orderStand.orders[
                                        orderStand.orders.length - 1
                                      ]
                                    : null
                                }
                              />
                            </Grid>
                          ))
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              ) : (
                <Card sx={{ height: '100%' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100px',
                      width: '100%',
                    }}
                  >
                    <Typography
                      variant='h3'
                      component='div'
                      sx={{ fontWeight: 'bold' }}
                    >
                      {t('This is the dashboard for admin.')}
                    </Typography>
                  </Box>
                </Card>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;
