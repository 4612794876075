import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from '../api/index.js';
import UserService from '../services';

export const registerUser = createAsyncThunk(
  "users/registerUser",
  async (data, thunkAPI) => {
    try {
      const postData = Object.assign({}, data);
      if (postData.roles) {
        postData.roles = [data.roles];
      } else {
        postData.roles = [];
      }
      return api.register(postData)
      .then((response) => {
        if (response.status === 201) {
          return { ...response.data, email: postData.email };
        } else {
          return thunkAPI.rejectWithValue('There was an error with your request. Please try again later.');
        }
      })
      .catch((e) => {
        return thunkAPI.rejectWithValue(e.response.data.violations ?
          e.response.data.violations[0].message
          : e.response.data.message ?
            e.response.data.message
            : e.response.data.detail
        );
      });
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.detail);
    }
  }
);

export const loginUser = createAsyncThunk(
  'users/loginUser',
  async (data, thunkAPI) => {
    try {
      return api.login(data)
        .then((response) => {
          if (response.status === 200) {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("id", response.data.data.id);

            // thunkAPI.dispatch(getUser()); 

            return { ...response.data };
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getUser = createAsyncThunk(
  'users/getUser',
  async(data, thunkAPI) => {
    try {
      return api.getUser(UserService.getUserId())
        .then((response) => {
          if (response.status === 200) {
            return { ...response.data };
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const uploadAvatar = createAsyncThunk(
  'user/uploadAvatar',
  async(file, thunkAPI) => {
    try {
      const data = new FormData();
      data.append('file', file);

      return api.uploadAvatar(UserService.getUserId(), data)
        .then((response) => {
          if (response.status === 201) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const updateUser = createAsyncThunk(
  'user/updateUser',
  async(data, thunkAPI) => {
    let id = UserService.getUserId();
    if (data.id) {
      id = data.id;
      delete data.id;
      delete data.email;
    }
    const postData = Object.assign({}, data);
    if (postData.roles) {
      postData.roles = [postData.roles];
    }
    try {
      return api.updateUser(id, postData)
        .then((response) => {
          if (response.status === 200) {
            return {...response.data, isPasswordUpdate: postData.password ? true : false};
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail ?
                e.response.data.detail
                : e.response.data['hydra:description']
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const resetPassword = createAsyncThunk(
  'resetPassword',
  async(data, thunkAPI) => {
    try {
      return api.resetPassword(data)
        .then((response) => {
          if (response.status === 204) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const saveNewPassword = createAsyncThunk(
  'saveNewPassword',
  async(data, thunkAPI) => {
    try {
      return api.saveNewPassword(data.token, data.data)
        .then((response) => {
          if (response.status === 204) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getEmployees = createAsyncThunk(
  'users/getEmployees',
  async(data, thunkAPI) => {
    try {
      return api.getEmployees(UserService.getUserId())
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const softDeleteUser = createAsyncThunk(
  'users/softDeleteUser',
  async(data, thunkAPI) => {
    try {
      return api.softDeleteUser(data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const getSelectedUser = createAsyncThunk(
  'users/getSelectedUser',
  async(data, thunkAPI) => {
    try {
      return api.getUser(data)
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
        .catch((e) => {
          return thunkAPI.rejectWithValue(e.response.data.violations ?
            e.response.data.violations[0].message
            : e.response.data.message ?
              e.response.data.message
              : e.response.data.detail
          );
        });
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data.message);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    isLoginSuccess: false,
    errorMessage: "",
    user: null,
    isUploadAvatarSuccess: false,
    isUpdateSuccess: false,
    isPasswordUpdateSuccess: false,
    employees: null,
    selectedUser: null,
  },
  reducers: {
    userClearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.isLoginSuccess = false;
      state.isUploadAvatarSuccess = false;
      state.isUpdateSuccess = false;
      state.isPasswordUpdateSuccess = false;
      state.selectedUser = null;
      return state;
    },
    logoutUser: (state) => {
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
      state.isLoginSuccess = false;
      state.errorMessage = "";
      state.user = null;
      state.employees = null;
      state.selectedUser = null;
      UserService.logout();
      return state;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(registerUser.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(registerUser.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.isLoginSuccess = true;
        state.user = {
          ...state.user,
          id: payload.data.id,
          token: payload.token
        };
        return state;
      })
      .addCase(loginUser.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(loginUser.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getUser.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.user = {
          ...state.user,
          ...payload
        };

        return state;
      })
      .addCase(getUser.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getUser.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(uploadAvatar.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isUploadAvatarSuccess = true;
        state.user = payload;
        return state;
      })
      .addCase(uploadAvatar.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(uploadAvatar.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(updateUser.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isPasswordUpdateSuccess = payload.isPasswordUpdate;
        if (state.user['@id'] === payload['@id']) {
          state.isUpdateSuccess = payload.isPasswordUpdate ? false : true;
          state.user = payload;
        } else {
          state.selectedUser = payload;
        }
        return state;
      })
      .addCase(updateUser.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(updateUser.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(resetPassword.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.user = payload;
        return state;
      })
      .addCase(resetPassword.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(resetPassword.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(saveNewPassword.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.user = payload;
        return state;
      })
      .addCase(saveNewPassword.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(saveNewPassword.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getEmployees.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.employees = payload['hydra:member'];
        return state;
      })
      .addCase(getEmployees.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getEmployees.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(softDeleteUser.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(softDeleteUser.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(softDeleteUser.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
      .addCase(getSelectedUser.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.selectedUser = payload;
        return state;
      })
      .addCase(getSelectedUser.pending, (state, { payload }) => {
        state.isFetching = true;
      })
      .addCase(getSelectedUser.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      })
  },
});

export const { userClearState, logoutUser } = userSlice.actions;

export const userSelector = (state) => state.user;
