import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
  Grid,
  Divider,
  Autocomplete,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { registerUser, loginUser, getUser, userSelector, userClearState } from '../features/UserSlice';
import { createShop, shopSelector, shopClearState } from '../features/ShopSlice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as i18n from '../utils/i18n';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import validateCIF from '../utils/validateCIF';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const Register = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const shop = useSelector(shopSelector);
  const initialValues = {
    name: '',
    cif: '',
    address: '',
    country: '',
    county: '',
    city: '',
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    password: '',
  };
  const [countryCode, setCountryCode] = useState('');
  const [county, setCounty] = useState('');
  const [counties, setCounties] = useState([]);
  const [cities, setCities] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (shop.isSuccess && user.isSuccess && user.isLoginSuccess) {
      dispatch(shopClearState());
      dispatch(userClearState());
      dispatch(getUser(user.id)).then(response => {
        if (user.isSuccess) {
          navigate('/app/dashboard', { replace: true });
        }
      });
    }
    if (shop.isError) {
      toast.error(t(shop.errorMessage), {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      dispatch(shopClearState());
    }
    if (user.isError) {
      toast.error(t(user.errorMessage), {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      dispatch(userClearState());
    }
  }, [user, shop, dispatch, navigate, t]);

  useEffect(() => {
    if (countryCode) {
      i18n.counties(countryCode).then(data => {
        if (data) {
          setCounties(data);
        }
      })
    }
  }, [countryCode]);

  useEffect(() => {
    if (countryCode && county) {
      i18n.cities(countryCode, county).then(data => {
        if (data) {
          setCities(data);
        }
      })
    }
  }, [countryCode, county]);

  const handleSubmit = (data) => {
    const shopData = _.pick(data, ['name', 'cif', 'address', 'country', 'county', 'city']);
    dispatch(createShop(shopData)).then(response => {
      if (!response.error) {
        let userData = _.pick(data, ['firstName', 'lastName', 'phone', 'email', 'password']);
        userData.shop = response.payload['@id'];
        dispatch(registerUser(userData)).then((response) => {
          if (!response.error) {
            dispatch(loginUser({email: userData.email, password: userData.password}));
          }
        });
      }
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  Yup.addMethod(Yup.string, 'cif', function (errorMessage) {
    return this.test(`cif`, errorMessage, function (value) {
      const { path, createError } = this;
      return validateCIF(value) || createError({ path, message: errorMessage })
    });
  });


  return (
    <>
      <Helmet>
        <title>{t("Register")}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={initialValues}
            validationSchema={
            Yup.object().shape({
              name: Yup.string().max(255).required(t('Company name is required')),
              cif: Yup.string().required(t('CIF is required')).cif(t('CIF is invalid')),
              address: Yup.string().max(255).required(t('Company address is required')),
              country: Yup.string().required(t('Country is required')),
              county: Yup.string().required(t('County/State is required')),
              city: Yup.string().required(t('City is required')),
              email: Yup.string().email(t('Must be a valid email')).max(255).required(t('Email is required')),
              firstName: Yup.string().max(255).required(t('First name is required')),
              lastName: Yup.string().max(255).required(t('Last name is required')),
              phone: Yup.string().max(20).required(t('Phone number is required')),
              password: Yup.string().max(255).required(t('Password is required'))
            })
          }
            onSubmit={(data) => handleSubmit(data)}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    {t("Create new account")}
                  </Typography>
                </Box>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label={t("Name")}
                      margin="dense"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(touched.cif && errors.cif)}
                      fullWidth
                      helperText={touched.cif && errors.cif}
                      label={t("CIF")}
                      margin="dense"
                      name="cif"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.cif}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.address && errors.address)}
                      fullWidth
                      helperText={touched.address && errors.address}
                      label={t("Address")}
                      margin="dense"
                      name="address"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.address}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      sx={{mt: 1, mb: 0.5}}
                      freeSolo
                      id="country"
                      name="country"
                      options={i18n.countries}
                      getOptionLabel={option => option.label}
                      onChange={(e, value) => {
                        setFieldValue('country', value !== null ? value.code : initialValues.country);
                        setCountryCode(value !== null ? value.code : initialValues.country);
                      }}
                      includeInputInList
                      onOpen={handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Country")}
                          name="country"
                          error={Boolean(touched.country && errors.country)}
                          helperText={touched.country && errors.country}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      sx={{mt: 1, mb: 0.5}}
                      freeSolo
                      id="county"
                      name="county"
                      disabled={!values.country || !counties.length}
                      options={counties}
                      getOptionLabel={option => option}
                      onChange={(e, value) => {
                        setFieldValue('county', value !== null ? value : initialValues.county);
                        setCounty(value !== null ? String(value) : initialValues.county);
                      }}
                      includeInputInList
                      onOpen={handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("County")}
                          name="county"
                          error={Boolean(touched.county && errors.county)}
                          helperText={touched.county && errors.county}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      sx={{mt: 1, mb: 0.5}}
                      freeSolo
                      id="city"
                      name="city"
                      disabled={!values.county || !cities.length}
                      options={cities}
                      getOptionLabel={option => option}
                      onChange={(e, value) => setFieldValue('city', value !== null ? value : initialValues.city)}
                      includeInputInList
                      onOpen={handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("City")}
                          name="city"
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider variant="middle" sx={{mt: 1, mb: 0.5}} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(touched.firstName && errors.firstName)}
                      fullWidth
                      helperText={touched.firstName && errors.firstName}
                      label={t("First name")}
                      margin="dense"
                      name="firstName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.firstName}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(touched.lastName && errors.lastName)}
                      fullWidth
                      helperText={touched.lastName && errors.lastName}
                      label={t("Last name")}
                      margin="dense"
                      name="lastName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.lastName}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.phone && errors.phone)}
                      fullWidth
                      helperText={touched.phone && errors.phone}
                      label={t("Phone number")}
                      margin="dense"
                      name="phone"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.phone}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label={t("Email Address")}
                      margin="dense"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label={t("Password")}
                      margin="dense"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type={showPassword ? 'text' : 'password'}
                      value={values.password}
                      variant="outlined"
                      InputProps={{
                        endAdornment:
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={(e) => e.preventDefault()}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                      }}
                    />
                  </Grid>
                </Grid>
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting && user.isFetching}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {t("Sign up now")}
                  </Button>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  {t("Have an account?")}
                  {' '}
                  <Link component={RouterLink} to="/login" variant="h6" underline="hover">
                    {t("Sign in")}
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Register;
