import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Button,
} from '@material-ui/core';
import { Chip, Stack } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridToolbarContainer } from '@mui/x-data-grid';
import { getStatuses, updateStatus, softDeleteStatus, deleteStatus, adminSelector, adminClearState } from '../../../features/AdminSlice';
import moment from 'moment';
import { toast } from 'react-toastify';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RestoreIcon from '@mui/icons-material/Restore';
import EditIcon from '@mui/icons-material/Edit';
import StatusManageForm from './StatusManageForm';
import Dialog from '../../Dialog';
import Confirm from '../../../components/Confirm';
import { useTranslation } from 'react-i18next';

const Statuses = (props) => {
  const { t } = useTranslation();
  const { statuses } = useSelector(adminSelector);
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [statusId, setStatusId] = useState(null);
  const [mode, setMode] = useState('add');
  const columns = [
    { field: 'name', headerName: t('Name'), flex: 1, editable: false },
    { field: 'color', headerName: t('Color'), flex: 1, editable: false,
      renderCell: (params) => {
        return <Chip sx={{ bgcolor: params.row.color }} />
      }
    },
    { field: 'created', headerName: t('Created'), flex: 1, editable: false },
    { field: 'updated', headerName: t('Updated'), flex: 1, editable: false },
    { field: 'active', headerName: t('Active'), flex: 1, editable: false, type: 'boolean' },
    {
      field: 'actions',
      type: 'actions',
      headerName: t('Actions'),
      width: 100,
      cellClassName: 'actions',
      getActions: ({id, row}) => {
        let actions = [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={() => handleOpen('edit', id)}
            color="warning"
            aria-label="edit"
          />
        ];
        if (row.active) {
          actions.push(
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Soft Delete"
              onClick={handleSoftDeleteClick(id)}
              color="warning"
              aria-label="soft-delete"
            />
          );
        } else {
          actions.push(
            <GridActionsCellItem
              icon={<RestoreIcon />}
              label="Restore"
              onClick={handleEdit({id: id, field: 'active', value: true})}
              color="success"
              aria-label="restore"
            />
          );
        }
        actions.push(<GridActionsCellItem
          icon={<DeleteForeverIcon />}
          label="Delete"
          onClick={() => handleConfirmOpen(id, row.name)}
          color="error"
          aria-label="delete"
        />);
        return actions;
      },
    },
  ];

  useEffect(() => {
    dispatch(getStatuses());
    return () => dispatch(adminClearState());
  }, [dispatch]);

  useEffect(() => {
    if (statuses.length) {
      const mappedRows = statuses.map(status => {
        return {
          id: status.id,
          name: t(status.name),
          slug: status.slug,
          color: status.color,
          created: moment(status.created.replace('+00:00', '')).format('YYYY-MM-DD HH:mm:ss'),
          updated: moment(status.updated.replace('+00:00', '')).format('YYYY-MM-DD HH:mm:ss'),
          active: status.active,
        }
      });
      setRows(mappedRows);
    } else {
      setRows([]);
    }
    return () => dispatch(adminClearState());
  }, [statuses, dispatch, t]);

  const handleEdit = (params) => (event) => {
    event.stopPropagation();
    dispatch(updateStatus({
      id: params.id,
      [params.field]: params.value,
    })).then(response => {
      if (!response.error) {
        toast.success(t('Data updated successfully'), {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        setRows((prev) => prev.map((row) => (
          row.id === params.id ?
          { ...row,
            ...response.payload,
            created: moment(response.payload.created.replace('+00:00', '')).format('YYYY-MM-DD HH:mm:ss'),
            updated: moment(response.payload.updated.replace('+00:00', '')).format('YYYY-MM-DD HH:mm:ss'),
          }
          : row
        )));
        dispatch(adminClearState());
      } else {
        toast.error(t(response.payload), {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        setRows((prev) => [...prev]);
        dispatch(adminClearState());
      }
    });
  }

  const handleSoftDeleteClick = (id) => (event) => {
    event.stopPropagation();
    dispatch(softDeleteStatus(id)).then(response => {
      if (!response.error) {
        toast.success(t('Data disabled successfully'), {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        dispatch(getStatuses());
        dispatch(adminClearState());
      } else {
        toast.error(t(response.payload), {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        dispatch(adminClearState());
      }
    });
  };

  const handleConfirmOpen = (id, name) => {
    setConfirmOpen(true);
    setStatusId(id);
    setConfirmMessage(t('Are you sure you want to delete status:') + ' ' + t(name) + '?');
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleConfirmAction = () => {
    if (statusId) {
      dispatch(deleteStatus(statusId)).then(response => {
        if (!response.error) {
          toast.success(t('Data removed successfully'), {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          handleConfirmClose();
          dispatch(getStatuses());
          dispatch(adminClearState());
        } else {
          toast.error(t(response.payload), {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          dispatch(adminClearState());
        }
      });
    }
  }

  const handleOpen = (mode, id = null) => {
    setOpen(true);
    setMode(mode);
    setStatusId(id);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  return (
    <Card>
      <CardHeader
        title={t("Statuses")}
      />
      <Divider />
      <CardContent>
        <DataGrid
          componentsProps={{
            pagination: {
              labelRowsPerPage: t('Rows per page')
            }
          }}
          components={{
            NoRowsOverlay: () => (
              <Stack alignItems="center" justifyContent="center" sx={{pt: 15, fontSize: 16, fontWeight: 'bold'}}>
                {t("No data found")}
              </Stack>
            ),
            Toolbar: () => (
              <GridToolbarContainer sx={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}>
                <Button color="primary" startIcon={<AddIcon />} onClick={() => handleOpen('add')}>
                  {t("Add Status")}
                </Button>
              </GridToolbarContainer>
            )
          }}
          rows={rows}
          columns={columns}
          autoHeight
          disableColumnFilter
          disableColumnMenu
          disableSelectionOnClick
          density="comfortable"
        />
        <Dialog
          title={mode === 'add' ? "Add status" : "Edit status"}
          open={open}
          handleClose={handleClose}
        >
          <StatusManageForm handleClose={handleClose} mode={mode} statusId={statusId} />
        </Dialog>
        <Confirm
          open={confirmOpen}
          handleClose={handleConfirmClose}
          message={confirmMessage}
          handleConfirmAction={handleConfirmAction}
        />
      </CardContent>
    </Card>
  );
};

export default Statuses;
