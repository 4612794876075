import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Grid,
  InputAdornment,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Autocomplete,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { registerUser, getEmployees, getSelectedUser, updateUser, userSelector } from '../../features/UserSlice';
import { getShops, shopSelector } from '../../features/ShopSlice';
import Loading from '../Loading';
import Can from '../../can';
import { useTranslation } from 'react-i18next';

const UserManageForm = (props) => {
  const {handleClose, mode, userId} = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user, selectedUser } = useSelector(userSelector);
  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    roles: '',
    shop: null
  });
  const [showPassword, setShowPassword] = useState(false);
  const [validationSchema, setValidationSchema] = useState({
    email: Yup.string().email(t('Must be a valid email')).max(255).required(t('Email is required')),
    firstName: Yup.string().max(255).required(t('First name is required')),
    lastName: Yup.string().max(255).required(t('Last name is required')),
    phone: Yup.string().max(20).required(t('Phone number is required')),
    password: Yup.string().max(255).required(t('Password is required')),
    roles: Yup.string().max(255).required(t('Role is required'))
  });
  const {shops} = useSelector(shopSelector);

  useEffect(() => {
    if (mode === 'edit' && userId) {
      dispatch(getSelectedUser(userId));
    }
  }, [dispatch, mode, userId]);

  useEffect(() => {
    dispatch(getShops());
  }, [dispatch]);

  useEffect(() => {
    let initialVal = {};
    if (mode === 'edit' && selectedUser) {
      initialVal = {
        firstName: selectedUser.firstName,
        lastName: selectedUser.lastName,
        email: selectedUser.email,
        phone: selectedUser.phone,
        roles: selectedUser.roles.filter((value, index) => value !== 'ROLE_USER').shift()
      };
      if (Can('view', 'all')) {
        initialVal['shop'] = selectedUser.shop;
      }
      setValidationSchema({
        email: Yup.string().email(t('Must be a valid email')).max(255).required(t('Email is required')),
        firstName: Yup.string().max(255).required(t('First name is required')),
        lastName: Yup.string().max(255).required(t('Last name is required')),
        phone: Yup.string().max(20).required(t('Phone number is required')),
        roles: Yup.string().max(255).required(t('Role is required'))
      });
    } else {
      initialVal = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        roles: '',
      };
      if (Can('view', 'all')) {
        initialVal['shop'] = '';
      }
      setValidationSchema({
        email: Yup.string().email(t('Must be a valid email')).max(255).required(t('Email is required')),
        firstName: Yup.string().max(255).required(t('First name is required')),
        lastName: Yup.string().max(255).required(t('Last name is required')),
        phone: Yup.string().max(20).required(t('Phone number is required')),
        password: Yup.string().max(255).required(t('Password is required')),
        roles: Yup.string().max(255).required(t('Role is required'))
      });
    }
    setInitialValues(initialVal);
  }, [selectedUser, mode, t]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (data, formikData) => {
    if (mode === 'add') {
      if (Can('view', 'all')) {
        data.shop = data.shop['@id'];
      } else {
        data.shop = user.shop['@id'];
      }
      dispatch(registerUser(data)).then((response) => {
        if (!response.error) {
          handleClose();
          dispatch(getEmployees());
        }
      });
    } else {
      if (Can('view', 'all')) {
        data.shop = data.shop['@id'];
      }
      data.id = selectedUser['@id'];
      dispatch(updateUser(data)).then((response) => {
        if (!response.error) {
          handleClose();
          dispatch(getEmployees());
        }
      });
    }
    formikData.setSubmitting(false);
  };

  if (mode === 'edit' && !selectedUser) {
    return <Loading />;
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={Yup.object().shape(validationSchema)}
            onSubmit={(data, formikData) => handleSubmit(data, formikData)}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
              dirty,
              isValid
            }) => (
              <Form id={mode}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.firstName && errors.firstName)}
                      fullWidth
                      helperText={touched.firstName && errors.firstName}
                      label={t("First name")}
                      margin="dense"
                      name="firstName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.firstName}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.lastName && errors.lastName)}
                      fullWidth
                      helperText={touched.lastName && errors.lastName}
                      label={t("Last name")}
                      margin="dense"
                      name="lastName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.lastName}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.phone && errors.phone)}
                      fullWidth
                      helperText={touched.phone && errors.phone}
                      label={t("Phone number")}
                      margin="dense"
                      name="phone"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.phone}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label={t("Email Address")}
                      margin="dense"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email || ''}
                      variant="outlined"
                      disabled={mode === 'edit' ? true : false}
                    />
                  </Grid>
                  {mode === 'add' && (
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        label={t("Password")}
                        margin="dense"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type={showPassword ? 'text' : 'password'}
                        value={values.password}
                        variant="outlined"
                        InputProps={{
                          endAdornment:
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={(e) => e.preventDefault()}
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="roles">{t("Department")}</InputLabel>
                      <Select
                        labelId="roles"
                        label={t("Department")}
                        value={values.roles}
                        name="roles"
                        onChange={handleChange}
                      >
                        <MenuItem value="ROLE_BAR">{t("Bar")}</MenuItem>
                        <MenuItem value="ROLE_KITCHEN">{t("Kitchen")}</MenuItem>
                        <MenuItem value="ROLE_WAITER">{t("Waiter")}</MenuItem>
                        <MenuItem value="ROLE_MANAGER">{t("Management")}</MenuItem>
                        {Can('view', 'all') && (
                          <MenuItem value="ROLE_SHOP">{t("Owner")}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  {Can('view', 'all') && shops && (
                    <Grid item xs={12}>
                      <Autocomplete
                        fullWidth
                        sx={{mt: 1, mb: 0.5}}
                        freeSolo
                        id="shop"
                        name="shop"
                        options={shops}
                        value={values.shop}
                        getOptionLabel={option => option.name || ""}
                        onChange={(e, object) => setFieldValue('shop', object)}
                        includeInputInList
                        onOpen={handleBlur}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t("Shop")}
                            name="shop"
                            error={Boolean(touched.shop && errors.shop)}
                            helperText={touched.shop && errors.shop}
                          />
                        )}
                      />
                    </Grid>
                  )}
                </Grid>
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {t("Save")}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
}

export default UserManageForm;
