import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Grid,
  Typography,
  Divider,
  Button,
  Card,
  CardContent,
  IconButton,
  TextField,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  orderSelector,
  getProductsByParentId,
  addToCart,
  getOrder,
} from '../../features/OrderSlice';
import Loading from 'src/components/Loading';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useParams } from 'react-router';
import ClearFilteredButton from './ClearFilteredButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Badge from '@mui/material/Badge';
import { getTotalItems } from 'src/utils/cartHelper.js';
import Dialog from '../../components/Dialog';

const OrderStandProducts = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { products, cartItems, isFetching } = useSelector(orderSelector);
  const navigate = useNavigate();
  const location = useLocation();
  const { name, seats, status, statuses, parentCategory } =
    location.state || {};
  const { standId, orderId, parentId } = useParams();
  const [filteredProduct, setFilteredProduct] = useState('');
  const [activeThreeCharsProduct, setActiveThreeCharsProduct] = useState('');
  const [uniqueProducts, setUniqueProducts] = useState([]);
  const isEditingOrder = Boolean(orderId);
  const [open, setOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [comments, setComments] = useState('');

  useEffect(() => {
    if (orderId) {
      dispatch(getOrder(orderId));
    }
  }, [dispatch, orderId]);

  useEffect(() => {
    dispatch(getProductsByParentId(parentId));
  }, [dispatch, parentId]);

  useEffect(() => {
    if (products) {
      const computedUniqueProducts = products
        .reduce((acc, product) => {
          const firstThreeCharsProducts = product.name
            .slice(0, 3)
            .toLowerCase();

          if (!acc.includes(firstThreeCharsProducts)) {
            acc.push(firstThreeCharsProducts);
          }
          return acc;
        }, [])
        .toSorted();

      setUniqueProducts(computedUniqueProducts);
    }
  }, [products]);

  const handleOpen = (product) => {
    setSelectedProduct(product);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  const handleIncrementProduct = (product) => {
    dispatch(addToCart(product));
  };

  const handleBackClick = () => {
    navigate(`/app/stands/${standId}`, {
      state: { name, seats, status, statuses },
      replace: true,
    });
  };

  const handleBackToLastStepClick = () => {
    if (filteredProduct !== '') {
      setFilteredProduct('');
      setActiveThreeCharsProduct('');
    } else {
      navigate(`/app/stands/${standId}`, {
        state: { name, seats, status, statuses },
        replace: true,
      });
    }
  };

  const handleOrderItemsClick = () => {
    if (isEditingOrder) {
      navigate(`/app/stands/${standId}/orders/${orderId}`, {
        state: { name, seats, status, statuses, orderId },
        replace: true,
      });
    } else {
      navigate(`/app/stands/${standId}/checkout`, {
        state: { name, seats, status, statuses },
        replace: true,
      });
    }
  };

  const capitalizeFirstChar = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const findCartItemTotalQty = (productId) => {
    return cartItems.reduce((total, item) => {
      return item.id === productId ? total + item.qty : total;
    }, 0);
  };

  const handleCommentsChange = (event) => {
    setComments(event.target.value);
  };

  const handleSubmitComments = (event) => {
    event.preventDefault();
    const productWithComments = { ...selectedProduct, comments };
    handleIncrementProduct(productWithComments);
    setComments('');
    setOpen(false);
  };

  if (!products || isFetching) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Order Subcategories</title>
      </Helmet>

      <Grid
        container
        sx={{
          backgroundColor: '#FAFAFA',
        }}
      >
        <Grid
          container
          sx={{
            backgroundColor: '#F5F5F5',
          }}
        >
          <Grid
            item
            xs={6}
          >
            <Button
              onClick={handleBackClick}
              sx={{ color: '#949494' }}
            >
              <ArrowBackIosIcon
                sx={{ fontSize: '2.5rem', fontWeight: 'bold' }}
              />
              <Typography
                variant='h6'
                sx={{
                  color: '#949494',
                  fontSize: '1.5rem',
                  textTransform: 'capitalize',
                }}
              >
                {t('Menu')}
              </Typography>
            </Button>
          </Grid>

          <Grid
            item
            xs={6}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'right',
              paddingRight: 1,
            }}
          >
            <Typography
              variant='h6'
              sx={{ color: '#949494', fontSize: '1.5rem' }}
            >
              {t('T') + ': ' + (name || t('Default Table Name'))}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
        >
          <Divider />
        </Grid>

        <Grid
          container
          sx={{
            padding: 1,
          }}
        >
          <Grid
            container
            item
            xs={12}
            sx={{
              mb: 1,
            }}
          >
            <Grid
              item
              xs={10}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography sx={{ color: '#949494', fontSize: '20px' }}>
                {filteredProduct !== ''
                  ? `${parentCategory.name} > ${capitalizeFirstChar(
                      filteredProduct
                    )}`
                  : parentCategory.name}
              </Typography>
            </Grid>

            <Grid
              item
              xs={2}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Button
                variant='contained'
                sx={{
                  padding: 1,
                  borderRadius: '0 !important',
                  backgroundColor: '#E35131 !important',
                  width: '100%',
                }}
                onClick={handleBackToLastStepClick}
              >
                <ClearFilteredButton />
              </Button>
            </Grid>
          </Grid>

          {products && products.length === 0 ? (
            <Grid
              item
              xs={12}
              sx={{ marginBottom: '20px' }}
            >
              <Card>
                <CardContent>
                  <Typography sx={{ textAlign: 'center', fontSize: '1rem' }}>
                    {`No products for the '${parentCategory.name}' category`}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ) : null}

          <Grid
            item
            xs={10}
            sx={{
              paddingRight: 2,
            }}
          >
            {products &&
              products
                .filter(
                  (product) =>
                    filteredProduct === '' ||
                    product.name.toLowerCase().startsWith(filteredProduct)
                )
                .toSorted((a, b) => a.name.localeCompare(b.name))
                .map((product, index) => (
                  <Grid
                    key={product.name}
                    sx={{
                      width: '100%',
                      marginBottom: 1,
                      borderRadius: '0 !important',
                      backgroundColor: '#E8E8E8',
                      padding: 1,
                    }}
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        style={{
                          display: 'block',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          maxWidth: '100%',
                          fontFamily: 'Roboto',
                          fontSize: '20px',
                          fontStyle: 'normal',
                          fontWeight: '500',
                          lineHeight: 'normal',
                          color: '#949494',
                          textTransform: 'capitalize',
                        }}
                      >
                        {t(product.name)}
                      </Typography>
                    </Grid>

                    <Grid
                      container
                      item
                      xs={12}
                      sx={{
                        mt: 1,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Grid
                        item
                        xs={2}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        <Button
                          sx={{
                            display: 'flex',
                            alignItems: 'end',
                            backgroundColor: '#52B9C7',
                            '&:hover': {
                              backgroundColor: '#008B8B',
                            },
                            borderRadius: '50px',
                          }}
                          size='medium'
                          onClick={() => handleOpen(product)}
                        >
                          <AddCircleOutlineIcon
                            sx={{
                              marginRight: '10px',
                              color: 'white',
                            }}
                          />
                          <Typography
                            sx={{
                              color: 'white',
                              textTransform: 'none',
                              fontSize: 'medium',
                            }}
                          >
                            Extra
                          </Typography>
                        </Button>
                      </Grid>

                      <Grid
                        item
                        xs={8}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          color: '#949494',
                        }}
                      >
                        <Typography>
                          {findCartItemTotalQty(product.id) > 0
                            ? `${findCartItemTotalQty(product.id)}x`
                            : ''}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={2}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-end',
                        }}
                      >
                        <IconButton
                          sx={{
                            backgroundColor: '#52B9C7',
                            width: '30px',
                            height: '30px',
                            '&:hover': {
                              backgroundColor: '#008B8B',
                            },
                          }}
                          onClick={() => handleIncrementProduct(product)}
                        >
                          <Typography
                            variant='h2'
                            sx={{
                              color: 'white',
                              fontWeight: 'bold',
                            }}
                          >
                            +
                          </Typography>
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}

            {cartItems.length > 0 && (
              <Grid
                container
                alignItems='center'
                justifyContent='center'
                position={'sticky'}
                bottom='8px'
              >
                <Button
                  sx={{
                    width: '100%',
                    fontSize: '16px',
                    border: '1px solid #008B8B',
                    backgroundColor: 'rgba(82, 185, 199, 0.9)',
                    color: 'white',
                    maxWidth: '90%',
                    height: '50px',
                    '&:hover': {
                      backgroundColor: 'rgba(82, 185, 199, 0.9)',
                    },
                  }}
                  onClick={() => handleOrderItemsClick()}
                  endIcon={
                    <Badge
                      badgeContent={getTotalItems(cartItems)}
                      color='error'
                    >
                      <ShoppingCartIcon
                        sx={{
                          width: '25px',
                          height: '25px',
                        }}
                      />
                    </Badge>
                  }
                >
                  <span
                    style={{
                      display: 'block',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      textTransform: 'capitalize',
                    }}
                  >
                    {t('Order Items')}
                  </span>
                </Button>
              </Grid>
            )}
          </Grid>

          <Grid
            item
            xs={2}
            sx={{
              backgroundColor: '#F5F5F5',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginBottom: 1,
            }}
          >
            {uniqueProducts
              .filter(
                (product) =>
                  activeThreeCharsProduct === '' ||
                  product === activeThreeCharsProduct
              )
              .map((threeCharsProduct, index) => (
                <Button
                  key={threeCharsProduct}
                  variant='outlined'
                  sx={{
                    fontSize: '16px',
                    marginBottom: 1,
                    padding: 1,
                    width: '100%',
                    justifyContent: 'center',
                    borderRadius: '0 !important',
                    backgroundColor: '#E38263',
                    border: '1px solid #d8623c',
                    '&:hover': {
                      backgroundColor: '#ed9c82',
                      borderColor: '#d8623c',
                    },
                  }}
                  onClick={() => {
                    setFilteredProduct(threeCharsProduct);
                    setActiveThreeCharsProduct(threeCharsProduct);
                  }}
                >
                  <span
                    style={{
                      display: 'block',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      maxWidth: '100%',
                      color: '#f5f5f5',
                      textTransform: 'capitalize',
                    }}
                  >
                    {threeCharsProduct}
                  </span>
                </Button>
              ))}
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        title={t('Extra options for') + ' ' + (selectedProduct?.name || t('product'))}
        open={open}
        handleClose={handleClose}
        maxWidth='xs'
        fullWidth
      >
        <form onSubmit={handleSubmitComments}>
          <TextField
            label={t('Comments')}
            value={comments}
            onChange={handleCommentsChange}
            fullWidth
            margin='normal'
            variant='outlined'
            multiline
            rows={4}
          />
          <Button
            type='submit'
            variant='contained'
            color='primary'
            fullWidth
          >
            {t('Add with Comments')}
          </Button>
        </form>
      </Dialog>
    </>
  );
};

export default OrderStandProducts;
