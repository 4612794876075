import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Box, Container, Typography } from '@material-ui/core';
import { GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';
import { toast } from 'react-toastify';
import moment from 'moment';
import InventoryProductListResults from '../components/inventory/product/InventoryProductListResults';
import InventoryProductListToolbar, { StyledTitle } from '../components/inventory/product/InventoryProductListToolbar';
import { getInventory, updateInventoryProduct, softDeleteInventoryProduct, deleteInventoryProduct, shopSelector, shopClearState } from '../features/ShopSlice';
import Loading from '../../src/components/Loading';
import Can from '../../src/can';
import escapeRegExp from '../../src/utils/escapeRegExp';
import { useTranslation } from 'react-i18next';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useParams } from 'react-router';
import CustomizedDialogs from '../components/Dialog';

const InventoryProductList = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { inventory, isError, errorMessage, isFetching } = useSelector(shopSelector);
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [mode, setMode] = useState('add');
  const [inventoryProductId, setInventoryProductId] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const { inventoryId } = useParams();
  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);
  const columns = [
    { field: 'product', headerName: t('Product'), flex: 1 },
    { field: 'unit', headerName: t('Unit'), flex: 1 },
    { field: 'vat', headerName: t('Vat'), flex: 1, hide: inventory?.documentType?.name === 'Inventory' },
    { field: 'price', headerName: t('Price'), flex: 1, hide: inventory?.documentType?.name === 'Inventory' },
    { field: 'vatPrice', headerName: t('VAT Price'), flex: 1, hide: inventory?.documentType?.name === 'Inventory' },
    { field: 'documentQuantity', headerName: t('Doc. Quantity'), flex: 1 },
    { field: 'qty', headerName: t('Physical Quantity'), flex: 1 },
    { field: 'vatIncluded', headerName: t('VAT Included'), flex: 1, type: 'boolean', hide: inventory?.documentType?.name === 'Inventory' },
    { field: 'created', headerName: t('Created'), flex: 1 },
    { field: 'updated', headerName: t('Updated'), flex: 1 },
    { field: 'active', headerName: t('Active'), flex: 1, type: 'boolean' },
    { field: 'actions',
      type: 'actions',
      headerName: t('Actions'),
      cellClassName: 'actions',
      getActions: ({id, row}) => {
        let actions = [];

        if (Can('manage', 'inventoryProducts')) {
          actions.push(
            <GridActionsCellItem
            icon={<EditIcon />}
            label={t("Edit")}
            onClick={() => handleOpen('edit', row.id)}
            color="warning"
            aria-label="edit"
            />
          )
          if (row.active) {
            actions.push(
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Soft Delete"
                onClick={handleSoftDeleteClick(id)}
                color="warning"
                aria-label="soft-delete"
              />
            );
          } else {
            actions.push(
              <GridActionsCellItem
                icon={<RestoreIcon />}
                label="Restore"
                onClick={handleRestore({id: id, field: 'active', value: true})}
                color="success"
                aria-label="restore"
              />
            );
          }
          actions.push(
            <GridActionsCellItem
              icon={<DeleteForeverIcon />}
              label="Delete"
              onClick={() => {
                if (inventory.inventoryProducts.length === 1) {
                  openDeleteConfirmationDialog();
                } else {
                  handleConfirmOpen(row.id, row.product);
                }
              }}
              color="error" 
              aria-label="delete"
            />
          );
        }

        return actions;
      },
    }
  ];

  useEffect(() => {
    dispatch(getInventory(inventoryId));
  }, [dispatch, inventoryId]);

  useEffect(() => {
    if (isError) {
      toast.error(t(errorMessage), {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
    return () => {
      dispatch(shopClearState());
    }
  }, [isError, errorMessage, dispatch, t]);

  useEffect(() => {
    if (inventory && Array.isArray(inventory.inventoryProducts)) {
      setRows(mapInventoryProductsToRows(inventory.inventoryProducts));
    }
  }, [inventory]);

  const mapInventoryProductsToRows = (inventoryProducts) => {
    return inventoryProducts.map((inventoryProduct, index) => {
      return {
        id: inventoryProduct.id,
        inventoryProductId: inventoryProduct['@id'],
        product: inventoryProduct.product.name,
        unit: inventoryProduct.unit.name,
        vat: inventoryProduct.vat.name,
        price: inventoryProduct.price,
        vatPrice: inventoryProduct.vatPrice,
        documentQuantity: inventoryProduct.documentQuantity,
        qty: inventoryProduct.qty,
        vatIncluded: inventoryProduct.vatIncluded,
        created: moment(inventoryProduct.created).format("YYYY-MM-DD"),
        updated: moment(inventoryProduct.updated).format("YYYY-MM-DD"),
        active: inventoryProduct.active,
      }
    })
  }

  const requestSearch = (searchValue) => {
    if (inventory && inventory.inventoryProducts) {
      setSearchText(searchValue);
      const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
      const filteredRows = mapInventoryProductsToRows(inventory.inventoryProducts).filter((row) => {
        return Object.keys(row).some((field) => {
          if (field === 'inventoryProductId' || !row[field]) {
            return false;
          }
          return searchRegex.test(row[field].toString());
        });
      });
      setRows(filteredRows);
    }
  };

  const handleOpen = (mode, id = null) => {
    setOpen(true);
    setMode(mode);
    setInventoryProductId(id);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
    dispatch(getInventory(inventoryId));
  };

  const openAddDialog = () => {
    handleOpen('add');
  }

  const handleConfirmOpen = (id, product, edit = false) => {
    if (edit) {
      console.log('edit');
    } else {
      setConfirmOpen(true);
      setInventoryProductId(id);
      setConfirmMessage(t('Are you sure you want to delete inventory product: ') + product + '?');
    }
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleSoftDeleteClick = (inventoryProductId) => (event) => {
    event.stopPropagation();
    dispatch(softDeleteInventoryProduct(inventoryProductId)).then(response => {
      if (!response.error) {
        toast.success(t('Inventory product disabled successfully'), {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        dispatch(getInventory(inventoryId));
        dispatch(shopClearState());
      } else {
        toast.error(t(response.payload), {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        dispatch(shopClearState());
      }
    });
  };

  const handleRestore = (params) => (event) => {
    event.stopPropagation();

    dispatch(updateInventoryProduct({
      id: params.id,
      [params.field]: params.value,
    })).then(response => {
      if (!response.error) {
        toast.success(t('Inventory product restored successfully'), {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        dispatch(getInventory(inventoryId));
        setRows((prev) => prev.map((row) => (
          row.id === params.id ?
          { ...row,
            ...response.payload,
            product: response.payload.product.name,
            unit: response.payload.unit.name,
            vat: response.payload.vat.name,
            created: moment(response.payload.created).format('YYYY-MM-DD'),
            updated: moment(response.payload.updated).format("YYYY-MM-DD"),
          }
          : row
        )));
        dispatch(shopClearState());
      } else {
        toast.error(t(response.payload), {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        setRows((prev) => [...prev]);
        dispatch(shopClearState());
      }
    });
  }

  const openDeleteConfirmationDialog = () => {
    setDeleteConfirmationDialogOpen(true);
  };
  
  const closeDeleteConfirmationDialog = () => {
    setDeleteConfirmationDialogOpen(false);
  };  

  const handleConfirmAction = () => {
    if (inventoryProductId) {
      dispatch(deleteInventoryProduct(inventoryProductId)).then(response => {
        if (!response.error) {
          toast.success(t('Inventory product removed successfully'), {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          handleConfirmClose();
          dispatch(getInventory(inventoryId));
          dispatch(shopClearState());
        } else {
          toast.error(t(response.payload), {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          dispatch(shopClearState());
        }
      });
    }
  }

  if (isFetching && !inventory) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>{t("Inventory Products")}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>

          <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}
          >
            <StyledTitle>{inventory ? inventory.name : t("Inventory Products")}</StyledTitle>
            
            <InventoryProductListToolbar />
          </Box>
          
          <InventoryProductListResults
            rows={rows}
            columns={columns}
            searchText={searchText}
            requestSearch={requestSearch}
            openAddDialog={openAddDialog}
            mode={mode}
            open={open}
            handleClose={handleClose}
            inventoryProductId={inventoryProductId}
            confirmOpen={confirmOpen}
            handleConfirmClose={handleConfirmClose}
            confirmMessage={confirmMessage}
            handleConfirmAction={handleConfirmAction}
          />

          <CustomizedDialogs
            open={deleteConfirmationDialogOpen}
            handleClose={closeDeleteConfirmationDialog}
            title="Cannot delete row."
            maxWidth="xs"
            fullWidth
          >
            <Typography color="error">You must have at least one product.</Typography>
          </CustomizedDialogs>

        </Container>
      </Box>
    </>
  )
}

export default InventoryProductList;
