import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, FieldArray } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Grid,
  Autocomplete,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  updateInventory,
  shopSelector,
  getProducts,
  getProductsByShopIdForAdmin,
  getUnits,
  getVats,
  getDocumentTypes,
  customCreateInventory,
  getShop,
} from '../../features/ShopSlice';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { userSelector } from 'src/features/UserSlice';
import _ from 'lodash';
import { useParams } from 'react-router';
import UserService from 'src/services';

const InventoryManageForm = (props) => {
  const { t } = useTranslation();
  const { handleClose, mode } = props;
  const { inventory, products, units, vats, documentTypes, inventories, shop } =
    useSelector(shopSelector);
  const { user } = useSelector(userSelector);
  const { shopId } = useParams();
  const isAdmin = UserService.isAdmin();

  const shopName = isAdmin ? shop.name : user.shop.name;
  const shopNamePrefix = shopName.substr(0, 3).toUpperCase();
  const existingInventoryNumbers = inventories.map((inventory) => {
    const match = inventory.name.match(/\d+$/);
    return match ? parseInt(match[0], 10) : 0;
  });
  const maxInventoryNumber = Math.max(...existingInventoryNumbers);
  let nextInventoryNumber;
  if (isNaN(maxInventoryNumber) || maxInventoryNumber <= 0) {
    nextInventoryNumber = 1;
  } else {
    nextInventoryNumber = maxInventoryNumber + 1;
  }
  const inventoryName = `${shopNamePrefix}-${String(
    nextInventoryNumber
  ).padStart(5, '0')}`;

  const dispatch = useDispatch();
  const [selectedDocumentType, setSelectedDocumentType] = useState({
    name: null,
    id: null,
  });
  const [selectedProductIds, setSelectedProductIds] = useState([]);

  const initialValues = {
    active: true,
    name: inventoryName,
    documentType: '',
    rows: [
      {
        product: '',
        unit: '',
        vat: '',
        price: 0,
        vatPrice: 0,
        documentQuantity: '',
        qty: '',
        vatIncluded: true,
        active: true,
      },
    ],
  };

  const safeInitialValues = {
    active: initialValues.active,
    name: initialValues.name || '',
    documentType: initialValues.documentType || '',
    rows: initialValues.rows.map((row) => ({
      product: row.product || '',
      unit: row.unit || '',
      vat: row.vat || '',
      price: row.price || 0,
      vatPrice: row.vatPrice || 0,
      documentQuantity: row.documentQuantity || '',
      qty: row.qty || '',
      vatIncluded: row.vatIncluded,
      active: row.active || true,
    })),
  };

  const emptyRowValues = {
    product: '',
    unit: '',
    vat: '',
    price: 0,
    vatPrice: 0,
    documentQuantity: '',
    qty: '',
    vatIncluded: true,
    active: true,
  };

  const validationSchema = {
    documentType: Yup.string()
      .max(255)
      .required(t('Document type is required')),

    rows: Yup.array().of(
      Yup.object().shape({
        product: Yup.string().max(255).required(t('Product is required')),
        price: Yup.number()
          .typeError(t('You must specify a number'))
          .required(t('Price is required')),
        vatPrice: Yup.number()
          .typeError(t('You must specify a number'))
          .required(t('VAT price is required')),
        documentQuantity: Yup.number()
          .typeError(t('You must specify a number'))
          .required(t('Quantity is required')),
        qty: Yup.number()
          .typeError(t('You must specify a number'))
          .required(t('Quantity is required')),
      })
    ),
  };

  useEffect(() => {
    if (shopId && isAdmin) {
      dispatch(getProductsByShopIdForAdmin(shopId));
    } else {
      dispatch(getProducts());
    }
    dispatch(getUnits());
    dispatch(getVats());
    dispatch(getDocumentTypes());
    if (shopId && isAdmin) {
      dispatch(getShop(shopId));
    }
  }, [dispatch, shopId, isAdmin]);

  const handleSubmit = (data, formikData) => {
    if (mode === 'add') {
      data.shop = isAdmin ? shopId : user.shop.id;
      data.documentType = selectedDocumentType.id;
      data.rows.forEach((row, index) => {
        row.productId = selectedProductIds[index];
      });
      dispatch(customCreateInventory(data)).then((response) => {
        if (!response.error) {
          toast.success(t('Data added successfully'), {
            position: 'bottom-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          handleClose();
        } else {
          toast.error(t(response.payload), {
            position: 'bottom-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
        }
      });
    } else {
      data.id = inventory.id;
      data.documentType = inventory.documentType;
      dispatch(updateInventory(data)).then((response) => {
        if (!response.error) {
          toast.success(t('Data updated successfully'), {
            position: 'bottom-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          handleClose();
        } else {
          toast.error(t(response.payload), {
            position: 'bottom-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
        }
      });
    }
    formikData.setSubmitting(false);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <Container maxWidth='lg'>
          <Formik
            initialValues={safeInitialValues}
            enableReinitialize={true}
            validationSchema={Yup.object().shape(validationSchema)}
            onSubmit={(data, formikData) => handleSubmit(data, formikData)}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
            }) => (
              <Form id='add-inventory'>
                <Grid
                  item
                  xs={2}
                >
                  <TextField
                    fullWidth
                    label={t('Name')}
                    margin='dense'
                    name='name'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    InputProps={{
                      readOnly: true,
                    }}
                    variant='outlined'
                  />
                </Grid>

                <Grid
                  item
                  xs={2}
                  sx={{ mb: 5 }}
                >
                  <Autocomplete
                    sx={{ mt: 1, mb: 0.5 }}
                    freeSolo
                    name='documentType'
                    value={
                      values.documentType
                        ? _.find(documentTypes, { '@id': values.documentType })
                        : { '@id': '', name: '' }
                    }
                    options={documentTypes}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, value) => {
                      setFieldValue(
                        'documentType',
                        value !== null ? value['@id'] : values.documentType
                      );
                      setSelectedDocumentType(
                        value
                          ? { name: value.name, id: value.id }
                          : { name: null, id: null }
                      );
                    }}
                    includeInputInList
                    onOpen={handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('Document Type')}
                        name='documentType'
                        error={Boolean(
                          touched.documentType && errors.documentType
                        )}
                        helperText={touched.documentType && errors.documentType}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  container
                  spacing={1}
                >
                  <FieldArray name='rows'>
                    {({ remove, push }) => (
                      <>
                        <Grid
                          container
                          spacing={1}
                        >
                          <Grid
                            item
                            xs={12}
                          >
                            <Button
                              onClick={() => push(emptyRowValues)}
                              color='primary'
                              variant='contained'
                              sx={{ mb: 1.5 }}
                            >
                              + {t('Add Product')}
                            </Button>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          spacing={1}
                        >
                          {values.rows.map((row, index) => (
                            <React.Fragment key={index}>
                              <Grid
                                item
                                xs={
                                  selectedDocumentType.name === 'Inventory'
                                    ? 3
                                    : 2
                                }
                              >
                                <Autocomplete
                                  sx={{ mt: 1, mb: 0.5 }}
                                  freeSolo
                                  name={`rows[${index}].product`}
                                  value={
                                    values.rows &&
                                    values.rows[index] &&
                                    values.rows[index].product
                                      ? _.find(products, {
                                          '@id': values.rows[index].product,
                                        })
                                      : { '@id': '', name: '' }
                                  }
                                  options={products}
                                  getOptionLabel={(option) => option.name}
                                  onChange={(e, value) => {
                                    setFieldValue(
                                      `rows[${index}].product`,
                                      value !== null
                                        ? value['@id']
                                        : values.rows[index].product
                                    );

                                    if (value) {
                                      const selectedProductId = value.id;
                                      setSelectedProductIds((prevIds) => {
                                        const updatedIds = [...prevIds];
                                        updatedIds[index] = selectedProductId;
                                        return updatedIds;
                                      });

                                      const selectedProductUnitId =
                                        value.unit['@id'];
                                      const selectedProductVatId =
                                        value.vat['@id'];
                                      const selectedProductQuantity =
                                        value.quantity;

                                      const foundUnit = _.find(units, {
                                        '@id': selectedProductUnitId,
                                      });
                                      const foundVat = _.find(vats, {
                                        '@id': selectedProductVatId,
                                      });

                                      if (foundUnit) {
                                        setFieldValue(
                                          `rows[${index}].unit`,
                                          foundUnit['@id']
                                        );
                                      }
                                      if (foundVat) {
                                        setFieldValue(
                                          `rows[${index}].vat`,
                                          foundVat['@id']
                                        );
                                      }
                                      if (
                                        selectedProductQuantity != null &&
                                        selectedDocumentType.name ===
                                          'Inventory'
                                      ) {
                                        setFieldValue(
                                          `rows[${index}].documentQuantity`,
                                          selectedProductQuantity
                                        );
                                        setFieldValue(
                                          `rows[${index}].qty`,
                                          selectedProductQuantity
                                        );
                                      }
                                    }
                                  }}
                                  includeInputInList
                                  onOpen={handleBlur}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={t('Product')}
                                      name={`rows[${index}].product`}
                                      error={Boolean(
                                        touched.rows &&
                                          touched.rows[index] &&
                                          touched.rows[index].product &&
                                          errors.rows &&
                                          errors.rows[index] &&
                                          errors.rows[index].product
                                      )}
                                      helperText={
                                        touched.rows &&
                                        touched.rows[index] &&
                                        touched.rows[index].product &&
                                        errors.rows &&
                                        errors.rows[index] &&
                                        errors.rows[index].product
                                      }
                                    />
                                  )}
                                />
                              </Grid>

                              <Grid
                                item
                                xs={
                                  selectedDocumentType.name === 'Inventory'
                                    ? 3
                                    : 1
                                }
                              >
                                <TextField
                                  fullWidth
                                  label={t('Unit')}
                                  margin='dense'
                                  value={
                                    values.rows &&
                                    values.rows[index] &&
                                    values.rows[index].unit
                                      ? _.find(units, {
                                          '@id': values.rows[index].unit,
                                        }).name
                                      : ''
                                  }
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  variant='outlined'
                                />
                              </Grid>

                              {selectedDocumentType.name !== 'Inventory' && (
                                <Grid
                                  item
                                  xs={1}
                                >
                                  <TextField
                                    fullWidth
                                    label={t('Vat')}
                                    margin='dense'
                                    value={
                                      values.rows &&
                                      values.rows[index] &&
                                      values.rows[index].vat
                                        ? _.find(vats, {
                                            '@id': values.rows[index].vat,
                                          }).name
                                        : ''
                                    }
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    variant='outlined'
                                  />
                                </Grid>
                              )}

                              {selectedDocumentType.name !== 'Inventory' && (
                                <Grid
                                  item
                                  xs={1}
                                >
                                  <TextField
                                    fullWidth
                                    label={t('Price')}
                                    margin='dense'
                                    name={`rows[${index}].price`}
                                    error={Boolean(
                                      touched.rows &&
                                        touched.rows[index] &&
                                        touched.rows[index].price &&
                                        errors.rows &&
                                        errors.rows[index] &&
                                        errors.rows[index].price
                                    )}
                                    helperText={
                                      touched.rows &&
                                      touched.rows[index] &&
                                      touched.rows[index].price &&
                                      errors.rows &&
                                      errors.rows[index] &&
                                      errors.rows[index].price
                                    }
                                    onBlur={handleBlur}
                                    variant='outlined'
                                    value={values.rows[index].price}
                                    onChange={(e) => {
                                      handleChange(e);
                                      const vatValue = values.rows[index].vat
                                        ? _.find(vats, {
                                            '@id': values.rows[index].vat,
                                          }).value
                                        : 0;
                                      setFieldValue(
                                        `rows[${index}].vatPrice`,
                                        (e.target.value * vatValue) / 100
                                      );
                                    }}
                                  />
                                </Grid>
                              )}

                              {selectedDocumentType.name !== 'Inventory' && (
                                <Grid
                                  item
                                  xs={1}
                                >
                                  <TextField
                                    fullWidth
                                    label={t('VAT Price')}
                                    margin='dense'
                                    name={`rows[${index}].vatPrice`}
                                    error={Boolean(
                                      touched.rows &&
                                        touched.rows[index] &&
                                        touched.rows[index].vatPrice &&
                                        errors.rows &&
                                        errors.rows[index] &&
                                        errors.rows[index].vatPrice
                                    )}
                                    helperText={
                                      touched.rows &&
                                      touched.rows[index] &&
                                      touched.rows[index].vatPrice &&
                                      errors.rows &&
                                      errors.rows[index] &&
                                      errors.rows[index].vatPrice
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type='number'
                                    variant='outlined'
                                    value={values.rows[index].vatPrice}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </Grid>
                              )}

                              <Grid
                                item
                                xs={
                                  selectedDocumentType.name === 'Inventory'
                                    ? 3
                                    : 2
                                }
                              >
                                <TextField
                                  fullWidth
                                  label={t('Doc. Quantity')}
                                  margin='dense'
                                  name={`rows[${index}].documentQuantity`}
                                  error={Boolean(
                                    touched.rows &&
                                      touched.rows[index] &&
                                      touched.rows[index].documentQuantity &&
                                      errors.rows &&
                                      errors.rows[index] &&
                                      errors.rows[index].documentQuantity
                                  )}
                                  helperText={
                                    touched.rows &&
                                    touched.rows[index] &&
                                    touched.rows[index].documentQuantity &&
                                    errors.rows &&
                                    errors.rows[index] &&
                                    errors.rows[index].documentQuantity
                                  }
                                  onBlur={handleBlur}
                                  value={values.rows[index].documentQuantity}
                                  type='number'
                                  variant='outlined'
                                  onChange={(e) => {
                                    handleChange(e);
                                    const { value } = e.target;
                                    setFieldValue(`rows[${index}].qty`, value);
                                  }}
                                  InputProps={{
                                    readOnly:
                                      selectedDocumentType.name === 'Inventory',
                                  }}
                                />
                              </Grid>

                              <Grid
                                item
                                xs={2}
                              >
                                <TextField
                                  fullWidth
                                  label={t('Physical Quantity')}
                                  margin='dense'
                                  name={`rows[${index}].qty`}
                                  error={Boolean(
                                    touched.rows &&
                                      touched.rows[index] &&
                                      touched.rows[index].qty &&
                                      errors.rows &&
                                      errors.rows[index] &&
                                      errors.rows[index].qty
                                  )}
                                  helperText={
                                    touched.rows &&
                                    touched.rows[index] &&
                                    touched.rows[index].qty &&
                                    errors.rows &&
                                    errors.rows[index] &&
                                    errors.rows[index].qty
                                  }
                                  onBlur={handleBlur}
                                  value={values.rows[index].qty}
                                  onChange={handleChange}
                                  variant='outlined'
                                  type='number'
                                />
                              </Grid>

                              {selectedDocumentType.name !== 'Inventory' && (
                                <Grid
                                  item
                                  xs={1}
                                >
                                  <Box
                                    display='flex'
                                    alignItems='center'
                                    height='100%'
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={
                                            values.rows[index].vatIncluded
                                          }
                                          onChange={() => {
                                            const updatedRows = [
                                              ...values.rows,
                                            ];
                                            updatedRows[index].vatIncluded =
                                              !updatedRows[index].vatIncluded;
                                            setFieldValue('rows', updatedRows);
                                          }}
                                          name={`rows[${index}].vatIncluded`}
                                          color='primary'
                                        />
                                      }
                                      label={t('VAT Included')}
                                    />
                                  </Box>
                                </Grid>
                              )}

                              <Grid
                                item
                                xs={1}
                              >
                                <Box
                                  display='flex'
                                  alignItems='center'
                                  height='100%'
                                  ml={8}
                                >
                                  <GridActionsCellItem
                                    icon={
                                      <DeleteForeverIcon
                                        style={{ fontSize: 30 }}
                                      />
                                    }
                                    label='Delete'
                                    onClick={() => {
                                      if (values.rows.length > 1) {
                                        remove(index);
                                      } else {
                                        props.setErrorDialogOpen(true);
                                      }
                                    }}
                                    color='error'
                                    aria-label='delete'
                                  />
                                </Box>
                              </Grid>
                            </React.Fragment>
                          ))}
                        </Grid>
                      </>
                    )}
                  </FieldArray>
                </Grid>

                <Box sx={{ py: 2, display: 'flex', justifyContent: 'center' }}>
                  <Button
                    color='primary'
                    disabled={isSubmitting}
                    type='submit'
                    variant='contained'
                  >
                    {t('Save')}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default InventoryManageForm;
