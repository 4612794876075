import {
    Avatar,
    Card,
    CardContent,
    Grid,
    Typography
  } from '@material-ui/core';
  import { shopSelector } from '../../../features/ShopSlice';
  import { useSelector } from 'react-redux';
  import { orange } from '@material-ui/core/colors';
  import FastfoodOutlinedIcon from '@mui/icons-material/FastfoodOutlined';
  import { useNavigate } from 'react-router-dom';
  import Loading from 'src/components/Loading';
  
  const RecipesCard = ({ shop }) => {
    const navigate = useNavigate();
    const { isFetching } = useSelector(shopSelector);

    const handleCardClick = () => {
      navigate(`/app/admin/shops/${shop.id}/recipes`, { replace: false });
    };

    if (isFetching && !shop) {
      return <Loading />;
    }

    return (
      <Card
        sx={{ 
          height: '100%',
          cursor: 'pointer', 
          '&:hover': { 
            backgroundColor: 'rgba(0,0,0,0.04)', 
          } 
        }}
        onClick={handleCardClick}
      >
        <CardContent>
          <Grid
            container
            spacing={3}
            sx={{ justifyContent: 'space-between' }}
          >
            <Grid item>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="h4"
              >
                RECIPES
              </Typography>
              
              <Typography
                color="textPrimary"
                variant="h5"
                sx={{ mt: 2 }}
              >
                Total: { shop.recipes ? shop.recipes.length : 0 }
              </Typography>
            </Grid>
            <Grid item>
              <Avatar
                sx={{
                  backgroundColor: orange[600],
                  height: 56,
                  width: 56
                }}
              >
                <FastfoodOutlinedIcon />
              </Avatar>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
    
  };
  
  export default RecipesCard;
  