const Logo = (props) => (
  <img
    alt='Logo'
    src='/static/logo.png'
    style={{ width: 60, height: 60 }}
    {...props}
  />
);

export default Logo;
